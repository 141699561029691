<template>
  <div :class="['SellingAction', parentContext]">
    <div class="dropdownMenu" v-click-outside="closeMenu" @click.stop>
      <!-- Post Menu Item -->
      <div
        class="menu-item menu-link"
        @click="openMediaOverlay"
        :class="{ pressed: selectedCard === 'Post' }"
      >
        <svg class="compactImg" alt="view wishlist icon">
          <use href="@/assets/icons/iconset.svg#addedtowishlist_compact"></use>
        </svg>
        Post
      </div>
      <!-- Product Menu Item -->
      <div
        class="menu-item menu-link"
        @click="openProductOverlay"
        :class="{ pressed: selectedCard === 'Product' }"
      >
        Product
      </div>

      <!-- Service Menu Item -->
      <div
        class="menu-item menu-link"
        @click="openServiceOverlay"
        :class="{ pressed: selectedCard === 'Service' }"
      >
        Service
      </div>
    </div>

    <!-- Add Product Overlay -->
    <Overlay
      :visible="showOverlay && overlayType === 'Product'"
      :title="dynamicTitleProduct"
      :smallTextAfterTitle="'You can change this later'"
      :showBackArrow="shouldShowBackArrow"
      :currentStep="currentStep"
      @close="closeOverlay"
      @previousStep="goToPreviousStep"
    >
      <template #before-buttons>
        <AddProductForm
          ref="addProductFormRef"
          :userUnitsOrg="userUnitsOrg"
          :userCredentials="userCredentials"
          :currentStep="currentStep"
          @productAddedSuccessfully="handleProductAdded"
          @update-title="handleTitleUpdate"
          @save-request="triggerAddProduct"
          @update-canPreview="canPreview = $event"
          @update-currentStep="handleStepUpdate('product')"
        />
      </template>
      <div class="row alignCentreRow">
        <button
          @click="closeOverlay"
          v-if="!isSaveLoading"
          class="secondaryButton"
        >
          Cancel
        </button>
        <button
          v-if="currentStep !== 'preview'"
          @click="validateFormAndProceed('Product')"
        >
          Next
        </button>
        <button
          @click="saveProduct"
          v-if="user.loggedIn && currentStep === 'preview' && !isSaveLoading"
        >
          Save
        </button>
        <button v-if="isSaveLoading" disabled>Processing...</button>
      </div>
    </Overlay>

    <!-- Add Service Overlay -->
    <Overlay
      :visible="showOverlay && overlayType === 'Service'"
      :title="dynamicTitleService"
      :smallTextAfterTitle="'You can change this later'"
      :showBackArrow="shouldShowBackArrow"
      :currentStep="currentStep"
      @close="closeOverlay"
      @previousStep="goToPreviousStep"
    >
      <template #before-buttons>
        <AddServiceForm
          v-if="overlayType === 'Service'"
          ref="addServiceFormRef"
          :user="user"
          :userCredentials="userCredentials"
          :userUnitsOrg="userUnitsOrg"
          :currentStep="currentStep"
          @serviceAddedSuccessfully="handleServiceAdded"
          @update-title="handleTitleUpdate"
          @save-request="triggerAddService"
          @update-canPreview="canPreview = $event"
          @update-currentStep="handleStepUpdate('service')"
        />
      </template>
      <div class="row alignCentreRow">
        <button
          class="secondaryButton"
          @click="closeOverlay"
          v-if="!isSaveLoading"
        >
          Cancel
        </button>
        <button
          v-if="currentStep !== 'preview'"
          @click="validateFormAndProceed('Service', true)"
        >
          Next
        </button>
        <button
          @click="saveService"
          v-if="user.loggedIn && currentStep === 'preview' && !isSaveLoading"
        >
          Save
        </button>
        <button v-if="isSaveLoading" disabled>Processing...</button>
      </div>
    </Overlay>

    <!-- Add Media Overlay -->
    <Overlay
      :visible="showOverlay && overlayType === 'Media'"
      :title="mediaOverlayTitle"
      smallTextAfterTitle="Uploading image, audio or video"
      :showBackArrow="shouldShowBackArrow"
      :currentStep="currentStep"
      @close="closeOverlay"
      @previousStep="goToPreviousStep"
    >
      <template #before-buttons>
        <AddMediaForm
          ref="addMediaFormRef"
          :userCredentials="userCredentials"
          :currentStep="currentStep"
          :currentProduct="currentProduct"
          @mediaAddedSuccessfully="handleMediaAdded"
          @update-currentStep="handleStepUpdate('media')"
          @update-itemName="handleMediaTitleUpdate"
          @update-caption="handleCaptionUpdate"
          @update-canPreview="canPreview = $event"
        />
      </template>
      <div class="row alignCentreRow">
        <button
          @click="closeOverlay"
          v-if="!isSaveLoading"
          class="secondaryButton"
        >
          Cancel
        </button>
        <button
          v-if="currentStep !== 'preview'"
          @click="validateFormAndProceed('Media')"
        >
          Next
        </button>
        <button
          @click="saveMedia"
          v-if="user.loggedIn && currentStep === 'preview' && !isSaveLoading"
        >
          Save
        </button>
        <button v-if="isSaveLoading" disabled>Processing...</button>
      </div>
    </Overlay>

    <!-- Add Multimedia Overlay -->
    <Overlay
      :visible="showOverlay && overlayType === 'Multimedia'"
      title="Add Multimedia post"
      smallTextAfterTitle="Upload your P5.js sketch"
      :showBackArrow="shouldShowBackArrow"
      :currentStep="currentStep"
      @close="closeOverlay"
      @previousStep="goToPreviousStep"
    >
      <template #before-buttons>
        <AddMultimediaForm
          ref="addMultimediaFormRef"
          :user="user"
          :userUnitsOrg="userUnitsOrg"
          :currentStep="currentStep"
          @postAddedSuccessfully="handleMultimediaAdded"
          @update-title="handleTitleUpdate"
          @save-request="triggerAddMultimedia"
          @update-canPreview="canPreview = $event"
          @update-currentStep="handleStepUpdate('post')"
        />
      </template>
      <div class="row alignCentreRow">
        <button
          @click="closeOverlay"
          v-if="!isSaveLoading"
          class="secondaryButton"
        >
          Cancel
        </button>
        <button
          v-if="currentStep !== 'preview'"
          @click="validateFormAndProceed('Multimedia')"
        >
          Next
        </button>
        <button
          @click="saveMultimedia"
          v-if="user.loggedIn && currentStep === 'preview' && !isSaveLoading"
        >
          Save
        </button>
        <button v-if="isSaveLoading" disabled>Processing...</button>
      </div>
    </Overlay>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, ref, onMounted, watch } from "vue";
import { auth, db } from "@/firebase";
import { collection, getDocs } from "firebase/firestore";
import { toast } from "vue3-toastify";

import Overlay from "@/components/GenericComponents/Overlay.vue";
import AddProductForm from "@/components/DashboardComponents/EcosystemComponents/AddProductForm.vue";
import AddServiceForm from "@/components/DashboardComponents/EcosystemComponents/AddServiceForm.vue";
import AddMediaForm from "@/components/DashboardComponents/EcosystemComponents/AddMediaForm.vue";
import AddMultimediaForm from "@/components/DashboardComponents/EcosystemComponents/AddMultimediaForm.vue";
import ImageUpload from "@/components/GenericComponents/ImageUpload.vue";
import { uploadMediaFile } from "@/utils/uploadUtils";

export default {
  name: "SellingAction",
  components: {
    Overlay,
    AddProductForm,
    AddServiceForm,
    ImageUpload,
    AddMultimediaForm,
    AddMediaForm,
  },
  props: {
    userCredentials: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    userUnitsOrg: {
      type: Object,
      required: true,
    },
    userIsInAnOrg: {
      type: Boolean,
      required: true,
    },
    parentContext: {
      type: String,
      default: "Ecosystem",
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const orgs = ref([]);
    const selectedCard = ref(null);
    let triggerAddProduct;
    let triggerAddService;
    const showOverlay = ref(false);
    const overlayType = ref("");
    const currentStep = ref("initial");
    const addMediaFormRef = ref(null);
    const currentProduct = ref(null);

    const isSaveLoading = ref(false);
    const addProductFormRef = ref(null);
    const addServiceFormRef = ref(null);
    const canPreview = ref(false);
    const dynamicTitle = ref("Edit details");
    const dynamicTitleProduct = ref("Add Product");
    const dynamicTitleService = ref("Add Service");
    const mediaOverlayTitle = ref("Add Media Post");
    const currentOrgUID = computed(() => store.getters.currentOrgUID);

    const addMultimediaFormRef = ref(null);
    const triggerAddMultimedia = () => {
      if (addMultimediaFormRef.value) {
        addMultimediaFormRef.value.submitPost();
      }
    };
    const openMultimediaOverlay = () => {
      openOverlay("Multimedia"); // Ensure 'Post' correctly sets overlayType
    };

    const openMediaOverlay = (product = null) => {
      // Set the title based on whether we're editing or creating
      if (product && product.id) {
        currentProduct.value = product;
        mediaOverlayTitle.value = "Edit Post Details";
      } else {
        currentProduct.value = null;
        mediaOverlayTitle.value = "Add Media Post";
      }
      openOverlay("Media");
    };

    const closeMenu = () => {
      emit("closeSellingAction"); // Emit event to parent to close the dropdown
    };

    const selectCard = (type) => {
      if (type === "Product" || type === "Service") {
        openOverlay(type);
      }
      if (selectedCard.value === type) {
        selectedCard.value = null;
      } else {
        selectedCard.value = type;
      }
    };

    auth.onAuthStateChanged((user) => {
      store.dispatch("fetchUser", user);
    });
    auth.onAuthStateChanged((userCredentials) => {
      store.dispatch("fetchUserCredentials", userCredentials);
    });

    const user = computed(() => {
      return store.getters.user;
    });
    const userCredentials = computed(() => {
      return store.getters.userCredentials;
    });

    const validateForm = () => {
      if (
        !itemName.value ||
        !description.value ||
        !price.value ||
        !previewUrl.value
      ) {
        errorMessage.value =
          "All fields are required and a preview must be generated.";
        console.error("Validation failed:", {
          itemName: itemName.value,
          description: description.value,
          price: price.value,
          previewUrl: previewUrl.value,
        });
        return;
      }

      console.log("Validation passed. Moving to preview step.");

      gatherMultimediaDataForPreview();

      // Directly update the step
      currentStep.value = "preview";

      // Emit updates to parent component
      emit("update-canPreview", true);
      emit("update-currentStep", "preview");
    };

    const validateFormAndProceed = (type) => {
      console.log(`validateFormAndProceed called for: ${type}`);

      let isValid = false;

      if (type === "Product" && addProductFormRef.value) {
        isValid = addProductFormRef.value.handleFormValidation();
      } else if (type === "Service" && addServiceFormRef.value) {
        isValid = addServiceFormRef.value.handleFormValidation();
      } else if (type === "Media" && addMediaFormRef.value) {
        isValid = addMediaFormRef.value.handleFormValidation();
        console.log("[SellingAction] Media form validation result:", isValid);
        if (!isValid) {
          console.error("[SellingAction] Media form validation failed.");
          return;
        }
      } else if (type === "Multimedia" && addMultimediaFormRef.value) {
        console.log("Calling validateForm on addMultimediaFormRef...");
        isValid = addMultimediaFormRef.value.validateForm();

        if (!isValid) {
          console.error("Validation failed for multimedia form.");
          return;
        }

        console.log("Validation passed. Updating multimedia form step.");
      }

      if (isValid) {
        console.log(`Setting currentStep to preview for ${type}`);
        currentStep.value = "preview";
      }
    };
    const orgUid = Object.keys(props.userUnitsOrg)[0];
    const mediaLoading = computed(() => store.getters.mediaLoading);

    const handleProductAdded = () => {
      isSaveLoading.value = false;
      store.dispatch("fetchProducts", { orgUid: orgUid });
      toast.success("Product added successfully!");

      emit("itemEdited");
      closeOverlay();
    };

    const handleServiceAdded = () => {
      isSaveLoading.value = false;
      store.dispatch("fetchServices", { orgUid: orgUid });
      toast.success("Service added successfully!");
      emit("itemEdited");
      closeOverlay();
    };

    const handleMultimediaAdded = () => {
      isSaveLoading.value = false;
      store.dispatch("fetchMultimediaPosts"); // Refresh multimedia posts list
      toast.success("Multimedia post added successfully!");
      closeOverlay();
    };

    const handleTitleUpdate = (newTitle) => {
      if (overlayType.value === "Product") {
        dynamicTitleProduct.value = newTitle;
      } else if (overlayType.value === "Service") {
        dynamicTitleService.value = newTitle;
      } else if (overlayType.value === "Multimedia") {
        dynamicTitle.value = newTitle; // Make sure this updates correctly
      }
    };
    
    // Separate handler for media title to avoid changing overlay title
    const handleMediaTitleUpdate = (newTitle) => {
      // Don't update overlay title, just log for debugging
      console.log("[SellingAction] Media title updated:", newTitle);
    };
    
    const ownerId = computed(
      () =>
        props.userCredentials?.data?.uid || store.getters.user?.data.uid || null
    );
    const handleMediaAdded = async (mediaData) => {
      console.log("[SellingAction] handleMediaAdded received:", mediaData);
      // Ensure owner is defined:
      isSaveLoading.value = true;

      try {
        // Process new files (if any)
        let imageUrls = [];
        
        // Check if we have multiple files (images)
        if (mediaData.files && mediaData.files.length > 0) {
          console.log("[SellingAction] Processing multiple image files:", mediaData.files.length);
          
          // Filter out files that are already strings (URLs) to avoid upload errors
          const filesToUpload = mediaData.files.filter(file => 
            file instanceof File && !file.isExistingFile
          );
          
          // Collect existing URLs to preserve them
          const existingUrls = mediaData.files
            .filter(file => typeof file === 'string' || file.isExistingFile)
            .map(file => typeof file === 'string' ? file : file.url);
          
          console.log(`[SellingAction] Found ${filesToUpload.length} new files to upload`);
          console.log(`[SellingAction] Found ${existingUrls.length} existing URLs to preserve`);
          
          // Add existing URLs first
          imageUrls.push(...existingUrls);
          
          // Then upload new files
          if (filesToUpload.length > 0) {
            // Upload all new files in parallel
            const uploadPromises = filesToUpload.map(file => uploadMediaFile(file));
            const newUploadedUrls = await Promise.all(uploadPromises);
            imageUrls.push(...newUploadedUrls);
            
            console.log("[SellingAction] Uploaded new files:", newUploadedUrls);
          }
        }
        // Handle single file upload (backwards compatibility)
        else if (mediaData.file instanceof File && !mediaData.file.isExistingFile) {
          try {
            console.log("Uploading single file to storage:", mediaData.file);
            const uploadedUrl = await uploadMediaFile(mediaData.file);
            console.log("[SellingAction] Uploaded media file URL:", uploadedUrl);
            imageUrls.push(uploadedUrl);
          } catch (uploadError) {
            console.error("Upload error:", uploadError);
          }
        }
        // Handle existing mediaUrl if present (backwards compatibility)
        else if (mediaData.mediaUrl && !imageUrls.includes(mediaData.mediaUrl)) {
          console.log("[SellingAction] Using existing mediaUrl:", mediaData.mediaUrl);
          imageUrls.push(mediaData.mediaUrl);
        }
        
        // Filter out any empty values and remove duplicates
        imageUrls = [...new Set(imageUrls)].filter(Boolean);
        
        console.log("[SellingAction] Final image URLs:", imageUrls);

        // Ensure we have the owner
        const ownerUid = ownerId.value || mediaData.owner || store.getters.user?.data?.uid;
        
        console.log("[SellingAction] Owner UID for media post:", ownerUid);
        
        // Determine the media type - default to "image"
        const mediaType = mediaData.mediaType || "image";
        
        // Prepare the payload data
        const payloadData = {
          itemName: mediaData.itemName,
          caption: mediaData.caption,
          images: imageUrls,
          owner: ownerUid,
          organisation: currentOrgUID.value,
          mediaType: mediaType,
          type: "media",
          thumbnailIndex: 0
        };
        
        // Add ID if we're editing an existing item
        if (mediaData.id) {
          payloadData.id = mediaData.id;
        }
        
        console.log("[SellingAction] Sending payload to store:", payloadData);
        
        // Now dispatch the action with the standardized data
        let result;
        try {
          if (mediaData.id) {
            // For editing - try both namespaced and non-namespaced approaches
            try {
              await store.dispatch("mediaActions/updateMediaItem", {
                id: mediaData.id,
                collection: mediaType === "image" ? "images" : 
                           mediaType === "video" ? "videos" : "audios",
                data: payloadData
              });
            } catch (err) {
              console.log("Failed with namespaced action, trying non-namespaced:", err);
              await store.dispatch("updateMedia", {
                id: mediaData.id,
                collection: mediaType === "image" ? "images" : 
                           mediaType === "video" ? "videos" : "audios",
                data: payloadData
              });
            }
          } else {
            // For creating - try both approaches to ensure compatibility
            try {
              result = await store.dispatch("mediaActions/addMediaPost", payloadData);
            } catch (err) {
              console.log("Failed with namespaced action, trying non-namespaced:", err);
              result = await store.dispatch("addMedia", payloadData);
            }
            
            if (result && result.id) {
              console.log("Media saved with real ID:", result.id);
            }
          }
        } catch (error) {
          console.log("Error saving media:", error);
          throw error; // Re-throw to be handled by the outer catch
        }

        toast.success("Media post added successfully!");
        closeOverlay();
      } catch (error) {
        console.error("Error in handleMediaAdded:", error);
        toast.error("Failed to add media post: " + error.message);
      } finally {
        isSaveLoading.value = false;
      }
    };
    // Also implement handleCaptionUpdate similarly:
    const handleCaptionUpdate = (newCaption) => {
      // console.log("[SellingAction] Caption updated:", newCaption);
    };

    const saveMedia = async () => {
      isSaveLoading.value = true;
      if (addMediaFormRef.value) {
        // First, trigger the form's submit (which emits mediaAddedSuccessfully)
        addMediaFormRef.value.handleSubmit();
      }
    };

    const openOverlay = (type) => {
      overlayType.value = type;
      showOverlay.value = true;
      currentStep.value = "initial";
      store.dispatch("toggleNavbarVisibility", false); // Hide the Navbar
    };

    const closeOverlay = () => {
      // Reset important state variables first
      currentProduct.value = null;
      
      // Reset all refs to the currentStep before resetting forms
      currentStep.value = "initial";
      
      // Clear form data for all forms
      console.log(`Closing overlay - resetting all forms. Current overlay: ${overlayType.value}`);
      
      // ALWAYS reset the media form first (this seems to be problematic)
      if (addMediaFormRef.value) {
        console.log("🔄 Forcefully resetting media form");
        // Explicitly null out the props that might be causing issues
        try {
          addMediaFormRef.value.resetForm();
        } catch (error) {
          console.error("Error resetting media form:", error);
        }
      }
      
      // Reset other forms
      if (addServiceFormRef.value) {
        console.log("Resetting service form");
        addServiceFormRef.value.resetForm();
      }
      if (addProductFormRef.value) {
        console.log("Resetting product form");
        addProductFormRef.value.resetForm();
      }
      if (addMultimediaFormRef.value) {
        console.log("Resetting multimedia form");
        addMultimediaFormRef.value.resetForm();
      }
      
      // Update UI state
      store.dispatch("toggleNavbarVisibility", true); // Show the Navbar
      showOverlay.value = false;
      currentStep.value = "initial";
      selectedCard.value = null;
      
      // Reset overlay type (do this after handling the forms)
      overlayType.value = "";
      
      // Reset titles
      mediaOverlayTitle.value = "Add Media Post";
      dynamicTitleProduct.value = "Add Product";
      dynamicTitleService.value = "Add Service";
      
      isSaveLoading.value = false;
      
      // Final backup reset with delay just to be sure
      setTimeout(() => {
        if (addMediaFormRef.value) {
          console.log("Backup reset of media form");
          addMediaFormRef.value.resetForm();
        }
      }, 100);
    };

    const goToNextStep = () => {
      if (overlayType.value === "Product" && addProductFormRef.value) {
        if (!addProductFormRef.value.handleFormValidation()) {
          return;
        }
      } else if (overlayType.value === "Service" && addServiceFormRef.value) {
        if (!addServiceFormRef.value.handleFormValidation()) {
          return;
        }
      }

      if (overlayType.value === "Product" && currentStep.value === "initial") {
        addProductFormRef.value.gatherDataForPreview();
        currentStep.value = "preview";
      } else if (overlayType.value === "Service") {
        if (currentStep.value === "initial") {
          currentStep.value = "availability";
        } else if (currentStep.value === "availability") {
          if (
            addServiceFormRef.value.selectedDays &&
            addServiceFormRef.value.selectedDays.filter((day) => day).length ===
              0
          ) {
            addServiceFormRef.value.gatherServiceDataForPreview();
            currentStep.value = "preview";
          } else {
            currentStep.value = "customization";
          }
        } else if (currentStep.value === "customization") {
          addServiceFormRef.value.gatherServiceDataForPreview();
          currentStep.value = "preview";
        }
      }
    };

    const goToPreviousStep = () => {
      console.log(`Going to previous step from ${currentStep.value} in ${overlayType.value} form`);
      
      // Each form type has its own navigation flow
      if (overlayType.value === "Product") {
        // Product form: preview -> initial
        if (currentStep.value === "preview") {
          currentStep.value = "initial";
        }
      } else if (overlayType.value === "Service") {
        // Service form has 3 steps: initial -> availability -> customization -> preview
        if (currentStep.value === "customization") {
          currentStep.value = "availability";
        } else if (currentStep.value === "availability") {
          currentStep.value = "initial";
        } else if (currentStep.value === "preview") {
          currentStep.value = "customization"; // Go back to customization from preview
        }
      } else if (overlayType.value === "Multimedia") {
        // Multimedia form: preview -> initial
        if (currentStep.value === "preview") {
          currentStep.value = "initial";
        }
      } else if (overlayType.value === "Media") {
        // Media form only has two steps: initial and preview
        if (currentStep.value === "preview") {
          currentStep.value = "initial";
        }
      }
    };

    const shouldShowBackArrow = computed(() => {
      console.log(`Computing shouldShowBackArrow: overlay=${overlayType.value}, step=${currentStep.value}`);
      
      // All forms should show back arrow on any step except initial
      // This is consistent across all form types
      return currentStep.value !== "initial";
    });

    const saveProduct = async () => {
      isSaveLoading.value = true;
      if (addProductFormRef.value) {
        try {
          await addProductFormRef.value.addOrUpdateProduct();
          emit("itemEdited");
        } catch (error) {
          console.error("Error saving product:", error);
        } finally {
          isSaveLoading.value = false;
        }
      } else {
        console.error("Form reference is not correctly set.");
        isSaveLoading.value = false;
      }
    };

    const saveService = async () => {
      isSaveLoading.value = true;
      if (addServiceFormRef.value) {
        try {
          await addServiceFormRef.value.addOrUpdateService();
          emit("itemEdited");
        } catch (error) {
          console.error("Error saving service:", error);
        } finally {
          isSaveLoading.value = false;
        }
      } else {
        console.error("Form reference is not correctly set.");
        isSaveLoading.value = false;
      }
    };

    const saveMultimedia = async () => {
      isSaveLoading.value = true;
      if (addMultimediaFormRef.value) {
        try {
          await addMultimediaFormRef.value.submitPost();
        } catch (error) {
          console.error("Error saving multimedia post:", error);
          toast.error("Failed to save multimedia post: " + error.message);
        } finally {
          isSaveLoading.value = false;
        }
      } else {
        console.error("Multimedia form reference is not correctly set.");
        isSaveLoading.value = false;
      }
    };

    const handleStepUpdate =
      (formType) =>
      ({ form, step }) => {
        if (form === formType) {
          console.log(`Updating currentStep for ${formType}:`, step);
          // Make sure we don't accept invalid steps for Media form
          if (formType === 'media' && step !== 'initial' && step !== 'preview') {
            console.warn(`Invalid step '${step}' for Media form, defaulting to preview`);
            currentStep.value = 'preview';
          } else {
            currentStep.value = step;
          }
        }
      };

    const skipToPreview = () => {
      if (addServiceFormRef.value) {
        if (addServiceFormRef.value.customAvailability) {
          addServiceFormRef.value.customAvailability.value = [];
        }
        if (addServiceFormRef.value.selectedSlots) {
          addServiceFormRef.value.selectedSlots.value = new Array(7)
            .fill([])
            .map(() => []);
        }
      }
      currentStep.value = "preview";
    };

    onMounted(async () => {
      const querySnapshot = await getDocs(collection(db, "custom-orgs"));
      orgs.value = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    });

    watch(showOverlay, (newValue) => {
      if (newValue) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    });

    watch(overlayType, (newVal) => {
      console.log("DEBUG: overlayType changed to", newVal);
    });

    watch(showOverlay, (newVal) => {
      console.log("DEBUG: showOverlay changed to", newVal);
    });

    watch(currentStep, (newStep) => {
      console.log(`DEBUG: currentStep changed to ${newStep}`);
      console.log("Overlay Type:", overlayType.value);

      if (newStep === "preview") {
        if (overlayType.value === "Service") {
          console.log(
            "DEBUG: Calling gatherServiceDataForPreview in SellingAction.vue"
          );
          addServiceFormRef.value?.gatherServiceDataForPreview();
        } else if (overlayType.value === "Multimedia") {
          console.log(
            "DEBUG: Calling gatherMultimediaDataForPreview in SellingAction.vue"
          );
          addMultimediaFormRef.value?.gatherMultimediaDataForPreview();
        }
      }
    });

    // Open Product Overlay
    const openProductOverlay = (product = null) => {
      // Set the appropriate title based on whether we're editing or creating
      if (product && product.id) {
        currentProduct.value = product;
        dynamicTitleProduct.value = "Edit Product Details";
      } else {
        currentProduct.value = null;
        dynamicTitleProduct.value = "Add Product";
      }
      openOverlay("Product");
    };

    // Open Service Overlay
    const openServiceOverlay = (service = null) => {
      // Set the appropriate title based on whether we're editing or creating
      if (service && service.id) {
        currentProduct.value = service;
        dynamicTitleService.value = "Edit Service Details";
      } else {
        currentProduct.value = null;
        dynamicTitleService.value = "Add Service";
      }
      openOverlay("Service");
    };

    return {
      user,
      userCredentials,
      orgs,
      selectedCard,
      selectCard,
      handleProductAdded,
      handleServiceAdded,
      dynamicTitle,
      dynamicTitleProduct,
      dynamicTitleService,
      mediaOverlayTitle,
      currentStep,
      showOverlay,
      overlayType,
      openOverlay,
      closeOverlay,
      isSaveLoading,
      goToPreviousStep,
      goToNextStep,
      shouldShowBackArrow,
      handleTitleUpdate,
      handleMediaTitleUpdate,
      saveProduct,
      saveService,
      saveMultimedia,
      addProductFormRef,
      addServiceFormRef,
      triggerAddProduct,
      triggerAddService,
      validateForm,
      validateFormAndProceed,
      canPreview,
      handleStepUpdate,
      skipToPreview,
      openProductOverlay,
      openServiceOverlay,
      closeMenu,
      openMultimediaOverlay,
      handleMultimediaAdded,
      triggerAddMultimedia,
      addMultimediaFormRef,
      handleMediaAdded,
      handleCaptionUpdate,
      saveMedia,
      openMediaOverlay,
      addMediaFormRef,
      mediaLoading,
      currentProduct,
    };
  },
};
</script>

<style scoped>
.SellingAction {
  /* Positioning similar to dropdownMenu */
  position: absolute;

  background: var(--glass-bg);
  box-shadow: var(--glass-box-shadow);
  border-radius: var(--borderRadius);
  overflow: hidden;
  z-index: 2;
  padding: var(--smallerMargin);
  transition: all 0.15s ease;
  backdrop-filter: var(--glass-backdrop-filter);
  -webkit-backdrop-filter: var(--glass-backdrop-filter);
  border: var(--glass-border);
}

/* Positioning for Ecosystem */
.SellingAction.Ecosystem {
  top: calc(100% + var(--smallMargin));
  right: calc(var(--smallClickableHeight) + var(--margin) + var(--margin));
}

/* Positioning for UserProfile */
.SellingAction.UserProfile {
  top: inherit;
  margin-left: var(--smallMargin);
}

.menu-item {
  border-radius: var(--borderRadius);
  padding: var(--smallMargin);
  width: 100%;
  height: var(--clickableHeight);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.15s;
  cursor: pointer;
  border-bottom: 1px solid var(--borderColour);
  margin-bottom: var(--tinyMargin);
}

.menu-item:first-child {
  border-top: 1px solid var(--borderColour);
}

.menu-item:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.menu-item:hover {
  opacity: 0.8;
  transform: translateY(var(--transY));
}

.menu-link {
  width: 100%;
  text-align: right;
  position: relative;
}

.menu-link.pressed {
  border-bottom: none;
  box-shadow: var(--neuMorphBoxInnerSmall);
  transform: translateY(1px);
}

/* Optional: Adjust SVG sizes if necessary */
.compactImg {
  margin-right: var(--smallerMargin);
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
}

@media (min-width: 590px) {
  .SellingAction.UserProfile {
    top: var(--clickableHeight);
    right: var(--margin);
  }
}
</style>