<template>
  <div class="masonry-marketplace">
    <!-- Use the FiltersComponent here -->
    <FiltersComponent
      @update:searchQuery="searchQuery = $event"
      @update:filterType="filterType = $event"
      @update:sortOrder="sortOrder = $event"
      @update:selectedCategory="selectedCategory = $event"
      :categories="categories"
    />
    <!-- <button @click="updateCategoryNames" class="update-button">update</button> -->

    <div v-if="!hasInitialItems">👀 No products or services available</div>

    <div class="masonry-grid-container" @scroll="handleScroll">
      <div class="masonry-grid">
        <div
          v-for="(column, colIndex) in columns"
          :key="`column-${colIndex}`"
          class="masonry-column"
        >
          <transition-group name="fade" tag="div" class="masonry-column-items">
            <figure class="masonry-item" v-for="item in column" :key="item.id">
              <div class="image-container" @click="redirectToProductView(item)">
                <!-- For media items (images, videos, audio) -->
                <ImageCard
                  v-if="
                    item.mediaType === 'image' ||
                    item.type === 'media' ||
                    item.type === 'image'
                  "
                  :item="item"
                  @select="redirectToProductView"
                />

                <!-- For products/services with an images array -->
                <img
                  v-if="
                    item.images?.[item.thumbnailIndex] &&
                    item.type !== 'media' &&
                    item.mediaType !== 'image'
                  "
                  :src="item.images?.[item.thumbnailIndex]"
                  alt="item thumbnail"
                  class="itemThumbnail"
                />
                <!-- For multimedia (P5 sketch) -->
                <P5Sketch
                  v-if="item && item.type === 'multimedia' && item.p5SketchUrl"
                  :sketchUrl="item.p5SketchUrl"
                  @mounted="console.log('Loaded Sketch:', item.p5SketchUrl)"
                />

                <!-- Fallback if no image is available -->
                <div
                  class="no-image"
                  v-if="
                    !item.images?.length &&
                    !item.mediaUrl &&
                    item.type !== 'media' &&
                    item.mediaType !== 'image'
                  "
                >
                  No image available
                </div>
              </div>

              <div class="item-details">
                <div
                  class="row justifyToStartEnd alignCentreRow divMicroMarginTopNoMarginBottom"
                >
                  <div class="row alignCentreRow">
                    <small
                      ><strong>{{
                        truncatedItemName(item.itemName || item.title || "")
                      }}</strong></small
                    >
                  </div>
                  <div class="row alignCentreRow gap-small">
                    <div v-if="item.type === 'product'">
                      <small v-if="item.existingStock === 0" class="soldout"
                        >Sold</small
                      >
                      <small
                        v-else-if="item.existingStock < 10"
                        :class="{ stockWarningColor: item.existingStock < 2 }"
                      >
                        {{ item.existingStock }} in stock
                      </small>
                      <small v-else class="grayedOut">In stock</small>
                    </div>
                    <small v-if="item.type === 'service'" class="service-label"
                      >Service</small
                    >
                    <div
                      v-if="isItemOwnedByCurrentUser(item)"
                      @click="editItem(item)"
                      class="pointer"
                    >
                      <small class="gray pointer">Edit</small>
                    </div>
                  </div>
                </div>

                <div class="productActions divMicroMarginTop">
                  <div v-if="!currentUserUid">
                    <small
                      class="login-message actionText"
                      @click="toggleLoginModal"
                      >Login to see the price</small
                    >
                  </div>
                  <div
                    class="row alignCentreRow justifyToStartEnd fullWidth"
                    v-else
                  >
                    <!-- Price section (left side) -->
                    <div class="price-container">
                      <template v-if="item.type === 'product'">
                        <CentTranslatedPrice
                          :isProductOwner="isItemOwnedByCurrentUser(item)"
                          :product="item"
                          @price-clicked="handlePriceClicked"
                        >
                          <span
                            class="gray"
                            v-if="
                              !isItemOwnedByCurrentUser(item) &&
                              showOtherUserPrice
                            "
                          >
                            ≈ {{ formatNumberTo4Digits(item.price) }}
                            {{ item.coinSymbol }}
                          </span>
                        </CentTranslatedPrice>
                      </template>
                      <template v-else-if="item.type === 'service'">
                        <CentTranslatedPrice
                          v-if="isItemOwnedByCurrentUser(item)"
                          :isProductOwner="isItemOwnedByCurrentUser(item)"
                          :product="item"
                          @price-clicked="handlePriceClicked"
                        >
                          <span
                            class="gray"
                            v-if="
                              !isItemOwnedByCurrentUser(item) &&
                              showOtherUserPrice
                            "
                            >≈ {{ formatNumberTo4Digits(item.price) }}
                            {{ item.coinSymbol }}</span
                          >
                        </CentTranslatedPrice>
                        <button
                          v-else-if="
                            !isNaN(convertedPrices[item.id]?.convertedPrice)
                          "
                          @click="redirectToProductView(item)"
                          class="smallerClickableHeight"
                        >
                          <span class="nomargintopbottom row alignCentreRow">
                            <strong>
                              {{
                                formatNumberTo4Digits(
                                  convertedPrices[item.id]?.convertedPrice
                                )
                              }}
                              {{ convertedPrices[item.id]?.coinSymbol }}</strong
                            ><span
                              class="gray"
                              v-if="
                                !isItemOwnedByCurrentUser(item) &&
                                showOtherUserPrice
                              "
                            >
                              ≈ {{ formatNumberTo4Digits(item.price) }}
                              {{ item.coinSymbol }}</span
                            >

                            <svg class="compactImg" alt="book a slot icon">
                              <use
                                href="@/assets/icons/iconset.svg#calendar_compact"
                              ></use>
                            </svg>
                          </span>
                        </button>
                        <button
                          v-else
                          class="secondaryButton smallerClickableHeight"
                        >
                          <span
                            class="nomargintopbottom"
                            @click="fetchConvertedPrice(item)"
                          >
                            Refresh
                          </span>
                        </button>
                      </template>
                      <!-- Invisible spacer when no price is shown -->
                      <div v-else class="invisible-spacer"></div>
                    </div>

                    <!-- Action buttons (right side) -->
                    <div
                      v-if="!isItemOwnedByCurrentUser(item)"
                      class="action-buttons row gap-small"
                    >
                      <div class="wishlist-button">
                        <div
                          v-if="isWishlisted[item.id]"
                          @click="toggleWishlist(item)"
                          class="secondaryButton innerSticker clickable wishlist-button"
                        >
                          <svg class="compactImg">
                            <use
                              href="@/assets/icons/iconset.svg#addedtowishlist_compact"
                            ></use>
                          </svg>
                        </div>
                        <div
                          v-else
                          @click="toggleWishlist(item)"
                          class="secondaryButton innerSticker clickable wishlist-button"
                        >
                          <svg class="compactImg">
                            <use
                              href="@/assets/icons/iconset.svg#addtowishlist_compact"
                            ></use>
                          </svg>
                        </div>
                      </div>
                      <ClapButton
                        :receiverUid="item.owner"
                        :buyerOrgUID="organisationUid"
                        :receiverOrgUID="item.organisation"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </figure>
          </transition-group>
        </div>
      </div>
    </div>
    <Overlay
      :visible="showOverlay"
      :title="dynamicTitle"
      :smallTextAfterTitle="
        currentStep !== 'delete'
          ? 'You can change this later'
          : 'All information related to this item will be permanently deleted.'
      "
      :showBackArrow="shouldShowBackArrow"
      :currentStep="currentStep"
      :currentProduct="currentProduct"
      @close="closeOverlay"
      @previousStep="goToPreviousStep"
    >
      <template #before-buttons="{ product }">
        <AddProductForm
          v-if="
            !isFetching && currentProduct && currentProduct.type === 'product'
          "
          ref="addFormRef"
          :currentProduct="currentProduct"
          :userUnitsOrg="userUnitsOrg || {}"
          :currentStep="currentStep"
          @productAddedSuccessfully="handleProductAdded"
          @update-title="handleTitleUpdate"
          @update-canPreview="canPreview = $event"
        />
        <AddServiceForm
          v-if="
            !isFetching && currentProduct && currentProduct.type === 'service'
          "
          ref="addFormRef"
          :user="user || {}"
          :currentProduct="currentProduct"
          :userUnitsOrg="userUnitsOrg || {}"
          :currentStep="currentStep"
          @serviceAddedSuccessfully="handleServiceAdded"
          @update-title="handleTitleUpdate"
          @update-canPreview="canPreview = $event"
        />
        <AddMediaForm
          v-if="
            !isFetching && currentProduct && currentProduct.type === 'media'
          "
          ref="addFormRef"
          :userCredentials="user || {}"
          :currentProduct="currentProduct"
          :currentStep="currentStep"
          @mediaAddedSuccessfully="handleMediaAdded"
          @update-title="handleTitleUpdate"
          @update-canPreview="canPreview = $event"
          @update-currentStep="handleStepUpdate"
        />
        <div v-if="isFetching" class="loading-spinner">Loading...</div>
      </template>
      <div class="row alignCentreRow">
        <button
          class="secondaryButton"
          type="button"
          @click="currentStep = 'delete'"
          v-if="currentStep !== 'delete' && currentStep !== 'preview'"
        >
          <svg class="compactImg">
            <use href="@/assets/icons/iconset.svg#trash_compact"></use>
          </svg>
          Delete item
        </button>

        <button
          class="secondaryButton"
          @click="currentStep = 'initial'"
          v-if="!isSaveLoading && currentStep == 'delete'"
          v-haptic
        >
          Go back
        </button>
        <button
          type="button"
          @click="confirmDelete"
          v-if="currentStep == 'delete' && !isSaveLoading"
        >
          <svg class="compactImg">
            <use href="@/assets/icons/iconset.svg#trash_compact"></use>
          </svg>
          Yes, delete
        </button>
        <button
          class="secondaryButton"
          @click="closeOverlay"
          v-if="!isSaveLoading && currentStep !== 'delete'"
          v-haptic
        >
          Cancel
        </button>
        <button
          v-if="
            !canPreview && currentStep !== 'preview' && currentStep !== 'delete'
          "
          @click="validateForm"
        >
          Next
        </button>
        <button
          @click="goToNextStep"
          v-if="
            canPreview &&
            !isSaveLoading &&
            currentStep !== 'preview' &&
            currentStep !== 'delete'
          "
          v-haptic
        >
          Next
        </button>

        <button
          @click="saveItem"
          v-if="currentStep === 'preview' && !isSaveLoading"
          :disabled="isSaveLoading"
          v-haptic
        >
          Save
        </button>
        <button v-if="isSaveLoading" disabled>Processing...</button>
      </div>
    </Overlay>
  </div>
</template>

<script>
import {
  ref,
  computed,
  reactive,
  onMounted,
  watch,
  watchEffect,
  onBeforeUnmount,
} from "vue";
import { useStore } from "vuex";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import { db, auth } from "@/firebase";
import CentTranslatedPrice from "@/components/DashboardComponents/EcosystemComponents/ProductListComponents/CentTranslatedPrice.vue";
import ClapButton from "@/components/DashboardComponents/EcosystemComponents/ProductListComponents/ClapButton.vue";

import AvatarLightMicro from "@/components/UserComponents/AvatarLightMicro.vue";
import { useRouter } from "vue-router";
import { toast } from "vue3-toastify";
import { getConvertedPrice } from "@/utils/priceUtils";
import { formatNumberTo4Digits } from "@/utils/numberFormattingTo4digits";

import AddProductForm from "@/components/DashboardComponents/EcosystemComponents/AddProductForm.vue";
import AddServiceForm from "@/components/DashboardComponents/EcosystemComponents/AddServiceForm.vue";
import AddMediaForm from "@/components/DashboardComponents/EcosystemComponents/AddMediaForm.vue";
import Overlay from "@/components/GenericComponents/Overlay.vue";
import FiltersComponent from "@/components/DashboardComponents/EcosystemComponents/MasonryMarketplaceComponents/FiltersComponent.vue";
import P5Sketch from "@/components/DashboardComponents/EcosystemComponents/OddFormats/P5Sketch.vue"; // Ensure this path is correct
import ImageCard from "@/components/DashboardComponents/EcosystemComponents/MasonryMarketplaceComponents/ImageCard.vue";

export default {
  name: "MasonryMarketplace",
  components: {
    CentTranslatedPrice,
    AvatarLightMicro,
    AddProductForm,
    AddServiceForm,
    AddMediaForm,
    Overlay,
    FiltersComponent,
    P5Sketch,
    ImageCard,
    ClapButton,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    services: {
      type: Array,
      default: () => [],
    },
    multimediaPosts: { type: Array, default: () => [] },
    showItems: {
      type: String,
      default: "allUsers",
    },
    currentUserUid: String,
    profileUserUid: String,
    buyerOrgUID: String,
    organisationUid: String,
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const hasInitialItems = ref(false);
    const searchQuery = ref("");
    const filterType = ref("");
    const sortOrder = ref("desc");
    const itemsToLoad = ref(10);
    const currentUserUid = ref(auth.currentUser ? auth.currentUser.uid : null);
    const isWishlisted = reactive({});
    const convertedPrices = reactive({});
    const isFetching = ref(false);
    const isLoadingMoreItems = ref(false);
    const currentProduct = ref({});
    const showOverlay = ref(false);
    const currentStep = ref("initial");
    const dynamicTitle = ref("Edit product details");

    const fetchedImages = ref([]);
    const fetchImages = async () => {
      try {
        // Fetch images
        const imagesSnapshot = await getDocs(collection(db, "images"));
        const images = imagesSnapshot.docs.map((doc) => {
          const data = doc.data();

          // Create a normalized item with consistent field names
          const normalizedItem = {
            id: doc.id,
            ...data,
            type: "media", // Use 'media' as the standard type
            mediaType: "image",
          };

          // If images array exists, use it; otherwise create it from mediaUrl
          if (
            !normalizedItem.images ||
            !Array.isArray(normalizedItem.images) ||
            normalizedItem.images.length === 0
          ) {
            const imageUrl = data.mediaUrl || data.image || "";
            if (imageUrl) {
              normalizedItem.images = [imageUrl];
              // Keep mediaUrl for backward compatibility
              normalizedItem.mediaUrl = imageUrl;
            } else {
              normalizedItem.images = [];
            }
          }

          // Ensure thumbnailIndex exists
          if (normalizedItem.thumbnailIndex === undefined) {
            normalizedItem.thumbnailIndex = 0;
          }

          return normalizedItem;
        });

        // Also fetch videos if they exist in a separate collection
        try {
          const videosSnapshot = await getDocs(collection(db, "videos"));
          const videos = videosSnapshot.docs.map((doc) => {
            const data = doc.data();

            // Create a normalized item for videos
            const normalizedItem = {
              id: doc.id,
              ...data,
              type: "media",
              mediaType: "video",
            };

            // Similar normalization for videos
            if (
              !normalizedItem.images ||
              !Array.isArray(normalizedItem.images) ||
              normalizedItem.images.length === 0
            ) {
              const videoUrl = data.mediaUrl || data.videoUrl || "";
              if (videoUrl) {
                normalizedItem.images = [videoUrl];
                normalizedItem.mediaUrl = videoUrl;
              } else {
                normalizedItem.images = [];
              }
            }

            if (normalizedItem.thumbnailIndex === undefined) {
              normalizedItem.thumbnailIndex = 0;
            }

            return normalizedItem;
          });
          images.push(...videos);
        } catch (error) {
          console.log("No videos collection or error fetching videos:", error);
        }

        // Also fetch audios if they exist in a separate collection
        try {
          const audiosSnapshot = await getDocs(collection(db, "audios"));
          const audios = audiosSnapshot.docs.map((doc) => {
            const data = doc.data();

            // Create a normalized item for audios
            const normalizedItem = {
              id: doc.id,
              ...data,
              type: "media",
              mediaType: "audio",
            };

            // Similar normalization for audios
            if (
              !normalizedItem.images ||
              !Array.isArray(normalizedItem.images) ||
              normalizedItem.images.length === 0
            ) {
              const audioUrl = data.mediaUrl || data.audioUrl || "";
              if (audioUrl) {
                normalizedItem.images = [audioUrl];
                normalizedItem.mediaUrl = audioUrl;
              } else {
                normalizedItem.images = [];
              }
            }

            if (normalizedItem.thumbnailIndex === undefined) {
              normalizedItem.thumbnailIndex = 0;
            }

            return normalizedItem;
          });
          images.push(...audios);
        } catch (error) {
          console.log("No audios collection or error fetching audios:", error);
        }

        console.log("Fetched and normalized media items:", images);
        fetchedImages.value = images;
      } catch (error) {
        console.error("Error fetching media:", error);
      }
    };

    const isCurrentUserOrg = computed(() => {
      return props.organisationUid === props.currentUserUid;
    });

    const categoryMapping = {
      Baby: "Home & Living",
      "Arts and Collectibles": "Arts & Culture",
      "Toys and Games": "Sports & Outdoors",
      "Health and Beauty": "Health & Wellness",
      "Pet Supplies": "Home & Living",
      "Toys and Hobbies": "Sports & Outdoors",
      "Business and Industry": "Business & Industrial",
      "Electronics & Tech Goods": "Electronics & Technology",
      "Fashion & Accessories": "Fashion & Beauty",
      "Arts & Entertainment": "Arts & Culture",
      "Other/Specialty": "Other",
      "Healthcare and Wellness": "Health & Wellness",
      "Retail and Wholesale": "Business & Industrial",
      "Education, Training, and Coaching": "Education",
      "Hospitality, Events, and Entertainment": "Services",
      "Business and Professional": "Business & Industrial",
      "Finance and Banking": "Business & Industrial",
      "Transportation and Logistics": "Transportation",
      "Government and Security": "Services",
      "Technology and Digital": "Electronics & Technology",
      "Design, and Media": "Arts & Culture",
      "Home and Personal Services": "Services",
      "Environmental and Utilities": "Services",
      "Real Estate and Property": "Home & Living",
      "Agriculture and Fisheries": "Business & Industrial",
      "Animal Care and Services": "Services",
      "Other Services": "Services",
    };

    const organisationUid = computed(() => {
      return isCurrentUserOrg.value
        ? props.organisationUid
        : props.organisationUid;
    });
    const categories = reactive([
      {
        name: "Technology",
        type: "both", // 'product', 'service', or 'both'
        conditions: [
          "New",
          "Open Box",
          "Refurbished",
          "Used",
          "For Parts/Not Working",
        ],
      },
      {
        name: "Fashion & Beauty",
        type: "both",
        conditions: [
          "New with Tags",
          "New without Tags",
          "Used",
          "Like New",
          "Good",
          "Acceptable",
        ],
      },
      {
        name: "Home & Living",
        type: "both",
        conditions: [
          "New",
          "Used",
          "Refurbished",
          "Like New",
          "Good",
          "Acceptable",
        ],
      },
      {
        name: "Arts & Culture",
        type: "both",
        conditions: ["New", "Used", "Collectible", "Digital Goods"],
      },
      {
        name: "Business",
        type: "both",
        conditions: ["New", "Used", "Refurbished"],
      },
      {
        name: "Health & Wellness",
        type: "both",
        conditions: ["New", "Open Box", "Used", "For Parts/Not Working"],
      },
      {
        name: "Sports & Outdoors",
        type: "both",
        conditions: ["New", "Used", "Refurbished"],
      },
      {
        name: "Transportation",
        type: "both",
        conditions: ["New", "Used", "Refurbished", "For Parts/Not Working"],
      },
      {
        name: "Education",
        type: "service",
        // No conditions for services
      },
      {
        name: "Services",
        type: "service",
        // A general category for other services
      },
      {
        name: "Other",
        type: "both",
        conditions: ["New", "Used", "Refurbished"],
      },
    ]);

    const updateCategoryNames = async () => {
      // Define the mapping from old category names to new category names
      const categoryNameMapping = {
        "Electronics & Technology": "Technology",
        "Arts & Entertainment": "Arts & Culture",
        "Business & Industrial": "Business",
        "Automotive & Transportation": "Transportation",
        "Education & Training": "Education",
        // The rest remain the same
        "Fashion & Beauty": "Fashion & Beauty",
        "Home & Living": "Home & Living",
        "Health & Wellness": "Health & Wellness",
        "Sports & Outdoors": "Sports & Outdoors",
        Services: "Services",
        Other: "Other",
      };

      // Confirm the action with the user
      const confirmed = confirm(
        "This action will update the category names of all items in the database. Do you want to proceed?"
      );

      if (!confirmed) {
        return;
      }

      try {
        // Fetch all products and services
        const productsSnapshot = await getDocs(collection(db, "products"));
        const servicesSnapshot = await getDocs(collection(db, "services"));

        // Combine products and services
        const allItems = computed(() => {
          return [
            ...props.products,
            ...props.services,
            ...fetchedImages.value,
            ...props.multimediaPosts,
          ];
        });

        console.log("Multimedia Posts:", props.multimediaPosts);

        // Prepare batch updates
        const batchSize = 500; // Firestore batch limit
        let batch = writeBatch(db);
        let counter = 0;

        for (const docSnap of allItems) {
          const data = docSnap.data();
          const oldCategory = data.category;

          if (oldCategory && categoryNameMapping[oldCategory]) {
            const newCategory = categoryNameMapping[oldCategory];
            batch.update(docSnap.ref, { category: newCategory });
            counter++;

            // Commit batch if batchSize is reached
            if (counter % batchSize === 0) {
              await batch.commit();
              batch = writeBatch(db);
              console.log(`${counter} documents updated so far...`);
            }
          }
        }

        // Commit any remaining batch operations
        if (counter % batchSize !== 0) {
          await batch.commit();
        }

        alert(
          `Finished updating categories. Total documents updated: ${counter}`
        );

        // Refresh items to reflect changes
        await refreshItems();
      } catch (error) {
        console.error("Error updating categories:", error);
        alert("An error occurred while updating categories.");
      }
    };

    const refreshItems = async () => {
      // Re-fetch products and services
      await store.dispatch("fetchProducts", {
        batchSize: 10,
        refresh: true,
        orgUID: buyerOrgUID.value,
      });
      await store.dispatch("fetchServices", {
        batchSize: 10,
        refresh: true,
        orgUID: buyerOrgUID.value,
      });

      // Re-process items
      await processItems(allItems.value);
    };

    const selectedCategory = ref("");

    const buyerOrgUID = computed(() => {
      return isCurrentUserOrg.value ? props.organisationUid : props.buyerOrgUID;
    });
    const shouldShowBackArrow = computed(() => {
      // Show back arrow for any step except "initial"
      // This ensures it shows for "availability", "customization", "preview", and "delete" steps
      return currentStep.value !== "initial";
    });
    const isSaveLoading = ref(false);
    const canPreview = ref(false);

    const addFormRef = ref(null);

    const user = ref(null);
    const userUnitsOrg = ref({});

    // Column management
    const getNumberOfColumns = () => {
      const width = window.innerWidth;
      if (width < 590) return 2;
      if (width < 1080) return 3; // Set to 3 instead of 4 for medium-sized screens
      return 3; // Set to 3 for larger screens as well
    };

    const numberOfColumns = ref(getNumberOfColumns());

    const columns = ref([]);
    const processedItems = ref([]);

    const filteredItems = computed(() => {
      let items = processedItems.value;

      // Apply organization filtering
      if (store.state.UIState.showOwnOrganisationOnly) {
        items = items.filter(
          (item) => item.organisation === props.organisationUid
        );
      }

      // Apply ownership filters
      if (props.showItems === "currentUser") {
        items = items.filter((item) => item.owner === currentUserUid.value);
      } else if (props.showItems === "otherUsers") {
        items = items.filter((item) => item.owner !== currentUserUid.value);
      } else if (props.showItems === "profileUser") {
        items = items.filter((item) => item.owner === props.profileUserUid);
      }

      // Apply type filtering
      if (filterType.value) {
        items = items.filter(
          (item) => item.type === filterType.value || item.type === "multimedia"
        ); // Add multimedia filtering
      }

      // Apply search filtering
      if (searchQuery.value) {
        items = items.filter(
          (item) =>
            item.itemName
              .toLowerCase()
              .includes(searchQuery.value.toLowerCase()) ||
            item.itemDescription
              ?.toLowerCase()
              .includes(searchQuery.value.toLowerCase())
        );
      }

      // Apply category filtering
      if (selectedCategory.value) {
        items = items.filter(
          (item) => item.category === selectedCategory.value
        );
      }

      // Sort by creation date (last added first) or price as fallback
      const sorted = [...items].sort((a, b) => {
        // Check if items have createdAt timestamps
        const aTimestamp = a.createdAt?.toMillis
          ? a.createdAt.toMillis()
          : a.createdAt?.seconds
          ? a.createdAt.seconds * 1000
          : 0;
        const bTimestamp = b.createdAt?.toMillis
          ? b.createdAt.toMillis()
          : b.createdAt?.seconds
          ? b.createdAt.seconds * 1000
          : 0;

        // If both items have timestamps, sort by timestamp
        if (aTimestamp && bTimestamp) {
          if (sortOrder.value === "asc") {
            return aTimestamp - bTimestamp; // Oldest first
          } else {
            return bTimestamp - aTimestamp; // Newest first (default)
          }
        }

        // Fallback to price-based sorting if timestamps aren't available
        if (sortOrder.value === "asc") {
          return (
            (parseFloat(convertedPrices[a.id]?.convertedPrice) || 0) -
            (parseFloat(convertedPrices[b.id]?.convertedPrice) || 0)
          );
        } else {
          return (
            (parseFloat(convertedPrices[b.id]?.convertedPrice) || 0) -
            (parseFloat(convertedPrices[a.id]?.convertedPrice) || 0)
          );
        }
      });

      hasInitialItems.value = sorted.length > 0;
      return sorted.slice(0, itemsToLoad.value);
    });

    const distributeItems = () => {
      const cols = Array.from({ length: numberOfColumns.value }, () => []);
      const sorted = [...filteredItems.value].sort((a, b) => {
        // Check if items have createdAt timestamps
        const aTimestamp = a.createdAt?.toMillis
          ? a.createdAt.toMillis()
          : a.createdAt?.seconds
          ? a.createdAt.seconds * 1000
          : 0;
        const bTimestamp = b.createdAt?.toMillis
          ? b.createdAt.toMillis()
          : b.createdAt?.seconds
          ? b.createdAt.seconds * 1000
          : 0;

        // If both items have timestamps, sort by timestamp
        if (aTimestamp && bTimestamp) {
          if (sortOrder.value === "asc") {
            return aTimestamp - bTimestamp; // Oldest first
          } else {
            return bTimestamp - aTimestamp; // Newest first (default)
          }
        }

        // Fallback to price-based sorting if timestamps aren't available
        if (sortOrder.value === "asc") {
          return (
            (parseFloat(convertedPrices[a.id]?.convertedPrice) || 0) -
            (parseFloat(convertedPrices[b.id]?.convertedPrice) || 0)
          );
        } else {
          return (
            (parseFloat(convertedPrices[b.id]?.convertedPrice) || 0) -
            (parseFloat(convertedPrices[a.id]?.convertedPrice) || 0)
          );
        }
      });
      sorted.forEach((item, index) => {
        cols[index % numberOfColumns.value].push(item);
      });
      columns.value = cols;
      hasInitialItems.value = sorted.length > 0;
    };

    const handleResize = () => {
      const newColumnCount = getNumberOfColumns();
      if (newColumnCount !== numberOfColumns.value) {
        numberOfColumns.value = newColumnCount;
        distributeItems();
      }
    };
    const windowWidth = ref(window.innerWidth);

    const updateWindowWidth = () => {
      windowWidth.value = window.innerWidth;
    };

    // Watchers
    watch(
      () => [filteredItems.value, numberOfColumns.value],
      () => {
        distributeItems();
      },
      { deep: true }
    );

    watch(
      () => sortOrder.value,
      () => {
        distributeItems();
      }
    );

    onMounted(async () => {
      await fetchImages(); // Make sure we actually fetch the images

      distributeItems();
      window.addEventListener("resize", handleResize);
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", updateWindowWidth);

      if (allItems.value.length > 0) {
        processItems(allItems.value);
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateWindowWidth);
    });
    const showOtherUserPrice = computed(() => windowWidth.value > 590);

    const openOverlay = () => {
      console.log("Opening overlay...");
      currentStep.value = "initial";
      showOverlay.value = true;
      store.dispatch("toggleNavbarVisibility", false);
    };

    const goToNextStep = async () => {
      if (!addFormRef.value.handleFormValidation()) {
        return;
      }
      if (currentProduct.value.type === "product") {
        if (currentStep.value === "initial") {
          currentStep.value = "preview";
        }
      } else if (currentProduct.value.type === "service") {
        // For service form, handle each step transition
        console.log(
          `[MasonryMarketplace] Processing next step for service form, current step: ${currentStep.value}`
        );

        if (currentStep.value === "initial") {
          // Validate the form before proceeding
          if (addFormRef.value.handleFormValidation()) {
            currentStep.value = "availability";
            console.log("[MasonryMarketplace] Moving to availability step");
          }
        } else if (currentStep.value === "availability") {
          // If no days are selected, skip to preview
          if (
            addFormRef.value.selectedDays &&
            addFormRef.value.selectedDays.filter((day) => day).length === 0
          ) {
            if (addFormRef.value.handleFormValidation()) {
              await addFormRef.value.gatherServiceDataForPreview();
              currentStep.value = "preview";
              console.log(
                "[MasonryMarketplace] No days selected, moving to preview step"
              );
            }
          } else {
            // If days are selected, move to customization
            if (addFormRef.value.handleFormValidation()) {
              currentStep.value = "customization";
              console.log("[MasonryMarketplace] Moving to customization step");
            }
          }
        } else if (currentStep.value === "customization") {
          // From customization, move to preview
          if (addFormRef.value.handleFormValidation()) {
            await addFormRef.value.gatherServiceDataForPreview();
            console.log(
              "[MasonryMarketplace] Gathered service data for preview"
            );
            currentStep.value = "preview";
          }
        }
      }
    };

    const goToPreviousStep = () => {
      console.log("Going to previous step...");

      // Delete step always goes back to initial for all form types
      if (currentStep.value === "delete") {
        currentStep.value = "initial";
        return;
      }

      // Handle specific form types
      if (currentProduct.value.type === "product") {
        if (currentStep.value === "preview") {
          currentStep.value = "initial";
        }
      } else if (currentProduct.value.type === "service") {
        if (currentStep.value === "customization") {
          currentStep.value = "availability";
        } else if (currentStep.value === "availability") {
          currentStep.value = "initial";
        } else if (currentStep.value === "preview") {
          currentStep.value = "availability";
        }
      } else if (currentProduct.value.type === "media") {
        if (currentStep.value === "preview") {
          currentStep.value = "initial";
        }
      }
    };

    const closeOverlay = () => {
      console.log("Closing overlay...");
      showOverlay.value = false;
      currentStep.value = "initial";
      store.dispatch("clearProduct");
      store.dispatch("toggleNavbarVisibility", true);
    };

    const toggleLoginModal = () => {
      store.dispatch("toggleLoginModal", true);
    };

    const fetchConvertedPrice = async (item) => {
      // Fetch buyer and seller UIDs
      const buyerOrgUID =
        props.currentUserUid || store.getters.currentUserOrgUID; // Fallback to store getter if undefined
      const sellerOrgUID = item.itemOwner || item.organisation; // Ensure at least one UID is set

      // Debug logs to track UID values
      console.log("Buyer Org UID:", buyerOrgUID);
      console.log("Seller Org UID:", sellerOrgUID);
      console.log("Item details:", item);

      // Skip price conversion for media posts that don't have prices
      if (!item.price && (item.mediaType || item.fileType)) {
        // For media posts without prices, set convertedPrice to null
        convertedPrices[item.id] = { convertedPrice: null, coinSymbol: null };
        return;
      }

      // Skip if price is missing or invalid
      if (
        item.price === undefined ||
        item.price === null ||
        isNaN(item.price)
      ) {
        convertedPrices[item.id] = { convertedPrice: null, coinSymbol: null };
        return;
      }

      // Check if both UIDs are available before proceeding
      if (!buyerOrgUID || !sellerOrgUID) {
        console.error("Organization UIDs are missing for item:", item);
        convertedPrices[item.id] = { convertedPrice: null, coinSymbol: null };
        return; // Exit the function early if UIDs are missing
      }

      try {
        // Fetch the converted price from the utility function
        const { convertedPrice, coinSymbol } = await getConvertedPrice(
          item,
          sellerOrgUID,
          item.price
        );

        // Check if the converted price is valid
        if (!isNaN(convertedPrice)) {
          // Store the converted price in the reactive object
          convertedPrices[item.id] = { convertedPrice, coinSymbol };
          distributeItems(); // Re-distribute items after fetching price
        } else {
          console.warn(`Invalid price calculated for item ${item.id}`);
          convertedPrices[item.id] = { convertedPrice: null, coinSymbol: null };
        }
      } catch (error) {
        // Handle errors during the price conversion process
        console.error(
          `Failed to fetch converted price for item ${item.id}:`,
          error
        );
        convertedPrices[item.id] = { convertedPrice: null, coinSymbol: null };
      }
    };

    const fetchOwnerData = async (ownerId) => {
      // Validate ownerId before trying to fetch data
      if (!ownerId) {
        console.warn("Owner ID is missing");
        return null;
      }

      try {
        const userDoc = await getDoc(doc(db, "users", ownerId));
        if (userDoc.exists()) {
          return userDoc.data();
        }
        return null;
      } catch (error) {
        console.error(`Error fetching owner data for ID ${ownerId}:`, error);
        return null;
      }
    };

    const refreshPage = () => {
      location.reload();
    };

    const allItems = computed(() => {
      return [
        ...props.products,
        ...props.services,
        ...fetchedImages.value, // include your images here
      ];
    });

    const processItems = async (items) => {
      // Filter out invalid items (must have an ID)
      const validItems = items.filter((item) => item && item.id);

      // Filter out items we already processed
      const existingItemIds = new Set(processedItems.value.map((pi) => pi.id));
      const newItems = validItems.filter(
        (item) => !existingItemIds.has(item.id)
      );

      // Process each item with error handling
      const promises = newItems.map(async (item) => {
        try {
          const ownerData = await fetchOwnerData(item.owner);
          await fetchConvertedPrice(item);
          return { ...item, ownerData };
        } catch (error) {
          console.error(`Error processing item ${item.id}:`, error);
          // Return the item without owner data rather than failing completely
          return { ...item, ownerData: null };
        }
      });

      // Collect results and update state
      const newProcessedItems = await Promise.all(promises);
      processedItems.value = [...processedItems.value, ...newProcessedItems];
    };

    const allItemsWithOwnerData = computed(async () => {
      // Not used in this implementation, can be removed if unnecessary
      const items = allItems.value;
      const promises = items.map(async (item) => {
        const ownerData = await fetchOwnerData(item.owner);
        await fetchConvertedPrice(item);
        return { ...item, ownerData };
      });

      return await Promise.all(promises);
    });

    watchEffect(() => {
      if (allItems.value.length > 0 && props.storeIsReady) {
        processItems(allItems.value);
      }
    });

    const addOwnerDataToItems = async () => {
      const items = allItems.value;
      const promises = items.map(async (item) => {
        const ownerData = await fetchOwnerData(item.owner);
        await fetchConvertedPrice(item);
        return { ...item, ownerData };
      });

      processedItems.value = await Promise.all(promises);
    };

    const handlePriceClicked = async (eventData) => {
      console.log("EVENT DATA - priceclicked");
      console.log(eventData);
      if (!eventData.product || !eventData.product.owner) {
        console.error("Product data is missing in eventData:", eventData);
        return;
      }

      // Extract all values from eventData
      const product = eventData.product;
      const productPrice =
        eventData.productPrice !== undefined
          ? eventData.productPrice
          : product.price;
      const convertedPrice = eventData.convertedPrice;
      const sellerCoinSymbol = eventData.sellerCoinSymbol || "SellerCoinSymbol";
      const buyerCoinSymbol = eventData.buyerCoinSymbol || "BuyerCoinSymbol";

      console.log("SellerCoinSymbol in handlePriceClicked:", eventData);
      console.log("EXACT VALUES BEING USED:", {
        productPrice, // Original price in SELLER's coins
        convertedPrice, // Converted price in BUYER's coins
      });

      // Create the payload with explicit values to ensure no data is lost
      const actionPayload = {
        product,
        // Original price in SELLER's coins - what the seller will receive
        productPrice: productPrice || 0,
        // Converted price in BUYER's coins - what the buyer will pay
        convertedPrice: convertedPrice || 0,
        buyerCoinSymbol: buyerCoinSymbol || "",
        sellerCoinSymbol: sellerCoinSymbol || "",
      };

      await store.dispatch("UIState/fetchOtherUserData", actionPayload);
      await store.dispatch("UIState/setMessagesOpen", true);
      await store.dispatch("UIState/setMessagesMaximised", true);
      await store.dispatch("UIState/setShowInteractiveMessage", true);
    };

    const redirectToProductView = async (item) => {
      console.log("Redirecting to product view with item:", item);

      // For P5.js sketches, open in a new tab
      if (item.type === "multimedia" && item.p5SketchUrl) {
        window.open(item.p5SketchUrl, "_blank");
        return;
      }

      // For all other items (products, services, media), route to ProductView
      if (item.id) {
        // For media items, we need to mark them properly so ProductView can handle them
        if (
          item.mediaType === "image" ||
          item.mediaType === "video" ||
          item.mediaType === "audio"
        ) {
          // We want to store the full item data temporarily so ProductView can access it
          // This is the same approach used for products and services
          console.log("Storing media item for product view:", item);
          await store.dispatch("setCurrentMedia", item);
        }

        router.push({ name: "ProductView", params: { id: item.id } });
      } else {
        console.error("Missing item ID in redirectToProductView", item);
      }
    };

    const handleProductAdded = async (newProduct) => {
      console.log("handleProductAdded", newProduct);
      toast.success("Product edited successfully");

      isSaveLoading.value = false;

      // Refresh the products list
      await store.dispatch("fetchProducts", {
        batchSize: 10,
        refresh: true,
        orgUID: props.buyerOrgUID,
      });

      closeOverlay();
    };

    const handleServiceAdded = async (newService) => {
      console.log("handleServiceAdded", newService);
      toast.success("Service edited successfully");

      isSaveLoading.value = false;

      // Refresh the services list
      await store.dispatch("fetchServices", { batchSize: 10, refresh: true });

      closeOverlay();
    };

    const handleMediaAdded = async (newMedia) => {
      console.log("handleMediaAdded", newMedia);
      isSaveLoading.value = true;

      try {
        // Determine which collection to use based on media type
        let collectionName = "images"; // Default to images
        if (newMedia.mediaType === "video") {
          collectionName = "videos";
        } else if (newMedia.mediaType === "audio") {
          collectionName = "audios";
        }

        // If we have a file, upload it to storage
        if (newMedia.file && !newMedia.file.isExistingFile) {
          console.log("Uploading new media file:", newMedia.file);
          await store.dispatch("uploadMediaFile", {
            file: newMedia.file,
            type: newMedia.mediaType,
            owner: newMedia.owner,
            organisation: newMedia.organisation,
          });
        }

        // If we have an ID, update the existing record; otherwise, create a new one
        if (newMedia.id) {
          console.log("Updating existing media with ID:", newMedia.id);

          // Handle files to upload and existing URLs more carefully
          const existingUrls = newMedia.existingUrls || [];
          let filesToProcess = [];

          // If we have files property, these are new files to upload
          if (
            newMedia.files &&
            Array.isArray(newMedia.files) &&
            newMedia.files.length > 0
          ) {
            filesToProcess = newMedia.files;
          }

          // Upload files if needed (one at a time for now)
          const uploadedUrls = [];
          for (const file of filesToProcess) {
            if (file instanceof File) {
              try {
                console.log("Uploading file:", file.name);
                const uploadResult = await store.dispatch("uploadMediaFile", {
                  file,
                  type: newMedia.mediaType,
                  owner: newMedia.owner,
                  organisation: newMedia.organisation,
                });
                uploadedUrls.push(uploadResult);
              } catch (uploadErr) {
                console.error("Error uploading file:", uploadErr);
              }
            }
          }

          console.log("Uploaded new URLs:", uploadedUrls);
          console.log("Existing URLs to preserve:", existingUrls);

          // Combine all URLs
          const allImages = [...existingUrls, ...uploadedUrls].filter(Boolean);
          console.log("Combined images for update:", allImages);

          try {
            await store.dispatch("mediaActions/updateMediaItem", {
              id: newMedia.id,
              collection: collectionName,
              data: {
                itemName: newMedia.itemName,
                caption: newMedia.caption,
                type: "media",
                mediaType: newMedia.mediaType,
                organisation: newMedia.organisation,
                owner: newMedia.owner,
                thumbnailIndex: newMedia.thumbnailIndex || 0,
                // If updatedAt isn't already there, add it
                updatedAt: serverTimestamp(),
              },
              // Pass existingUrls as a separate parameter so they're properly handled
              existingUrls: allImages,
            });
          } catch (err) {
            console.log(
              "Failed with namespaced action, trying non-namespaced:",
              err
            );
            // Fall back to directly calling the action in the store
            await store.dispatch("updateMedia", {
              id: newMedia.id,
              collection: collectionName,
              data: {
                itemName: newMedia.itemName,
                caption: newMedia.caption,
                type: "media",
                mediaType: newMedia.mediaType,
                organisation: newMedia.organisation,
                owner: newMedia.owner,
                thumbnailIndex: newMedia.thumbnailIndex || 0,
                updatedAt: serverTimestamp(),
              },
              existingUrls: allImages,
            });
          }
          toast.success("Media updated successfully");
        } else {
          console.log("Adding new media item");

          // Handle files to upload and existing URLs
          const existingUrls = newMedia.existingUrls || [];
          let filesToProcess = [];

          // If we have files property, these are new files to upload
          if (
            newMedia.files &&
            Array.isArray(newMedia.files) &&
            newMedia.files.length > 0
          ) {
            filesToProcess = newMedia.files;
          }

          // Upload files if needed (one at a time for now)
          const uploadedUrls = [];
          for (const file of filesToProcess) {
            if (file instanceof File) {
              try {
                console.log("Uploading file:", file.name);
                const uploadResult = await store.dispatch("uploadMediaFile", {
                  file,
                  type: newMedia.mediaType,
                  owner: newMedia.owner,
                  organisation: newMedia.organisation,
                });
                uploadedUrls.push(uploadResult);
              } catch (uploadErr) {
                console.error("Error uploading file:", uploadErr);
              }
            }
          }

          console.log("Uploaded new URLs:", uploadedUrls);
          console.log("Existing URLs to preserve:", existingUrls);

          // Combine all URLs
          const allImages = [...existingUrls, ...uploadedUrls].filter(Boolean);
          console.log("Combined images for create:", allImages);

          try {
            await store.dispatch("mediaActions/addMediaPost", {
              itemName: newMedia.itemName,
              caption: newMedia.caption,
              // Use the combined images array
              images: allImages,
              type: "media",
              mediaType: newMedia.mediaType,
              owner: newMedia.owner,
              organisation: newMedia.organisation,
              thumbnailIndex: newMedia.thumbnailIndex || 0,
            });
          } catch (err) {
            console.log(
              "Failed with namespaced action, trying non-namespaced:",
              err
            );
            // Fall back to directly calling the action in the store
            await store.dispatch("addMedia", {
              itemName: newMedia.itemName,
              caption: newMedia.caption,
              images: allImages,
              type: "media",
              mediaType: newMedia.mediaType,
              owner: newMedia.owner,
              organisation: newMedia.organisation,
              thumbnailIndex: newMedia.thumbnailIndex || 0,
            });
          }
          toast.success("Media added successfully");
        }

        // Refresh images list
        await fetchImages();
      } catch (error) {
        console.error("Error saving media:", error);
        toast.error("Error saving media: " + error.message);
      } finally {
        isSaveLoading.value = false;
        closeOverlay();
      }
    };

    const handleStepUpdate = (stepData) => {
      console.log("[MasonryMarketplace] handleStepUpdate received:", stepData);

      if (stepData && stepData.step) {
        // Validate the step is appropriate for the current form
        if (
          stepData.form === "service" &&
          [
            "initial",
            "availability",
            "customization",
            "preview",
            "delete",
          ].includes(stepData.step)
        ) {
          console.log(
            `[MasonryMarketplace] Updating to service step: ${stepData.step}`
          );
          currentStep.value = stepData.step;
        } else if (
          stepData.form === "media" &&
          ["initial", "preview", "delete"].includes(stepData.step)
        ) {
          console.log(
            `[MasonryMarketplace] Updating to media step: ${stepData.step}`
          );
          currentStep.value = stepData.step;
        } else if (
          stepData.form === "product" &&
          ["initial", "preview", "delete"].includes(stepData.step)
        ) {
          console.log(
            `[MasonryMarketplace] Updating to product step: ${stepData.step}`
          );
          currentStep.value = stepData.step;
        } else {
          console.log(`[MasonryMarketplace] Setting step to: ${stepData.step}`);
          currentStep.value = stepData.step;
        }
      }
    };

    const handleTitleUpdate = (newTitle) => {
      dynamicTitle.value = newTitle;
    };

    const validateForm = async () => {
      console.log(
        "[MasonryMarketplace] validateForm called, currentStep:",
        currentStep.value
      );

      if (!addFormRef.value) {
        console.log("addFormRef is null");
        return;
      }

      // Call the form validation and get the result
      const isValid = await addFormRef.value.handleFormValidation();

      if (isValid) {
        // If validation passes, manually proceed to the next step based on current form type
        if (currentProduct.value.type === "service") {
          if (currentStep.value === "initial") {
            currentStep.value = "availability";
          } else if (currentStep.value === "availability") {
            // Check if days are selected
            if (
              addFormRef.value.selectedDays &&
              addFormRef.value.selectedDays.filter((day) => day).length === 0
            ) {
              // If no days selected, skip to preview
              await addFormRef.value.gatherServiceDataForPreview();
              currentStep.value = "preview";
            } else {
              // If days are selected, go to customization
              currentStep.value = "customization";
            }
          } else if (currentStep.value === "customization") {
            await addFormRef.value.gatherServiceDataForPreview();
            currentStep.value = "preview";
          }
        } else if (currentProduct.value.type === "product") {
          // For product, go straight to preview
          currentStep.value = "preview";
        } else if (currentProduct.value.type === "media") {
          // For media, go straight to preview
          currentStep.value = "preview";
        }
      }
    };

    const saveItem = async () => {
      isSaveLoading.value = true;
      try {
        if (addFormRef.value) {
          if (currentProduct.value.type === "product") {
            await addFormRef.value.addOrUpdateProduct();
          } else if (currentProduct.value.type === "service") {
            await addFormRef.value.addOrUpdateService();
          } else if (currentProduct.value.type === "media") {
            await addFormRef.value.handleSubmit();
          }
          emit("itemEdited");
        } else {
          console.error("Form reference is not correctly set.");
        }
      } catch (error) {
        console.error("Error in saveItem:", error);
      } finally {
        isSaveLoading.value = false;
      }
    };

    const deleteSubcollection = async (
      parentCollection,
      parentId,
      subcollection
    ) => {
      const subcollectionRef = collection(
        db,
        parentCollection,
        parentId,
        subcollection
      );
      const snapshot = await getDocs(subcollectionRef);

      if (snapshot.empty) {
        console.log(`No documents found in ${subcollection} subcollection.`);
        return;
      }

      const batch = writeBatch(db);
      snapshot.forEach((docSnap) => {
        batch.delete(docSnap.ref);
      });

      await batch.commit();
      console.log(
        `Deleted all documents in ${subcollection} subcollection for ${parentCollection}/${parentId}.`
      );
    };

    const confirmDelete = async () => {
      console.log("Confirming delete of item:", currentProduct.value);
      try {
        isSaveLoading.value = true; // Start loading indicator

        if (!currentProduct.value || !currentProduct.value.id) {
          throw new Error("No item ID found.");
        }

        // Determine the correct collection based on the item type
        let parentCollection;
        let refreshAction;

        if (currentProduct.value.type === "product") {
          parentCollection = "products";
          refreshAction = async () => {
            await store.dispatch("fetchProducts", {
              batchSize: 10,
              refresh: true,
              orgUID: props.buyerOrgUID,
            });
          };
        } else if (currentProduct.value.type === "service") {
          parentCollection = "services";
          refreshAction = async () => {
            await store.dispatch("fetchServices", {
              batchSize: 10,
              refresh: true,
              orgUID: props.buyerOrgUID,
            });
          };
        } else if (currentProduct.value.type === "media") {
          // For media items, check the mediaType to determine the collection
          const mediaType = currentProduct.value.mediaType;
          if (mediaType === "image") {
            parentCollection = "images";
          } else if (mediaType === "video") {
            parentCollection = "videos";
          } else if (mediaType === "audio") {
            parentCollection = "audios";
          } else {
            parentCollection = "images"; // Default to images if no specific type
          }

          refreshAction = async () => {
            await fetchImages(); // Refresh all media items
          };
        } else {
          throw new Error(`Unknown item type: ${currentProduct.value.type}`);
        }

        const parentId = currentProduct.value.id;
        console.log(
          `Deleting item from collection: ${parentCollection} with ID: ${parentId}`
        );

        // Delete the subcollection first if it's a service
        if (currentProduct.value.type === "service") {
          await deleteSubcollection(parentCollection, parentId, "slots");
        }

        // Then delete the main document
        await deleteDoc(doc(db, parentCollection, parentId));

        toast.success(
          `${
            currentProduct.value.type === "media"
              ? "Media"
              : currentProduct.value.type
          } deleted successfully.`
        );

        // Refresh the relevant list
        await refreshAction();

        emit("itemEdited");
        closeOverlay();
      } catch (error) {
        toast.error(
          `Failed to delete ${currentProduct.value.type || "item"}: ${
            error.message
          }`
        );
        console.error(`Deletion Error:`, error);
      } finally {
        isSaveLoading.value = false; // End loading indicator
      }
    };

    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const docHeight = document.documentElement.scrollHeight;

      const scrollThreshold = 100;

      if (scrollTop + windowHeight + scrollThreshold >= docHeight) {
        console.log("Near bottom, fetching more items");
        itemsToLoad.value += 10;
        fetchMoreItems();
      }
    };

    const fetchMoreItems = async () => {
      if (isLoadingMoreItems.value) {
        return; // Prevent duplicate fetches
      }
      isLoadingMoreItems.value = true;
      console.log("Fetching more items...");

      try {
        const lastVisibleProduct =
          store.state.productActions.lastVisibleProduct;
        const lastVisibleService =
          store.state.serviceActions.lastVisibleService;

        if (!lastVisibleProduct && !lastVisibleService) {
          console.log("No more items to fetch.");
          return;
        }

        await store.dispatch("fetchProducts", {
          batchSize: 10,
          orgUID: props.buyerOrgUID,
        });
        await store.dispatch("fetchServices", {
          batchSize: 10,
          orgUID: props.buyerOrgUID,
        });

        await processItems(allItems.value); // Process and append new items
      } catch (error) {
        console.error("Error fetching more items:", error);
      } finally {
        isLoadingMoreItems.value = false;
        console.log("Finished fetching more items.");
      }
    };

    const toggleWishlist = (item) => {
      if (isWishlisted[item.id]) {
        removeFromWishlist(item);
      } else {
        addToWishlist(item);
      }
    };

    const addToWishlist = async (item) => {
      if (currentUserUid.value) {
        try {
          const wishlistDocRef = doc(
            db,
            "users",
            currentUserUid.value,
            "Wishlist",
            item.id
          );
          await setDoc(wishlistDocRef, {
            ...item,
            createdAt: serverTimestamp(),
          });
          isWishlisted[item.id] = true;
          emit("wishlistUpdated");
          toast.success("Added to wishlist.", { autoClose: 3000 });
        } catch (error) {
          console.error("Error adding to wishlist: ", error);
          toast.error("Error adding to wishlist.", { autoClose: 3000 });
        }
      } else {
        toast.error("You must be logged in to add items to wishlist.", {
          autoClose: 3000,
        });
      }
    };

    const removeFromWishlist = async (item) => {
      if (currentUserUid.value) {
        try {
          const wishlistDocRef = doc(
            db,
            "users",
            currentUserUid.value,
            "Wishlist",
            item.id
          );
          await deleteDoc(wishlistDocRef);
          isWishlisted[item.id] = false;
          emit("wishlistUpdated");
          toast.success("Removed from wishlist.", { autoClose: 3000 });
        } catch (error) {
          isWishlisted[item.id] = true;
          console.error("Error removing from wishlist: ", error);
          toast.error("Error removing from wishlist.", { autoClose: 3000 });
        }
      } else {
        toast.error(
          "You must be logged in to remove items from the wishlist.",
          {
            autoClose: 3000,
          }
        );
      }
    };

    const truncatedItemName = (itemName) => {
      if (itemName && itemName.length > 16) {
        const firstPart = itemName.substring(0, 8).trim();
        const lastPart = itemName.substring(itemName.length - 3).trim();
        return `${firstPart}(…)${lastPart}`;
      }
      return itemName;
    };

    const isItemOwnedByCurrentUser = (item) => {
      return item.owner === currentUserUid.value;
    };

    const editItem = async (item) => {
      isFetching.value = true;
      console.log("Editing item:", item);
      try {
        if (item.type === "service") {
          await store.dispatch("fetchService", item.id);
          currentProduct.value = { ...item, type: "service" };
          // Set appropriate title for service edit
          dynamicTitle.value = "Edit Service Details";
        } else if (item.type === "product") {
          await store.dispatch("fetchProduct", item.id);
          currentProduct.value = { ...item, type: "product" };
          // Set appropriate title for product edit
          dynamicTitle.value = "Edit Product Details";
        } else if (
          item.mediaType === "image" ||
          item.mediaType === "video" ||
          item.mediaType === "audio" ||
          item.type === "image" ||
          item.type === "media"
        ) {
          // For media items like images, videos, or audio
          console.log("Setting media item for editing:", item);

          // Clone the item to avoid modifying the original
          const mediaItem = { ...item };

          // Ensure images array is properly formatted
          if (mediaItem.images && !Array.isArray(mediaItem.images)) {
            // Convert to array if it's not already
            mediaItem.images = [mediaItem.images];
            console.log(
              "Converted non-array images to array:",
              mediaItem.images
            );
          } else if (
            !mediaItem.images ||
            (Array.isArray(mediaItem.images) && mediaItem.images.length === 0)
          ) {
            // Initialize empty array if missing
            mediaItem.images = [];

            // If we have mediaUrl but no images array, add it to images array
            if (mediaItem.mediaUrl) {
              mediaItem.images.push(mediaItem.mediaUrl);
              console.log("Added mediaUrl to images array:", mediaItem.images);
            } else if (mediaItem.image) {
              mediaItem.images.push(mediaItem.image);
              console.log("Added image to images array:", mediaItem.images);
            }
          }

          // Filter out any blob URLs from the images array
          if (Array.isArray(mediaItem.images)) {
            mediaItem.images = mediaItem.images.filter((url) => {
              if (typeof url === "string" && url.startsWith("blob:")) {
                console.log("Removing blob URL from images array:", url);
                return false;
              }
              return true;
            });
            console.log("Filtered images array:", mediaItem.images);
          }

          // For backward compatibility, set mediaUrl field if needed
          if (Array.isArray(mediaItem.images) && mediaItem.images.length > 0) {
            mediaItem.mediaUrl =
              mediaItem.images[mediaItem.thumbnailIndex || 0] ||
              mediaItem.images[0];
          }

          // Make sure we have the correct media type field
          if (!mediaItem.mediaType) {
            // Try to infer mediaType from properties
            if (
              mediaItem.mediaUrl?.includes(".mp4") ||
              mediaItem.mediaUrl?.includes("video")
            ) {
              mediaItem.mediaType = "video";
            } else if (
              mediaItem.mediaUrl?.includes(".mp3") ||
              mediaItem.mediaUrl?.includes("audio")
            ) {
              mediaItem.mediaType = "audio";
            } else {
              mediaItem.mediaType = "image"; // Default to image
            }
          }

          // Check for different title field naming
          if (mediaItem.title && !mediaItem.itemName) {
            mediaItem.itemName = mediaItem.title;
          }

          // Check for different description field naming
          if (mediaItem.description && !mediaItem.caption) {
            mediaItem.caption = mediaItem.description;
          } else if (mediaItem.itemDescription && !mediaItem.caption) {
            mediaItem.caption = mediaItem.itemDescription;
          }

          console.log("Prepared media item for editing:", mediaItem);
          currentProduct.value = { ...mediaItem, type: "media" };
          // Set appropriate title for media edit
          dynamicTitle.value = "Edit Media Post";
        }
        openOverlay();
      } catch (error) {
        console.error(`Failed to fetch ${item.type || item.mediaType}:`, error);
      } finally {
        isFetching.value = false;
      }
    };

    return {
      hasInitialItems,
      searchQuery,
      filterType,
      sortOrder,
      itemsToLoad,
      filteredItems,
      handleScroll,
      toggleWishlist,
      isWishlisted,
      truncatedItemName,
      isItemOwnedByCurrentUser,
      handlePriceClicked,
      editItem,
      redirectToProductView,
      currentUserUid,
      convertedPrices,
      formatNumberTo4Digits,
      isFetching,
      currentProduct,
      showOverlay,
      currentStep,
      dynamicTitle,
      openOverlay,
      closeOverlay,
      shouldShowBackArrow,
      goToPreviousStep,
      goToNextStep,
      isSaveLoading,
      canPreview,
      addFormRef,
      user,
      userUnitsOrg,
      handleProductAdded,
      handleServiceAdded,
      handleMediaAdded,
      handleStepUpdate,
      handleTitleUpdate,
      validateForm,
      saveItem,
      confirmDelete,
      toggleLoginModal,
      isLoadingMoreItems,
      refreshPage,
      columns, // Exposed for template
      selectedCategory,
      categories,
      updateCategoryNames,
      showOtherUserPrice,
      categoryMapping,
    };
  },
};
</script>

<style scoped>
.masonry-grid-container {
  height: calc(100vh - (var(--clickabelHeight) + var(--bigMargin)));
  overflow-y: auto;
  margin-bottom: calc((var(--biggerMargin) * 3 + var(--margin)) * -1);
}

.masonry-marketplace {
  width: 100%;
  padding-bottom: calc(var(--smallClickableHeight) * 2);
}

.filters {
  display: flex;
  margin-bottom: var(--smallMargin);
  gap: var(--smallMargin);
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.search-container input {
  width: 100%;
  padding: var(--smallerMargin);
  padding-left: calc(var(--smallClickableHeight) * 0.9);
  border-radius: var(--borderRadius);
}

.search-container .search-icon {
  position: absolute;
  left: var(--smallerMargin);
  pointer-events: none; /* Allow clicks to pass through to the input */
  display: flex;
  align-items: center;
  opacity: 0.6;
}

.masonry-grid {
  display: flex;
  gap: var(--smallMargin);
}

.masonry-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--smallerMargin);
}

.masonry-item {
  background: var(--backgroundColourOrg);
  border-radius: var(--borderRadius);
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative; /* Ensure the item details are positioned correctly */
}

figure.masonry-item {
  margin: 0;
  margin-bottom: var(--smallerMargin);
}

.masonry-item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: var(--borderRadius);
  overflow: hidden;
  background: var(--solidMidLightgrey);
}

.no-image {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--solidMidLightgrey);
  border-radius: var(--borderRadius);
  overflow: hidden;
}
.grayedOut,
.soldout,
.service-label {
  color: var(--solidMidgrey);
}

.image-container {
  position: relative;
}

.top-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height for more gradient */
  background: linear-gradient(
    to bottom,
    var(--backgroundColourOrgTransp) 10%,
    transparent
  );
  z-index: 1;
}

.user-avatar-small {
  top: var(--tinyMargin);
  left: var(--tinyMargin);
  z-index: 2; /* Ensure the avatar is above the gradient */
}

.item-details {
  top: 0px;
  bottom: 0;
  width: 100%;
  background: var(--backgroundColourOrgTransp);
  padding-bottom: var(--tinyMargin);
  box-sizing: border-box;
  z-index: 2;
}

.row {
  display: flex;
  justify-content: space-between;
}

.productActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.stockWarningColor {
  color: var(--red);
}

.soldout {
  color: var(--solidMidgrey);
}

.actionsRow {
  align-items: center;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.price-container {
  flex: 1;
  min-width: 0;
}

.invisible-spacer {
  width: 1px;
  height: 1px;
  flex: 1;
}

.action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--smallerMargin);
  flex: 2;
}

.innerSticker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--smallerClickableHeight);
  height: var(--smallerClickableHeight);
  border-radius: 50%;
  background-color: var(--backgroundColourOrg);
  padding: var(--tinyMargin);
  cursor: pointer;
}

.wishlist-button {
  border: transparent !important;
  padding: 0px 0 0px 0px !important;
}

.ownedByCurrentUser .innerSticker {
  width: fit-content;
  border-radius: var(--borderRadiusButton);
  gap: var(--tinyMargin);
  padding: 0 var(--smallMargin);
}

/* Remove column-count-based styles */

.masonry-grid {
  /* Previously used column-count is removed */
}

.masonry-column {
  /* Flex-based columns */
}

/* Responsive column layout */
@media (max-width: 590px) {
  /* Update based on manual column count */
}

@media (min-width: 591px) and (max-width: 1080px) {
  /* Update based on manual column count */
}

@media (min-width: 1081px) {
  /* Update based on manual column count */
}
</style>
