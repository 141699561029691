// utils/exchangeRateCalculator.js

/**
 * Calculates the price in money sender's coins based on a price in money receiver's coins.
 * Used when the receiver (seller) defines the price and we need to convert it to sender's (buyer's) currency.
 *
 * @param {number} senderMembersCount - Number of members in the sender's organization
 * @param {number} receiverMembersCount - Number of members in the receiver's organization
 * @param {number} priceInReceiverCoins - Price in receiver's coins
 * @param {number} senderTotalCoins - Total coins in sender's organization
 * @param {number} receiverTotalCoins - Total coins in receiver's organization
 * @returns {number} - Price in sender's coins
 */
export function calculatePrice(
  senderMembersCount,
  receiverMembersCount,
  priceInReceiverCoins,
  senderTotalCoins,
  receiverTotalCoins
) {
  // Handle potential division by zero
  if (!senderMembersCount || !receiverMembersCount) {
    console.error("Member count cannot be zero");
    return 0;
  }

  // Handle zero or negative values in total coins
  if (senderTotalCoins <= 0 || receiverTotalCoins <= 0) {
    console.error("Total coins must be greater than zero");
    return 0;
  }

  // Calculate per capita values with high precision
  const senderPerCapita = senderTotalCoins / senderMembersCount;
  const receiverPerCapita = receiverTotalCoins / receiverMembersCount;

  // Use precision scaling similar to Solidity implementation
  const PRECISION_FACTOR = 1e18;
  
  // Calculate conversion rate similar to how it's done in Solidity
  // But maintain the same formula logic from the original JS implementation
  const conversionRate = (senderPerCapita * PRECISION_FACTOR) / receiverPerCapita;
  
  // Apply the conversion rate and scale back
  const priceInSenderCoins = (priceInReceiverCoins * conversionRate) / PRECISION_FACTOR;
  
  return priceInSenderCoins;
}

/**
 * Calculates the amount in receiver's coins based on an amount in sender's coins.
 * Used when the sender defines the amount and we need to convert it to receiver's currency.
 *
 * @param {number} senderMembersCount - Number of members in the sender's organization
 * @param {number} receiverMembersCount - Number of members in the receiver's organization
 * @param {number} amountInSenderCoins - Amount in sender's coins
 * @param {number} senderTotalCoins - Total coins in sender's organization
 * @param {number} receiverTotalCoins - Total coins in receiver's organization
 * @returns {Object} - Object containing the amount in receiver's coins and the conversion rate
 */
export function calculateAmountForReceiver(
  senderMembersCount,
  receiverMembersCount,
  amountInSenderCoins,
  senderTotalCoins,
  receiverTotalCoins
) {
  console.log(`senderMembersCount: ${senderMembersCount}`);
  console.log(`receiverMembersCount: ${receiverMembersCount}`);
  console.log(`amountInSenderCoins: ${amountInSenderCoins}`);
  console.log(`senderTotalCoins: ${senderTotalCoins}`);
  console.log(`receiverTotalCoins: ${receiverTotalCoins}`);

  // Handle potential division by zero
  if (!senderMembersCount || !receiverMembersCount) {
    console.error("Member count cannot be zero");
    return { amountInReceiverCoins: 0, conversionRate: 0 };
  }

  // Handle zero or negative values in total coins
  if (senderTotalCoins <= 0 || receiverTotalCoins <= 0) {
    console.error("Total coins must be greater than zero");
    return { amountInReceiverCoins: 0, conversionRate: 0 };
  }

  const senderPerCapita = senderTotalCoins / senderMembersCount;
  const receiverPerCapita = receiverTotalCoins / receiverMembersCount;

  console.log(`Sender Per Capita: ${senderPerCapita}`);
  console.log(`Receiver Per Capita: ${receiverPerCapita}`);

  // Use precision scaling similar to Solidity implementation
  const PRECISION_FACTOR = 1e18;
  
  // Calculate conversion rate with improved precision
  const conversionRate = (receiverPerCapita * PRECISION_FACTOR) / senderPerCapita;
  console.log(`Conversion Rate: ${conversionRate / PRECISION_FACTOR}`);

  // Apply the conversion rate and scale back
  const amountInReceiverCoins = (amountInSenderCoins * conversionRate) / PRECISION_FACTOR;
  console.log(`Amount in Receiver's Coins: ${amountInReceiverCoins}`);

  return {
    amountInReceiverCoins,
    conversionRate: conversionRate / PRECISION_FACTOR,
  };
}

// Maintain backward compatibility with the old function name
export const calculateReversePrice = calculateAmountForReceiver;
