<template>
  <div class="add-multimedia-form">
    <form @submit.prevent="handleSubmit">
      <div v-if="currentStep === 'initial'">
        <div
          class="drop-area column alignCentre"
          @click="triggerFileInput"
          @dragover.prevent
          @dragenter.prevent="handleDragEnter"
          @dragleave.prevent="handleDragLeave"
          @drop.prevent="handleDrop"
        >
          <svg class="smaller">
            <use href="@/assets/icons/iconset.svg#upload"></use>
          </svg>
          <div class="alignCentre">
            <p class="nomargintopbottom">
              Drag your P5.js file here or click to upload
            </p>
          </div>
        </div>
        <input
          type="file"
          id="p5File"
          accept=".js"
          ref="fileInput"
          style="display: none"
          @change="handleFileUpload"
          required
        />
        <div class="fullWidth">
          <label for="title">Title</label>
          <input
            type="text"
            id="title"
            class="fullWidth alignCentre"
            v-model="title"
            required
            @input="updateParentTitle"
          />
        </div>
        <div class="fullWidth">
          <label for="description">Description</label>
          <textarea
            id="description"
            class="fullWidth"
            v-model="description"
            required
            @input="updateParentDescription"
          ></textarea>
        </div>
        <div class="fullWidth">
          <label for="price">Price</label>
          <input
            type="number"
            id="price"
            class="fullWidth"
            v-model="price"
            required
            min="0"
          />
        </div>
      </div>

      <div v-if="currentStep === 'preview'" class="preview-container">
        <h3>Preview</h3>
        {{ previewUrl }}
        <P5Sketch v-if="previewUrl" :sketchUrl="previewUrl" />
      </div>

      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    </form>
  </div>
</template>

<script>
import { ref, watch, computed } from "vue";
import { db, auth } from "@/firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { uploadP5File } from "@/utils/uploadUtils";
import P5Sketch from "@/components/DashboardComponents/EcosystemComponents/OddFormats/P5Sketch.vue";
import { useStore } from "vuex";

export default {
  name: "AddMultimediaForm",
  components: { P5Sketch },
  props: {
    currentStep: {
      type: String,
      required: true,
    },
    userUnitsOrg: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const title = ref("");
    const description = ref("");
    const price = ref(0);
    const previewUrl = ref(null);
    const isUploading = ref(false);
    const errorMessage = ref(null);
    let uploadedFile = null;
    const fileInput = ref(null);

    // Computed property to get the organisation UID
    const organisationUID = computed(() => {
      const keys = Object.keys(props.userUnitsOrg);
      if (keys.length > 0) {
        return keys[0];
      }
      return null;
    });

    const triggerFileInput = () => {
      fileInput.value.click();
    };

    const validateFile = (file) => {
      if (!file) {
        errorMessage.value = "No file selected. Please choose a P5.js file.";
        return false;
      }
      if (!file.name.endsWith(".js")) {
        errorMessage.value = "Invalid file type. Please upload a .js file.";
        return false;
      }
      return true;
    };

    const handleFileUpload = (event) => {
      errorMessage.value = null;
      const file = event.target.files[0];
      if (!validateFile(file)) return;

      uploadedFile = file;
      previewUrl.value = URL.createObjectURL(file);
    };

    const handleDrop = (event) => {
      event.preventDefault();
      errorMessage.value = null;
      const file = event.dataTransfer.files[0];
      if (!validateFile(file)) return;

      uploadedFile = file;
      previewUrl.value = URL.createObjectURL(file);
    };

    const updateParentTitle = () => {
      emit("update-title", title.value);
    };

    const updateParentDescription = () => {
      emit("update-description", description.value);
    };

    const gatherMultimediaDataForPreview = () => {
      console.log("gatherMultimediaDataForPreview called");
      console.log("Current state before gathering:", {
        title: title.value,
        description: description.value,
        price: price.value,
        previewUrl: previewUrl.value,
      });
    };

    const handleFormValidation = () => {
      console.log("handleFormValidation called in AddMultimediaForm.");
      console.log("Current state before validation:", {
        title: title.value,
        description: description.value,
        price: price.value,
        previewUrl: previewUrl.value,
      });

      if (
        !title.value ||
        !description.value ||
        !price.value ||
        !previewUrl.value
      ) {
        errorMessage.value =
          "All fields are required and a preview must be generated.";
        console.error("Validation failed:", {
          title: title.value,
          description: description.value,
          price: price.value,
          previewUrl: previewUrl.value,
        });
        return false;
      }

      console.log("Validation passed. Moving to preview step.");
      gatherMultimediaDataForPreview();

      // Explicitly prevent unintended calls to `gatherServiceDataForPreview`
      emit("update-canPreview", true);
      emit("update-currentStep", "preview");

      return true;
    };

    const submitPost = async () => {
      if (!uploadedFile) {
        errorMessage.value = "Please upload a P5.js file before submitting.";
        return;
      }

      isUploading.value = true;
      try {
        const fileUrl = await uploadP5File(uploadedFile);

        // Dispatch Vuex action instead of adding directly to Firestore
        await store.dispatch("addMultimediaPost", {
          title: title.value,
          description: description.value,
          price: price.value,
          p5SketchUrl: fileUrl,
          type: "multimedia",
          owner: auth.currentUser ? auth.currentUser.uid : "anonymous",
          organisation: organisationUID.value, // Add organisation field
          createdAt: serverTimestamp(),
        });

        emit("postAddedSuccessfully");
      } catch (error) {
        console.error("Error uploading multimedia post:", error);
        errorMessage.value = "Failed to upload the P5.js file. Try again.";
      } finally {
        isUploading.value = false;
      }
    };

    const resetForm = () => {
      title.value = "";
      description.value = "";
      price.value = 0;
      previewUrl.value = null;
      errorMessage.value = null;
      uploadedFile = null;
      emit("update-currentStep", "initial");
    };

    return {
      title,
      description,
      price,
      previewUrl,
      isUploading,
      errorMessage,
      handleFileUpload,
      submitPost,
      triggerFileInput,
      fileInput,
      handleDrop,
      handleFormValidation,
      gatherMultimediaDataForPreview,
      updateParentTitle,
      updateParentDescription,
      resetForm,
      organisationUID,
    };
  },
};
</script>

<style scoped>
.add-multimedia-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.drop-area {
  border: 1px dashed var(--solidMidDarkgrey);
  padding: var(--margin);
  text-align: center;
  border-radius: var(--borderRadius);
  width: 100%;
  cursor: pointer;
}

.drop-area:hover {
  border-color: var(--primaryColour);
  background-color: var(--solidMidLightgrey);
}

.drop-area.drag-over {
  border-color: green;
  background-color: var(--lightGreen);
}

.preview-container {
  margin-top: 1rem;
}

.error {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}
</style>
