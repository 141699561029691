// src/utils/uploadUtils.js
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const storage = getStorage();

// Existing function for P5.js files (unchanged)
export const uploadP5File = async (file) => {
  if (!file) throw new Error("No file provided for upload");
  const fileRef = ref(storage, `multimedia/${Date.now()}_${file.name}`);
  try {
    await uploadBytes(fileRef, file);
    const fileUrl = await getDownloadURL(fileRef);
    return fileUrl;
  } catch (error) {
    console.error("Error uploading P5.js file:", error);
    throw error;
  }
};

// New function for general media files
export const uploadMediaFile = async (file) => {
  if (!file) throw new Error("No file provided for upload");

  // Check if the file is a URL string (either a blob URL or an existing remote URL)
  if (typeof file === 'string') {
    if (file.startsWith('blob:')) {
      console.log("File is a Blob URL - skipping upload:", file);
    } else if (file.startsWith('http')) {
      console.log("File is already a remote URL - skipping upload:", file);
    } else {
      console.log("File is a string but not a recognized URL format:", file);
    }
    return file; // Just return the URL as-is, can't upload a URL string
  }
  
  // Check if it's not a File/Blob object
  if (!(file instanceof Blob) && !(file instanceof File)) {
    throw new Error("Invalid file object");
  }

  let folder = "images"; // Default to images folder
  
  // Try to determine the folder based on the file type
  if (file.type) {
    if (file.type.startsWith("image/")) {
      folder = "images";
    } else if (file.type.startsWith("video/")) {
      folder = "videos";
    } else if (file.type.startsWith("audio/")) {
      folder = "audios";
    } else {
      console.warn("Unknown file type, defaulting to images folder:", file.type);
    }
  } else {
    console.warn("File has no type property, defaulting to images folder");
  }

  const fileRef = ref(storage, `${folder}/${Date.now()}_${file.name || 'untitled'}`);
  try {
    console.log(`Uploading file to ${folder}...`);
    await uploadBytes(fileRef, file);
    const fileUrl = await getDownloadURL(fileRef);
    console.log(`[uploadMediaFile] Uploaded file to ${folder}: ${fileUrl}`);
    return fileUrl;
  } catch (error) {
    console.error("Error uploading media file:", error);
    throw error;
  }
};
