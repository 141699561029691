<template>
  <div>
    <div
      v-if="isVisible && !isMinimised"
      class="backdrop"
      @click="minimise"
    ></div>
    <transition name="slide-right">
      <div
        v-show="isVisible"
        class="messaging-container shadow"
        :class="{ open: isOpen, minimised: isMinimised }"
      >
        <div class="messaging-header row alignCentreRow">
          <template v-if="otherUserData">
            <AvatarLightSmall
              class="clickableAnim"
              :user="otherUserData"
              @click="handleAvatarClick(otherUserData.uid)"
            />
          </template>

          <template v-else>
            <div class="column searchColumn">
              <div class="search-container">
                <input
                  type="text"
                  v-model="searchQuery"
                  placeholder="Search user..."
                  @focus="handleSearchFocus"
                  @blur="handleSearchBlur"
                  @input="onSearch"
                />
                <svg class="search-icon compactImg">
                  <use href="../../assets/icons/iconset.svg#lens-compact"></use>
                </svg>
              </div>
            </div>
          </template>
          <div class="row alignCentreRow">
            <svg
              v-if="otherUserData"
              class="smaller clickableAnim"
              @click="backToNewMessage"
              v-haptic
            >
              <use href="../../assets/icons/iconset.svg#backToMessages"></use>
            </svg>
            <!-- <svg class="smaller" @click="minimise" v-haptic v-if="!isMinimised">
                <use href="../../assets/icons/iconset.svg#arrowdown_carousel"></use>
              </svg>
              <svg
                class="smaller clickableAnim"
                @click="minimise"
                v-haptic
                v-else
              >
                <use href="../../assets/icons/iconset.svg#arrowup_carousel"></use>
              </svg> -->
            <svg class="smaller clickableAnim" @click="close" v-haptic>
              <use href="../../assets/icons/iconset.svg#cross"></use>
            </svg>
          </div>
        </div>

        <div class="separator divNoMarginTopBottom"></div>
        <div
          v-show="!isMinimised && !otherUserData"
          :class="{
            otherUsers: !isMinimised && !otherUserData,
            minimised: isMinimised,
          }"
        >
          <RecentMessages :searchQuery="searchQuery" />
        </div>
        <div
          v-show="!isMinimised && otherUserData"
          :class="{
            'messaging-body': !isMinimised && otherUserData,
            minimised: isMinimised,
          }"
        >
          <CoinRain ref="coinRainRef" :emojis="['🪙', '💰', '💎']" />

          <div class="messages" ref="messagesContainer">
            <p v-if="messages.length === 0" class="nomargintopbottom">
              <em> Start a conversation </em>
              <em v-if="showInteractiveMessage"> or make an offer.</em>
            </p>
            <div
              v-for="(message, index) in messages"
              :key="index"
              :id="message.id || index"
              :class="{
                'message-from-user': message.from === currentUserUid,
                'message-from-other': message.from !== currentUserUid,
                wideImage: message.messageMedia,
              }"
            >
              <div class="message-content">
                <div
                  class="imgContainer"
                  v-if="message.isInteractive && message.productImageUrl"
                >
                  <img
                    :src="message.productImageUrl"
                    alt="Product"
                    class="product-image"
                  />
                </div>
                <div class="imgContainer" v-if="message.messageMedia">
                  <img
                    :src="message.messageMedia"
                    alt="Image"
                    class="message-image"
                  />
                </div>
                <p class="nomargintopbottom">{{ message.messageBody }}</p>
                <div
                  v-if="message.isInteractive"
                  class="separator_sticker divSmallerMarginTopSmallMarginBottom"
                ></div>
                <div class="row alignCentreRow justifyToStartEnd">
                  <h3
                    v-if="message.isInteractive"
                    class="nomargintopbottom price"
                  >
                    <!-- Item display logic for all types (product, service, send) -->
                    <div>
                      <strong>
                        <!-- The FROM check determines if this user is the sender -->
                        <span v-if="message.from === currentUserUid">
                          <!-- Sender sees their currency (what they pay) -->
                          {{ message.convertedPrice }}
                          {{ message.buyerCoinSymbol || message.coinSymbol }}
                        </span>
                        <!-- This user is the receiver -->
                        <span v-else>
                          <!-- Receiver sees their currency (what they get) -->
                          {{ message.productPrice }}
                          {{ message.sellerCoinSymbol }}
                        </span>
                        <!-- Debug display, change to v-if="false" for production -->
                        <small
                          class="debug-msg"
                          v-if="true"
                          style="
                            font-size: 0.7rem;
                            color: #888;
                            display: block;
                            margin-top: 4px;
                          "
                        >
                          DEBUG - Sender pays: {{ message.convertedPrice }}
                          {{ message.buyerCoinSymbol || message.coinSymbol }},
                          Receiver gets: {{ message.productPrice }}
                          {{ message.sellerCoinSymbol }}
                        </small>
                      </strong>
                    </div>
                  </h3>
                  <div
                    v-if="
                      message.isInteractive &&
                      message.from === currentUserUid &&
                      message.interactiveResponseStatus === 'pending'
                    "
                  >
                    <button
                      class="compactButton red secondaryButton"
                      @click="revokeOffer(message)"
                    >
                      <svg class="compactImg">
                        <use
                          href="../../assets/icons/iconset.svg#compactCross"
                        ></use>
                      </svg>
                      Cancel
                    </button>
                  </div>
                </div>
                <span>
                  <small
                    class="gray"
                    v-if="
                      message.isInteractive && message.from === currentUserUid
                    "
                  >
                    Offer {{ message.interactiveResponseStatus }}</small
                  >
                </span>

                <!-- <span
                    v-if="message.interactiveResponseStatus === 'revoked'"
                    class="gray"
                  >
                    <small>Offer revoked</small>
                  </span>

                  <span
                    v-else-if="
                      message.isInteractive && message.from !== currentUserUid
                    "
                    class="nomargintopbottom"
                  >
                    d
                    <small
                      v-if="message.interactiveResponseStatus === 'accepted'"
                      class="gray"
                    >
                      Offer accepted
                    </small>
                    <small
                      v-else-if="message.interactiveResponseStatus === 'rejected'"
                      class="gray"
                    >
                      Offer rejected
                    </small>
                    <small v-else class="gray"> Pending acceptance... </small>
                  </span> -->

                <div
                  v-if="
                    message.isInteractive && message.from !== currentUserUid
                  "
                  :class="[
                    'interactive-options',
                    message.interactiveResponseStatus !== 'pending'
                      ? 'divNoMarginTopBottom'
                      : 'divNotSoSmallMarginTopSmallMarginBottom',
                  ]"
                  class="interactive-options divNotSoSmallMarginTopSmallMarginBottom"
                >
                  <template
                    v-if="message.interactiveResponseStatus !== 'pending'"
                  >
                    <small>{{
                      message.interactiveResponseStatus === "accepted"
                        ? "Offer accepted"
                        : "Offer rejected"
                    }}</small>
                  </template>
                  <template
                    v-else-if="
                      message.interactiveResponseStatus === 'pending' &&
                      message.from !== currentUserUid
                    "
                  >
                    <button
                      class="compactButton secondaryButton"
                      @click="handleReject(message)"
                    >
                      <svg class="compactImg">
                        <use
                          href="../../assets/icons/iconset.svg#compactCross"
                        ></use>
                      </svg>
                      Reject
                    </button>
                    <button
                      class="compactButton green"
                      :disabled="processingMessageId === message.id"
                      @click="handleAccept(message)"
                    >
                      <svg class="compactImg">
                        <use
                          href="../../assets/icons/iconset.svg#checkmark_compact"
                        ></use>
                      </svg>
                      {{
                        processingMessageId === message.id
                          ? "Processing..."
                          : "Accept"
                      }}
                    </button>
                  </template>
                </div>
                <div class="message-time">
                  <div v-if="message.from === currentUserUid">
                    <div class="row microGap">
                      {{ formatMessageTime(message.messageTime) }}

                      <svg v-if="message.read" class="tinyImg">
                        <use
                          href="../../assets/icons/iconset.svg#tick_two_tiny"
                        ></use>
                      </svg>
                    </div>
                  </div>
                  <div v-else>
                    {{ formatMessageTime(message.messageTime) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="separator"></div>
          <div
            v-if="!isLoading && showInteractiveMessage"
            class="interactive-message-container"
          >
            <InteractiveMessage
              :productId="productInfo?.productId || ''"
              :type="productInfo?.type || 'Send'"
              :itemName="productInfo?.itemName || 'Transfer details'"
              :productPrice="productInfo?.productPrice || 0"
              :productImageUrl="productInfo?.productImageUrl || ''"
              :convertedPrice="productInfo?.convertedPrice || 0"
              :coinSymbol="productInfo?.sellerCoinSymbol || 'Ʉ'"
              :sellerCoinSymbol="productInfo?.sellerCoinSymbol || 'Ʉ'"
              :buyerCoinSymbol="productInfo?.buyerCoinSymbol || 'Ʉ'"
              :otherUserData="otherUserData"
              :buyerOrgUID="buyerOrgUID"
              :currentUserWallet="currentUserWallet"
              :bookingDate="productInfo?.bookingDate || ''"
              :bookingTime="productInfo?.bookingTime || ''"
              :slotDuration="productInfo?.slotDuration || 0"
              :transferAmount="Number(productInfo?.transferAmount || 0)"
              :customTransfer="true"
              @makeOffer="handleOffer"
            />
          </div>
          <div v-if="isLoading">Loading...</div>
          <div class="row alignCentreRow">
            <svg class="smaller clickableAnim" @click="toggleEmojiPicker">
              <use href="@/assets/icons/iconset.svg#smile"></use>
            </svg>
            <!-- Add this image icon, visible when the emoji picker is open and in 'emoji' mode -->
            <svg
              v-if="isEmojiPickerOpen && pickerMode === 'emoji'"
              class="smaller clickableAnim"
              @click="switchToImagePicker"
            >
              <use href="@/assets/icons/iconset.svg#thumbnail"></use>
            </svg>
            <!-- NEW: Message Money Icon -->
            <svg
              class="smaller clickableAnim"
              @click="triggerCustomTransferMode"
            >
              <use href="../../assets/icons/iconset.svg#message_money"></use>
            </svg>
            <input
              ref="messageInput"
              type="text"
              placeholder="Type a message..."
              v-model="newMessage"
              @keydown.enter="handleEnter"
              @compositionstart="handleCompositionStart"
              @compositionend="handleCompositionEnd"
            />
            <svg class="smaller" alt="send message icon" @click="sendMessage">
              <use href="../../assets/icons/iconset.svg#message"></use>
            </svg>
          </div>

          <EmojiAndMediaPicker
            :isVisible="isEmojiPickerOpen"
            :pickerMode="pickerMode"
            :recent-emojis="recentEmojis"
            :existing-images="existingImages"
            @emojiSelected="handleEmojiSelected"
            @closePicker="closeEmojiPicker"
            @imageSelected="handleImageSelected"
            @removeImage="removeImage"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  computed,
  ref,
  onMounted,
  onUnmounted,
  watch,
  nextTick,
  reactive,
} from "vue";

import { useStore } from "vuex";
import { useRouter } from "vue-router";

import { auth, db } from "@/firebase";
import {
  doc,
  setDoc,
  getDoc,
  arrayUnion,
  onSnapshot,
  updateDoc,
  increment,
  collection,
  getDocs,
} from "firebase/firestore";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

import { toast } from "vue3-toastify";
import { formatNumberTo4Digits } from "@/utils/numberFormattingTo4digits";
import { getConvertedPrice } from "@/utils/priceUtils";
import { calculateReversePrice } from "@/utils/exchangeRateCalculator";

import AvatarLightSmall from "@/components/UserComponents/AvatarLightSmall.vue";
import InteractiveMessage from "@/components/MessagingComponents/InteractiveMessage.vue";
import RecentMessages from "@/components/MessagingComponents/RecentMessages.vue";
import EmojiAndMediaPicker from "@/components/MessagingComponents/EmojiAndMediaPicker.vue";
// import MessagesTransfer from "@/components/MessagingComponents/MessagesTransfer.vue";
import CoinRain from "@/components/AnimationComponents/CoinRain.vue";

export default {
  name: "Messages",
  components: {
    AvatarLightSmall,
    InteractiveMessage,
    RecentMessages,
    EmojiAndMediaPicker,
    CoinRain,
    // MessagesTransfer,
  },
  props: {
    isOpen: Boolean,
  },
  setup(props) {
    const store = useStore();
    const isLoading = ref(false);
    const router = useRouter();
    const coinRainRef = ref(null);

    const lockBodyScroll = () => {
      if (window.matchMedia("(max-width: 450px)").matches) {
        document.body.classList.add("lock-scroll");
      }
    };

    const unlockBodyScroll = () => {
      document.body.classList.remove("lock-scroll");
    };
    const customTransfer = ref(false);

    const buyerCoinSymbolComputed = computed(
      () => productInfo?.buyerCoinSymbol || "BuyerCoin"
    );

    const triggerCustomTransferMode = () => {
      console.log("Triggering custom transfer mode");
      // Set productInfo for a 'send' type transfer
      store.dispatch("UIState/setProductInfo", {
        type: "send", // or 'request' based on your logic
        transferAmount: 0, // Initial amount
        itemName: "Transfer",
        sellerCoinSymbol: sellerCoinSymbol.value,
        buyerCoinSymbol: buyerCoinSymbolComputed.value,
        // Include other necessary defaults
      });
      store.dispatch("UIState/setShowInteractiveMessage", true);
    };

    // this  part of the code is for the EmojiAndMediaPicker.vue
    const isEmojiPickerOpen = ref(false);
    const recentEmojis = ref([]);
    const uploadedImage = ref(null);
    const existingImages = ref([]);
    const pickerMode = ref("emoji");
    const messageInput = ref(null);

    const closeEmojiPicker = () => {
      isEmojiPickerOpen.value = false;
      pickerMode.value = "emoji";
    };
    const toggleEmojiPicker = () => {
      if (isEmojiPickerOpen.value && pickerMode.value === "image") {
        // If image picker is open, switch back to emoji picker
        pickerMode.value = "emoji";
      } else if (isEmojiPickerOpen.value && pickerMode.value === "emoji") {
        // If emoji picker is open, close it
        isEmojiPickerOpen.value = false;
      } else {
        // Open the emoji picker
        isEmojiPickerOpen.value = true;
        pickerMode.value = "emoji";
      }
    };

    const switchToImagePicker = () => {
      pickerMode.value = "image";
    };

    const handleEmojiSelected = (emoji) => {
      insertEmojiAtCursor(emoji);
      addEmojiToRecent(emoji);
    };

    const insertEmojiAtCursor = (emoji) => {
      const input = messageInput.value;
      if (input) {
        const start = input.selectionStart;
        const end = input.selectionEnd;
        const textBefore = newMessage.value.substring(0, start);
        const textAfter = newMessage.value.substring(end);
        newMessage.value = textBefore + emoji + textAfter;
        nextTick(() => {
          input.setSelectionRange(start + emoji.length, start + emoji.length);
          input.focus();
        });
      } else {
        newMessage.value += emoji;
      }
    };

    const addEmojiToRecent = (emoji) => {
      recentEmojis.value = recentEmojis.value.filter((e) => e !== emoji);
      recentEmojis.value.unshift(emoji);
      if (recentEmojis.value.length > 20) {
        recentEmojis.value.pop();
      }
      localStorage.setItem("recentEmojis", JSON.stringify(recentEmojis.value));
    };

    const handleImageSelected = (imageData) => {
      uploadedImage.value = imageData;
      existingImages.value = [imageData.url];
    };

    const removeImage = () => {
      uploadedImage.value = null;
      existingImages.value = [];
    };

    onMounted(() => {
      const storedEmojis = localStorage.getItem("recentEmojis");
      if (storedEmojis) {
        recentEmojis.value = JSON.parse(storedEmojis);
      }
    });
    // end of code for the EmojiAndMediaPicker.vue

    const transferAmount = computed(
      () => store.getters["UIState/transferAmount"]
    );

    const otherUserData = computed(
      () => store.getters["UIState/otherUserData"]
    );

    const getCurrentUserDisplayName = async () => {
      const userRef = doc(db, "users", auth.currentUser.uid);
      const userDoc = await getDoc(userRef);
      return userDoc.exists() ? userDoc.data().displayName : null;
    };

    const getRecipientDisplayName = async (recipientId) => {
      const userRef = doc(db, "users", recipientId);
      const userDoc = await getDoc(userRef);
      return userDoc.exists() ? userDoc.data().displayName : null;
    };

    const goToUserProfile = (userUID) => {
      router.push({ path: `/userprofile/${userUID}` });
    };

    const handleAvatarClick = (userUID) => {
      if (window.innerWidth <= 450) {
        minimise();
      }
      goToUserProfile(userUID);
    };

    const unsubscribe = ref(null);
    const messages = reactive([]);
    const currentUserUid = ref(null);
    const messagesContainer = ref(null);
    const generateMessageId = () =>
      `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

    const offerResponses = ref({});
    const getSellerStatus = (messageId) => {
      const sellerMessage = messages.value.find((msg) => msg.id === messageId);
      return sellerMessage
        ? sellerMessage.interactiveResponseStatus
        : "unknown";
    };
    const sellerCoinSymbol = computed(() => {
      const symbol = store.getters.getCoinSymbol;
      if (!symbol) {
        store.dispatch("fetchMonetaryParameters", otherUserData.value?.orgUID);
        return "Ʉ";
      }
      return symbol;
    });

    const fetchMessages = () => {
      if (!otherUserData.value) return;
      const messageDocRef = doc(
        db,
        "users",
        currentUserUid.value,
        "Messages",
        otherUserData.value.uid
      );

      onSnapshot(
        messageDocRef,
        (docSnap) => {
          if (docSnap.exists()) {
            const fetchedMessages = docSnap
              .data()
              .messages.map((message, index) => ({
                ...message,
                id: message.id || `msg-${index}`,
              }));
            // Ensure the reactivity by updating the entire reactive array
            messages.splice(
              0,
              messages.length,
              ...fetchedMessages.sort((a, b) => a.messageTime - b.messageTime)
            );

            // Force scroll to bottom with messages are fetched (with slight delay to ensure rendering)
            setTimeout(() => {
              scrollToBottom(true); // Force scroll to bottom
            }, 100);
          } else {
            messages.splice(0, messages.length); // Clear the messages if no data exists
          }
        },
        (error) => {
          console.error("Error fetching messages:", error);
        }
      );
    };

    const currentUserWallet = computed(() => {
      const wallets = store.state.wallets;
      console.log("Wallets in messages:", wallets);
      return wallets ? wallets[buyerOrgUID.value] : null;
    });

    const isBalanceInsufficient = computed(() => {
      console.log("Checking balance:", currentUserWallet.value);
      return (
        currentUserWallet.value &&
        currentUserWallet.value.currentAccount.balance <
          productInfo.value.productPrice
      );
    });

    const scrollToBottom = (force = false) => {
      nextTick(() => {
        if (!messagesContainer.value) return;
        const container = messagesContainer.value;
        if (force) {
          // Always scroll to bottom if forced
          container.scrollTop = container.scrollHeight;
        } else {
          // Keep near-bottom logic
          const threshold = 100;
          const position = container.scrollTop + container.clientHeight;
          const height = container.scrollHeight;
          if (height - position <= threshold) {
            container.scrollTop = height;
          }
        }
      });
    };

    watch(otherUserData, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        fetchMessages();
      }
    });

    const newMessage = ref("");
    const isComposing = ref(false); // Track whether user is interacting with the native input

    const maxMessageLength = 40000;
    const validateMessageLength = () => {
      if (newMessage.value.length > maxMessageLength) {
        newMessage.value = newMessage.value.slice(0, maxMessageLength);
        toast.error("Your message is too long. Please shorten it a little.");
      }
    };

    watch(newMessage, validateMessageLength);

    const isVisible = computed(() => store.state.UIState.areMessagesOpen);
    const isMinimised = computed(
      () => store.state.UIState.areMessagesMinimised
    );
    const showInteractiveMessage = computed(
      () => store.state.UIState.showInteractiveMessage
    );

    const productInfo = computed(() => {
      const info = store.state.UIState.productInfo;
      console.log("messages productInfo");
      console.log(info);
      if (info && typeof info.productPrice === "string") {
        info.productPrice = parseFloat(info.productPrice);
      }
      return info;
    });

    const productPriceType = computed(
      () => typeof productInfo.value?.productPrice
    );
    const isSending = ref(false);
    const sendMessage = async () => {
      if (newMessage.value.length > maxMessageLength) {
        newMessage.value = newMessage.value.slice(0, maxMessageLength);
        toast.error("Your message is too long. Please shorten it a little.");
        return;
      }

      if (newMessage.value.trim() === "" && !uploadedImage.value) {
        return; // Do not send empty messages unless there is an image
      }
      isSending.value = true; // Set flag to prevent multiple sends

      try {
        // Fetch display names with fallback values
        const [senderDisplayName, recipientDisplayName] = await Promise.all([
          getCurrentUserDisplayName().then((name) => name || "Unit User"),
          getRecipientDisplayName(otherUserData.value.uid).then(
            (name) => name || "Unit User"
          ),
        ]);

        const messageId = generateMessageId();
        const message = {
          // ... existing message properties
          id: messageId,
          from: auth.currentUser.uid,
          to: otherUserData.value.uid,
          senderDisplayName,
          recipientDisplayName,
          messageBody: newMessage.value,
          messageTime: Date.now(),
          isInteractive: false,
          read: false,
        };

        // Upload the image to Firebase Storage if present
        // Upload the image to Firebase Storage if present
        if (uploadedImage.value && uploadedImage.value.file) {
          const storage = getStorage(); // Initialize Firebase Storage
          const imageRef = storageRef(
            storage,
            `messages/${messageId}/${uploadedImage.value.file.name}`
          );
          await uploadBytes(imageRef, uploadedImage.value.file);
          const imageUrl = await getDownloadURL(imageRef);
          message.messageMedia = [imageUrl];
        }

        // Mark all unread messages from the other user as read
        const messageDocRef = doc(
          db,
          "users",
          otherUserData.value.uid,
          "Messages",
          auth.currentUser.uid
        );
        const docSnap = await getDoc(messageDocRef);

        if (docSnap.exists()) {
          const updatedMessages = docSnap.data().messages.map((msg) => {
            if (msg.from === otherUserData.value.uid && !msg.read) {
              return { ...msg, read: true };
            }
            return msg;
          });

          await updateDoc(messageDocRef, { messages: updatedMessages });

          // Update the reactive state to reflect the read status
          updatedMessages.forEach((updatedMessage) => {
            const index = messages.findIndex(
              (msg) => msg.id === updatedMessage.id
            );
            if (index !== -1) {
              messages[index] = updatedMessage;
            }
          });
        }

        // Add the new message to Firestore
        await Promise.all([
          setDoc(
            doc(
              db,
              "users",
              auth.currentUser.uid,
              "Messages",
              otherUserData.value.uid
            ),
            { messages: arrayUnion(message) },
            { merge: true }
          ),
          setDoc(
            doc(
              db,
              "users",
              otherUserData.value.uid,
              "Messages",
              auth.currentUser.uid
            ),
            { messages: arrayUnion(message) },
            { merge: true }
          ),
        ]);

        newMessage.value = "";
        uploadedImage.value = null;
        existingImages.value = [];
        isEmojiPickerOpen.value = false;
        pickerMode.value = "emoji"; // Reset picker mode

        scrollToBottom();
      } catch (error) {
        console.error("Error sending message:", error);
      } finally {
        isSending.value = false; // Reset flag
      }
    };

    const handleCompositionStart = () => {
      console.log("Composition started");
      isComposing.value = true;
    };

    const handleCompositionEnd = () => {
      console.log("Composition ended");
      isComposing.value = false;
    };

    const handleEnter = () => {
      if (!isComposing.value) {
        sendMessage(); // Send message if not composing
      } else {
        console.log("Still composing, not sending");
      }
    };

    async function getUserOrganisationUID(userUID) {
      try {
        const unitsCollectionRef = collection(db, "users", userUID, "Units");
        const unitsSnapshot = await getDocs(unitsCollectionRef);

        if (!unitsSnapshot.empty) {
          const firstOrgUID = unitsSnapshot.docs[0].data().organisationUID;
          return firstOrgUID;
        } else {
          console.log("No organisation UIDs found in Units!");
          return null;
        }
      } catch (error) {
        console.error("Error getting organisation UID:", error);
        return null;
      }
    }

    // New function to get a user's coin symbol directly
    async function getUserCoinSymbol(userUID) {
      try {
        // First get the user's organization UID
        const orgUID = await getUserOrganisationUID(userUID);
        if (!orgUID) {
          console.log("Could not get org UID for user:", userUID);
          return "Ʉ"; // Default symbol
        }

        // Then get the organization's details to get the coin symbol
        const orgDocRef = doc(db, "custom-orgs", orgUID);
        const orgDoc = await getDoc(orgDocRef);

        if (orgDoc.exists() && orgDoc.data().monetary_parameters) {
          const coinSymbol = orgDoc.data().monetary_parameters.coin_symbol;
          console.log(`User ${userUID} coin symbol: ${coinSymbol}`);
          return coinSymbol || "Ʉ";
        } else {
          console.log("No monetary parameters found for org:", orgUID);
          return "Ʉ"; // Default symbol
        }
      } catch (error) {
        console.error("Error getting user coin symbol:", error);
        return "Ʉ"; // Default symbol on error
      }
    }

    // Function to parse abbreviated values like "10K" or "2.5M" into their numeric equivalents
    function parseAbbreviatedValue(value) {
      console.log(
        "Parsing abbreviated value in Messages.vue:",
        value,
        "Type:",
        typeof value
      );

      // If value is already a number, return it
      if (typeof value === "number") return value;

      // If value is not a string or is empty, return 0
      if (typeof value !== "string" || !value.trim()) return 0;

      // Convert to uppercase for consistent handling
      const upperValue = value.trim().toUpperCase();
      console.log("Uppercase value:", upperValue);

      // Check for abbreviations
      if (upperValue.endsWith("K")) {
        // Handle thousands (K)
        const numPart = parseFloat(upperValue.slice(0, -1));
        const result = isNaN(numPart) ? 0 : numPart * 1000;
        console.log("Parsed K value:", upperValue, "→", result);
        return result;
      } else if (upperValue.endsWith("M")) {
        // Handle millions (M)
        const numPart = parseFloat(upperValue.slice(0, -1));
        const result = isNaN(numPart) ? 0 : numPart * 1000000;
        console.log("Parsed M value:", upperValue, "→", result);
        return result;
      } else if (upperValue.endsWith("G")) {
        // Handle billions (G)
        const numPart = parseFloat(upperValue.slice(0, -1));
        const result = isNaN(numPart) ? 0 : numPart * 1000000000;
        console.log("Parsed G value:", upperValue, "→", result);
        return result;
      } else if (upperValue.endsWith("T")) {
        // Handle trillions (T)
        const numPart = parseFloat(upperValue.slice(0, -1));
        const result = isNaN(numPart) ? 0 : numPart * 1000000000000;
        console.log("Parsed T value:", upperValue, "→", result);
        return result;
      } else {
        // No abbreviation, try to parse as a regular number
        const numValue = parseFloat(upperValue);
        console.log("Parsed regular number:", upperValue, "→", numValue);
        return isNaN(numValue) ? 0 : numValue;
      }
    }

    const handlemakeOffer = async (productDetailsInput) => {
      try {
        // Log the original input to see exactly what we're receiving
        console.log(
          "COMPLETE ORIGINAL PRODUCT DETAILS INPUT:",
          JSON.stringify(productDetailsInput, null, 2)
        );

        // Create a deep copy of productDetails to avoid mutation issues
        const productDetails = JSON.parse(JSON.stringify(productDetailsInput));

        // Parse any abbreviated values in the input
        if (typeof productDetails.transferAmount === "string") {
          productDetails.transferAmount = parseAbbreviatedValue(
            productDetails.transferAmount
          );
          console.log("Parsed transferAmount:", productDetails.transferAmount);
        }

        if (typeof productDetails.productPrice === "string") {
          productDetails.productPrice = parseAbbreviatedValue(
            productDetails.productPrice
          );
          console.log("Parsed productPrice:", productDetails.productPrice);
        }

        if (typeof productDetails.convertedPrice === "string") {
          productDetails.convertedPrice = parseAbbreviatedValue(
            productDetails.convertedPrice
          );
          console.log("Parsed convertedPrice:", productDetails.convertedPrice);
        }

        // URGENT FIX: For products, ensure the original price values are preserved
        if (
          productDetails.type === "product" ||
          productDetails.type === "service"
        ) {
          console.log("IMPORTANT - Copied product details:", {
            productPrice: productDetails.productPrice,
            convertedPrice: productDetails.convertedPrice,
            productObj: productDetails.product
              ? {
                  price: productDetails.product.price,
                  id: productDetails.product.id,
                  type: productDetails.product.type,
                }
              : null,
          });

          // Ensure values are numbers (not strings) and preserve original values
          if (typeof productDetails.productPrice === "string") {
            productDetails.productPrice = parseFloat(
              productDetails.productPrice
            );
          }
          if (typeof productDetails.convertedPrice === "string") {
            productDetails.convertedPrice = parseFloat(
              productDetails.convertedPrice
            );
          }

          // Check if values seem to be mixed up
          if (
            Math.abs(
              productDetails.productPrice - productDetails.convertedPrice
            ) < 0.01
          ) {
            console.warn(
              "Product and converted prices are almost identical - possible error in exchange rate calculation"
            );

            // Re-calculate the converted price properly
            try {
              const buyerOrgUID = await getUserOrganisationUID(
                currentUserUid.value
              );
              const sellerOrgUID = await getUserOrganisationUID(
                otherUserData.value.uid
              );

              // Get fresh organization details for calculation
              await Promise.all([
                store.dispatch("fetchAndCommitOrgMembersCount", {
                  orgUID: buyerOrgUID,
                  context: "buyer",
                }),
                store.dispatch("fetchAndCommitOrgTotalCoins", {
                  orgUID: buyerOrgUID,
                  context: "buyer",
                }),
                store.dispatch("fetchAndCommitOrgMembersCount", {
                  orgUID: sellerOrgUID,
                  context: "seller",
                }),
                store.dispatch("fetchAndCommitOrgTotalCoins", {
                  orgUID: sellerOrgUID,
                  context: "seller",
                }),
              ]);

              const buyerOrgDetails =
                store.getters.getBuyerOrgDetails(buyerOrgUID);
              const sellerOrgDetails =
                store.getters.getSellerOrgDetails(sellerOrgUID);

              const buyerMembersCount = buyerOrgDetails?.membersCount || 1;
              const buyerTotalCoins = buyerOrgDetails?.totalOrgCoins || 100;
              const sellerMembersCount = sellerOrgDetails?.membersCount || 1;
              const sellerTotalCoins = sellerOrgDetails?.totalOrgCoins || 100;

              // Recalculate the price conversion using the seller's original price and per-capita values
              const sellerPrice = parseFloat(productDetails.productPrice);
              const buyerPerCapita = buyerTotalCoins / buyerMembersCount;
              const sellerPerCapita = sellerTotalCoins / sellerMembersCount;

              // Calculate exchange rate: buyer per-capita / seller per-capita
              // This is the ratio of how much seller currency 1 unit of buyer currency is worth
              const exchangeRate = buyerPerCapita / sellerPerCapita;

              // Apply exchange rate to convert seller price to buyer price
              const newBuyerPrice = sellerPrice / exchangeRate;

              console.log("Exchange calculation:", {
                buyerPerCapita,
                sellerPerCapita,
                exchangeRate,
                sellerPrice,
                newBuyerPrice,
              });

              // Update the convertedPrice with the correctly calculated value
              productDetails.convertedPrice = newBuyerPrice;
              console.log("Recalculated convertedPrice:", newBuyerPrice);

              if (Math.abs(sellerPrice - newBuyerPrice) < 0.01) {
                // Still too similar, report issue
                toast.warning(
                  "There may be an issue with price conversion. Please check the details before proceeding."
                );
              }
            } catch (error) {
              console.error("Error recalculating price conversion:", error);
              toast.warning(
                "There may be an issue with price conversion. Please check the details before proceeding."
              );
            }
          }
        }

        // Fetch display names with fallback values
        const [senderDisplayName, recipientDisplayName] = await Promise.all([
          getCurrentUserDisplayName().then((name) => name || "Unit User"),
          getRecipientDisplayName(otherUserData.value.uid).then(
            (name) => name || "Unit User"
          ),
        ]);

        // Parse transferAmount if it's a string with abbreviations
        let transferAmount =
          productDetails.transferAmount || productDetails.productPrice;
        if (typeof transferAmount === "string") {
          transferAmount = parseAbbreviatedValue(transferAmount);
        }
        console.log("Final transferAmount:", transferAmount);
        const messageId = generateMessageId();
        await store.dispatch("UIState/setShowInteractiveMessage", false);

        const sellerOrgUid = await getUserOrganisationUID(
          otherUserData.value.uid
        );

        // Get both amounts properly
        // For receiver (seller): needs productPrice in THEIR currency
        // For sender (buyer): needs convertedPrice in THEIR currency

        // Get buyer org UID and required organization details for conversion
        const buyerOrgUID = await getUserOrganisationUID(currentUserUid.value);

        let buyerAmount, sellerAmount;

        // Get organization details for exchange rate calculation - needed for all types
        try {
          const buyerOrgDetails = await store.dispatch(
            "fetchOrgDetails",
            buyerOrgUID
          );
          const sellerOrgDetails = await store.dispatch(
            "fetchOrgDetails",
            sellerOrgUid
          );

          // Ensure we have valid values for exchange rate calculation
          const buyerMembersCount = Math.max(
            buyerOrgDetails?.membersCount || 1,
            1
          );
          const buyerTotalCoins = Math.max(
            buyerOrgDetails?.totalOrgCoins || 100,
            100
          );
          const sellerMembersCount = Math.max(
            sellerOrgDetails?.membersCount || 1,
            1
          );
          const sellerTotalCoins = Math.max(
            sellerOrgDetails?.totalOrgCoins || 100,
            100
          );

          // Calculate per-capita values for exchange rate
          const buyerPerCapita = buyerTotalCoins / buyerMembersCount;
          const sellerPerCapita = sellerTotalCoins / sellerMembersCount;

          console.log("Buyer per capita:", buyerPerCapita);
          console.log("Seller per capita:", sellerPerCapita);

          // STEP 1: Detect the type of interaction
          if (
            productDetails.type === "send" ||
            productDetails.type === "request"
          ) {
            // TRANSFERS: Sender explicitly sets an amount in THEIR currency
            buyerAmount = transferAmount; // Already parsed to a number

            // Calculate what the receiver will get in THEIR currency using exchange rate
            const result = calculateReversePrice(
              buyerMembersCount,
              sellerMembersCount,
              buyerAmount,
              buyerTotalCoins,
              sellerTotalCoins
            );

            sellerAmount = result.amountInReceiverCoins;
            console.log("Exchange calculation result:", result);
            console.log(
              "TRANSFER - sender pays:",
              buyerAmount,
              "receiver gets:",
              sellerAmount
            );
          } else {
            // PRODUCTS/SERVICES

            // Ensure values are parsed as numbers
            if (typeof productDetails.productPrice === "string") {
              productDetails.productPrice = parseFloat(
                productDetails.productPrice
              );
            }
            if (typeof productDetails.convertedPrice === "string") {
              productDetails.convertedPrice = parseFloat(
                productDetails.convertedPrice
              );
            }
            if (typeof transferAmount === "string") {
              transferAmount = parseFloat(transferAmount);
            }

            // Get the original price values
            const originalSellerPrice =
              Number(productDetails.productPrice) || 0;
            const originalBuyerPrice =
              Number(productDetails.convertedPrice) || 0;
            const userModifiedAmount = Number(transferAmount) || 0;

            console.log("Original seller price:", originalSellerPrice);
            console.log("Original buyer price:", originalBuyerPrice);
            console.log("User entered amount:", userModifiedAmount);

            // Check if the user has modified the amount in the interactive message
            if (Math.abs(userModifiedAmount - originalBuyerPrice) > 0.01) {
              console.log(
                "User modified the amount - recalculating seller amount based on exchange rate"
              );

              // Use the user's entered amount (in buyer's currency)
              buyerAmount = userModifiedAmount; // Already parsed to a number

              // Calculate what receiver should get based on the modified amount
              const result = calculateReversePrice(
                buyerMembersCount,
                sellerMembersCount,
                buyerAmount,
                buyerTotalCoins,
                sellerTotalCoins
              );

              sellerAmount = result.amountInReceiverCoins;
              console.log("Custom amount exchange result:", result);
            } else {
              // Use original values from the product
              buyerAmount = originalBuyerPrice;
              sellerAmount = originalSellerPrice;
              console.log("Using original product prices without modification");
            }

            console.log(
              "PRODUCT/SERVICE - seller gets:",
              sellerAmount,
              "buyer pays:",
              buyerAmount
            );
          }

          // Verify the results are valid (different values, positive numbers)
          if (Math.abs(sellerAmount - buyerAmount) < 0.01 && sellerAmount > 0) {
            console.warn("WARNING: Calculated prices are too similar");
            toast.warning("There may be an issue with price conversion.");
          }

          if (!sellerAmount || isNaN(sellerAmount) || sellerAmount <= 0) {
            console.warn("Invalid seller amount detected:", sellerAmount);
            toast.error("Error calculating price for receiver.");

            // Set a minimal valid amount to avoid crashes
            sellerAmount = 0.01;
          }
        } catch (error) {
          console.error("Error during price conversion:", error);
          toast.error("Error calculating exchange rate. Please try again.");

          // Set fallback values
          if (
            productDetails.type === "product" ||
            productDetails.type === "service"
          ) {
            // For products, use the original values
            buyerAmount = Number(productDetails.convertedPrice) || 0;
            sellerAmount = Number(productDetails.productPrice) || 0;
          } else {
            // For transfers, use a simple estimate
            buyerAmount = Number(transferAmount) || 0;
            sellerAmount = buyerAmount; // Just use same amount as fallback
          }
        }

        let messageBody = "";
        switch (productDetails.type) {
          case "send":
            messageBody = `I'd like to send you funds.`;
            break;
          case "request":
            messageBody = `I'd like to request some funds.`;
            break;
          case "product":
            messageBody = `I'd like to make an offer for ${productDetails.itemName}.`;
            break;
          case "service":
            messageBody = productDetails.bookingTime
              ? `I'd like to make a booking request for ${productDetails.bookingDate} at ${productDetails.bookingTime}.`
              : `I'd like to make a booking request for ${productDetails.itemName}.`;
            break;
          default:
            messageBody = `I'd like to proceed with ${productDetails.itemName}.`;
        }

        // For the seller (receiver), get their coin symbol directly
        const currentSellerCoinSymbol = await getUserCoinSymbol(
          otherUserData.value.uid
        );
        // For the buyer (sender), use our current user's coin symbol
        const currentBuyerCoinSymbol = await getUserCoinSymbol(
          currentUserUid.value
        );

        console.log("Buyer coin symbol:", currentBuyerCoinSymbol);
        console.log("Seller coin symbol:", currentSellerCoinSymbol);

        // Now that we've calculated both amounts in their respective currencies,
        // we're ready to create the message with the correct values
        console.log("FINAL CALCULATED AMOUNTS:", {
          buyerAmount, // Amount in sender/buyer's currency
          sellerAmount, // Amount in receiver/seller's currency
        });

        // Log the exact values we'll use in the message for clarity
        console.log("FINAL VALUES THAT WILL BE USED IN MESSAGE:", {
          senderSees: buyerAmount + " " + currentBuyerCoinSymbol,
          receiverSees: sellerAmount + " " + currentSellerCoinSymbol,
          type: productDetails.type,
        });

        console.log("Buyer coin symbol:", currentBuyerCoinSymbol);
        console.log("Seller coin symbol:", currentSellerCoinSymbol);

        const message = {
          id: messageId,
          from: currentUserUid.value,
          to: otherUserData.value.uid,
          senderDisplayName,
          recipientDisplayName,
          messageBody,
          messageTime: Date.now(),
          isInteractive: true,
          type: productDetails.type || "product",
          productImageUrl: productDetails.productImageUrl || "",
          productId: productDetails.productId || "",
          itemName: productDetails.itemName || "",
          // CRITICAL: Make sure these values are assigned correctly
          // For product/service, use the original values from the product
          // For transfers, use our calculated conversion
          // productPrice - for seller/receiver's currency
          productPrice: (() => {
            let value = sellerAmount;

            // Basic validation - just make sure it's a positive number
            if (!value || isNaN(value) || value <= 0) {
              console.warn("Invalid seller amount detected");
              toast.error("Error: Invalid price value. Please try again.");
              value = 0.01; // Just set a minimal valid value to avoid crashes
            }

            console.log(`Setting productPrice in message to: ${value}`);
            return formatNumberTo4Digits(value);
          })(),

          // convertedPrice - for buyer/sender's currency
          convertedPrice: (() => {
            let value = buyerAmount;

            // Basic validation - just make sure it's a positive number
            if (!value || isNaN(value) || value <= 0) {
              console.warn("Invalid buyer amount detected");
              toast.error("Error: Invalid price conversion. Please try again.");
              value = 0.01; // Just set a minimal valid value to avoid crashes
            }

            console.log(`Setting convertedPrice in message to: ${value}`);
            return formatNumberTo4Digits(value);
          })(),
          // Include both symbols explicitly to avoid any ambiguity
          coinSymbol: currentBuyerCoinSymbol || "Ʉ", // Legacy field - sender's symbol
          buyerCoinSymbol: currentBuyerCoinSymbol || "Ʉ", // Sender's symbol
          sellerCoinSymbol: currentSellerCoinSymbol || "Ʉ", // Receiver's symbol
          bookingDate: productDetails.bookingDate || "",
          bookingTime: productDetails.bookingTime || "",
          slotDuration: productDetails.slotDuration || 0,
          interactiveResponse: null,
          interactiveResponseStatus: "pending",
        };

        await Promise.all([
          setDoc(
            doc(
              db,
              "users",
              otherUserData.value.uid,
              "Messages",
              currentUserUid.value
            ),
            { messages: arrayUnion(message) },
            { merge: true }
          ),
          setDoc(
            doc(
              db,
              "users",
              currentUserUid.value,
              "Messages",
              otherUserData.value.uid
            ),
            { messages: arrayUnion(message) },
            { merge: true }
          ),
        ]);
        console.log(message);
        toast.info("Message sent.");
      } catch (error) {
        await store.dispatch("UIState/setShowInteractiveMessage", true);
        toast.error("Message not sent:", error);
        console.error("Error exchanging message:", error);
      }
    };

    const minimise = () => store.dispatch("UIState/toggleMessagesMinimised");
    const close = () => {
      store.dispatch("UIState/clearProductInfo"); // Clear product info on close
      store.dispatch("UIState/setMessagesOpen", false);
    };
    const handleSearchFocus = () => {
      if (isMinimised.value) {
        store.dispatch("UIState/toggleMessagesMinimised");
      }
      isSearchFocused.value = true;
    };

    const buyerOrgUID = computed(() => store.getters.buyerOrgUID);
    const sellerOrgUID = computed(() => store.getters.sellerOrgUID);

    const processingMessageId = ref(null);

    const handleAccept = async (originalMessage) => {
      // Prevent multiple clicks by checking if this message is already processing
      if (processingMessageId.value === originalMessage.id) return;
      processingMessageId.value = originalMessage.id; // disable button immediately

      const acceptanceMessageId = generateMessageId();

      // Prepare the acceptance message
      const acceptanceMessage = {
        id: acceptanceMessageId,
        from: currentUserUid.value,
        to: originalMessage.from,
        messageBody: "Offer accepted.",
        messageTime: Date.now(),
        interactiveResponseStatus: "accepted",
      };

      try {
        // Update Firestore for both users
        await updateMessageForBothUsers(
          originalMessage,
          acceptanceMessage,
          "accepted"
        );

        // Handle the transaction after the offer is accepted
        const transactionDetails = {
          buyerUID: originalMessage.from, // Person who sent the offer
          sellerUID: currentUserUid.value, // Person accepting (receiver)
          buyerOrgUID:
            currentUserUid.value === originalMessage.from
              ? buyerOrgUID.value
              : sellerOrgUID.value,
          sellerOrgUID:
            currentUserUid.value === originalMessage.from
              ? sellerOrgUID.value
              : buyerOrgUID.value,
          // IMPORTANT: These fields are named based on the original transaction flow:
          // - productPrice is seller's original price (in seller's currency)
          // - convertedPrice is the price for the buyer (in buyer's currency)
          // The exchange system will calculate the conversion as needed
          productPrice: parseFloat(originalMessage.productPrice), // Seller's original price (what seller gets)
          convertedPrice: parseFloat(originalMessage.convertedPrice), // Buyer's price (what buyer pays)
          itemName: originalMessage.itemName,
          type: originalMessage.type,
        };

        await store.dispatch(
          "coinCreationActions/handleTransaction",
          transactionDetails
        );

        if (productInfo.value && productInfo.value.productId) {
          const productRef = doc(db, "products", productInfo.value.productId);
          await updateDoc(productRef, { existingStock: increment(-1) });
        }
        coinRainRef.value?.startAnimation();
        toast.success("Offer accepted and transaction completed successfully.");
      } catch (error) {
        console.error("Error during the accept process:", error);
        toast.error("Failed to accept the offer. Please try again.");
      } finally {
        processingMessageId.value = null; // Re-enable the button
      }
    };

    const handleReject = async (originalMessage) => {
      const rejectionMessageId = generateMessageId();

      // Prepare the rejection message
      const rejectionMessage = {
        id: rejectionMessageId,
        from: currentUserUid.value,
        to: originalMessage.from,
        messageBody: "Offer not accepted.",
        messageTime: Date.now(),
        interactiveResponseStatus: "rejected",
      };

      try {
        // Update Firestore for both users
        await updateMessageForBothUsers(
          originalMessage,
          rejectionMessage,
          "rejected"
        );

        toast.success("Offer rejected successfully.");
      } catch (error) {
        console.error("Error sending rejection message:", error);
        toast.error("Failed to reject the offer. Please try again.");
      }
    };

    const updateMessageForBothUsers = async (
      originalMessage,
      newMessage,
      status
    ) => {
      const senderMessageRef = doc(
        db,
        "users",
        originalMessage.from,
        "Messages",
        currentUserUid.value
      );

      const recipientMessageRef = doc(
        db,
        "users",
        currentUserUid.value,
        "Messages",
        originalMessage.from
      );

      // Update the original message status for both users
      const updateMessageStatus = async (docRef) => {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const messages = docSnap.data().messages;
          const updatedMessages = messages.map((msg) => {
            if (msg.id === originalMessage.id) {
              return {
                ...msg,
                interactiveResponseStatus: status,
              };
            }
            return msg;
          });

          await updateDoc(docRef, { messages: updatedMessages });
        }
      };

      await updateMessageStatus(senderMessageRef);
      await updateMessageStatus(recipientMessageRef);

      // Add the new message to both users' Firestore documents
      await setDoc(
        senderMessageRef,
        { messages: arrayUnion(newMessage) },
        { merge: true }
      );

      await setDoc(
        recipientMessageRef,
        { messages: arrayUnion(newMessage) },
        { merge: true }
      );
    };

    const hasResponse = (messageId) => {
      if (!messages.value) return false;
      return messages.value.some((msg) => msg.linkedMessageId === messageId);
    };

    const backToNewMessage = () => {
      store.dispatch("UIState/clearProductInfo"); // Clear product info on back
      store.dispatch("UIState/setOtherUserData", null);
    };

    const formatMessageTime = (timestamp) => {
      const messageDate = new Date(timestamp);
      const currentDate = new Date();
      const oneWeekAgo = new Date(
        currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
      );

      const isSameDay =
        messageDate.toDateString() === currentDate.toDateString();
      const isWithinAWeek = messageDate > oneWeekAgo;
      const isSameMonth =
        messageDate.getMonth() === currentDate.getMonth() &&
        messageDate.getFullYear() === currentDate.getFullYear();
      const isSameYear =
        messageDate.getFullYear() === currentDate.getFullYear();

      let options = { hour: "2-digit", minute: "2-digit", hour12: false };

      if (!isSameDay) {
        options = { ...options, weekday: "short", day: "2-digit" };

        if (!isWithinAWeek) {
          options = { ...options, month: "2-digit" };

          if (!isSameMonth || !isSameYear) {
            options = { ...options, year: "numeric" };
          }
        }
      }

      return new Intl.DateTimeFormat("en-GB", options).format(messageDate);
    };

    const searchQuery = ref("");
    const isSearchFocused = ref(false);
    const limitCount = ref(10);

    const handleSearchBlur = () => {
      isSearchFocused.value = false;
    };

    const revokeOffer = async (message) => {
      try {
        const senderMessageRef = doc(
          db,
          "users",
          currentUserUid.value,
          "Messages",
          message.to
        );

        const recipientMessageRef = doc(
          db,
          "users",
          message.to,
          "Messages",
          currentUserUid.value
        );

        const updateMessageStatus = async (docRef) => {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const messages = docSnap.data().messages;
            const updatedMessages = messages.map((msg) => {
              if (msg.id === message.id) {
                return {
                  ...msg,
                  interactiveResponseStatus: "revoked",
                };
              }
              return msg;
            });

            await updateDoc(docRef, {
              messages: updatedMessages,
            });
          }
        };

        await updateMessageStatus(senderMessageRef);
        await updateMessageStatus(recipientMessageRef);

        toast.success("Offer revoked successfully.");
      } catch (error) {
        console.error("Error revoking offer:", error);
        toast.error("Failed to revoke the offer. Please try again.");
      }
    };

    const markMessageAsRead = async (message) => {
      // Only mark as read if the message thread is open and not minimized
      if (
        !message.read &&
        message.to === currentUserUid.value &&
        isVisible.value &&
        !isMinimised.value
      ) {
        try {
          const messageDocRef = doc(
            db,
            "users",
            currentUserUid.value,
            "Messages",
            otherUserData.value.uid
          );

          const docSnap = await getDoc(messageDocRef);
          if (docSnap.exists()) {
            const updatedMessages = docSnap.data().messages.map((msg) => {
              if (msg.id === message.id) {
                return { ...msg, read: true };
              }
              return msg;
            });

            await updateDoc(messageDocRef, {
              messages: updatedMessages,
            });

            // Reflect the update in the reactive messages array
            const index = messages.findIndex((msg) => msg.id === message.id);
            if (index !== -1) {
              messages[index] = { ...messages[index], read: true };
            }
          }
        } catch (error) {
          console.error("Error marking message as read:", error);
        }
      }
    };
    const markAllMessagesAsRead = async () => {
      if (!otherUserData.value) return;
      try {
        const messageDocRef = doc(
          db,
          "users",
          currentUserUid.value,
          "Messages",
          otherUserData.value.uid
        );

        const docSnap = await getDoc(messageDocRef);
        if (docSnap.exists()) {
          const messages = docSnap.data().messages;
          const updatedMessages = messages.map((msg) => {
            if (!msg.read) {
              return { ...msg, read: true };
            }
            return msg;
          });

          await updateDoc(messageDocRef, {
            messages: updatedMessages,
          });
        }
        await store.dispatch(
          "UIState/fetchUnreadMessagesCount",
          currentUserUid.value
        );
      } catch (error) {
        console.error("Error marking all messages as read:", error);
      }
    };

    watch(otherUserData, async (newVal, oldVal) => {
      if (newVal !== oldVal) {
        fetchMessages(); // fetch the messages of the new user
        await markAllMessagesAsRead(); // mark all messages in this thread as read
      }
    });

    watch([isVisible, isMinimised], async ([newIsVisible, newIsMinimised]) => {
      const isMobile = window.matchMedia("(max-width: 450px)").matches;

      if (newIsVisible && !newIsMinimised) {
        await markAllMessagesAsRead();

        // Force scroll on open with a slight delay to ensure all content is rendered:
        nextTick(() => {
          // Use setTimeout to ensure DOM is fully updated
          setTimeout(() => {
            scrollToBottom(true); // pass force=true
            console.log("Auto-scrolled to bottom of messages");
          }, 100);
        });

        if (isMobile) {
          lockBodyScroll();
        }
      } else {
        unlockBodyScroll();
      }
    });

    watch(
      messages,
      (newMessages) => {
        // Only mark messages as read if the thread is visible and not minimized
        if (isVisible.value && !isMinimised.value) {
          newMessages.forEach((message) => {
            markMessageAsRead(message);
          });
        }
      },
      { deep: true }
    );

    watch([isVisible, isMinimised], ([newIsVisible, newIsMinimised]) => {
      const isMobile = window.matchMedia("(max-width: 450px)").matches;

      if (newIsVisible && !newIsMinimised && isMobile) {
        lockBodyScroll();
      } else {
        unlockBodyScroll();
      }
    });

    onMounted(() => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          currentUserUid.value = user.uid;
          store.dispatch("fetchAndSetBuyerOrgUID", user.uid);
          if (otherUserData.value && otherUserData.value.uid) {
            store.dispatch("fetchAndSetSellerOrgUID", otherUserData.value.uid);
          }
          fetchMessages(); // Ensure messages are fetched when the component mounts

          watch(
            otherUserData,
            (newOtherUserData) => {
              if (newOtherUserData && newOtherUserData.uid) {
                store
                  .dispatch("fetchAndSetSellerOrgUID", newOtherUserData.uid)
                  .then(() => {
                    isLoading.value = false;
                  })
                  .catch((error) => {
                    console.error("Error loading org UIDs:", error);
                  });
              }
            },
            { immediate: true }
          );
        }
      });
    });

    onUnmounted(() => {
      if (unsubscribe.value) {
        unsubscribe.value();
      }
      unlockBodyScroll();
    });

    watch(isComposing, (newValue, oldValue) => {
      if (newValue) {
        console.log("Watcher: Composition started");
      } else {
        console.log("Watcher: Composition ended");
      }
    });

    return {
      currentUserUid,
      isLoading,
      otherUserData,
      productInfo,
      productPriceType,
      newMessage,
      isComposing,
      isMinimised,
      isVisible,
      sendMessage,
      handleCompositionStart,
      handleCompositionEnd,
      handleEnter,
      minimise,
      close,
      handlemakeOffer,
      handleOffer: handlemakeOffer,
      handleAccept,
      handleReject,
      hasResponse,
      buyerOrgUID,
      sellerOrgUID,
      messagesContainer,
      messages,
      sellerCoinSymbol,
      formatMessageTime,
      showInteractiveMessage,
      generateMessageId,
      backToNewMessage,
      searchQuery,
      isSearchFocused,
      limitCount,
      onSearch() {
        limitCount.value = 10;
      },
      handleSearchBlur,
      handleSearchFocus,
      goToUserProfile,
      handleAvatarClick,
      isBalanceInsufficient,
      currentUserWallet,
      revokeOffer,
      getSellerStatus,
      transferAmount,
      unlockBodyScroll,
      lockBodyScroll,
      markMessageAsRead,
      markAllMessagesAsRead,
      getUserOrganisationUID,
      isSending,
      updateMessageForBothUsers,
      handleSearchBlur,
      handleSearchFocus,
      searchQuery,
      isSearchFocused,
      limitCount,

      // EmojiAndMediaPicker.vue
      toggleEmojiPicker,
      isEmojiPickerOpen,
      recentEmojis,
      handleEmojiSelected,
      insertEmojiAtCursor,
      addEmojiToRecent,
      handleImageSelected,
      removeImage,
      uploadedImage,
      existingImages,
      switchToImagePicker,
      pickerMode,
      messageInput,
      buyerCoinSymbolComputed,
      closeEmojiPicker,
      triggerCustomTransferMode,
      customTransfer, // expose customTransfer so it's available in the template
      triggerCustomTransferMode, // expose the function to trigger custom transfer mode
      coinRainRef,
      processingMessageId,
      handleCompositionStart,
      handleCompositionEnd,
      handleEnter,
    };
  },
};
</script>

<style scoped>
.messaging-container {
  height: auto;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 41%; /* Adjust the width as necessary */
  background-color: var(--backgroundColourOrg);
  box-shadow: 0 2px 6px var(--darkbluetransplight);
  border-top-left-radius: var(--borderRadius);
  transition: all 0.15s ease-out;
  transform: translateY(0%); /* Start visible */
  opacity: 1; /* Fully visible */
  z-index: 3; /* Ensure it's above other content */
  max-height: 90vh;
}

.messaging-container.open {
  width: 50%;
  min-width: 425px;
  max-width: 475px;
}

.messaging-container:not(.open) {
  transform: translateY(100%); /* Slide out of view when not open */
  opacity: 0; /* Hide when not open */
}

.messaging-container.minimised {
  max-height: calc(var(--clickableHeight) + var(--smallMargin) * 2);
  min-width: 200px;
}

.messaging-header {
  padding: var(--smallMargin);
  background-color: var(--solidlightgrey);
  color: var (--black);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top-left-radius: var(--borderRadiusBigger);
  border-top-right-radius: var(--borderRadiusBigger);
}
.imgContainer {
  width: 100%; /* Adjust the width as necessary, or set a fixed size for a square */
  height: 0; /* Initial height to 0 */
  padding-top: 100%; /* This creates a square aspect ratio */
  position: relative; /* This allows the absolute positioning of the image inside */
  margin: 0 auto; /* Center the container if its width is less than 100% */
  border-radius: var(--borderRadius);
  margin-bottom: var(--smallerMargin);
  border: 0.77px solid var(--solidLighterGrey);
  background-color: var(--backgroundLighterGraySuperTransp);
}

.product-image,
.message-image {
  position: absolute; /* Position the image absolutely within the .imgContainer */
  top: 0;
  left: 0;
  width: 100%; /* Make the image cover the full width of the container */
  height: 100%; /* Make the image cover the full height of the container */
  object-fit: cover; /* This makes the image cover the area, being cropped if necessary */
  border-radius: var(--borderRadius);
}

.wideImage {
  width: 75% !important; /* Ensures this rule takes precedence */
}

.messaging-body {
  padding: var(--smallMargin);
}

.searchContainer {
  padding: var(--smallMargin);
  padding-top: 0;
}
.interactive-options {
  display: flex; /* Use flexbox for horizontal layout */
  justify-content: flex-start; /* Align the buttons to the right */
  gap: var(--smallMargin); /* Add some space between the buttons */
}

.otherUsers {
  padding: var(--smallMargin);
  padding-top: 0;
  min-height: 400px;
  height: 400px;
  max-height: calc(400px + var(--clickableHeight));
  overflow-y: auto;
}

.separator_sticker {
  opacity: 0.6;
}

.messages {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 55vh;
  min-height: 33vh;
  padding: var(--smallMargin);
  box-shadow: var(--neuMorphBoxInnerSmall) !important;
  border-radius: var(--borderRadius);
  padding-bottom: var(--smallMargin);
}

.lock-scroll {
  overflow: hidden;
}

.message-from-user,
.message-from-other {
  padding: var(--smallMargin);
  margin: var(--microMargin) 0;
  border-radius: var(--borderRadiusBigger);
  max-width: 75%;
  word-break: break-word; /* Ensures text breaks to prevent overflow */
}

.message-from-user {
  align-self: flex-end;
  background-color: var(--purple100);
  border-top-right-radius: 0;
}
.message-from-other {
  align-self: flex-start;
  background-color: var(--gray300);
  border-top-left-radius: 0;
}

input[type="text"] {
  width: 100%;
  padding: var(--smallMargin);
  border-radius: var(--borderRadiusBigger);
}

.message-time {
  text-align: right;
  height: var(--smallMargin);
  font-size: var(--smallerFontsize);
  opacity: 0.7;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: var(--microMargin);
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
}
.searchColumn {
  width: 100%;
}

.search-container input {
  width: 100%;
  padding: var(--smallMargin);
  padding-left: calc(var(--smallClickableHeight) * 0.9);
  border-radius: var(--borderRadius);
}

.search-container input:focus {
  padding-left: var(--smallMargin); /* Adjust padding to account for the icon */
}

.search-container .search-icon {
  position: absolute;
  left: var(--smallerMargin);
  pointer-events: none; /* Allow clicks to pass through to the input */
  display: flex;
  align-items: center;
  opacity: 0.6;
}

.search-container input:focus + .search-icon {
  display: none;
}

.search-container input:not(:focus):not(:placeholder-shown) + .search-icon {
  display: flex;
}

/* Initial state for entering */
.slide-right-enter-from {
  transform: translateX(100%);
}

/* Active state for entering */
.slide-right-enter-active {
  transition: transform 0.15s ease-out;
}

/* Final state for entering */
.slide-right-enter-to {
  transform: translateX(0);
}

/* Initial state for leaving */
.slide-right-leave-from {
  transform: translateX(0);
}

/* Active state for leaving */
.slide-right-leave-active {
  transition: transform 0.15s ease-out;
}

/* Final state for leaving */
.slide-right-leave-to {
  transform: translateX(100%);
}

/* SMALL SCREENS */
@media only screen and (max-width: 450px) {
  body.lock-scroll {
    overflow: hidden;
  }
  .messaging-container.open {
    width: 100vw;
    min-width: 100vw;
  }

  .messaging-container.minimised {
    max-height: calc(var(--clickableHeight) + var(--smallMargin) * 2);
    min-width: 200px;
    width: 260px;
  }

  .messages {
    max-height: calc(65vh - var(--clickableHeight));
    height: calc(65vh - var(--clickableHeight));
  }

  .otherUsers {
    max-height: calc(65vh + var(--clickableHeight) + var(--tinyMargin));
    height: calc(65vh + var(--clickableHeight) + var(--tinyMargin));
  }
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);
    background-color: var(--backgroundColourOrgTransp);
    z-index: 3; /* Ensure it's behind the messaging container but above other content */
  }
}
</style>
