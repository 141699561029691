<template>
  <div class="dashboard main_container">
    <div v-if="user.loggedIn" class="userLoggedIn">
      <YourUnitverse />
    </div>
    <div v-else class="userNotLoggedIn">
      <Landing />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { auth } from "@/firebase";
import YourUnitverse from "@/components/DashboardComponents/YourUnitverse.vue";
import Landing from "@/components/LandingComponents/Landing.vue";

export default {
  name: "DashboardView",
  components: {
    YourUnitverse,
    Landing,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    // Watch for authentication state changes
    auth.onAuthStateChanged((user) => {
      store.dispatch("fetchUser", user);
      if (user) {
        store.dispatch("fetchUserCredentials", user);
      }
    });

    const user = computed(() => store.getters.user);
    const userRolePrivileges = computed(() => store.getters.userRolePrivileges);

    // Check for query parameters when view is mounted
    onMounted(() => {
      const querySection = router.currentRoute.value.query.section;
      if (querySection) {
        localStorage.setItem("lastPressedCard", querySection);
        // Set the appropriate index based on the section
        if (querySection === "monetary") {
          localStorage.setItem("lastSelectedIndex", "3");
        }
      }

      // Dispatch the getUserRolePrivileges action
      store.dispatch("getUserRolePrivileges", {
        orgUID: store.getters.currentOrgUID,
      });
    });

    return { user, userRolePrivileges };
  },
};
</script>

<style scoped>
.userLoggedIn,
.userNotLoggedIn {
  width: 100%;
}
</style>
