import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import ClickOutside from "./directives/clickOutside.js";
import hapticDirective from "./directives/hapticDirective.js";

import Vue3Toasity, { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Vue3TouchEvents from "vue3-touch-events";
import { createI18n } from "vue-i18n";
// import { useDragAndDrop } from "vue-fluid-dnd";

import messages from "../locales/loadLocale.js";

const defaultLanguage = localStorage.getItem("selectedLanguage") || "en";
export const i18n = createI18n({
  legacy: false,
  locale: defaultLanguage, // set locale from the storedLanguage or default to 'en'
  fallbackLocale: "pt", // set fallback locale
  messages, // Use the dynamically imported messages
});

const app = createApp(App);

// Initialize the dark mode state from localStorage
app.use(store);
store.dispatch("UIState/initDarkMode");

import { isMobileDevice } from "./utils/toastUtils";

app
  .directive("haptic", hapticDirective)
  .directive("click-outside", ClickOutside)
  .use(router)
  .use(Vue3TouchEvents)
  .use(i18n)
  .use(Vue3Toastify, {
    closeButton: false,
    containerClassName: isMobileDevice()
      ? "toast-container-class"
      : "toast-container-class Toastify__toast-container--center",
    toastClassName: "toast-class",
    bodyClassName: "toast-body-class",
    hideProgressBar: true,
    progressClassName: "progress-bar",
    autoClose: 4000,
    transition: toast.TRANSITIONS.SLIDE,
    position: isMobileDevice()
      ? toast.POSITION.BOTTOM_CENTER
      : toast.POSITION.TOP_CENTER,
    icon: false,
    multiple: true,
    style: {
      opacity: "1",
      userSelect: "initial",
    },
  })
  .mount("#app");

export { messages };
