<template>
  <!-- Fixed container so the canvas is easily visible -->
  <div
    ref="p5Container"
    class="p5-container"
    style="width: 600px; height: 400px; border: 3px solid red; z-index: 9999"
  />
</template>

<script>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import p5 from "p5";

export default {
  name: "P5Sketch",
  props: {
    sketchUrl: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    console.log("[P5Sketch] p5 version:", p5?.VERSION || "(unknown)");
    const p5Container = ref(null);
    let p5Instance = null;

    const loadSketch = async () => {
      console.log("[P5Sketch] loadSketch() container:", p5Container.value);
      if (!p5Container.value) {
        console.warn("[P5Sketch] Container not ready—bailing out.");
        return;
      }
      if (!props.sketchUrl) {
        console.warn("[P5Sketch] No sketchUrl provided.");
        return;
      }

      console.log("[P5Sketch] Fetching code from:", props.sketchUrl);
      try {
        const response = await fetch(props.sketchUrl);
        if (!response.ok) {
          throw new Error(
            `Fetch failed: ${response.status} ${response.statusText}`
          );
        }
        const sketchCode = await response.text();
        console.log(
          "[P5Sketch] Loaded P5 code (first 200 chars):",
          sketchCode.slice(0, 200)
        );

        // Basic check: the code must define setup() and draw()
        if (
          !sketchCode.includes("function setup") ||
          !sketchCode.includes("function draw")
        ) {
          throw new Error("Provided file missing setup() or draw().");
        }

        // Remove any existing p5 instance
        if (p5Instance) {
          console.log("[P5Sketch] Removing old p5 instance.");
          p5Instance.remove();
          p5Instance = null;
        }

        // --- Global Mode Evaluation ---
        // Evaluate the user code as-is so that global setup() and draw() are defined.
        const runGlobalSketch = new Function(`
            console.log("[P5Sketch] Executing user p5 code in global mode...");
            try {
              ${sketchCode}
            } catch (err) {
              console.error("[P5Sketch] Error in user p5 code:", err);
            }
          `);
        runGlobalSketch();

        // Instantiate p5 in global mode by passing null as the first parameter.
        console.log(
          "[P5Sketch] Instantiating p5 in global mode in container:",
          p5Container.value
        );
        p5Instance = new p5(null, p5Container.value);
        console.log("[P5Sketch] p5 instance created:", p5Instance);
      } catch (err) {
        console.error("[P5Sketch] Error while loading sketch:", err);
      }
    };

    // Reload the sketch when sketchUrl changes.
    watch(
      () => props.sketchUrl,
      (newVal, oldVal) => {
        console.log("[P5Sketch] sketchUrl changed:", oldVal, "->", newVal);
        loadSketch();
      }
    );

    onMounted(() => {
      console.log("[P5Sketch] onMounted -> calling loadSketch()");
      loadSketch();
    });

    onBeforeUnmount(() => {
      console.log("[P5Sketch] onBeforeUnmount -> removing p5 instance.");
      if (p5Instance) {
        p5Instance.remove();
      }
    });

    return { p5Container };
  },
};
</script>

<style scoped>
.p5-container {
  display: block;
  overflow: visible;
  background: #fafafa;
}
</style>
