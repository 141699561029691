<template>
  <div class="product-list" @scroll="handleScroll" ref="scroller">
    <div v-if="visibleItems.length === 0" class="product-card placeholder-card">
      <div class="ThumbnailCarouselPlaceholder">
        <small>Your wishlist is empty</small>
      </div>
    </div>
    <div
      class="product-card"
      :class="{
        'product-card-wishlist': wishlist,
        yourProduct: isItemOwnedByCurrentUser(item),
      }"
      v-for="item in visibleItems"
      :key="item.id"
    >
      <ThumbnailCarousel :product="item" :wishlist="wishlist" />
      <div
        class="row justifyToStartEnd alignCentreRow divMicroMarginTopNoMarginBottom"
      >
        <div>
          <small
            ><strong>{{ truncatedItemName(item.itemName) }}</strong></small
          >
        </div>
        <div v-show="!wishlist">
          <template v-if="isItemOwnedByCurrentUser(item)">
            <small class="gray">Yours</small>
          </template>
          <template v-else>
            <div class="product-stock" v-if="item.type === 'product'">
              <small class="soldout" v-if="item.existingStock === 0"
                >Sold</small
              >
              <small
                v-else-if="item.existingStock < 10"
                :class="{ stockWarningColor: item.existingStock < 2 }"
              >
                {{ item.existingStock }} in stock
              </small>
              <small v-else class="grayedOut"> In stock </small>
            </div>
            <small v-if="item.type === 'service'" class="service-label">
              Service
            </small>
          </template>
        </div>
      </div>

      <div
        class="productActions divTinyMarginTop"
        v-show="buyerHasOrganisations && buyerOrgHasCoins"
      >
        <div class="price row" v-show="!wishlist">
          <template v-if="item.type === 'product'">
            <CentTranslatedPrice
              :isProductOwner="isItemOwnedByCurrentUser(item)"
              :product="item"
              @noCoinsInBuyerOrg="handleNoCoinsInBuyerOrg"
              @hasCoinsInBuyerOrg="handleHasCoinsInBuyerOrg"
              @userHasNoOrganisations="handleUserHasNoOrganisations"
              @userHasOrganisations="handleUserHasOrganisations"
              @price-clicked="handlePriceClicked"
            >
              <template v-slot:default>
                <svg
                  v-if="!isItemOwnedByCurrentUser(item)"
                  class="compactImg"
                  alt="view org icon"
                >
                  <use
                    href="@/assets/icons/iconset.svg#message_money_compact"
                  ></use>
                </svg>
              </template>
            </CentTranslatedPrice>
          </template>
          <template v-else>
            <CentTranslatedPrice
              v-if="isItemOwnedByCurrentUser(item)"
              :isProductOwner="isItemOwnedByCurrentUser(item)"
              :product="item"
              @noCoinsInBuyerOrg="handleNoCoinsInBuyerOrg"
              @hasCoinsInBuyerOrg="handleHasCoinsInBuyerOrg"
              @userHasNoOrganisations="handleUserHasNoOrganisations"
              @userHasOrganisations="handleUserHasOrganisations"
              @price-clicked="handlePriceClicked"
            >
              <template v-slot:default>
                <svg
                  v-if="!isItemOwnedByCurrentUser(item)"
                  class="compactImg"
                  alt="view org icon"
                >
                  <use
                    href="@/assets/icons/iconset.svg#message_money_compact"
                  ></use>
                </svg>
              </template>
            </CentTranslatedPrice>
            <button
              v-else
              @click="redirectToProductView(item)"
              class="secondaryButton smallerClickableHeight"
            >
              <span
                class="nomargintopbottom row alignCentreRow"
                v-if="convertedPrices[item.id]"
                ><strong>
                  {{
                    formatNumberTo4Digits(
                      convertedPrices[item.id]?.convertedPrice
                    )
                  }}
                  {{ convertedPrices[item.id]?.coinSymbol }}</strong
                >
                <svg class="compactImg" alt="book a slot icon">
                  <use href="@/assets/icons/iconset.svg#calendar_compact"></use>
                </svg>
              </span>

              <span class="nomargintopbottom" v-else
                >{{ convertedPrices }}Loading…</span
              >
            </button>
          </template>
          <!-- <div
            v-if="isItemOwnedByCurrentUser(item)"
            class="smallText row actionsRow justifyToStartEnd"
          >
            <small class="gray">Yours</small>
          </div> -->
        </div>

        <div
          v-if="!isItemOwnedByCurrentUser(item)"
          class="row actionsRow justifyToStartEnd"
        >
          <div class="imageActions" :class="{ wishlist: !wishlist }">
            <div
              v-if="wishlist"
              @click="removeFromWishlist(item)"
              class="innerSticker clickable wishlist-button"
            >
              <svg class="compactImg">
                <use href="@/assets/icons/iconset.svg#trash_compact"></use>
              </svg>
            </div>

            <div
              v-else-if="isWishlisted[item.id]"
              @click="removeFromWishlist(item)"
              class="secondaryButton innerSticker clickable wishlist-button"
            >
              <svg class="compactImg">
                <use
                  href="@/assets/icons/iconset.svg#addedtowishlist_compact"
                ></use>
              </svg>
            </div>

            <div
              v-else
              @click="addToWishlist(item)"
              class="secondaryButton innerSticker clickable wishlist-button"
            >
              <svg class="compactImg">
                <use
                  href="@/assets/icons/iconset.svg#addtowishlist_compact"
                ></use>
              </svg>
            </div>
          </div>
        </div>
        <div
          v-else
          @click="editItem(item)"
          class="row alignCentreRow ownedByCurrentUser"
        >
          <div class="innerSticker secondaryButton">
            <svg class="compactImg">
              <use href="@/assets/icons/iconset.svg#edit_compact"></use>
            </svg>
            <small>Edit</small>
          </div>
        </div>
      </div>
    </div>
    <Overlay
      :visible="showOverlay"
      :title="dynamicTitle"
      :smallTextAfterTitle="
        currentStep !== 'delete'
          ? 'You can change this later'
          : 'All information related to this item will be permanently deleted.'
      "
      :showBackArrow="shouldShowBackArrow"
      :currentStep="currentStep"
      :currentProduct="currentProduct"
      @close="closeOverlay"
      @previousStep="goToPreviousStep"
    >
      <template #before-buttons="{ product }">
        <AddProductForm
          v-if="
            !isFetching.value &&
            currentProduct &&
            currentProduct.type === 'product'
          "
          ref="addFormRef"
          :currentProduct="currentProduct"
          :userUnitsOrg="userUnitsOrg"
          :userCredentials="userCredentials"
          :currentStep="currentStep"
          @productAddedSuccessfully="handleProductAdded"
          @update-title="handleTitleUpdate"
          @update-canPreview="canPreview = $event"
        />
        <AddServiceForm
          v-if="
            !isFetching.value &&
            currentProduct &&
            currentProduct.type === 'service'
          "
          ref="addFormRef"
          :user="user"
          :currentProduct="currentProduct"
          :userUnitsOrg="userUnitsOrg"
          :userCredentials="userCredentials"
          :currentStep="currentStep"
          @serviceAddedSuccessfully="handleServiceAdded"
          @update-title="handleTitleUpdate"
          @update-canPreview="canPreview = $event"
        />
        <AddMediaForm
          v-if="
            !isFetching.value &&
            currentProduct &&
            currentProduct.type === 'media'
          "
          ref="addFormRef"
          :userCredentials="userCredentials"
          :currentProduct="currentProduct"
          :currentStep="currentStep"
          @mediaAddedSuccessfully="handleMediaAdded"
          @update-title="handleTitleUpdate"
          @update-canPreview="canPreview = $event"
          @update-currentStep="handleStepUpdate"
          @update-itemName="itemName => dynamicTitle = itemName || 'Edit media details'"
        />
        <div v-if="isFetching.value" class="loading-spinner">Loading...</div>
      </template>
      <div class="row alignCentreRow">
        <button
          class="secondaryButton"
          type="button"
          @click="currentStep = 'delete'"
          v-if="currentStep !== 'delete' && currentStep !== 'preview'"
        >
          <svg class="compactImg">
            <use href="@/assets/icons/iconset.svg#trash_compact"></use>
          </svg>
          Delete item
        </button>

        <button
          class="secondaryButton"
          @click="currentStep = 'initial'"
          v-if="!isSaveLoading && currentStep == 'delete'"
          v-haptic
        >
          Go back
        </button>
        <button
          type="button"
          @click="confirmDelete"
          v-if="currentStep == 'delete'"
        >
          <svg class="compactImg">
            <use href="@/assets/icons/iconset.svg#trash_compact"></use>
          </svg>
          Yes, delete
        </button>
        <button
          class="secondaryButton"
          @click="closeOverlay"
          v-if="!isSaveLoading && currentStep !== 'delete'"
          v-haptic
        >
          Cancel
        </button>
        <button
          v-if="
            !canPreview && currentStep !== 'preview' && currentStep !== 'delete'
          "
          @click="validateForm"
        >
          Next
        </button>
        <button
          @click="goToNextStep"
          v-if="
            canPreview &&
            !isSaveLoading &&
            currentStep !== 'preview' &&
            currentStep !== 'delete'
          "
          v-haptic
        >
          Next
        </button>

        <button
          @click="saveItem"
          v-if="currentStep === 'preview' && !isSaveLoading"
          :disabled="isSaveLoading"
          v-haptic
        >
          Save
        </button>
        <button v-if="isSaveLoading" disabled>Processing...</button>
      </div>
    </Overlay>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { formatNumberTo4Digits } from "@/utils/numberFormattingTo4digits";
import { formatNumberTo5Digits } from "@/utils/numberFormattingTo5digits";
import { getConvertedPrice } from "@/utils/priceUtils";

import { ref, computed, onMounted, watch, reactive } from "vue";
import { auth, db } from "@/firebase";
import {
  doc,
  getDoc,
  getDocs,
  deleteDoc,
  setDoc,
  serverTimestamp,
  collection,
  query,
  writeBatch,
} from "firebase/firestore";
import { toast } from "vue3-toastify";

import ThumbnailCarousel from "@/components/DashboardComponents/EcosystemComponents/ProductListComponents/ThumbnailCarousel.vue";
import TranslatedPrice from "@/components/DashboardComponents/EcosystemComponents/ProductListComponents/TranslatedPrice.vue";
import CentTranslatedPrice from "@/components/DashboardComponents/EcosystemComponents/ProductListComponents/CentTranslatedPrice.vue";

import Overlay from "@/components/GenericComponents/Overlay.vue";
import AddProductForm from "@/components/DashboardComponents/EcosystemComponents/AddProductForm.vue";
import AddServiceForm from "@/components/DashboardComponents/EcosystemComponents/AddServiceForm.vue"; // Import AddServiceForm
import AddMediaForm from "@/components/DashboardComponents/EcosystemComponents/AddMediaForm.vue"; // Import AddMediaForm

export default {
  name: "ScrollableMarketplaceList",
  props: {
    sortedProductsAndServices: {
      type: Array,
      required: true,
      default: () => [],
    },
    wishlist: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ThumbnailCarousel,
    TranslatedPrice,
    CentTranslatedPrice,
    Overlay,
    AddProductForm,
    AddServiceForm,
    AddMediaForm, // Register AddMediaForm
  },

  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const numberOfItemsToShow = ref(10);
    const scroller = ref(null);
    const user = computed(() => store.getters.user);
    const orgUID = ref(null);

    const currentUserUid = ref(auth.currentUser ? auth.currentUser.uid : null);
    const userUnitsOrg = ref({});
    const userUnitsFromStore = computed(
      () => store.getters.userUnits_customOrgs
    );
    const isWishlisted = ref({});
    const buyerOrgHasCoins = ref();
    const buyerHasOrganisations = ref(true);

    const currentProduct = ref({}); // Changed to ref instead of computed
    const showOverlay = ref(false);
    const currentStep = ref("initial");
    const deletionStep = ref(false);
    const canPreview = ref(false);
    const isSaveLoading = ref(false);
    const isFetching = ref(false); // Added loading state
    const shouldShowBackArrow = computed(() => {
      return currentStep.value === "preview" || currentStep.value === "delete";
    });
    const addFormRef = ref(null);

    const userCredentials = computed(() => store.getters.userCredentials);
    const convertedPrices = reactive({});
    const saveItem = async () => {
      isSaveLoading.value = true;
      try {
        if (addFormRef.value) {
          if (currentProduct.value.type === "product") {
            await addFormRef.value.addOrUpdateProduct();
          } else if (currentProduct.value.type === "service") {
            await addFormRef.value.addOrUpdateService();
          } else if (currentProduct.value.type === "media") {
            await addFormRef.value.handleSubmit();
          }
          emit("itemEdited");
        } else {
          console.error("Form reference is not correctly set.");
        }
      } catch (error) {
        console.error("Error in saveItem:", error);
      } finally {
        isSaveLoading.value = false;
      }
    };

    const redirectToProductView = (item) => {
      router.push({ name: "ProductView", params: { id: item.id } });
    };

    const fetchConvertedPrice = async (item) => {
      try {
        // console.log(`Fetching converted price for item: ${item.id}`);

        // Pass the price explicitly as priceArg
        const { convertedPrice, coinSymbol } = await getConvertedPrice(
          item,
          item.organisation,
          item.price
        );

        if (!isNaN(convertedPrice)) {
          convertedPrices[item.id] = { convertedPrice, coinSymbol };
          // console.log(
          //   `Fetched price: ${convertedPrice}, symbol: ${coinSymbol} for item: ${item.id}`
          // );
        } else {
          console.warn(`Invalid price calculated for item ${item.id}`);
        }
      } catch (error) {
        console.error(
          `Failed to fetch converted price for item ${item.id}:`,
          error
        );
      }
    };

    const dynamicFormComponent = computed(() => {
      return currentProduct.value && currentProduct.value.type === "service"
        ? "AddServiceForm"
        : "AddProductForm";
    });

    const validateForm = () => {
      if (addFormRef.value) {
        if (currentProduct.value.type === "product") {
          addFormRef.value.handleFormValidation();
        } else if (currentProduct.value.type === "service") {
          addFormRef.value.handleFormValidation();
        }
      } else {
        console.log("addFormRef is null");
      }
    };

    const handleTitleUpdate = (newTitle) => {
      dynamicTitle.value = newTitle;
    };

    const handleProductAdded = async (newProduct) => {
      console.log("handleProductAdded", newProduct);
      toast.success("Product edited successfully");

      isSaveLoading.value = false;

      // Refresh the products list
      await store.dispatch("fetchProducts", {
        batchSize: 10,
        refresh: true,
        orgUID: orgUID.value,
      });

      closeOverlay();
    };

    const handleServiceAdded = async (newService) => {
      console.log("handleServiceAdded", newService);
      toast.success("Service edited successfully");

      isSaveLoading.value = false;

      // Refresh the services list
      await store.dispatch("fetchServices", { batchSize: 10, refresh: true });

      closeOverlay();
    };
    
    const handleMediaAdded = async (newMedia) => {
      console.log("handleMediaAdded", newMedia);
      isSaveLoading.value = true;
      
      try {
        // Determine which collection to use based on media type
        let collectionName = "images"; // Default to images
        if (newMedia.mediaType === "video") {
          collectionName = "videos";
        } else if (newMedia.mediaType === "audio") {
          collectionName = "audios";
        }
        
        // If we have a file, upload it to storage
        if (newMedia.file && !newMedia.file.isExistingFile) {
          console.log("Uploading new media file:", newMedia.file);
          try {
            await store.dispatch("mediaActions/uploadMediaFile", {
              file: newMedia.file,
              type: newMedia.mediaType,
              owner: newMedia.owner,
              organisation: newMedia.organisation
            });
          } catch (err) {
            console.log("Failed with namespaced uploadMediaFile, trying direct import:", err);
            // Import and use the utility directly
            const { uploadMediaFile } = await import("@/utils/uploadUtils.js");
            await uploadMediaFile(newMedia.file);
          }
        }
        
        // If we have an ID, update the existing record; otherwise, create a new one
        if (newMedia.id) {
          console.log("Updating existing media with ID:", newMedia.id);
          try {
            await store.dispatch("mediaActions/updateMediaItem", {
              id: newMedia.id,
              collection: collectionName,
              data: {
                itemName: newMedia.itemName,
                caption: newMedia.caption,
                // Use the standardized images array format
                images: newMedia.images || [newMedia.mediaUrl].filter(Boolean),
                type: "media",
                mediaType: newMedia.mediaType,
                organisation: newMedia.organisation,
                owner: newMedia.owner,
                thumbnailIndex: 0,
                // If updatedAt isn't already there, add it
                updatedAt: serverTimestamp()
              }
            });
          } catch (err) {
            console.log("Failed with namespaced action, trying non-namespaced:", err);
            // Fall back to directly calling the action in the store
            await store.dispatch("updateMedia", {
              id: newMedia.id,
              collection: collectionName,
              data: {
                itemName: newMedia.itemName,
                caption: newMedia.caption,
                images: newMedia.images || [newMedia.mediaUrl].filter(Boolean),
                type: "media",
                mediaType: newMedia.mediaType,
                organisation: newMedia.organisation,
                owner: newMedia.owner,
                thumbnailIndex: 0,
                updatedAt: serverTimestamp()
              }
            });
          }
          toast.success("Media updated successfully");
        } else {
          console.log("Adding new media item");
          try {
            await store.dispatch("mediaActions/addMediaPost", {
              itemName: newMedia.itemName,
              caption: newMedia.caption,
              // Use the standardized images array format
              images: newMedia.images || [newMedia.mediaUrl].filter(Boolean),
              type: "media",
              mediaType: newMedia.mediaType,
              owner: newMedia.owner,
              organisation: newMedia.organisation,
              thumbnailIndex: 0
            });
          } catch (err) {
            console.log("Failed with namespaced action, trying non-namespaced:", err);
            // Fall back to directly calling the action in the store
            await store.dispatch("addMedia", {
              itemName: newMedia.itemName,
              caption: newMedia.caption,
              images: newMedia.images || [newMedia.mediaUrl].filter(Boolean),
              type: "media",
              mediaType: newMedia.mediaType,
              owner: newMedia.owner,
              organisation: newMedia.organisation,
              thumbnailIndex: 0
            });
          }
          toast.success("Media added successfully");
        }
        
        // Refresh the wishlist if we're in wishlist view
        if (props.wishlist) {
          emit("wishlistUpdated");
        }
      } catch (error) {
        console.error("Error saving media:", error);
        toast.error("Error saving media: " + error.message);
      } finally {
        isSaveLoading.value = false;
        closeOverlay();
      }
    };
    
    const handleStepUpdate = (stepData) => {
      if (stepData && stepData.step) {
        currentStep.value = stepData.step;
      }
    };

    auth.onAuthStateChanged((userCredentials) => {
      store.dispatch("fetchUserCredentials", userCredentials);
    });

    watch(currentProduct, (newVal) => {
      if (newVal && newVal.id) {
        showOverlay.value = true;
      }
    });

    watch(showOverlay, (newValue) => {
      if (newValue) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    });

    const openOverlay = () => {
      currentStep.value = "initial";
      showOverlay.value = true;
      store.dispatch("toggleNavbarVisibility", false);
    };

    const closeOverlay = () => {
      showOverlay.value = false;
      currentStep.value = "initial";
      store.dispatch("clearProduct");
      store.dispatch("toggleNavbarVisibility", true);
    };

    const dynamicTitle = ref("Edit product details");

    const goToNextStep = () => {
      if (!addFormRef.value.handleFormValidation()) {
        return;
      }
      if (currentProduct.value.type === "product") {
        if (currentStep.value === "initial") {
          currentStep.value = "preview";
        }
      } else if (currentProduct.value.type === "service") {
        if (currentStep.value === "initial") {
          currentStep.value = "availability";
        } else if (currentStep.value === "availability") {
          if (
            addFormRef.value.selectedDays &&
            addFormRef.value.selectedDays.filter((day) => day).length === 0
          ) {
            addFormRef.value.gatherServiceDataForPreview();
            currentStep.value = "preview";
          } else {
            currentStep.value = "customization";
          }
        } else if (currentStep.value === "customization") {
          addFormRef.value.gatherServiceDataForPreview();
          currentStep.value = "preview";
        }
      }
    };

    const goToPreviousStep = () => {
      if (currentProduct.value.type === "product") {
        if (currentStep.value === "preview" || currentStep.value === "delete") {
          currentStep.value = "initial";
        }
      } else {
        if (currentStep.value === "customization") {
          currentStep.value = "availability";
        } else if (currentStep.value === "availability") {
          currentStep.value = "initial";
        } else if (currentStep.value === "preview") {
          currentStep.value = "availability";
        }
      }
    };

    const deleteSubcollection = async (
      parentCollection,
      parentId,
      subcollection
    ) => {
      const subcollectionRef = collection(
        db,
        parentCollection,
        parentId,
        subcollection
      );
      const snapshot = await getDocs(subcollectionRef);

      if (snapshot.empty) {
        console.log(`No documents found in ${subcollection} subcollection.`);
        return;
      }

      const batch = writeBatch(db);
      snapshot.forEach((docSnap) => {
        batch.delete(docSnap.ref);
      });

      await batch.commit();
      console.log(
        `Deleted all documents in ${subcollection} subcollection for ${parentCollection}/${parentId}.`
      );
    };

    const confirmDelete = async () => {
      console.log("Confirming delete of item in ScrollableMarketplaceList:", currentProduct.value);
      try {
        isSaveLoading.value = true; // Start loading indicator

        if (!currentProduct.value || !currentProduct.value.id) {
          throw new Error("No item ID found.");
        }

        // Determine the correct collection based on the item type
        let parentCollection;
        let refreshAction;

        if (currentProduct.value.type === "product") {
          parentCollection = "products";
          refreshAction = async () => {
            await store.dispatch("fetchProducts", {
              batchSize: 10,
              refresh: true,
              orgUID: orgUID.value,
            });
          };
        } else if (currentProduct.value.type === "service") {
          parentCollection = "services";
          refreshAction = async () => {
            await store.dispatch("fetchServices", {
              batchSize: 10,
              refresh: true,
            });
          };
        } else if (currentProduct.value.type === "media") {
          // For media items, check the mediaType to determine the collection
          const mediaType = currentProduct.value.mediaType;
          if (mediaType === "image") {
            parentCollection = "images";
          } else if (mediaType === "video") {
            parentCollection = "videos";
          } else if (mediaType === "audio") {
            parentCollection = "audios";
          } else {
            parentCollection = "images"; // Default to images if no specific type
          }
          
          refreshAction = async () => {
            // For wishlist, just emit an event to refresh the list
            emit("wishlistUpdated");
          };
        } else {
          throw new Error(`Unknown item type: ${currentProduct.value.type}`);
        }

        const parentId = currentProduct.value.id;
        console.log(`Deleting item from collection: ${parentCollection} with ID: ${parentId}`);

        // Delete the subcollection first if it's a service
        if (currentProduct.value.type === "service") {
          await deleteSubcollection(parentCollection, parentId, "slots");
        }

        // Then delete the main document
        await deleteDoc(doc(db, parentCollection, parentId));

        toast.success(`${currentProduct.value.type === "media" ? "Media" : currentProduct.value.type} deleted successfully.`);

        // Refresh the relevant list
        await refreshAction();

        emit("itemEdited");
        closeOverlay();
      } catch (error) {
        toast.error(
          `Failed to delete ${currentProduct.value.type || "item"}: ${error.message}`
        );
        console.error(`Deletion Error:`, error);
      } finally {
        isSaveLoading.value = false; // End loading indicator
      }
    };

    const checkInitialState = () => {
      const customOrgs = store.getters.userUnits_customOrgs || {};
      const hasOrgs = Object.keys(customOrgs).length > 0;
      if (!hasOrgs) {
        handleUserHasNoOrganisations();
      } else {
        handleUserHasOrganisations();
      }

      const orgDetails = store.getters.getBuyerOrgDetails(
        store.state.currentOrgUID
      );
      if (!orgDetails || orgDetails.totalOrgCoins === 0) {
        handleNoCoinsInBuyerOrg();
      } else {
        handleHasCoinsInBuyerOrg();
      }
    };

    const hasTransactions = ref(false);

    const checkOrgForTransactions = async () => {
      if (!props.orgUID) {
        console.error("orgUID is null or undefined.");
        return;
      }

      const orgDocRef = doc(db, "custom-orgs", props.orgUID);
      const orgDocSnap = await getDoc(orgDocRef);

      if (orgDocSnap.exists()) {
        hasTransactions.value = orgDocSnap.data().hasTransactions || false;
      }
    };

    watch(
      () => props.orgUID,
      (newVal, oldVal) => {
        if (newVal !== oldVal && newVal !== undefined) {
          checkOrgForTransactions();
        }
      }
    );

    const handleNoCoinsInBuyerOrg = () => {
      buyerOrgHasCoins.value = false;
    };

    const handleHasCoinsInBuyerOrg = () => {
      buyerOrgHasCoins.value = true;
    };

    const handleUserHasNoOrganisations = () => {
      buyerHasOrganisations.value = false;
    };

    const handleUserHasOrganisations = () => {
      buyerHasOrganisations.value = true;
    };

    const handlePriceClicked = async (eventData) => {
      // console.log("handlePriceClicked called with eventData:", eventData);

      const { product, convertedPrice, buyerCoinSymbol, sellerCoinSymbol } =
        eventData;

      // console.log("Product inside handlePriceClicked:", product);
      // console.log("Converted Price:", convertedPrice);
      // console.log("Coin Symbol:", coinSymbol);
      const actionPayload = {
        product,
        convertedPrice,
        buyerCoinSymbol,
        sellerCoinSymbol,
      };

      // console.log("Action Payload for store.dispatch:", actionPayload);

      await store.dispatch("UIState/fetchOtherUserData", actionPayload);
      await store.dispatch("UIState/setMessagesOpen", true);
      await store.dispatch("UIState/setMessagesMaximised", true);
      await store.dispatch("UIState/setShowInteractiveMessage", true);
    };

    watch(buyerOrgHasCoins, (newValue) => {
      buyerHasOrganisations.value = newValue;
    });

    watch(buyerHasOrganisations, (newValue) => {
      buyerHasOrganisations.value = newValue;
    });

    const isItemOwnedByCurrentUser = computed(() => {
      return (item) => {
        return item.owner === currentUserUid.value;
      };
    });

    const truncatedItemName = (itemName) => {
      if (itemName && itemName.length > 16) {
        const firstPart = itemName.substring(0, 10).trim();
        const lastPart = itemName.substring(itemName.length - 3).trim();
        return `${firstPart}(…)${lastPart}`;
      }
      return itemName;
    };

    const truncatedItemDesc = (itemDesc) => {
      return itemDesc && itemDesc.length > 54
        ? `${itemDesc.substring(0, 54)}...`
        : itemDesc;
    };

    const formatPrice = (price) => {
      return formatNumberTo5Digits(price);
    };

    const handleScroll = () => {
      const { scrollLeft, scrollWidth, clientWidth } = scroller.value;
      if (scrollLeft + clientWidth >= scrollWidth - 100) {
        numberOfItemsToShow.value += 10;
      }
    };

    const addToCart = async (item) => {
      toast.error("Feature is under development.", {
        autoClose: 3000,
      });
    };

    const removeFromWishlist = async (item) => {
      if (currentUserUid.value) {
        try {
          const wishlistDocRef = doc(
            db,
            "users",
            currentUserUid.value,
            "Wishlist",
            item.id
          );
          await deleteDoc(wishlistDocRef);
          isWishlisted.value[item.id] = false;
          toast.success("Removed from wishlist.", { autoClose: 3000 });
          emit("wishlistUpdated");
        } catch (error) {
          isWishlisted.value[item.id] = true;
          console.error("Error removing from wishlist: ", error);
          toast.error("Error removing from wishlist.", { autoClose: 3000 });
        }
      } else {
        toast.error(
          "You must be logged in to remove items from the wishlist.",
          {
            autoClose: 3000,
          }
        );
      }
    };

    const addToWishlist = async (item) => {
      if (currentUserUid.value) {
        try {
          const wishlistDocRef = doc(
            db,
            "users",
            currentUserUid.value,
            "Wishlist",
            item.id
          );
          await setDoc(wishlistDocRef, {
            ...item,
            createdAt: serverTimestamp(),
          });
          isWishlisted.value[item.id] = true;
          emit("wishlistUpdated");
          toast.success("Added to wishlist.", { autoClose: 3000 });
        } catch (error) {
          console.error("Error adding to wishlist: ", error);
          toast.error("Error adding to wishlist.", { autoClose: 3000 });
        }
      } else {
        toast.error("You must be logged in to add items to wishlist.", {
          autoClose: 3000,
        });
      }
    };

    const getCurrencySymbol = (item) => {
      return item.coinSymbol || "Ʉ";
    };

    const editItem = async (item) => {
      // console.log(item.type);
      isFetching.value = true; // Set loading state to true
      console.log("Editing item in ScrollableMarketplaceList:", item);
      try {
        if (item.type === "service") {
          await store.dispatch("fetchService", item.id);
          // console.log("Fetched service successfully.");
          currentProduct.value = { ...item, type: "service" }; // Explicitly set the type
        } else if (item.type === "product") {
          await store.dispatch("fetchProduct", item.id);
          // console.log("Fetched product successfully.");
          currentProduct.value = { ...item, type: "product" }; // Explicitly set the type
        } else if (item.mediaType === "image" || item.type === "image") {
          // For media items like images, videos, or audio
          console.log("Setting media item for editing:", item);
          
          // Clone the item to avoid modifying the original
          const mediaItem = { ...item };
          
          // Make sure we have the correct field for image preview
          if (item.images && Array.isArray(item.images) && item.images.length > 0) {
            mediaItem.mediaUrl = item.images[0];
          } else if (item.mediaUrl) {
            // mediaUrl field already exists
          } else if (item.image) {
            mediaItem.mediaUrl = item.image;
          }
          
          // Make sure we have the correct media type field
          if (!mediaItem.mediaType) {
            // Try to infer mediaType from other properties
            if (mediaItem.mediaUrl?.includes('.mp4') || mediaItem.mediaUrl?.includes('video')) {
              mediaItem.mediaType = "video";
            } else if (mediaItem.mediaUrl?.includes('.mp3') || mediaItem.mediaUrl?.includes('audio')) {
              mediaItem.mediaType = "audio";
            } else {
              mediaItem.mediaType = "image"; // Default to image
            }
          }
          
          // Check for different title field naming
          if (mediaItem.title && !mediaItem.itemName) {
            mediaItem.itemName = mediaItem.title;
          }
          
          // Check for different description field naming
          if (mediaItem.description && !mediaItem.caption) {
            mediaItem.caption = mediaItem.description;
          } else if (mediaItem.itemDescription && !mediaItem.caption) {
            mediaItem.caption = mediaItem.itemDescription;
          }
          
          console.log("Prepared media item:", mediaItem);
          currentProduct.value = { ...mediaItem, type: "media" };
        }
        openOverlay();
      } catch (error) {
        console.error(`Failed to fetch ${item.type || item.mediaType}:`, error);
      } finally {
        isFetching.value = false; // Set loading state to false
      }
    };
    onMounted(async () => {
      // console.log("Component mounted. Starting to fetch converted prices...");
      const orgFetchPromises = props.sortedProductsAndServices.map((item) => {
        if (item.type === "service") {
          return fetchConvertedPrice(item);
        }
        return store.dispatch("fetchAndCommitOrgMembersCount", {
          orgUID: item.organisation,
          context: "seller",
        });
      });

      await Promise.all(orgFetchPromises);
      // console.log("Finished fetching all prices and organization data.");
      checkInitialState();

      // Initialize isWishlisted
      if (currentUserUid.value) {
        const q = query(
          collection(db, "users", currentUserUid.value, "Wishlist")
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          isWishlisted.value[doc.id] = true;
        });
      }
    });

    const visibleItems = computed(() => {
      return props.sortedProductsAndServices
        .filter((item) => {
          const orgDetails = store.getters.getSellerOrgDetails(
            item.organisation
          );
          return orgDetails && orgDetails.membersCount > 0;
        })
        .map((item) => {
          if (item.availability) {
            item.type = "service";
          } else if (item.existingStock !== undefined) {
            item.type = "product";
          }
          return item;
        })
        .slice(0, numberOfItemsToShow.value);
    });

    return {
      userCredentials,
      userUnitsOrg,
      visibleItems,
      handleScroll,
      scroller,
      removeFromWishlist,
      addToWishlist,
      addToCart,
      isWishlisted,
      formatPrice,
      truncatedItemName,
      truncatedItemDesc,
      getCurrencySymbol,
      isItemOwnedByCurrentUser,
      editItem,

      buyerOrgHasCoins,
      buyerHasOrganisations,
      handleNoCoinsInBuyerOrg,
      handleHasCoinsInBuyerOrg,
      handleUserHasNoOrganisations,
      handleUserHasOrganisations,
      handlePriceClicked,
      checkInitialState,
      hasTransactions,

      showOverlay,
      openOverlay,
      closeOverlay,
      dynamicTitle,
      currentStep,
      deletionStep,
      goToNextStep,
      goToPreviousStep,
      shouldShowBackArrow,
      handleTitleUpdate,
      handleProductAdded,
      handleServiceAdded,
      handleMediaAdded,
      handleStepUpdate,
      isSaveLoading,
      canPreview,
      validateForm,
      addFormRef,
      currentProduct,
      saveItem,
      confirmDelete,
      dynamicFormComponent,
      isFetching,
      user,
      redirectToProductView,
      formatNumberTo4Digits,
      convertedPrices,
      fetchConvertedPrice,
    };
  },
};
</script>

<style scoped>
.product-list {
  overflow-x: auto;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  box-shadow: var(--neuMorphBoxInner);
  border-radius: var(--borderRadiusBigger);
  height: auto;
  margin-bottom: var(--bigMargin);
}
.product-card {
  position: relative;
  display: inline-block;
  scroll-snap-align: start;
  width: 25%;
  padding: var(--smallerMargin) var(--smallerMargin) var(--smallMargin)
    var(--smallerMargin);
  height: auto;
  border-radius: var(--borderRadius);
  vertical-align: top;
}

.productActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.product-card-wishlist {
  width: 20%;
  padding-bottom: var(--microMargin) !important;
}
.yourProduct {
  opacity: 0.6;
}

.ThumbnailCarouselPlaceholder {
  position: relative;
  width: 100%;
  display: flex;
  height: 14vh;
  overflow: hidden;
  border-radius: var(--borderRadius);
}

.actionsRow {
  align-items: center;
}
.firstProdDescRow {
  align-items: baseline;
}

.innerSticker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--smallerClickableHeight);
  height: var(--smallerClickableHeight);
  border-radius: 50%;
  background-color: var(--lightGrey);
  padding: var(--tinyMargin);
  cursor: pointer;
}
.wishlist-button {
  border: transparent !important;
  padding: 2px 0 2px 4px !important;
}

.ownedByCurrentUser .innerSticker {
  width: fit-content;
  border-radius: var(--borderRadiusButton);
  gap: var(--tinyMargin);
  padding: 0 var(--smallMargin);
}

.descCont {
  margin-top: var(--smallMargin);
  margin-bottom: var(--smallMargin);
  overflow-wrap: break-word;
  white-space: normal;
}

.stockWarningColor {
  color: var(--red);
}
.grayedOut,
.soldout,
.service-label {
  color: var(--solidMidgrey);
}

@media (max-width: 590px) {
  .product-card {
    width: 50%;
    padding: var(--smallerMargin) var(--smallerMargin) var(--smallMargin)
      var(--smallerMargin);
  }
  .product-card-wishlist {
    width: 33%;
  }
}
</style>
