<template>
  <div class="addProductForm divMarginTop" ref="imageUploadRef">
    <form ref="formRef" @submit.prevent="addOrUpdateProduct">
      <div
        class="fullWidth column alignCentre"
        v-show="currentStep === 'initial'"
      >
        <div class="fullWidth">
          <label for="itemName">Product name</label>
          <input
            class="fullWidth alignCentre"
            type="text"
            id="itemName"
            v-model="itemName"
            required
            ref="nameInput"
            :class="{ 'highlight-input': highlightName }"
          />
        </div>

        <div class="fullWidth">
          <label for="itemDescription">Description</label>
          <textarea
            class="fullWidth"
            id="itemDescription"
            v-model="itemDescription"
            required
            ref="descriptionInput"
            :class="{ 'highlight-input': highlightDescription }"
          ></textarea>
          <AlertCard
            v-if="isTextTooLong"
            type="warning"
            id="char-limit-warning"
            iconId="error_warning"
            :canBeDismissed="false"
          >
            <template #text>
              Description cannot exceed 1500 characters.
              <p class="smallText nomargintopbottom">
                Characters: {{ characterCount }} / 1500
              </p>
            </template>
          </AlertCard>
        </div>
        <div class="fullWidth">
          <ImageUpload
            @updateImages="handleImageUpdates"
            @updateThumbnail="updateThumbnailIndex"
            :newImages="selectedFiles"
            :existingImages="existingImageUrls"
            :thumbnailIndex.sync="thumbnailIndex"
          />
        </div>
        <div class="row fullWidth">
          <div class="fullWidth">
            <label for="existingStock">Existing stock</label>
            <input
              type="number"
              id="existingStock"
              v-model.number="existingStock"
              required
              class="alignCentre fullWidth"
            />
          </div>
          <div class="fullWidth">
            <label for="price">Price/Item in {{ coinSymbol }}</label>
            <input
              type="number"
              id="price"
              v-model.number="price"
              required
              class="alignCentre fullWidth"
              ref="priceInput"
              :class="{ 'highlight-input': highlightPrice }"
              @blur="validatePrice"
              min="0"
            />
          </div>
        </div>
        <div class="row">
          <div class="category">
            <label for="category">Category</label>
            <select
              id="category"
              v-model="selectedCategory"
              @change="updateConditions"
            >
              <option
                v-for="category in categories"
                :key="category.name"
                :value="category.name"
              >
                {{ category.name }}
              </option>
            </select>
          </div>
          <div>
            <label for="condition">Condition</label>
            <select id="condition" v-model="selectedCondition">
              <option
                v-for="condition in currentConditions"
                :key="condition"
                :value="condition"
              >
                {{ condition }}
              </option>
            </select>
          </div>
        </div>
        <div class="setLocationFormGroup">
          <div class="row alignCentreRow fullWidth justifyToStartEnd">
            <ToggleSwitch
              label="Location"
              v-model="showMap"
              :justifyToStartEnd="true"
              :hideStatusText="true"
            >
              <template v-slot:tooltip>
                <Tooltip
                  :message="'Toggle ON to enable allow customers to see where the product is located. Toggle OFF to hide the location.'"
                />
              </template>
            </ToggleSwitch>
            <!-- <ToggleSwitch
              label="Public"
              v-model="isPublished"
              :justifyToEnd="true"
              :hideStatusText="true"
            >
              <template v-slot:tooltip>
                <Tooltip
                  :message="'Toggle ON to publish the product to the public. Toggle OFF to keep the product as a draft and publish later. You can change this later.'"
                />
              </template>
            </ToggleSwitch> -->
          </div>

          <SetLocation
            :showMap="showMap"
            :lastPickedLocation="location"
            @update-location="updateLocation"
          />
        </div>
      </div>
      <PreviewItem
        v-show="currentStep === 'preview'"
        :userUnitsOrg="userUnitsOrg"
        :coinSymbol="coinSymbol"
        :productData="gatheredProductData"
        :thumbnailIndex="thumbnailIndex"
      />
      <div v-if="currentStep === 'delete'" class="delete-confirmation">
        <p>Are you sure you want to delete this item?</p>
      </div>
    </form>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, reactive, watch, computed, watchEffect, onMounted } from "vue";
import {
  collection,
  addDoc,
  serverTimestamp,
  setDoc,
  doc,
} from "firebase/firestore";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import { db } from "@/firebase";
import { toast } from "vue3-toastify";
import ImageUpload from "@/components/GenericComponents/ImageUpload.vue";
import PreviewItem from "@/components/DashboardComponents/EcosystemComponents/PreviewItem.vue";
import SetLocation from "@/components/DashboardComponents/EcosystemComponents/SetLocation.vue";
import ToggleSwitch from "@/components/GenericComponents/ToggleSwitch.vue";
import StepCounter from "@/components/GenericComponents/StepCounter.vue";
import Tooltip from "@/components/GenericComponents/Tooltip.vue";
import AlertCard from "@/components/GenericComponents/AlertCard.vue";

export default {
  name: "AddProductForm",
  components: {
    ImageUpload,
    PreviewItem,
    SetLocation,
    ToggleSwitch,
    StepCounter,
    Tooltip,
    AlertCard,
  },
  props: {
    currentProduct: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    userUnitsOrg: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    userCredentials: {
      type: Object,
      required: false,
    },
    currentStep: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    console.log("userUnitsOrg in product form:", props.userUnitsOrg);

    const nameInput = ref(null);
    const descriptionInput = ref(null);
    const priceInput = ref(null);

    const itemName = ref("");
    const itemDescription = ref("");
    const price = ref(0);

    const highlightName = ref(false);
    const highlightDescription = ref(false);
    const highlightPrice = ref(false);
    const highlightFields = ref(false);

    const existingStock = ref(0);
    const selectedCategory = ref("Other");
    const selectedCondition = ref("New");
    const showPreview = ref(false);
    const selectedFiles = ref([]);
    const existingImageUrls = ref([]);
    const gatheredProductData = ref({});

    const editedImages = ref([]); // Stores objects { oldUrl, newFile }
    const removedImageUrls = ref([]); // Stores URLs of images to delete

    const hasSelectedFilesOrImages = computed(
      () => selectedFiles.value.length > 0 || existingImageUrls.value.length > 0
    );

    const isPublished = ref(true);
    const formRef = ref(null);
    const thumbnailIndex = ref(0);
    const isProcessing = ref(false);
    const location = ref(null);
    const showMap = ref(false);
    const canPreview = computed(() => {
      const hasName = itemName.value.trim() !== "";
      const hasDescription = itemDescription.value.trim() !== "";
      const hasValidPrice = price.value > 0 && !isNaN(parseFloat(price.value));

      return hasName && hasDescription && hasValidPrice;
    });

    const handleStepUpdate =
      (formType) =>
      ({ form, step }) => {
        if (form === formType) {
          currentStep.value = step;
        }
      };

    watchEffect(() => {
      emit("update-canPreview", canPreview.value);
    });
    emit("update-currentStep", { form: "product", step: "nextStep" });

    const isTextTooLong = computed(() => {
      return itemDescription.value.length > 1500;
    });

    const characterCount = computed(() => itemDescription.value.length);

    const getStorageRefFromUrl = (url) => {
      const storage = getStorage();
      const decodedUrl = decodeURIComponent(url);
      const baseUrl = `https://firebasestorage.googleapis.com/v0/b/${storage.app.options.storageBucket}/o/`;
      const path = decodedUrl.replace(baseUrl, "").split("?alt=media")[0];
      return storageRef(storage, path);
    };

    const replaceImage = async (oldImageUrl, newFile) => {
      try {
        const storage = getStorage();
        const fileRef = storageRef(storage, oldImageUrl); // Use storageRef
        await uploadBytes(fileRef, newFile);
        const newUrl = await getDownloadURL(fileRef);
        return newUrl;
      } catch (error) {
        console.error(`Failed to replace image at URL: ${oldImageUrl}`, error);
        throw error;
      }
    };

    // Function to delete an image from Firebase Storage using its URL
    const deleteImageByUrl = async (url) => {
      try {
        const fileRef = getStorageRefFromUrl(url);
        await deleteObject(fileRef);
        console.log(`Deleted image at URL: ${url}`);
      } catch (error) {
        console.error(`Failed to delete image at URL: ${url}`, error);
      }
    };

    const handleFormValidation = () => {
      resetHighlights();
      let firstInvalidField = null;
      let errors = [];

      if (!itemName.value.trim()) {
        highlightName.value = true;
        firstInvalidField = firstInvalidField || nameInput;
        errors.push("Product name is required.");
      }

      if (!itemDescription.value.trim()) {
        highlightDescription.value = true;
        firstInvalidField = firstInvalidField || descriptionInput;
        errors.push("Description is required.");
      }

      validatePrice(); // Adjust price if necessary

      if (
        price.value === null ||
        price.value === undefined ||
        price.value === ""
      ) {
        highlightPrice.value = true;
        firstInvalidField = firstInvalidField || priceInput;
        errors.push("Price cannot be empty.");
      } else if (isNaN(parseFloat(price.value))) {
        highlightPrice.value = true;
        firstInvalidField = firstInvalidField || priceInput;
        errors.push("Please enter a valid number for the price.");
      } else if (parseFloat(price.value) <= 0) {
        highlightPrice.value = true;
        firstInvalidField = firstInvalidField || priceInput;
        errors.push(
          parseFloat(price.value) < 0
            ? "Price cannot be negative."
            : "Price must be greater than zero."
        );
      }

      if (errors.length > 0) {
        if (firstInvalidField) {
          firstInvalidField.value.focus();
          firstInvalidField.value.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        toast.error(errors.join(" "));
        return false; // Indicate validation failure
      } else {
        resetHighlights();
        showPreview.value = true;
        gatherDataForPreview(); // Add this line
        return true; // Indicate validation success
      }
    };

    const validatePrice = async () => {
      if (!organisationUID.value) {
        console.error("Organisation UID not found.");
        return;
      }

      await store.dispatch("fetchAndCommitOrgTotalCoins", {
        orgUID: organisationUID.value,
        context: "seller",
      });

      const totalOrgCoins = store.getters.getSellerOrgTotalCoins(
        organisationUID.value
      );

      if (parseFloat(price.value) > totalOrgCoins) {
        toast.error(
          `The price cannot exceed the sum of all ${totalOrgCoins} existing ${coinName.value}s (${coinSymbol.value}).`
        );
        price.value = totalOrgCoins;
      }

      highlightPrice.value = false; // Ensure highlight is reset if corrected
      gatherDataForPreview(); // Ensure the preview data is updated after price validation
    };

    const highlightField = (field) => {
      switch (field) {
        case "name":
          nameInput.value?.focus();
          highlightName.value = true;
          break;
        case "description":
          descriptionInput.value?.focus();
          highlightDescription.value = true;
          break;
        case "price":
          priceInput.value?.focus();
          highlightPrice.value = true;
          break;
      }
      toast.error(`Please enter a valid ${field}.`);
    };

    const resetHighlights = () => {
      highlightName.value = false;
      highlightDescription.value = false;
      highlightPrice.value = false;
    };

    const focusAndHighlight = () => {
      if (nameInput.value) {
        nameInput.value.focus();
      }
      highlightFields.value = true;
      setTimeout(() => {
        highlightFields.value = false;
      }, 3000);
    };

    const handleSetLocation = () => {
      showMap.value = true;
    };
    const updateLocation = (newLocation) => {
      location.value = newLocation;
    };

    const coinSymbol = computed(() => {
      if (!props.userUnitsOrg) return "Ʉ";
      const firstKeyData = Object.values(props.userUnitsOrg)[0];
      return firstKeyData?.monetary_parameters?.coin_symbol || "Ʉ";
    });
    const coinName = computed(() => {
      if (!props.userUnitsOrg) return "Unit";
      const firstKeyData = Object.values(props.userUnitsOrg)[0];
      return firstKeyData?.monetary_parameters?.coin_name || "Unit";
    });

    const categories = reactive([
      {
        name: "Technology",
        type: "both", // 'product', 'service', or 'both'
        conditions: [
          "New",
          "Open Box",
          "Refurbished",
          "Used",
          "For Parts/Not Working",
        ],
      },
      {
        name: "Fashion & Beauty",
        type: "both",
        conditions: [
          "New with Tags",
          "New without Tags",
          "Used",
          "Like New",
          "Good",
          "Acceptable",
        ],
      },
      {
        name: "Home & Living",
        type: "both",
        conditions: [
          "New",
          "Used",
          "Refurbished",
          "Like New",
          "Good",
          "Acceptable",
        ],
      },
      {
        name: "Arts & Culture",
        type: "both",
        conditions: ["New", "Used", "Collectible", "Digital Goods"],
      },
      {
        name: "Business",
        type: "both",
        conditions: ["New", "Used", "Refurbished"],
      },
      {
        name: "Health & Wellness",
        type: "both",
        conditions: ["New", "Open Box", "Used", "For Parts/Not Working"],
      },
      {
        name: "Sports & Outdoors",
        type: "both",
        conditions: ["New", "Used", "Refurbished"],
      },
      {
        name: "Transportation",
        type: "both",
        conditions: ["New", "Used", "Refurbished", "For Parts/Not Working"],
      },

      {
        name: "Other",
        type: "both",
        conditions: ["New", "Used", "Refurbished"],
      },
    ]);

    const currentConditions = ref(categories[0].conditions);

    const updateConditions = () => {
      const category = categories.find(
        (cat) => cat.name === selectedCategory.value
      );
      if (category) {
        currentConditions.value = category.conditions;
        selectedCondition.value = category.conditions[0];
      }
    };

    const handleImageUpdates = (
      updatedImages,
      removedUrls,
      editedImagesList,
      imagePreviewUrls // Add this parameter
    ) => {
      console.log("handleImageUpdates called");
      console.log("Before update:", {
        existingImageUrls: existingImageUrls.value,
        selectedFiles: selectedFiles.value,
      });

      // Separate updatedImages into existing URLs and new files
      existingImageUrls.value = updatedImages.filter(
        (image) => typeof image === "string"
      );

      selectedFiles.value = updatedImages.filter(
        (image) => image instanceof File
      );

      // Collect URLs of removed images
      removedImageUrls.value = removedUrls;

      // Collect edited images
      editedImages.value = editedImagesList;

      // Update gatheredProductData with the updated list of image previews
      gatheredProductData.value.imagePreviews = imagePreviewUrls;

      console.log("After update:", {
        existingImageUrls: existingImageUrls.value,
        selectedFiles: selectedFiles.value,
      });
    };

    watch(
      () => props.currentStep,
      (newStep) => {
        if (newStep === "initial") {
          // Set title based on whether we're editing an existing product or adding new one
          if (props.currentProduct && props.currentProduct.id) {
            emit("update-title", "Edit Product Details");
          } else {
            emit("update-title", "Add Product");
          }
          showPreview.value = false;
        } else if (newStep === "preview") {
          showPreview.value = true;
          emit("update-title", "Preview");
          gatherDataForPreview();
        } else if (newStep === "delete") {
          // Allow delete step without showing validation errors
          emit("update-title", "Delete Confirmation");
        }
      }
    );
    
    // Watch for changes in currentProduct to update form title if needed
    watch(
      () => props.currentProduct,
      (newProduct) => {
        if (newProduct && Object.keys(newProduct).length > 0 && newProduct.id) {
          console.log("[AddProductForm] Editing existing product:", newProduct.id);
          // Set title for editing if we're on the initial step
          if (props.currentStep === "initial") {
            emit("update-title", "Edit Product Details");
          }
        } else if (props.currentStep === "initial") {
          // Set title for adding new product
          emit("update-title", "Add Product");
        }
      },
      { immediate: true } // Run immediately on component creation
    );

    const updateThumbnailIndex = (index) => {
      thumbnailIndex.value = index !== null && index !== undefined ? index : 0;
    };

    const gatherDataForPreview = () => {
      const fallbackItemName = "Unnamed item";
      const fallbackDescription = "No description provided.";
      
      // Create a combined array of images for preview
      // First, use existing URLs if available
      const imageUrlsForPreview = [...existingImageUrls.value];
      
      // Then add any new selected files as blob URLs
      if (selectedFiles.value && selectedFiles.value.length > 0) {
        selectedFiles.value.forEach(file => {
          if (file instanceof File) {
            const blobUrl = URL.createObjectURL(file);
            imageUrlsForPreview.push(blobUrl);
          } else if (typeof file === 'string') {
            imageUrlsForPreview.push(file);
          }
        });
      }
      
      // Now set all the product data
      gatheredProductData.value = {
        ...gatheredProductData.value, // Preserve existing properties
        itemName: itemName.value.trim() || fallbackItemName,
        itemDescription: itemDescription.value.trim() || fallbackDescription,
        existingStock: existingStock.value,
        price: price.value,
        selectedCategory: selectedCategory.value,
        selectedCondition: selectedCondition.value,
        location: location.value
          ? { lat: location.value.lat, lng: location.value.lng }
          : null, // Ensure the object structure
        imagePreviews: imageUrlsForPreview, // Set the image previews
        images: imageUrlsForPreview, // Also include images for PreviewItem component
      };
      
      console.log("Gathered product data for preview:", gatheredProductData.value);
      showPreview.value = true;
    };

    const organisationUID = computed(() => {
      // Check if currentProduct exists and has an organisation set
      if (props.currentProduct && props.currentProduct.organisation) {
        return props.currentProduct.organisation;
      }

      // If currentProduct does not have an organisation, fall back to userUnitsOrg
      const keys = Object.keys(props.userUnitsOrg);
      if (keys.length > 0) {
        return keys[0];
      }

      // Return null if no organisation can be determined
      return null;
    });

    const ownerUID = computed(() => {
      // Check if currentProduct exists and has an owner set
      if (props.currentProduct && props.currentProduct.owner) {
        return props.currentProduct.owner;
      }

      // If currentProduct does not have an owner, fall back to userCredentials
      return props.userCredentials?.data?.uid || null;
    });
    const triggerAddProduct = () => {
      addOrUpdateProduct();
    };

    const addOrUpdateProduct = async () => {
      try {
        console.log("Starting addOrUpdateProduct function");

        // Validate form
        if (!handleFormValidation()) {
          console.warn("Form validation failed");
          return;
        }

        // Handle edited images
        for (const { oldUrl, newFile } of editedImages.value) {
          const newUrl = await replaceImage(oldUrl, newFile);
          // Update existingImageUrls with the new URL
          const index = existingImageUrls.value.indexOf(oldUrl);
          if (index !== -1) {
            existingImageUrls.value[index] = newUrl;
          }
        }

        // Handle removed images
        for (const url of removedImageUrls.value) {
          await deleteImageByUrl(url);
          // Remove the URL from existingImageUrls
          const index = existingImageUrls.value.indexOf(url);
          if (index !== -1) {
            existingImageUrls.value.splice(index, 1);
          }
        }

        // Upload new images
        console.log("Uploading new images");
        const newImageUrls = await uploadNewImages(selectedFiles.value);
        console.log("New Image URLs received:", newImageUrls);

        // Prepare the images array
        const imageUrls = [...existingImageUrls.value, ...newImageUrls];

        // Prepare product data
        const productData = {
          itemName: itemName.value,
          itemDescription: itemDescription.value,
          existingStock: existingStock.value,
          price: price.value,
          category: selectedCategory.value,
          condition: selectedCondition.value,
          images: imageUrls, // Use the combined image URLs
          owner: ownerUID.value,
          organisation: organisationUID.value,
          createdAt: serverTimestamp(),
          isPublished: isPublished.value,
          coinName: coinName.value,
          coinSymbol: coinSymbol.value,
          thumbnailIndex: thumbnailIndex.value,
          location: location.value
            ? { lat: location.value.lat, lng: location.value.lng }
            : null, // Save as an object

          type: "product",
        };

        console.log("Product data being saved:", productData);

        let productRef;
        if (props.currentProduct && props.currentProduct.id) {
          console.log(
            "Updating existing product with ID:",
            props.currentProduct.id
          );
          productRef = doc(db, "products", props.currentProduct.id);
          await setDoc(productRef, productData, { merge: true });
          console.log(
            "Product updated successfully with ID:",
            props.currentProduct.id
          );
        } else {
          console.log("Creating a new product");
          productRef = await addDoc(collection(db, "products"), productData);
          console.log("New product created with ID:", productRef.id);
        }

        editedImages.value = [];
        removedImageUrls.value = [];

        console.log("Emitting productAddedSuccessfully event");
        emit("productAddedSuccessfully");
      } catch (error) {
        console.error("Error saving product:", error);
        toast.error("Error saving product: " + error.message);
      }
    };

    const uploadNewImages = async (files) => {
      const storage = getStorage();
      const imageUrls = [];
      for (const file of files) {
        const storagePath = `products/${itemName.value}/${file.name}`;
        const fileRef = storageRef(storage, storagePath);
        await uploadBytes(fileRef, file);
        const url = await getDownloadURL(fileRef);
        imageUrls.push(url);
      }
      return imageUrls;
    };

    watch(thumbnailIndex, (newValue) => {
      console.log("thumbnailIndex changed to:", newValue);
    });

    const uploadImagesAndGetUrls = async (files) => {
      console.log("uploadImagesAndGetUrls called with files:", files);
      const imageUrls = [...existingImageUrls.value];

      for (const file of files) {
        // Only upload new files
        if (file instanceof File) {
          const storagePath = `products/${itemName.value}/${file.name}`;
          const fileRef = storageRef(getStorage(), storagePath);
          const snapshot = await uploadBytes(fileRef, file);
          const url = await getDownloadURL(snapshot.ref);
          imageUrls.push(url);
          console.log("Uploaded file and got URL:", url);
        }
      }

      return imageUrls;
    };

    const fileToImageUrl = (file) => {
      const url = URL.createObjectURL(file);
      return url;
    };

    watch(selectedCategory, updateConditions);

    const resetForm = () => {
      // Existing resetForm code
      // Add this part to reset the form when there's no product prop passed
      if (!props.currentProduct || !props.currentProduct.id) {
        itemName.value = "";
        itemDescription.value = "";
        existingStock.value = 0;
        price.value = 0;
        selectedCategory.value = "Other";
        selectedCondition.value = "New";
        isPublished.value = true;
        location.value = null;
        selectedFiles.value = [];
        existingImageUrls.value = [];
        thumbnailIndex.value = 0;
        gatheredProductData.value = {};
      }
    };

    watch(price, (newValue, oldValue) => {
      validatePrice();
      gatherDataForPreview();
    });

    watch(
      [
        itemName,
        itemDescription,
        price,
        existingStock,
        selectedFiles,
        existingImageUrls,
      ],
      gatherDataForPreview,
      { immediate: true }
    );

    watch(
      () => props.currentStep,
      (newStep) => {
        if (newStep === "initial") {
          showPreview.value = false;
        }
      }
    );

    watch(
      () => props.overlayClosed,
      (newVal) => {
        if (newVal) {
          resetForm();
        }
      }
    );

    watch(
      () => props.currentProduct,
      (newProduct) => {
        if (newProduct && newProduct.id) {
          console.log("Initializing form with currentProduct:", newProduct);

          itemName.value = newProduct.itemName || "";
          itemDescription.value = newProduct.itemDescription || "";
          existingStock.value = newProduct.existingStock || 0;
          price.value = newProduct.price || 0;
          selectedCategory.value = newProduct.category || "Other";
          selectedCondition.value = newProduct.condition || "New";
          isPublished.value = newProduct.isPublished || true;

          // Pre-populate the location and show the map if location exists
          if (newProduct.location) {
            location.value = {
              lat: newProduct.location.lat,
              lng: newProduct.location.lng,
            }; // Update to read as an object
            showMap.value = true;
          } else {
            location.value = null;
            showMap.value = false;
          }

          // Clear selected files when editing a product
          selectedFiles.value = [];
          
          // Store existing image URLs
          const images = newProduct.images || [];
          existingImageUrls.value = Array.isArray(images) ? images : [images];
          console.log("Set existing images:", existingImageUrls.value);
          
          thumbnailIndex.value = newProduct.thumbnailIndex || 0;

          // Set up gathered product data with images
          gatherDataForPreview();
        } else {
          resetForm();
        }
      },
      { immediate: true }
    );

    onMounted(async () => {
      if (organisationUID.value) {
        await store.dispatch("fetchAndCommitOrgTotalCoins", {
          orgUID: organisationUID.value,
          context: "seller",
        });
      }
    });

    return {
      itemName,
      itemDescription,
      existingStock,
      price,
      categories,
      currentConditions,
      selectedCategory,
      selectedCondition,
      updateConditions,
      triggerAddProduct,
      showPreview,
      gatheredProductData,
      addOrUpdateProduct,
      handleImageUpdates,
      isPublished,
      formRef,
      coinSymbol,
      coinName,
      isProcessing,
      location,
      updateLocation,
      showMap,
      handleSetLocation,
      updateLocation,
      canPreview,
      nameInput,
      descriptionInput,
      priceInput,
      focusAndHighlight,
      highlightField,
      highlightFields,
      highlightName,
      highlightDescription,
      highlightPrice,
      resetHighlights,
      handleFormValidation,
      validatePrice,
      selectedFiles,
      hasSelectedFilesOrImages,
      existingImageUrls,
      fileToImageUrl,
      resetForm,
      isTextTooLong,
      characterCount,
      thumbnailIndex,
      updateThumbnailIndex,
      handleStepUpdate,
      gatherDataForPreview,
    };
  },
};
</script>

<style scoped>
.highlight-input {
  transition: background-color 0.3s ease-in-out;
  background-color: var(--lightestred);
}

.addProductForm {
  width: 100%;
}

.twoColOnDesktop {
  display: flex;
  flex-direction: row;
  gap: var(--smallMargin);
}

#category,
#condition {
  width: 100%;
}

textarea {
  resize: none;
  min-height: calc(var(--clickableHeight) * 1.6);
}
.setLocationFormGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.a.mapboxgl-ctrl-logo {
  display: none !important;
}

@media (max-width: 590px) {
  .twoColOnDesktop {
    flex-direction: column;
  }
}
</style>
