<template>
  <div class="micro-rain">
    <audio ref="audioElement" :src="audioPath" class="audio-element"></audio>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";

export default {
  props: {
    emojis: {
      type: [Array, String],
      default: () => ["🪙"],
    },
  },
  setup(props, context) {
    const audioElement = ref(null);
    // Use require to resolve the audio path
    const audioPath = ref("/audio/coins.mp3"); // No require needed
    let canvas = null;
    let context2D = null;
    const confetti = ref([]);
    let animationFrameId = null;
    const scaleFactor = Math.log(window.innerWidth) / Math.log(1920);
    const totalParticles = 50;
    let fadeAlpha = 1;
    let activeAnimations = 0;

    const emojiList = Array.isArray(props.emojis)
      ? props.emojis
      : [props.emojis];

    const Utils = {
      getRandomInRange: (min, max, precision = 0) => {
        const multiplier = Math.pow(10, precision);
        return (
          Math.floor((Math.random() * (max - min) + min) * multiplier) /
          multiplier
        );
      },
      getScaleFactor: () => scaleFactor,
      getRandomEmoji: () =>
        emojiList[Math.floor(Math.random() * emojiList.length)],
    };

    class ConfettiPiece {
      constructor() {
        this.emoji = Utils.getRandomEmoji();
        this.x = Utils.getRandomInRange(0, window.innerWidth);
        this.y = -Utils.getRandomInRange(50, 200);
        this.rotation = Utils.getRandomInRange(0, 360);
        this.rotationSpeed = Utils.getRandomInRange(-8, 8);
        this.speed = Utils.getRandomInRange(2, 5) * scaleFactor;
        this.size = Utils.getRandomInRange(12, 24);
        this.opacity = 1;
        this.scaleY = 1;
      }

      update() {
        this.y += this.speed;
        this.rotation += this.rotationSpeed;
        this.speed += 0.02; // Decrease this value to slow down the animations
        this.scaleY = Math.abs(Math.sin((this.rotation * Math.PI) / 180));
        this.opacity =
          1 - Math.min(1, (this.y / (window.innerHeight * 0.8)) * 1.2);
      }

      draw() {
        if (this.opacity <= 0) return;

        context2D.save();
        context2D.globalAlpha = this.opacity * fadeAlpha;
        context2D.translate(this.x, this.y);
        context2D.rotate((this.rotation * Math.PI) / 180);
        context2D.scale(1, this.scaleY);
        context2D.font = `${this.size}px serif`;
        context2D.textAlign = "center";
        context2D.textBaseline = "middle";
        context2D.fillText(this.emoji, 0, 0);
        context2D.restore();
      }
    }

    const initCanvas = () => {
      if (!canvas) {
        canvas = document.createElement("canvas");
        canvas.style.cssText = `
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 1000;
            opacity: 1;
          `;
        document.body.appendChild(canvas);
        context2D = canvas.getContext("2d");
        resizeCanvas();
      }
    };

    const resizeCanvas = () => {
      if (!canvas) return;
      const ratio = window.devicePixelRatio;
      canvas.width = window.innerWidth * ratio;
      canvas.height = window.innerHeight * ratio;
      context2D.scale(ratio, ratio);
    };

    const animate = () => {
      if (!context2D) return;

      context2D.clearRect(0, 0, canvas.width, canvas.height);

      confetti.value.forEach((piece, index) => {
        piece.update();
        piece.draw();

        if (piece.y > window.innerHeight + 100 || piece.opacity <= 0) {
          confetti.value.splice(index, 1);
        }
      });

      const fallenConfetti = confetti.value.filter(
        (piece) => piece.y > window.innerHeight * 0.7
      );
      if (fallenConfetti.length >= totalParticles * 0.8) {
        // Trigger fade-out earlier
        startFadeOut();
      } else {
        animationFrameId = requestAnimationFrame(animate);
      }
    };

    const startFadeOut = () => {
      const fadeDuration = 800;
      const startTime = Date.now();

      const fade = () => {
        const elapsed = Date.now() - startTime;
        fadeAlpha = 1 - elapsed / fadeDuration;

        if (elapsed < fadeDuration) {
          requestAnimationFrame(fade);
        } else {
          fadeAlpha = 1;
          confetti.value = [];
          if (canvas) canvas.style.opacity = "1";
        }
      };

      requestAnimationFrame(fade);
    };

    const cleanup = () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };

    const startAnimation = () => {
      activeAnimations++;
      initCanvas();

      if (audioElement.value) {
        audioElement.value.volume = 0.4;
        audioElement.value.currentTime = 0; // Reset audio to start
        audioElement.value
          .play()
          .then(() => {
            console.log("Audio playback started successfully");
          })
          .catch((error) => {
            console.error("Error playing audio:", error);
          });
      }

      const newParticles = Array.from(
        { length: totalParticles },
        () => new ConfettiPiece()
      );
      confetti.value = [...confetti.value, ...newParticles];

      fadeAlpha = 1;
      canvas.style.opacity = "1";

      if (!animationFrameId) {
        animate();
      }
    };

    onMounted(() => {
      initCanvas();
      window.addEventListener("resize", resizeCanvas);
    });

    onUnmounted(() => {
      cleanup();
      if (canvas) {
        document.body.removeChild(canvas);
        canvas = null;
      }
    });

    context.expose({
      startAnimation,
    });

    return {
      audioElement,
      audioPath,
      startAnimation,
    };
  },
};
</script>

<style scoped>
.micro-rain {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  pointer-events: none;
}

.audio-element {
  display: none;
}
</style>
