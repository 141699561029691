<template>
  <div class="navbar" :class="{ 'hidden-navbar': !navbarVisible }">
    <nav>
      <div class="userProfileLink">
        <!-- Add AvatarLight component here -->
        <router-link
          v-if="user.loggedIn && userUid"
          :to="`/userprofile/${userUid}`"
          v-haptic
        >
          <!-- <AvatarLight :user="userAuth" :hideName="true" /> -->

          <AvatarLightAndBalance v-if="userAuth" :userCred="userAuth" />
        </router-link>

        <router-link
          class="clickable"
          v-if="!user.loggedIn"
          :to="user.loggedIn ? (currentOrgUID ? '/dashboard' : '/') : '/'"
          v-haptic
          @click="changeTab('overview')"
        >
          <transition name="coin-flip" mode="out-in">
            <div class="row alignCentreRow" :key="displayKey">
              <CoinSymbol
                v-if="!logoUrl || !user.loggedIn || !isLogoValid"
                class="coin-symbol"
                size="big"
              />
              <img
                v-if="logoUrl && user.loggedIn && isLogoValid"
                :src="logoUrl"
                class="logo-image"
                alt="Logo"
                @error="handleImageError"
              />
            </div>
          </transition>
        </router-link>
      </div>

      <div class="navIconCont">
        <!-- Back Button -->
        <div
          v-if="user.loggedIn && $route.name !== 'home'"
          class="menu-item menu-link"
          @click="goBack"
        >
          <div class="column alignCentre iconColumn backArrow">
            <svg class="small">
              <use
                href="../../assets/icons/iconset.svg#arrowleft_carousel_big"
              ></use>
            </svg>
          </div>
        </div>
        <router-link
          v-haptic
          class="menu-item menu-link"
          to="/ecosystem"
          @click.native="resetActiveState('ecosystem')"
        >
          <div
            v-if="user.loggedIn"
            :class="{ active: activeTab === 'ecosystem' }"
            @click="changeTab('ecosystem')"
            v-haptic
            class="column alignCentre iconColumn"
          >
            <svg class="small">
              <use href="../../assets/icons/iconset.svg#shop"></use>
            </svg>
          </div>
        </router-link>

        <router-link
          v-haptic
          class="menu-item menu-link"
          to="/wallet"
          @click.native="resetActiveState('wallet')"
        >
          <div
            v-if="user.loggedIn"
            :class="{ active: activeTab === 'wallet' }"
            @click="changeTab('wallet')"
            v-haptic
            class="column alignCentre iconColumn wallet-icon"
          >
            <svg class="small">
              <use href="../../assets/icons/iconset.svg#wallet"></use>
            </svg>
          </div>
        </router-link>

        <div v-haptic class="menu-item menu-link" @click="toggleMessages">
          <div
            v-if="user.loggedIn"
            :class="{ active: areMessagesOpen }"
            class="column alignCentre iconColumn"
          >
            <svg class="small">
              <use href="../../assets/icons/iconset.svg#message"></use>
            </svg>
            <span v-if="unreadMessagesCount > 0" class="notification-dot">
              {{ unreadMessagesCount > 9 ? "+" : unreadMessagesCount }}
            </span>
          </div>
        </div>
      </div>

      <div v-if="user.loggedIn" id="placeholderspaceformenuAbsolute"></div>

      <div class="row alignCentreRow">
        <Menu class="clickable" />
      </div>
    </nav>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, ref, onMounted, onUnmounted, watch } from "vue";
import { collection, onSnapshot } from "firebase/firestore";
import { db, auth } from "@/firebase";
import debounce from "lodash.debounce";
import Menu from "./Menu.vue";
import CoinSymbol from "@/components/GenericComponents/CoinSymbol.vue";
import AvatarLight from "@/components/UserComponents/AvatarLight.vue";
import AvatarLightAndBalance from "@/components/UserComponents/AvatarLightAndBalance.vue";
export default {
  name: "Navbar",
  components: {
    Menu,
    CoinSymbol,
    AvatarLight,
    AvatarLightAndBalance,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    let lastScrollPosition = 0;
    const goBack = () => {
      router.go(-1);
    };

    const navbarVisible = computed(() => store.state.navbarVisible);
    const menuOpen = computed(() => store.getters.menuOpen);
    const activeTab = computed(() => store.getters.activeTab);
    const monetaryParameters = computed(
      () => store.getters.monetary_parameters
    );
    const currentCoinSymbol = ref("");
    const coinKey = ref(0);
    const isRotating = ref(false);
    const rotateClass = ref("");
    const areMessagesOpen = computed(
      () => store.getters["UIState/areMessagesOpen"]
    );
    const user = computed(() => store.getters.user);
    const userUid = ref(null);
    const userAuth = ref(null);
    const currentOrgUID = computed(() => store.getters.currentUserOrgUID);
    const logoUrl = computed(() => store.getters.getLogoUrl);
    const isLogoValid = ref(true);
    const displayKey = ref(0);
    const unreadMessagesCount = computed(
      () => store.getters["UIState/unreadMessagesCount"] || 0
    );

    // Ensure hooks are registered before any async operations
    onMounted(() => {
      window.addEventListener("scroll", debouncedHandleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    });

    // Function to initialize the authentication state listener
    const initAuthStateListener = () => {
      const unsubscribeAuth = auth.onAuthStateChanged((user) => {
        store.dispatch("fetchUser", user);

        if (user) {
          userUid.value = user.uid;
          userAuth.value = user;

          // Fetch user-related data
          fetchUserData(user.uid);
        }
      });

      // Cleanup auth state listener on unmount
      onUnmounted(() => {
        unsubscribeAuth();
      });
    };

    // Function to fetch user-related data
    const fetchUserData = async (uid) => {
      await store.dispatch("fetchCurrentUserOrgUID", uid);

      if (currentOrgUID.value) {
        await store.dispatch("fetchLogoUrl", currentOrgUID.value);
      }

      const walletCollection = collection(db, "users", uid, "Wallets");
      const unsubscribeWallets = onSnapshot(walletCollection, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added" || change.type === "modified") {
            const walletData = change.doc.data();
            if (walletData.isActiveMember) {
              const monetaryParams = walletData.monetary_parameters;
              if (monetaryParams) {
                const payload = {
                  coinName: monetaryParams.coin_name || "Unit",
                  coinSymbol: monetaryParams.coin_symbol || "Ʉ",
                };

                store.dispatch("updateCoinSymbol", {
                  symbol: payload.coinSymbol,
                  name: payload.coinName,
                });
                store.commit("SET_MONETARY_PARAMS", payload);
              }
            }
          }
        });
      });

      const messagesCollectionRef = collection(db, "users", uid, "Messages");
      const unsubscribeMessages = onSnapshot(
        messagesCollectionRef,
        (snapshot) => {
          if (!snapshot.empty) {
            store.dispatch("UIState/fetchUnreadMessagesCount", uid);
          }
        }
      );

      // Unsubscribe from listeners on unmount
      onUnmounted(() => {
        unsubscribeWallets();
        unsubscribeMessages();
      });
    };

    const handleImageError = () => {
      isLogoValid.value = false;
      displayKey.value += 1; // Update key to trigger transition to CoinSymbol
    };

    const toggleMessages = () => {
      store.dispatch("UIState/setMessagesMinimised", false);
      store.dispatch("UIState/setMessagesOpen", !areMessagesOpen.value);
      store.commit("SET_MENU_OPEN", false); // Close the menu when toggling messages
    };

    const changeTab = (tabName) => {
      if (tabName === "overview") {
        localStorage.setItem("lastPressedCard", "overview");
        localStorage.setItem("lastSelectedIndex", "0");
      }
      store.dispatch("changeActiveTab", tabName);
    };

    const resetActiveState = (tabName) => {
      store.dispatch("changeActiveTab", tabName);
    };

    const handleScroll = () => {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollThreshold = 10; // Set a threshold for the scroll

      // Ignore small scroll movements (like the bounce effect)
      if (
        Math.abs(currentScrollPosition - lastScrollPosition) < scrollThreshold
      ) {
        return;
      }

      if (currentScrollPosition < 0) return; // Prevent negative scroll values

      if (!menuOpen.value) {
        const shouldShowNavbar = currentScrollPosition < lastScrollPosition;
        store.dispatch("toggleNavbarVisibility", shouldShowNavbar);
      }

      lastScrollPosition = currentScrollPosition;
    };

    const debouncedHandleScroll = debounce(handleScroll, 15);

    // Initialize authentication listener on mounted
    onMounted(initAuthStateListener);

    return {
      navbarVisible,
      user,
      currentOrgUID,
      activeTab,
      changeTab,
      resetActiveState,
      toggleMessages,
      areMessagesOpen,
      unreadMessagesCount,
      logoUrl,
      isLogoValid,
      handleImageError,
      displayKey,
      currentCoinSymbol,
      coinKey,
      isRotating,
      rotateClass,
      monetaryParameters,
      userUid,
      userAuth,
      goBack,
    };
  },
};
</script>

<style scoped>
.navbar .logo-image {
  height: var(--clickableHeight);
  width: auto;
  border-radius: var(--borderRadius);
}

.userProfileLink {
  max-height: var(--clickableHeight);
  width: -webkit-fill-available;
}

#placeholderspaceformenuAbsolute {
  min-width: calc(var(--clickableHeight) - var(--smallMargin));
}

.navIconCont {
  display: flex;
  width: calc(100% - var(--clickableHeight));
  transform: translateX(var(--smallerMargin));
  justify-content: flex-end;
  transition: all 0.3s ease-in-out;
  align-items: center;
}

.navIconCont .iconColumn {
  padding: var(--tinyMargin);
  border-radius: var(--borderRadius);
  justify-content: center;
  position: relative; /* Added for positioning notification dot */
}

.backArrow {
  width: var(--smallerClickableHeight);
}

.notification-dot {
  position: absolute;
  top: calc(var(--clickableHeight) / 2 - 8px);
  right: 1px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  background-color: var(--red);
  border-radius: 50%;
  color: var(--backgroundColourOrg);
  text-align: center;
  font-size: 12px;
  padding: 0 4px;
  border: 1px solid var(--backgroundColourOrg);
}

.navIconCont .iconColumn svg use {
  box-shadow: var(--neuMorphBoxOuterSmall);
  padding: var(--tinyMargin);
  border-radius: var(--borderRadius);
  justify-content: center;
}

.navIconCont .iconColumn.active {
  box-shadow: var(--neuMorphBoxInnerSmall);
}

.navIconCont .iconColumn svg {
  height: var(--smallerClickableHeight);
  width: var(--smallerClickableHeight);
}

.circle {
  width: var(--clickableHeight);
  height: var(--clickableHeight);
  transition: transform 0.15s ease-in-out;
}

.coin-flip-enter-active,
.coin-flip-leave-active {
  animation: spin 0.15s linear forwards;
}

.rotate-to-90 {
  animation: rotateTo90 0.15s linear forwards;
}

.rotate-to-0 {
  animation: rotateTo0 0.15s linear forwards;
}

.wallet-icon {
  position: relative;
}

.small-coin-symbol {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-57%, -29%);
  width: 15px;
  height: 15px;
  padding: 9px;
  border: 0px transparent;
}

.logo-coin-rotate-enter-active,
.logo-coin-rotate-leave-active {
  animation: rotateTo90 0.3s linear forwards,
    rotateTo0 0.3s linear 0.3s forwards;
}

@keyframes rotateTo90 {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(90deg);
  }
}

@keyframes rotateTo0 {
  0% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0);
  }
}

@media (max-width: 590px) {
  .navIconCont {
    transform: none;
  }
}
</style>
