<template>
  <div class="orgMembers">
    <div class="innerSticker" :style="{ 'background-color': backgroundColor }">
      <!-- Header -->
      <div class="row card-header alignCentreRow">
        <div class="column">
          <h1 class="nomargintopbottom">Members</h1>
        </div>
        <!-- Invite button if userRegistration is allowed -->
        <div class="editing row" v-if="userRolePrivileges?.userRegistration">
          <InviteMembers :orgUID="orgUID" :members="members" />
        </div>
      </div>

      <div class="separator_sticker noMarginTop"></div>

      <!-- Members list -->
      <div class="members-container">
        <div
          v-for="member in sortedMembers"
          :key="member.user_details.email"
          class="member-item"
        >
          <!-- Left side: avatar + optional balance -->
          <div class="member-info">
            <UserAvatarInOrgMembers :member="member" />

            <!-- Show the balance only if the user has monetaryParameters privilege -->
            <div
              class="balance gray"
              v-if="userRolePrivileges?.monetaryParameters"
            >
              {{
                userBalances[member.userUID] != null
                  ? userBalances[member.userUID].toFixed(2)
                  : "..."
              }}
              {{ coinSymbol }}
            </div>
          </div>

          <!-- Right side: the action menu (remove user, verify user, etc.) -->
          <MemberDashboardActions
            :member="member"
            :orgUID="orgUID"
            :userRolePrivileges="userRolePrivileges"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { doc, getDoc } from "firebase/firestore";

import { db } from "@/firebase";
import InviteMembers from "@/components/DashboardComponents/InviteMembers.vue";
import UserAvatarInOrgMembers from "@/components/UserComponents/UserAvatarInOrgMembers.vue";
import MemberDashboardActions from "@/components/DashboardComponents/MemberDashboardActions.vue";

/**
 * OrgMembers - shows a list of organisation members, optionally displays
 * their balances (if the user has monetaryParameters), and includes the
 * MemberDashboardActions for removing / verifying them (if the user has privileges).
 *
 * Now we fetch the coin symbol ourselves from Firestore:
 *    doc(db, "custom-orgs", orgUID)
 * and read orgDoc.monetary_parameters.coin_symbol
 */
export default {
  name: "OrgMembers",
  components: {
    InviteMembers,
    UserAvatarInOrgMembers,
    MemberDashboardActions,
  },
  props: {
    members: {
      type: Array,
      required: true,
    },
    orgUID: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    userRolePrivileges: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const store = useStore();

    // Dictionary to store each member's balance: { [userUID]: number }
    const userBalances = ref({});
    // We'll fetch the coin symbol from the org doc
    const coinSymbol = ref("");

    // Sort members: roles first, then displayName, then isAnonymous
    const sortedMembers = computed(() => {
      return [...props.members].sort((a, b) => {
        // Sort by role
        if (a.roleTitle && !b.roleTitle) return -1;
        if (!a.roleTitle && b.roleTitle) return 1;

        // Then by displayName presence
        if (a.user_details?.displayName && !b.user_details?.displayName)
          return -1;
        if (!a.user_details?.displayName && b.user_details?.displayName)
          return 1;

        // Lastly by isAnonymous (we want non-anonymous first)
        if (a.isAnonymous && !b.isAnonymous) return 1;
        if (!a.isAnonymous && b.isAnonymous) return -1;

        return 0;
      });
    });

    /**
     * Fetch each member’s currentAccount balance from:
     *  /users/{userUID}/Wallets/{orgUID}/currentAccount.balance
     * Only if the current user has the monetaryParameters privilege.
     */
    const fetchAllBalances = async () => {
      if (!props.userRolePrivileges?.monetaryParameters) return; // no permission

      for (const member of props.members) {
        try {
          const walletRef = doc(
            db,
            "users",
            member.userUID,
            "Wallets",
            props.orgUID
          );
          const snap = await getDoc(walletRef);
          const walletData = snap.data();
          const balance = walletData?.currentAccount?.balance ?? 0;
          userBalances.value[member.userUID] = balance;
        } catch (error) {
          console.error("Error fetching member balance:", error);
        }
      }
    };

    /**
     * Fetch the coin symbol from: /custom-orgs/{orgUID}
     */
    const fetchCoinSymbol = async () => {
      try {
        const orgRef = doc(db, "custom-orgs", props.orgUID);
        const orgSnap = await getDoc(orgRef);
        const orgData = orgSnap.data();
        // In your logs, you have: orgData.monetary_parameters.coin_symbol
        coinSymbol.value = orgData?.monetary_parameters?.coin_symbol || "";
      } catch (err) {
        console.error("Error fetching org coin symbol:", err);
      }
    };

    onMounted(async () => {
      await fetchCoinSymbol();
      await fetchAllBalances();
    });

    return {
      sortedMembers,
      userBalances,
      coinSymbol,
    };
  },
};
</script>

<style scoped>
.members-container {
  display: flex;
  flex-direction: column;
}

.member-item {
  margin-bottom: var(--smallMargin);
  display: flex;
  justify-content: space-between; /* space between left and right sections */
  align-items: center;
}

.member-info {
  display: flex;
  align-items: center;
  gap: var(--smallMargin);
}
</style>
