<template>
  <div class="CreateCoin">
    <CoinRain ref="CoinRain" :emojis="['🪙', '💰', '💸', '💎']" />

    <div class="row divMarginBottom alignCentreRow sss justifyToStartEnd">
      <div class="row">
        <input
          type="number"
          v-model="coinAmount"
          min="1"
          class="smallInput shortInput"
        />
        <button v-if="canCreateCoin" @click="createCoin" class="smallButton">
          🤑 Create <span v-if="coinAmount > 1">coins</span
          ><span v-else>coin</span>
        </button>
      </div>

      <!-- <button
        @click="toggleLifecycleDropdown"
        class="smallButton fitContent"
        v-haptic
      >
        Coin life cycle
        <svg class="compactImg" v-if="isCoinLifecycleOpen">
          <use href="@/assets/icons/iconset.svg#arrowup_carousel"></use>
        </svg>
        <svg class="compactImg" v-else>
          <use href="@/assets/icons/iconset.svg#arrowdown_carousel"></use>
        </svg>
      </button> -->
    </div>
    <!-- <div class="column">
      <transition name="slide-fade">
        <OrgCoinLifecycleSankeyChart
          v-if="isCoinLifecycleOpen"
          :basicOrgDetails="basicOrgDetails"
        />
      </transition>
    </div> -->
  </div>
</template>

<script>
import { useStore } from "vuex";
import { getAuth } from "firebase/auth";
import { db } from "@/firebase";
import { onMounted, ref } from "vue";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";

import OrgCoinLifecycleSankeyChart from "@/components/DashboardComponents/OrgPublicPage/OrgCoinLifecycleSankeyChart.vue";
import ExchangeRate from "@/components/GenericComponents/CentExchangeRate.vue";
import CoinFallingAnimation from "@/components/AnimationComponents/CoinFallingAnimation.vue";
import CoinRain from "@/components/AnimationComponents/CoinRain.vue";

import { toast } from "vue3-toastify";

export default {
  name: "CreateCoin",
  components: {
    OrgCoinLifecycleSankeyChart,
    ExchangeRate,
    CoinFallingAnimation,
    CoinRain,
  },
  props: {
    basicOrgDetails: {
      type: Object,
      required: true,
    },
    viewedOrgUID: {
      type: String,
      required: true,
    },
    currentUserOrgUID: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const isCoinLifecycleOpen = ref(false);
    const canCreateCoin = ref(false);
    const CoinRain = ref(null);

    const toggleLifecycleDropdown = () => {
      isCoinLifecycleOpen.value = !isCoinLifecycleOpen.value;
    };
    const checkCreateCoinConditions = async () => {
      const user = getAuth().currentUser;
      if (!user || !props.viewedOrgUID) return;

      const userUID = user.uid;
      const orgRef = doc(db, "custom-orgs", props.viewedOrgUID);
      const orgDoc = await getDoc(orgRef);

      if (orgDoc.exists()) {
        const orgData = orgDoc.data();

        // Check if totalOrgCoins is 0 or less
        const hasFewOrNoCoins = orgData.totalOrgCoins <= 0;

        // Check if user has monetaryParameters privilege
        const rolesRef = collection(
          db,
          "custom-orgs",
          props.viewedOrgUID,
          "Roles"
        );
        const q = query(rolesRef, where(`filledBy.${userUID}`, "!=", null));
        const querySnapshot = await getDocs(q);

        let hasMonetaryPrivileges = false;

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            const roleData = doc.data();
            if (roleData.privileges && roleData.privileges.monetaryParameters) {
              hasMonetaryPrivileges = true;
            }
          });
        }

        // Update the ref based on the checks
        canCreateCoin.value = hasFewOrNoCoins || hasMonetaryPrivileges;
      }
    };
    const createCoin = async () => {
      if (!props.viewedOrgUID) {
        console.error("Organization UID is missing.");
        toast.error("Organization UID is missing.");
        return;
      }

      try {
        await store.dispatch("coinCreationActions/createCoin", {
          orgUID: props.viewedOrgUID,
          currentAccountLifespan:
            props.basicOrgDetails.currentAccountLifespan.value,
          coinAmount: coinAmount.value,
        });

        // toast.success("🤑 Coin created successfully in wallet.");
        emit("coinCreated");
        if (CoinRain.value) {
          CoinRain.value.startAnimation();
        }
      } catch (error) {
        console.error("Error creating coin in wallet:", error);
      }
    };
    onMounted(() => {
      checkCreateCoinConditions();
    });

    const coinAmount = ref(1);

    return {
      createCoin,
      isCoinLifecycleOpen,
      toggleLifecycleDropdown,
      canCreateCoin,
      coinAmount,
      CoinRain,
    };
  },
};
</script>

<style scoped>
.fitContent {
  width: fit-content;
}

.info-container {
  overflow-y: auto;
  max-height: 50vh;
}

.shortInput {
  width: 50px;
}
</style>
