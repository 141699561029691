<template>
  <div class="image-card" v-if="item" @click="$emit('select', item)">
    <img :src="getDisplayImage()" alt="Image card" class="image-card__img" />
    <div class="image-card__info">
      <small class="nomargintopbottom">{{ item.caption }}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getDisplayImage() {
      if (!this.item) return "";

      // Check images array first (new format)
      if (
        this.item.images &&
        Array.isArray(this.item.images) &&
        this.item.images.length > 0
      ) {
        const thumbnailIndex = this.item.thumbnailIndex || 0;
        if (this.item.images[thumbnailIndex]) {
          return this.item.images[thumbnailIndex];
        }
        return this.item.images[0]; // Fallback to first image
      }

      // Check mediaUrl (old format)
      if (this.item.mediaUrl) {
        return this.item.mediaUrl;
      }

      // Other possible field names
      return this.item.image || "";
    },
  },
};
</script>

<style scoped>
.image-card {
  cursor: pointer;
  border-radius: var(--borderRadius);
  overflow: hidden;
  background: var(--backgroundColourOrg);
  max-height: 60vh;
  overflow-y: scroll;
}
.image-card__img {
  width: 100%;
  height: auto;
  display: block;
  background: var(--gray100);
}
.image-card__info {
  padding: var(--smallerMargin);
  background: rgb(0 0 0 / 75%);
  color: var(--black);
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow: auto;
}
</style>
