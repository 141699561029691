import { db } from "@/firebase";
import {
  updateDoc,
  setDoc,
  limit,
  orderBy,
  query,
  writeBatch,
  getDoc,
  getDocs,
  collection,
  doc,
  serverTimestamp,
  arrayUnion,
  increment,
  runTransaction,
  where,
  deleteField,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { WalletSchema } from "@/schemas/WalletSchema";

export default {
  state: {
    // foundationOrgUID: "pvuw6AIKZcoRPug8BEZC",
    // foundationTemplateOrgUID: "UiHSOnsqdBACZjiCsgsU", // not used so far
    userUnits_customOrgs: null,
    role: null,
    monetary_parameters: {
      coinName: "Unit",
      coinSymbol: "Ʉ",
    },
    // customOrg: {}
  },
  getters: {
    // userUnits_customOrgs represents the actual data in Units stored in the Custom Org Firestore node. This could be more detailed data about the units the user is part of but stored in a different Firestore collection called "Custom Org". The data for this state is fetched and updated using the
    // foundationOrgUID: (state) => state.foundationOrgUID,
    foundationTemplateOrgUID: (state) => state.foundationTemplateOrgUID, // not used so far
    userUnits_customOrgs: (state) => state.userUnits_customOrgs,
    role: (state) => state.role,
    monetary_parameters: (state) => state.monetary_parameters,
  },
  mutations: {
    SET_USER_UNITS_CUSTOM_ORGS(state, userUnitsData) {
      state.userUnits_customOrgs = userUnitsData;
    },
    SET_ROLE(state, role) {
      if (role) {
        state.role = role;
      } else {
        state.role = null; // Clear the role data if no role is provided
      }
    },
    SET_MONETARY_PARAMS(state, payload) {
      // console.log(" SET_MONETARY_PARAMS from store");
      // console.log(payload);
      state.monetary_parameters = payload;
    },
    RESET_MONETARY_PARAMS(state) {
      state.monetary_parameters = {
        coinName: "Unit",
        coinSymbol: "Ʉ",
      };
    },
  },
  actions: {
    // get monetary_parametersN

    async fetchMonetaryParameters({ commit }, orgUID) {
      if (!orgUID) {
        const defaultParams = {
          coinName: "Unit",
          coinSymbol: "Ʉ",
        };
        commit("SET_MONETARY_PARAMS", defaultParams);
        return;
      }
      try {
        const orgDoc = await getDoc(doc(db, "custom-orgs", orgUID));

        // Use orgDoc.exists() instead of doc.exists()
        if (orgDoc.exists()) {
          const monetaryParameters = orgDoc.data().monetary_parameters;
          commit("SET_MONETARY_PARAMS", monetaryParameters);
          localStorage.setItem(
            "monetaryParameters",
            JSON.stringify(monetaryParameters)
          );
        } else {
          const storedParams = localStorage.getItem("monetaryParameters");
          if (storedParams) {
            commit("SET_MONETARY_PARAMS", JSON.parse(storedParams));
          } else {
            const defaultParams = {
              coinName: "Unit",
              coinSymbol: "Ʉ",
            };
            commit("SET_MONETARY_PARAMS", defaultParams);
          }
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },

    async setMonetaryParameters({ commit }, payload) {
      commit("SET_MONETARY_PARAMS", payload);
    },

    // get one role
    async getRoleInfo({ commit }, { orgUID, roleUID }) {
      try {
        // Ensure orgUID and roleUID are provided
        if (!orgUID || !roleUID) {
          throw new Error("Invalid orgUID or roleUID");
        }

        const docRef = doc(db, "custom-orgs", orgUID, "Roles", roleUID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const roleData = docSnap.data();

          // Validate that roleData contains the expected properties
          if (!roleData || typeof roleData.roleTitle !== "string") {
            throw new Error("Invalid role data");
          }

          commit("SET_ROLE", roleData); // Use mutation to set state
        } else {
          commit("SET_ROLE", null);
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching role information:", error);
        commit("SET_ROLE", null);
        // Optionally, you could handle the error differently, e.g., by showing a notification
      }
    },

    // Function for getting all the roles in the Unit
    async getRoles({ state }, unitRefOrOrgUID) {
      let unitDocRef;
      if (typeof unitRefOrOrgUID === "string") {
        unitDocRef = doc(db, "custom-orgs", unitRefOrOrgUID);
      } else {
        unitDocRef = unitRefOrOrgUID;
      }

      try {
        const rolesSnapshot = await getDocs(collection(unitDocRef, "Roles"));

        return rolesSnapshot.docs.map((doc) => doc.data());
      } catch (error) {
        console.error("Error fetching roles subcollection:", error);
        // Handle error or log as needed
        return null; // return null or an appropriate value when there's an error
      }
    },

    // MIGHT NOT NEED THIS since each user can only belong to an organisation.
    // Fecthing all the org/units a user may belong to
    async fetchAndCommitUserUnitsCustomOrgs({ commit, dispatch }, userId) {
      console.log("fetchAndCommitUserUnitsCustomOrgs");
      commit("SET_LOADING_STATE", true);

      if (!userId) {
        console.log("userId is not provided");
        return;
      }
      const userUnitsCollectionRef = collection(db, "users", userId, "Units");
      const querySnapshot = await getDocs(userUnitsCollectionRef);

      const fetchUnitPromises = querySnapshot.docs.map(async (document) => {
        const unitUID = document.id;
        const unitDocRef = doc(db, "custom-orgs", unitUID);
        const unitDocSnapshot = await getDoc(unitDocRef);

        if (unitDocSnapshot.exists()) {
          let unitData = unitDocSnapshot.data();
          // console.log("unitData", unitData);

          // Fetch Members subcollection
          try {
            const membersSnapshot = await getDocs(
              collection(unitDocRef, "Members")
            );
            unitData.members = membersSnapshot.docs.map((doc) => doc.data());
          } catch (error) {
            console.error("Error fetching Members subcollection:", error);
            // Handle error or log as needed
          }

          // Fetch BasicIncome subcollection
          // try {
          //   const basicIncomeSnapshot = await getDocs(
          //     collection(unitDocRef, "BasicIncome")
          //   );
          //   unitData.BasicIncome = basicIncomeSnapshot.docs.map((doc) =>
          //     doc.data()
          //   );
          // } catch (error) {
          //   console.error("Error fetching BasicIncome subcollection:", error);
          //   // Handle error or log as needed
          // }

          // Fetch roles subcollection
          try {
            unitData.roles = await dispatch("getRoles", unitDocRef);
          } catch (error) {
            console.error("Error fetching roles:", error);
          }

          return { unitUID, unitData };
        } else {
          console.error("Unit document does not exist:", unitUID);
        }
      });

      const fetchedUnits = await Promise.all(fetchUnitPromises);
      const userUnitsCustomOrgsData = Object.fromEntries(
        fetchedUnits.map(({ unitUID, unitData }) => [unitUID, unitData])
      );

      commit("SET_USER_UNITS_CUSTOM_ORGS", userUnitsCustomOrgsData);
      commit("SET_LOADING_STATE", false, { root: false });
    },

    // JOINING an ORGANISATION
    async joinCustomOrg({ dispatch }, payload) {
      const auth = getAuth();
      const { inviteUID, newOrgUID, roleID, userUID, orgNameArg, roleData } =
        payload;

      if (!newOrgUID || !userUID) {
        console.error("newOrgUID or userUID is null or undefined");
        return;
      }

      const orgRef = doc(db, "custom-orgs", newOrgUID);
      const memberRef = doc(orgRef, "Members", userUID);
      const userRef = doc(db, "users", userUID);
      const unitRef = doc(userRef, "Units", newOrgUID);
      const walletRef = doc(db, "users", userUID, "Wallets", newOrgUID);

      try {
        // Fetch the organization data at the start to ensure `orgData` is available throughout the function
        const orgDoc = await getDoc(orgRef);
        if (!orgDoc.exists()) {
          throw new Error("Organization document does not exist");
        }

        const orgData = orgDoc.data();
        console.log("orgData", orgData);
        // Validate monetary_parameters

        const initialCoins = orgData.monetary_parameters?.initialCoins || 0;

        // Batch initialization for creating the user role, member, and unit
        const batch = writeBatch(db);

        const memberDocSnapshot = await getDoc(memberRef);
        const isAlreadyMember = memberDocSnapshot.exists();

        const memberData = {
          userUID,
          isAnonymous: auth.currentUser.isAnonymous,
          joinedUnix: serverTimestamp(),
          user_details: auth.currentUser.isAnonymous
            ? {}
            : {
                displayName: auth.currentUser.displayName,
                photoURL: auth.currentUser.photoURL,
                email: auth.currentUser.email,
              },
        };

        if (isAlreadyMember) {
          console.log("User is already a member. Checking for role updates.");
          if (roleID && roleData) {
            const roleRef = doc(orgRef, "Roles", roleID);
            const roleDocSnapshot = await getDoc(roleRef);

            if (roleDocSnapshot.exists()) {
              const filledByData = {
                [userUID]: {
                  isAnonymous: auth.currentUser.isAnonymous,
                  natural_person: true,
                  user_details: {
                    displayName: auth.currentUser.displayName,
                    email: auth.currentUser.email,
                    photoURL: auth.currentUser.photoURL,
                  },
                },
              };

              batch.update(roleRef, {
                slotsFilled: increment(1),
                filledBy: {
                  ...roleDocSnapshot.data().filledBy,
                  ...filledByData,
                },
              });

              const userRoleRef = doc(db, "users", userUID, "RoleIDs", roleID);
              const userRoleData = {
                currentRole: roleID,
                roleTitle: roleData.roleTitle,
                joined: serverTimestamp(),
                organisationName: orgNameArg,
                organisationUID: newOrgUID,
              };
              batch.set(userRoleRef, userRoleData);

              batch.update(memberRef, {
                roleTitle: roleData.roleTitle,
                roleID: roleID,
              });
            }
          }
        } else {
          console.log(
            "User is not a member. Proceeding with the full join process."
          );

          if (roleID && roleData) {
            const roleRef = doc(orgRef, "Roles", roleID);
            const roleDocSnapshot = await getDoc(roleRef);

            if (roleDocSnapshot.exists()) {
              const filledByData = {
                [userUID]: {
                  isAnonymous: auth.currentUser.isAnonymous,
                  natural_person: true,
                  user_details: {
                    displayName: auth.currentUser.displayName,
                    email: auth.currentUser.email,
                    photoURL: auth.currentUser.photoURL,
                  },
                },
              };

              batch.update(roleRef, {
                slotsFilled: increment(1),
                filledBy: {
                  ...roleDocSnapshot.data().filledBy,
                  ...filledByData,
                },
              });

              const userRoleRef = doc(db, "users", userUID, "RoleIDs", roleID);
              const userRoleData = {
                currentRole: roleID,
                roleTitle: roleData.roleTitle,
                joined: serverTimestamp(),
                organisationName: orgNameArg,
                organisationUID: newOrgUID,
              };
              batch.set(userRoleRef, userRoleData);

              memberData.roleTitle = roleData.roleTitle;
              memberData.roleID = roleID;
            }
          }

          batch.set(memberRef, memberData);

          const unitData = {
            currentRole: roleID || null,
            joined: serverTimestamp(),
            organisationName: orgNameArg,
            organisationUID: newOrgUID,
          };
          batch.set(unitRef, unitData);
        }

        // Check if the wallet already exists
        const walletDocSnapshot = await getDoc(walletRef);
        let walletExists = walletDocSnapshot.exists();

        // Update the wallet, but only create it if it doesn't already exist
        if (!walletExists) {
          // Create the wallet with initial balance set to 0 if it does not exist
          const initialWalletData = {
            ...WalletSchema,
            orgName: orgNameArg,
            monetary_parameters: orgData.monetary_parameters,
            allowTrades: orgData.allowTrades,
            isActiveMember: true,
            currentAccount: {
              ...WalletSchema.currentAccount,
              balance: 0, // Set initial balance to 0
            },
          };
          batch.set(walletRef, initialWalletData);
        } else {
          // Update existing wallet fields, but do not modify the balance
          batch.update(walletRef, {
            orgName: orgNameArg,
            monetary_parameters: orgData.monetary_parameters,
            allowTrades: orgData.allowTrades,
            isActiveMember: true, // Set isActiveMember to true if the user re-joins
          });
        }

        // Function to update media (images/videos/audio)
        const updateMedia = async (orgData) => {
          try {
            // Update images (if collection exists)
            try {
              const imagesQuery = query(
                collection(db, "images"),
                where("owner", "==", userUID),
                where("organisation", "==", newOrgUID)
              );
              const imagesSnapshot = await getDocs(imagesQuery);
              imagesSnapshot.forEach((doc) => {
                batch.update(doc.ref, {
                  organisation: newOrgUID,
                  coinName: orgData.monetary_parameters.coinName,
                  coinSymbol: orgData.monetary_parameters.coinSymbol,
                });
              });
            } catch (error) {
              console.warn(
                "Images collection not found or error updating:",
                error
              );
            }

            // Skip videos/audio if collections don't exist yet
          } catch (error) {
            console.error("Error in updateMedia:", error);
          }
        };

        // Function to update products and services
        const updateProductsAndServices = async () => {
          try {
            // Fetch all products owned by the user
            const productsQuerySnapshot = await getDocs(
              query(collection(db, "products"), where("owner", "==", userUID))
            );

            // Fetch all services owned by the user
            const servicesQuerySnapshot = await getDocs(
              query(collection(db, "services"), where("owner", "==", userUID))
            );

            // Update each product with the new organisation details
            productsQuerySnapshot.forEach((productDoc) => {
              batch.update(productDoc.ref, {
                organisation: newOrgUID,
                coinName: orgData.monetary_parameters.coin_name,
                coinSymbol: orgData.monetary_parameters.coin_symbol,
              });
            });

            // Update each service with the new organisation details
            servicesQuerySnapshot.forEach((serviceDoc) => {
              batch.update(serviceDoc.ref, {
                organisation: newOrgUID,
                coinName: orgData.monetary_parameters.coinName,
                coinSymbol: orgData.monetary_parameters.coinSymbol,
              });
            });
          } catch (error) {
            console.error("Error updating products and services:", error);
            throw error; // Optionally, you can handle this differently
          }
        };

        // Invoke the function to update products and services
        await updateProductsAndServices();

        // Commit the batch to ensure all documents (wallet included) are created/updated
        await batch.commit();
        console.log("Wallet and member data created/updated.");

        // Now, pass initialCoins to the coin creation action only if the wallet is newly created
        if (!walletExists) {
          await dispatch("coinCreationActions/createCoin", {
            orgUID: newOrgUID,
            currentAccountLifespan:
              orgData.monetary_parameters?.currentAccount?.lifespan,
            coinAmount: initialCoins, // Pass initialCoins for coin creation
            userUID,
          });

          console.log(
            "Coin creation action completed with initial coins:",
            initialCoins
          );
        } else {
          console.log("Wallet already exists. Skipping coin creation.");
        }
      } catch (error) {
        console.error("Error in joinCustomOrg:", error);
      }
    },
    // Fetch organization member count
    async getOrgMembersCount({ commit }, orgUID) {
      if (!orgUID) {
        console.error("orgUID is undefined or null");
        return;
      }
      try {
        const membersSnap = await getDocs(
          collection(db, "custom-orgs", orgUID, "Members")
        );
        const orgDocRef = doc(db, "custom-orgs", orgUID);
        await setDoc(
          orgDocRef,
          { membersCount: membersSnap.size },
          { merge: true }
        );
        return membersSnap.size;
      } catch (error) {
        console.error("Error getting organisation members count: ", error);
        throw error;
      }
    },

    // DELETING ACCOUNT

    async deleteAccount({ dispatch }, { userUID }) {
      console.log("DELETING ACCOUNT STARTED!");
      console.log(userUID);
      try {
        const auth = getAuth();
        const batch = writeBatch(db);
        const userRef = doc(db, "users", userUID);
        const userUnitsRef = collection(db, "users", userUID, "Units");

        // Fetch the user's organisation memberships
        const userUnitsSnapshot = await getDocs(userUnitsRef);

        if (!userUnitsSnapshot || userUnitsSnapshot.empty) {
          console.error("No organisation found for userUID:", userUID);
          return;
        }

        // Remove the user from all organisations they belong to
        const leaveOrgPromises = userUnitsSnapshot.docs.map((unitDoc) => {
          const orgUID = unitDoc.id;
          console.log("Leaving organisation:", orgUID);
          return dispatch("leaveCustomOrg", { userUID, orgUID });
        });
        await Promise.all(leaveOrgPromises);

        // Delete the user's subcollections (e.g., Wallets, PastUnits, RoleIDs)
        const subcollections = ["Wallets", "PastUnits", "RoleIDs"];
        for (const subcollection of subcollections) {
          const subcollectionRef = collection(userRef, subcollection);
          const subcollectionSnapshot = await getDocs(subcollectionRef);
          subcollectionSnapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });
        }

        // Delete the user's document from the "users" collection
        batch.delete(userRef);

        // Commit the batch deletion
        await batch.commit();

        // Optionally, delete the user's Firebase Authentication account
        if (auth.currentUser && auth.currentUser.uid === userUID) {
          console.log("Deleting user account from Firebase Authentication");
          await auth.currentUser.delete();
        }

        console.log("Account deleted successfully");
      } catch (error) {
        console.error("Error deleting account:", error);
        throw error; // Re-throw to handle this in the Vue component
      }
    },

    // LEAVING AN ORGANISATION
    async leaveCustomOrg({ commit, state, dispatch }, payload) {
      const batch = writeBatch(db);
      const orgDocRef = doc(db, "custom-orgs", payload.orgUID);
      const userRef = doc(db, "users", payload.userUID);
      const userRolesRef = collection(userRef, "RoleIDs");

      console.log("leaveCustomOrg action started with payload:", payload);

      // Delete roles from the user's RoleIDs subcollection if they belong to the org the user is leaving
      const userRolesSnapshot = await getDocs(userRolesRef);
      userRolesSnapshot.forEach(async (roleDoc) => {
        if (
          roleDoc.exists() &&
          roleDoc.data().organisationUID === payload.orgUID
        ) {
          // Delete role from custom-orgs 'Roles' filledBy subcollection
          const roleRefInOrg = doc(
            db,
            "custom-orgs",
            payload.orgUID,
            "Roles",
            roleDoc.id,
            "filledBy",
            payload.userUID
          );
          batch.delete(roleRefInOrg);

          // Delete role from the user's RoleIDs subcollection
          console.log(`Deleting role ${roleDoc.id} from user's roles.`);
          batch.delete(roleDoc.ref);
        }
      });

      // Get the organization document snapshot
      const orgSnapshot = await getDoc(orgDocRef);
      if (!orgSnapshot.exists()) {
        console.error("No organisation found with id:", payload.orgUID);
        return;
      }
      const orgData = orgSnapshot.data();
      console.log("Organisation data retrieved:", orgData);

      // Get the members collection snapshot
      const membersSnapshot = await getDocs(collection(orgDocRef, "Members"));
      const membersCount = membersSnapshot.size;
      console.log("Number of members in org:", membersCount);

      const unitRef = doc(userRef, "Units", payload.orgUID);

      // Get the unit document snapshot
      const unitSnapshot = await getDoc(unitRef);
      const unitData = unitSnapshot.data();

      // Get member data for the user
      const memberRef = doc(orgDocRef, "Members", payload.userUID);
      const memberSnapshot = await getDoc(memberRef);
      const memberData = memberSnapshot.data();

      if (!memberData) {
        console.error("Error: Member data is null");
        return;
      }

      const safeMemberData = {
        userUID: memberData.userUID,
        roleTitle: memberData.roleTitle || "No Role Title", // Provide a default value
        roleID: memberData.roleID || "No Role ID", // Provide a default value
        isAnonymous: memberData.isAnonymous,
        joinedUnix: memberData.joinedUnix,
        leftUnix: Date.now(), // Use Date.now() for a consistent timestamp
        user_details: memberData.user_details || {}, // Ensure this is not undefined
        left: serverTimestamp(),
      };

      // Create a new document in the PastUnits collection for the user
      const pastUnitsRef = doc(userRef, "PastUnits", payload.orgUID);
      batch.set(pastUnitsRef, safeMemberData);

      if (memberData.roleTitle === undefined) {
        console.warn("roleTitle is undefined, setting default value.");
        memberData.roleTitle = "Unknown Role";
      }

      const isDeletingOrg =
        // membersCount === 1 && payload.orgUID !== state.foundationOrgUID;
        membersCount === 1;

      console.log("isDeletingOrg", isDeletingOrg);

      if (isDeletingOrg) {
        // if it NOT a Foundation org && if user is the last member, delete organisation document and all its subcollections
        const subcollections = [
          "Members",
          "Invites",
          "BasicIncome",
          "Roles",
          "PastMembers",
        ];

        // Loop through the subcollections and delete their documents
        for (const subcollection of subcollections) {
          const docsSnapshot = await getDocs(
            collection(orgDocRef, subcollection)
          );
          for (const doc of docsSnapshot.docs) {
            batch.delete(doc.ref);
          }
        }

        batch.delete(orgDocRef);
      } else {
        // Delete the member document
        batch.delete(memberRef);

        // Delete the role from custom-orgs Roles collection.
        console.log(
          "Debug: Payload userRoles before deletion",
          payload.userRoles
        );
        if (payload.userRoles) {
          for (const roleUID of Object.keys(payload.userRoles)) {
            console.log(`Debug: Processing roleUID: ${roleUID} for deletion.`);

            // Delete role from the user's RoleIDs subcollection
            const roleRefInUser = doc(userRef, "RoleIDs", roleUID);
            batch.delete(roleRefInUser);

            // Check if the role document exists before attempting to decrement slotsFilled
            const roleRef = doc(
              db,
              "custom-orgs",
              payload.orgUID,
              "Roles",
              roleUID
            );
            const roleSnap = await getDoc(roleRef);

            if (roleSnap.exists()) {
              batch.update(roleRef, {
                [`filledBy.${payload.userUID}`]: deleteField(),
                slotsFilled: increment(-1), // Decrement by 1
              });
            } else {
              // If the role document does not exist, log a warning and skip the update
              console.warn(
                `Role document for roleID ${roleUID} does not exist, skipping slotsFilled update.`
              );
            }
          }
        } else {
          console.log(
            "Debug: No userRoles found in payload, skipping role deletion."
          );
        }

        // Add member detail into the PastMembers sub-collection of the organization
        const pastMembersRef = doc(orgDocRef, "PastMembers", payload.userUID);
        batch.set(pastMembersRef, {
          userUID: memberData.userUID,
          roleTitle: safeMemberData.roleTitle, // Use the safe value
          roleID: safeMemberData.roleID, // Use the safe value
          isAnonymous: memberData.isAnonymous,
          joinedUnix: memberData.joinedUnix,
          leftUnix: Date.now(),
          user_details: memberData.user_details || {},
          left: serverTimestamp(),
        });

        // Decrement the count of filled slots in the BasicIncome document
        const basicIncomeQuerySnapshot = await getDocs(
          collection(orgDocRef, "BasicIncome")
        );
        const documents = basicIncomeQuerySnapshot.docs.map((doc) => ({
          key: Number(doc.id),
          data: doc.data(),
        }));
        const sortedDocuments = documents.sort((a, b) => b.key - a.key);
        const highestDocument = sortedDocuments[0];

        if (highestDocument) {
          const highestNumber = highestDocument.key;
          const highestData = highestDocument.data;

          // Access the fields inside the highestData object
          const { value, slots, slotsFilled } = highestData;

          // Decrease slotsFilled by 1
          const updatedSlotsFilled = slotsFilled - 1;

          // Update the slotsFilled field in the highest document
          const highestDocRef = doc(
            collection(orgDocRef, "BasicIncome"),
            highestNumber.toString()
          );
          batch.update(highestDocRef, { slotsFilled: updatedSlotsFilled });
        }
      }

      // Delete the unit document
      batch.delete(unitRef);

      // Update products and services owned by the user
      const updateProductsAndServices = async () => {
        const productsQuerySnapshot = await getDocs(
          query(
            collection(db, "products"),
            where("owner", "==", payload.userUID),
            where("organisation", "==", payload.orgUID)
          )
        );

        const servicesQuerySnapshot = await getDocs(
          query(
            collection(db, "services"),
            where("owner", "==", payload.userUID),
            where("organisation", "==", payload.orgUID)
          )
        );

        productsQuerySnapshot.forEach((doc) => {
          batch.update(doc.ref, { organisation: null, price: 0 });
        });

        servicesQuerySnapshot.forEach((doc) => {
          batch.update(doc.ref, { organisation: null, price: 0 });
        });
      };

      await updateProductsAndServices();

      // Commit the batch write
      try {
        console.log(
          "Attempting to commit batch operations for leaveCustomOrg."
        );
        await batch.commit();
        console.log("Batch commit successful for leaveCustomOrg.");
      } catch (error) {
        console.error("Batch commit failed for leaveCustomOrg:", error);
      }

      let payLoadUserID = payload.userUID;

      // Fetch the updated coin symbol after leaving the organization
      dispatch("fetchUserOrgCoinSymbol", payLoadUserID).then(() => {
        console.log(
          "Updated coin symbol after leaving organization:" + payLoadUserID
        );
      });

      // Update isActiveMember in user's wallet to false
      const walletRef = doc(
        db,
        "users",
        payload.userUID,
        "Wallets",
        payload.orgUID
      );
      await updateDoc(walletRef, { isActiveMember: false });
      if (membersCount > 1) {
        // Only update if it's not the last member
        try {
          await dispatch("getOrgMembersCount", payload.orgUID);
        } catch (error) {
          console.error("Error updating membersCount after leaving: ", error);
        }
      }
      // After successful leaving the organiaation, commit the mutations in the other module
      console.log("Finalizing leaveCustomOrg action.");

      commit("SET_USER_UNITS", {});
      commit("SET_CURRENT_ORG_UID", null);
      commit("SET_USER_UNITS_CUSTOM_ORGS", null);
      commit("SET_CURRENT_ORG_NAME", null);
      commit("RESET_MONETARY_PARAMS");
      commit("CLEAR_ROLE_DATA");
      // commit("RESET_ORG_DETAILS");
      // commit("RESET_TOTAL_ORG_COINS");
    },

    // ACCEPTING INVITES
    // This function is dispatched when a user accepts an invite

    async acceptInvite(
      { dispatch, commit },
      { inviteUID, oldOrgUID, newOrgUID, userUID, invitedRole, orgNameArg }
    ) {
      console.log("acceptInvite action started");
      commit("SET_INVITE_STATUS", "validating");
      let roleUID = invitedRole?.roleUID; // Fetch roleUID from the invitedRole if available

      try {
        // Fetch invite details
        const inviteDocRef = doc(
          db,
          "custom-orgs",
          newOrgUID,
          "Invites",
          inviteUID
        );
        const inviteDoc = await getDoc(inviteDocRef);
        const inviteData = inviteDoc.data();

        if (!inviteData) {
          commit("SET_INVITE_STATUS", "invalid");
          throw new Error("Invite does not exist");
        }

        if (inviteData.status === "accepted") {
          commit("SET_INVITE_STATUS", "already_accepted");
          throw new Error("Invite already accepted");
        }

        let roleData = null;

        // Fetch role data if roleUID is not null
        if (roleUID) {
          roleData = await dispatch("fetchRoleData", {
            orgUID: newOrgUID,
            roleUID,
          });

          if (!roleData) {
            console.error("Failed to fetch role data");
            return; // Stop the function execution
          }

          commit("SET_ROLE_DATA", roleData);
        }

        // Check if the user is already in the new organization
        const userUnitDocRef = doc(db, "users", userUID, "Units", newOrgUID);
        const userUnitDoc = await getDoc(userUnitDocRef);
        const isUserAlreadyInOrg = userUnitDoc.exists();

        if (isUserAlreadyInOrg) {
          console.log(
            "User is already in the organization. Updating role only."
          );
          // If the user is already in the organization, just update the role (if any)
          if (roleUID) {
            await dispatch("joinCustomOrg", {
              inviteUID,
              newOrgUID,
              userUID,
              roleID: roleUID || null,
              orgNameArg,
              roleData: roleUID ? roleData : null,
            });
          }
        } else {
          // If the user is not in the organization, handle the full invite process
          if (oldOrgUID && oldOrgUID !== newOrgUID) {
            const userUnitDocRef = doc(
              db,
              "users",
              userUID,
              "Units",
              oldOrgUID
            );
            const userUnitDoc = await getDoc(userUnitDocRef);
            if (userUnitDoc.exists()) {
              // User is in another organization, so they must leave the current one first
              await dispatch("leaveCustomOrg", { userUID, orgUID: oldOrgUID });
            }
          }

          // Let the user join the new organization
          await dispatch("joinCustomOrg", {
            inviteUID,
            newOrgUID,
            userUID,
            roleID: roleUID || null,
            orgNameArg,
            roleData: roleUID ? roleData : null,
          });
        }
        let updateData = {};

        if (inviteData.oneTimeUse && inviteData.status !== "accepted") {
          updateData.status = "accepted";
        }

        updateData.inviteeUIDs = arrayUnion(userUID);

        // Update the invite status from 'pending' to 'accepted'
        await updateDoc(inviteDocRef, updateData);

        console.log("Invite accepted successfully");
      } catch (error) {
        console.error("Error accepting invite:", error);
        throw error;
      }
    },

    /////////////////////////
    async getOrgMembersCount({ commit }, orgUID) {
      if (!orgUID) {
        console.error("orgUID is undefined or null");
        return;
      }
      try {
        const membersSnap = await getDocs(
          collection(db, "custom-orgs", orgUID, "Members")
        );
        const orgDocRef = doc(db, "custom-orgs", orgUID);
        await setDoc(
          orgDocRef,
          { membersCount: membersSnap.size },
          { merge: true }
        );
        return membersSnap.size;
      } catch (error) {
        console.error("Error getting organisation members count: ", error);
        throw error;
      }
    },
  },
};
