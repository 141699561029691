import {
  collection,
  addDoc,
  getDocs,
  query,
  orderBy,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "@/firebase";

export default {
  state: {
    multimediaPosts: [],
    loading: false,
  },
  getters: {
    multimediaPosts: (state) => state.multimediaPosts,
  },
  actions: {
    async addMultimediaPost({ commit, dispatch }, postData) {
      try {
        commit("SET_LOADING", true);
        const docRef = await addDoc(collection(db, "multimedia"), {
          ...postData,
          createdAt: serverTimestamp(), // Use Firestore server timestamp
        });
        console.log("Multimedia post added with ID:", docRef.id);

        // Fetch latest posts after adding a new one
        await dispatch("fetchMultimediaPosts");
      } catch (error) {
        console.error("Error adding multimedia post:", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async fetchMultimediaPosts({ commit }) {
      try {
        commit("SET_LOADING", true);
        const q = query(
          collection(db, "multimedia"),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);
        const posts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        commit("SET_MULTIMEDIA_POSTS", posts);
      } catch (error) {
        console.error("Error fetching multimedia posts:", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },
  },
  mutations: {
    SET_MULTIMEDIA_POSTS(state, posts) {
      state.multimediaPosts = posts;
    },
    SET_LOADING(state, status) {
      state.loading = status;
    },
  },
};
