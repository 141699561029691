<template>
  <div class="interactive-message">
    <!-- CoinRain -->
    <CoinRain ref="coinRainRef" :emojis="['🪙']" />

    <!-- Optional product image -->
    <div
      v-if="productImageUrl"
      class="product-image-container"
      :class="{ 'circle-image': type === 'send' || type === 'request' }"
    >
      <img :src="productImageUrl" alt="Product" class="product-image" />
    </div>

    <!-- Product / Transfer Details -->
    <div class="product-details">
      <!-- Possibly show the item name if service/product -->
      <div class="divnomargintopbottom">
        <div
          class="product-name"
          v-if="
            (type === 'service' && bookingDate) ||
            (type === 'product' && itemName)
          "
        >
          <strong>{{ truncatedItemName || "Details" }}</strong>
        </div>
        <!-- Service date/time if relevant -->
        <small v-if="type === 'service' && bookingDate" class="gray">
          {{ formattedBookingDate }}, {{ formattedBookingTime }} ({{
            slotDuration
          }}
          min)
          <br />
        </small>
        <small v-if="!itemName && !productImageUrl" class="purple500">
          Transfer details
        </small>
      </div>

      <div class="product-price">
        <!-- PRODUCT / SERVICE BLOCK -->
        <template
          v-if="
            (type === 'service' && bookingDate) ||
            (type === 'product' && itemName)
          "
        >
          <!-- If customTransfer is true for product/service, show an input -->
          <template v-if="shouldShowProductInput">
            <div class="transfer-input-container row alignCentreRow">
              <input
                type="text"
                inputmode="decimal"
                v-model="localTransferAmountInput"
                @input="handleTransferAmountInput"
                class="transfer-input smallInput"
                placeholder="Enter amount"
              />
              <span>{{ buyerCoinSymbol }}</span>
            </div>
            <!-- Translated price underneath -->
            <small v-if="localTransferAmount > 0" class="gray">
              <span class="price-label">Seller receives:</span>
              {{ translatedAmount }} {{ otherCoinSymbol }}
            </small>
            <br />
          </template>
          <!-- Otherwise, show read-only price display -->
          <template v-else>
            <!-- IMPORTANT: Buyer sees their converted price prominently -->
            <div>
              <span class="price-label">You pay:</span>
              <strong>{{ formattedDisplayPrice }}</strong> {{ buyerCoinSymbol }}
            </div>
            <!-- Original price in seller's currency shown as reference -->
            <span class="gray">
              <span class="price-label">Seller receives:</span>
              {{ formatNumberTo4Digits(productPrice) }} {{ sellerCoinSymbol }}
            </span>
            <br />
          </template>
        </template>

        <!-- SEND / REQUEST BLOCK -->
        <template
          v-else-if="
            type &&
            (type.toLowerCase() === 'send' || type.toLowerCase() === 'request')
          "
        >
          <!-- If not in custom mode, just show preset amount -->
          <div v-if="!customTransfer">
            <!-- Buyer/sender sees amount in THEIR currency -->
            <div>
              <span class="price-label">You send:</span>
              <strong>{{ transferAmount }} {{ buyerCoinSymbol }}</strong>
            </div>
            <!-- What the receiver will get (in receiver's currency) -->
            <span class="gray">
              <span class="price-label">Recipient receives:</span>
              {{ formatNumberTo4Digits(productPrice) }} {{ sellerCoinSymbol }}
            </span>
          </div>
          <!-- If custom mode, allow manual entry in buyerCoin (sender's currency) -->
          <div v-else class="transfer-input-container row alignCentreRow">
            <input
              type="text"
              inputmode="decimal"
              v-model="localTransferAmountInput"
              @input="handleTransferAmountInput"
              class="transfer-input smallInput"
              placeholder="Enter amount"
            />
            <span>{{ buyerCoinSymbol }}</span>
          </div>
          <!-- Show what receiver will get (receiver's currency) -->
          <small v-if="customTransfer && localTransferAmount > 0" class="gray">
            <span class="price-label">Recipient receives:</span>
            {{ translatedAmount }} {{ otherCoinSymbol }}
          </small>
        </template>
      </div>
    </div>

    <!-- Action Buttons -->
    <div class="actions">
      <div class="column alignRightColumn notSoSmallGap">
        <!-- If balance is sufficient, show main action -->
        <div v-if="!isBalanceInsufficient">
          <!-- No custom transfer => single button -->
          <template v-if="!customTransfer">
            <div
              @click="handleMakeOffer"
              class="row alignCentreRow button smallButton smallerClickableHeight noWrap"
            >
              {{ actionLabel }}
              <svg
                v-if="type === 'service'"
                class="smaller compactImg"
                alt="book a slot icon"
              >
                <use href="@/assets/icons/iconset.svg#calendar_compact"></use>
              </svg>
              <svg v-else class="smaller compactImg" alt="offer icon">
                <use
                  href="@/assets/icons/iconset.svg#message_money_compact"
                ></use>
              </svg>
            </div>
          </template>

          <!-- Custom => validate localTransferAmount -->
          <template v-else>
            <div v-if="localTransferAmount > 0 && !transferError">
              <div
                @click="handleMakeOffer"
                class="row alignCentreRow button smallButton smallerClickableHeight noWrap"
              >
                {{ actionLabel }}
                <svg
                  v-if="type === 'service'"
                  class="smaller compactImg"
                  alt="book a slot icon"
                >
                  <use href="@/assets/icons/iconset.svg#calendar_compact"></use>
                </svg>
                <svg v-else class="smaller compactImg" alt="offer icon">
                  <use
                    href="@/assets/icons/iconset.svg#message_money_compact"
                  ></use>
                </svg>
              </div>
            </div>
            <div v-else>
              <small class="error">{{ transferError }}</small>
            </div>
          </template>
        </div>
        <small class="cancelText gray" @click="handleClearProduct">
          Cancel
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted, watch } from "vue";
import { formatNumberTo4Digits } from "@/utils/numberFormattingTo4digits";
import { format } from "date-fns";
import { useStore } from "vuex";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "@/firebase";
import { toast } from "vue3-toastify";

import CoinRain from "@/components/AnimationComponents/CoinRain.vue";

// Function to parse abbreviated values like "10K" or "2.5M" into their numeric equivalents
function parseAbbreviatedValue(value) {
  console.log("Parsing abbreviated value:", value, "Type:", typeof value);

  // If value is already a number, return it
  if (typeof value === "number") return value;

  // If value is not a string or is empty, return 0
  if (typeof value !== "string" || !value.trim()) return 0;

  // Convert to uppercase for consistent handling
  const upperValue = value.trim().toUpperCase();
  console.log("Uppercase value:", upperValue);

  // Check for abbreviations
  if (upperValue.endsWith("K")) {
    // Handle thousands (K)
    const numPart = parseFloat(upperValue.slice(0, -1));
    const result = isNaN(numPart) ? 0 : numPart * 1000;
    console.log("Parsed K value:", upperValue, "→", result);
    return result;
  } else if (upperValue.endsWith("M")) {
    // Handle millions (M)
    const numPart = parseFloat(upperValue.slice(0, -1));
    const result = isNaN(numPart) ? 0 : numPart * 1000000;
    console.log("Parsed M value:", upperValue, "→", result);
    return result;
  } else if (upperValue.endsWith("G")) {
    // Handle billions (G)
    const numPart = parseFloat(upperValue.slice(0, -1));
    const result = isNaN(numPart) ? 0 : numPart * 1000000000;
    console.log("Parsed G value:", upperValue, "→", result);
    return result;
  } else if (upperValue.endsWith("T")) {
    // Handle trillions (T)
    const numPart = parseFloat(upperValue.slice(0, -1));
    const result = isNaN(numPart) ? 0 : numPart * 1000000000000;
    console.log("Parsed T value:", upperValue, "→", result);
    return result;
  } else {
    // No abbreviation, try to parse as a regular number
    const numValue = parseFloat(upperValue);
    console.log("Parsed regular number:", upperValue, "→", numValue);
    return isNaN(numValue) ? 0 : numValue;
  }
}

export default {
  name: "InteractiveMessage",
  components: {
    CoinRain,
  },
  props: {
    productPrice: { type: [Number, String], default: 0 }, // in sellerCoin
    convertedPrice: { type: [Number, String], default: 0 }, // in buyerCoin
    productImageUrl: { type: String, default: "" },
    itemName: { type: String, default: "" },
    transferAmount: {
      type: [Number, String],
      default: 0,
      validator: function (value) {
        // If a string is passed, console.warn and convert to Number
        if (typeof value === "string") {
          console.warn(
            "transferAmount was passed as a string, converting to number"
          );
          return true; // still valid, but we'll convert it in setup()
        }
        return true;
      },
    },
    type: { type: String, default: "product" },
    bookingDate: { type: String, default: "" },
    bookingTime: { type: String, default: "" },
    slotDuration: { type: Number, default: 0 },
    sellerCoinSymbol: { type: String, default: "" }, // "other" coin
    buyerCoinSymbol: { type: String, default: "" }, // "sender" coin
    coinSymbol: { type: String, default: "" },
    otherUserData: { type: Object, default: () => ({}) },
    buyerOrgUID: { type: String, default: "" },
    currentUserWallet: { type: Object, default: () => ({}) },
    customTransfer: { type: Boolean, default: false },
  },
  emits: ["makeOffer"],
  setup(props, { emit }) {
    const store = useStore();
    const transferError = ref("");

    // Convert transferAmount to number if it's a string
    const transferAmountNumber = computed(() => {
      if (typeof props.transferAmount === "string") {
        return Number(props.transferAmount);
      }
      return props.transferAmount || 0;
    });

    // The user types in buyerCoin (the sender's coin).
    const localTransferAmount = ref(
      transferAmountNumber.value || props.convertedPrice || 0
    );

    // Text input for better decimal input handling
    const localTransferAmountInput = ref(
      (transferAmountNumber.value || props.convertedPrice || 0).toString()
    );

    // CoinRain
    const coinRainRef = ref(null);

    // Wallet balance in buyerCoin
    const walletBalance = ref(0);

    // Fetch wallet
    const fetchUserWalletBalance = async () => {
      if (auth.currentUser && props.buyerOrgUID) {
        try {
          const walletRef = doc(
            db,
            "users",
            auth.currentUser.uid,
            "Wallets",
            props.buyerOrgUID
          );
          const snap = await getDoc(walletRef);
          walletBalance.value = snap.exists()
            ? snap.data()?.currentAccount?.balance || 0
            : 0;
        } catch (error) {
          walletBalance.value = 0;
          console.error("Error fetching user wallet balance:", error);
        }
      } else {
        walletBalance.value = 0;
      }
    };
    onMounted(fetchUserWalletBalance);

    // If the parent's buyerOrgUID or currentUserWallet changes, refresh
    watch(
      () => [props.currentUserWallet, props.buyerOrgUID],
      fetchUserWalletBalance,
      { immediate: true }
    );

    // If the parent changes the item (productPrice, convertedPrice, or transferAmount),
    // reset localTransferAmount to match the new item:
    watch(
      () => [props.productPrice, props.convertedPrice, props.transferAmount],
      () => {
        console.log("Props changed, updating localTransferAmount");
        console.log("Previous localTransferAmount:", localTransferAmount.value);
        console.log(
          "Previous localTransferAmountInput:",
          localTransferAmountInput.value
        );

        const newValue = props.transferAmount || props.convertedPrice || 0;
        console.log(
          "New value from props:",
          newValue,
          "Type:",
          typeof newValue
        );

        // Parse the value to handle abbreviations like K, M, etc.
        const parsedValue = parseAbbreviatedValue(newValue);
        console.log("Parsed value:", parsedValue);

        localTransferAmount.value = parsedValue;
        localTransferAmountInput.value = newValue.toString();

        console.log("Updated localTransferAmount:", localTransferAmount.value);
        console.log(
          "Updated localTransferAmountInput:",
          localTransferAmountInput.value
        );
      }
    );

    // For product/service => show input if customTransfer
    const shouldShowProductInput = computed(() => {
      if (
        (props.type === "product" || props.type === "service") &&
        props.customTransfer
      ) {
        return true;
      }
      return false;
    });

    // Handle user input with better validation for decimal numbers and abbreviations
    const handleTransferAmountInput = () => {
      console.log("Original input:", localTransferAmountInput.value);

      // Allow K, M, G, T abbreviations along with numbers and decimal point
      const cleanedInput = localTransferAmountInput.value
        .replace(/[^0-9.KMGTkmgt]/g, "") // Allow numbers, decimal point, and abbreviations
        .replace(/(\..*)\./g, "$1"); // Allow only one decimal point

      console.log("Cleaned input:", cleanedInput);

      // Update the input field with cleaned value
      localTransferAmountInput.value = cleanedInput;

      // Parse the input value, handling abbreviations like K, M, etc.
      const numericValue = parseAbbreviatedValue(cleanedInput);
      console.log("Parsed numeric value:", numericValue);

      // Only update numeric value if it's a valid number
      if (!isNaN(numericValue)) {
        localTransferAmount.value = numericValue;
        console.log("Updated localTransferAmount:", localTransferAmount.value);
        validateLocalTransfer(numericValue);
      }
    };

    // Validate the transfer amount against user's wallet balance
    const validateLocalTransfer = (amount) => {
      const balance = walletBalance.value || 0;
      const minAmount = balance / 1_000_000; // small fraction

      if (!amount || amount <= 0) {
        transferError.value = `Please enter a positive amount (min: ${minAmount.toFixed(
          5
        )})`;
        // Don't show toast while typing
      } else if (amount > balance) {
        transferError.value = `Amount exceeds your balance of ${balance.toFixed(
          2
        )}`;
        // Don't automatically modify user input, just show the error
      } else {
        transferError.value = "";
      }
    };

    // Local coin => always buyerCoinSymbol (sender)
    const localCoinSymbol = computed(
      () => props.buyerCoinSymbol || "BuyerCoin"
    );

    // The "other" coin => sellerCoinSymbol
    const otherCoinSymbol = computed(
      () => props.sellerCoinSymbol || "SellerCoin"
    );

    /*
      Conversion:
      productPrice => sellerCoin
      convertedPrice => buyerCoin
      localTransferAmount => typed in buyerCoin

      ratio = productPrice / convertedPrice => how many sellerCoin per buyerCoin
      translatedAmount => localTransferAmount * ratio => approximate sellerCoin
    */
    const conversionRate = computed(() => {
      const productPriceNum = Number(props.productPrice || 0);
      const convertedPriceNum = Number(props.convertedPrice || 0);
      if (!productPriceNum || !convertedPriceNum) return 0;
      return productPriceNum / convertedPriceNum;
    });

    const translatedAmount = computed(() => {
      if (!conversionRate.value || !localTransferAmount.value) return "";
      const rawSellerCoin = localTransferAmount.value * conversionRate.value;
      return formatNumberTo4Digits(rawSellerCoin);
    });

    // If custom => show localTransferAmount in buyerCoin
    // else => show "convertedPrice" if available
    const formattedDisplayPrice = computed(() => {
      if (props.customTransfer) {
        return formatNumberTo4Digits(localTransferAmount.value);
      }
      return formatNumberTo4Digits(Number(props.convertedPrice || 0));
    });

    // Basic existing
    const truncatedItemName = computed(() => {
      const item = props.itemName || "";
      if (item.length <= 20) return item;
      return `${item.substring(0, 15)}(…)${item.substring(item.length - 3)}`;
    });

    const formattedBookingDate = computed(() => {
      if (props.bookingDate) {
        try {
          const d = new Date(props.bookingDate);
          if (!isNaN(d.getTime())) return format(d, "eee, MMM do");
        } catch {}
      }
      return "";
    });

    const formattedBookingTime = computed(() => {
      if (props.bookingTime) {
        try {
          const t = new Date(`1970-01-01T${props.bookingTime}`);
          if (!isNaN(t.getTime())) return format(t, "p");
        } catch {}
      }
      return "";
    });

    const actionLabel = computed(() => {
      switch (props.type?.toLowerCase()) {
        case "send":
          return "Send";
        case "request":
          return "Request";
        case "product":
          return "Make offer";
        case "service":
          return "Make offer";
        default:
          return "Action";
      }
    });

    // Check if typed buyerCoin is more than user's wallet
    const isBalanceInsufficient = computed(() => {
      return walletBalance.value < Number(formattedDisplayPrice.value || 0);
    });

    // Final accept/offer
    const handleMakeOffer = () => {
      if (props.customTransfer) {
        validateLocalTransfer(localTransferAmount.value);

        // Check if there are validation errors
        if (!localTransferAmount.value || transferError.value) {
          toast.error(transferError.value || "Please enter a valid amount");
          return;
        }
      }

      // Use typed buyerCoin or fallback - ensure we're using numeric values, parsing any abbreviations
      let finalAmount = parseAbbreviatedValue(props.transferAmount);
      if (props.customTransfer) {
        finalAmount = localTransferAmount.value; // Already parsed in handleTransferAmountInput
      } else if (props.convertedPrice) {
        finalAmount = parseAbbreviatedValue(props.convertedPrice);
      }

      // Create new object with updated values
      const updatedProps = {
        ...props,
        // The main numeric amount in buyerCoin - ensure it's a number, not a formatted string
        transferAmount: finalAmount,
        // Also update these values to ensure consistency
        productPrice: finalAmount, // This will be converted to seller's currency by Messages component
        convertedPrice: finalAmount, // For custom transfers, this is what the sender pays
        // Make sure we're passing the coin symbols correctly
        buyerCoinSymbol: props.buyerCoinSymbol,
        sellerCoinSymbol: props.sellerCoinSymbol,
      };

      console.log(
        "Sending transfer amount:",
        finalAmount,
        "Type:",
        typeof finalAmount
      );

      // Pass the updated props to parent
      emit("makeOffer", updatedProps);
      coinRainRef.value?.startAnimation();
    };

    const handleClearProduct = () => {
      store.dispatch("UIState/clearProductInfo");
    };

    return {
      coinRainRef,
      localTransferAmount,
      localTransferAmountInput,
      transferError,
      walletBalance,

      // Expose for template
      productImageUrl: computed(() => props.productImageUrl),
      type: computed(() => props.type),
      bookingDate: computed(() => props.bookingDate),
      bookingTime: computed(() => props.bookingTime),
      slotDuration: computed(() => props.slotDuration),
      transferAmount: computed(() => props.transferAmount),
      customTransfer: computed(() => props.customTransfer),

      truncatedItemName,
      formattedBookingDate,
      formattedBookingTime,
      actionLabel,
      isBalanceInsufficient,
      formattedDisplayPrice,
      shouldShowProductInput,
      localCoinSymbol,
      otherCoinSymbol,
      translatedAmount,

      validateLocalTransfer,
      handleTransferAmountInput,
      handleMakeOffer,
      handleClearProduct,
    };
  },
};
</script>

<style scoped>
.interactive-message {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--smallMargin);
  width: 100%;
  justify-content: space-between;
}
.product-image-container {
  width: calc(var(--clickableHeight) * 1.3);
  height: calc(var(--clickableHeight) * 1.3);
  margin-right: var(--smallerMargin);
  border-radius: var(--borderRadius);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: var(--backgroundLighterGraySuperTransp);
}
.circle-image {
  border-radius: 50%;
  border: 1px solid var(--black);
  min-width: fit-content;
  padding: 0 var(--tinyMargin);
}
.product-image {
  width: 100%;
  object-fit: cover;
  border-radius: var(--borderRadius);
}
.product-details {
  flex-grow: 1;
  display: flex;
  gap: var(--smallerMargin);
}
.product-price {
  display: flex;
  flex-direction: column;
  gap: var(--microMargin);
  margin-right: var(--bigMargin);
}
/* Input + coin horizontally */
.transfer-input-container {
  display: flex;
  align-items: center;
  gap: var(--microMargin);
  color: var(--solidMidgrey);
}
.transfer-input {
  width: 90px;
  margin-right: var(--microMargin);
  border-radius: var(--borderRadiusBigger);
  transition: all 0.2s ease;
}

.transfer-input:focus {
  outline: none;
  border-color: var(--purple400);
  box-shadow: 0 0 0 2px rgba(156, 39, 176, 0.2);
}
.actions {
  display: flex;
  gap: var(--smallerMargin);
  align-items: flex-start;
}
.alignRightColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.error {
  color: var(--red);
  font-size: 0.8em;
  margin-top: 4px;
  max-width: 120px;
  text-align: right;
}

.price-label {
  font-size: 0.8rem;
  color: var(--text-color-2-unit);
  margin-right: 4px;
  font-weight: 500;
}
</style>
