<template>
  <div class="add-media-form">
    <form @submit.prevent="handleSubmit">
      <!-- Initial Upload Step -->
      <div class="column" v-if="currentStep === 'initial'">
        <!-- itemName Field -->
        <div class="fullWidth">
          <label for="itemName">Item name</label>
          <input
            type="text"
            id="itemName"
            class="fullWidth alignCentre"
            v-model="itemName"
            required
            ref="titleInput"
            :class="{ 'highlight-input': highlightItemName }"
          />
        </div>

        <!-- File Upload -->
        <div class="fullWidth">
          <ImageUpload
            @updateImages="handleFileUpdate"
            @updateThumbnail="handleThumbnailUpdate"
            :thumbnailIndex="localThumbnailIndex"
            :newImages="selectedFiles && selectedFiles.length > 0 ? selectedFiles : []"
            :existingImages="!selectedFile || selectedFile.isExistingFile
                ? previewUrl
                  ? [previewUrl]
                  : []
                : []"
          />
        </div>

        <!-- Caption Field -->
        <div class="fullWidth">
          <label for="caption">Caption</label>
          <textarea
            id="caption"
            class="fullWidth alignCentre"
            v-model="caption"
            required
            ref="captionInput"
            :class="{ 'highlight-input': highlightCaption }"
          ></textarea>
        </div>
      </div>

      <!-- Preview Step -->
      <div v-if="currentStep === 'preview'" class="preview-container">
        <!-- Image Preview with Carousel for Multiple Images -->
        <div v-if="isImage">
          <ProductCarousel
            v-if="previewImages.length > 0"
            :product="{ 
              images: previewImages,
              thumbnailIndex: localThumbnailIndex // Use the selected thumbnail index
            }"
          />
          <div v-else class="placeholderImageNotAbsolute">
            <p>No image available</p>
          </div>
        </div>

        <!-- Video Preview -->
        <div v-else-if="isVideo">
          <video :src="previewUrl" controls class="preview-video"></video>
        </div>

        <!-- Audio Preview -->
        <div v-else-if="isAudio">
          <audio :src="previewUrl" controls class="preview-audio"></audio>
        </div>

        <!-- Title and Caption -->
        <div class="media-details">
          <h3 class="itemName-preview">{{ itemName }}</h3>
          <p class="caption-preview">{{ caption }}</p>

          <!-- Show how many images will be uploaded -->
          <div v-if="isImage && selectedFiles.length > 1" class="image-count">
            <small>{{ selectedFiles.length }} images will be uploaded</small>
          </div>
        </div>
      </div>

      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    </form>
  </div>
</template>

<script>
import { ref, watch, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import ImageUpload from "@/components/GenericComponents/ImageUpload.vue";
import ProductCarousel from "@/components/ProductComponents/ProductCarousel.vue";
import { toast } from "vue3-toastify";

export default {
  name: "AddMediaForm",
  components: { ImageUpload, ProductCarousel },
  props: {
    currentStep: {
      type: String,
      required: true,
    },
    userCredentials: {
      type: Object,
      required: true,
    },
    currentProduct: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    "mediaAddedSuccessfully",
    "update-currentStep",
    "update-canPreview",
    "update-itemName",
    "update-caption",
    "update-title",
  ],
  setup(props, { emit }) {
    const store = useStore();
    console.log("AddMediaForm received currentProduct:", props.currentProduct);

    // Get org and owner UIDs from store/props
    const currentOrgUID = computed(() => store.getters.currentOrgUID);
    const ownerUID = computed(
      () =>
        props.userCredentials?.data?.uid || store.getters.user?.data.uid || null
    );

    // Form fields and error states
    const itemName = ref(props.currentProduct?.itemName || "");
    const caption = ref(props.currentProduct?.caption || "");
    const previewUrl = ref(props.currentProduct?.mediaUrl || null);
    const errorMessage = ref("");
    const highlightItemName = ref(false);
    const highlightCaption = ref(false);
    const selectedFiles = ref([]);
    const selectedFile = ref(null);
    const isImage = ref(props.currentProduct?.mediaType === "image" || false);
    const isVideo = ref(props.currentProduct?.mediaType === "video" || false);
    const isAudio = ref(props.currentProduct?.mediaType === "audio" || false);
    const acceptedTypes = ".jpg,.jpeg,.png,.gif,video/*,audio/*";
    const localThumbnailIndex = ref(props.currentProduct?.thumbnailIndex || 0);

    // Maintain a map of file to URL for cleanup
    const fileToUrlMap = new Map();
    
    // Computed property to generate preview image URLs for the carousel
    const previewImages = computed(() => {
      // If we have files to show
      if (isImage.value && selectedFiles.value.length > 0) {
        // Use a Set to track unique URLs and create a new array from it
        // This guarantees no duplicates in the final array
        const uniqueUrls = new Set();
        
        // Process each file and get its URL
        for (const file of selectedFiles.value) {
          let url;
          
          if (typeof file === "string") {
            // It's already a URL string
            url = file;
          } else if (file instanceof File) {
            // For File objects, create blob URLs
            if (!fileToUrlMap.has(file)) {
              const objectUrl = URL.createObjectURL(file);
              fileToUrlMap.set(file, objectUrl);
            }
            url = fileToUrlMap.get(file);
          } else if (file && file.url && typeof file.url === "string") {
            // For objects with URL properties
            url = file.url;
          } else {
            continue; // Skip invalid entries
          }
          
          // Add to Set (automatically handles duplicates)
          uniqueUrls.add(url);
        }
        
        // Convert Set to Array
        const images = Array.from(uniqueUrls);
        console.log("[AddMediaForm] Generated preview images:", images);
        return images;
      } 
      // Otherwise just use previewUrl if available
      else if (previewUrl.value) {
        return [previewUrl.value];
      }
      
      // Default empty array
      return [];
    });

    // Refs for input elements so they can be focused when errors occur
    const titleInput = ref(null);
    const captionInput = ref(null);

    // Initialize the form with data from currentProduct if available
    if (props.currentProduct && Object.keys(props.currentProduct).length > 0) {
      console.log(
        "Preparing to populate form with media:",
        props.currentProduct
      );

      // Determine media type first
      if (
        props.currentProduct.mediaType === "image" ||
        !props.currentProduct.mediaType
      ) {
        isImage.value = true;
        isVideo.value = false;
        isAudio.value = false;
      } else if (props.currentProduct.mediaType === "video") {
        isImage.value = false;
        isVideo.value = true;
        isAudio.value = false;
      } else if (props.currentProduct.mediaType === "audio") {
        isImage.value = false;
        isVideo.value = false;
        isAudio.value = true;
      }

      console.log("Media type determined:", {
        isImage: isImage.value,
        isVideo: isVideo.value,
        isAudio: isAudio.value,
      });

      // Set fields from the product
      itemName.value = props.currentProduct.itemName || props.currentProduct.title || "";
      caption.value = props.currentProduct.caption || 
                      props.currentProduct.itemDescription || 
                      props.currentProduct.description || "";

      // Handle images
      if (props.currentProduct.images && Array.isArray(props.currentProduct.images) && props.currentProduct.images.length > 0) {
        // Use the images array directly but ensure uniqueness with a Set
        console.log("Using images array with", props.currentProduct.images.length, "images:", props.currentProduct.images);
        
        // Create a Set from the array to remove duplicates, then convert back to array
        const uniqueImages = Array.from(new Set(props.currentProduct.images));
        console.log("After removing duplicates:", uniqueImages.length, "unique images");
        
        // Store only the unique URLs to avoid duplicates in the array
        selectedFiles.value = uniqueImages;
        
        // Set the first image as preview (from the uniqueImages array)
        previewUrl.value = uniqueImages[0];
      } 
      // Fall back to mediaUrl if there's no images array
      else if (props.currentProduct.mediaUrl) {
        console.log("Using mediaUrl:", props.currentProduct.mediaUrl);
        previewUrl.value = props.currentProduct.mediaUrl;
        selectedFiles.value = [props.currentProduct.mediaUrl];
      }
      // No images at all
      else {
        console.warn("No image URLs found for this media item");
        selectedFiles.value = [];
        previewUrl.value = null;
      }
      
      // If we have any images to show, enable preview
      if (selectedFiles.value.length > 0 || previewUrl.value) {
        emit("update-canPreview", true);
      }
    }


    
    const handleFileUpdate = (
      updatedImages,
      removedUrls,
      editedImages,
      previewUrls
    ) => {
      console.log("[AddMediaForm] handleFileUpdate called", {
        updatedImages,
        removedUrls,
        editedImages,
        previewUrls,
      });

      // Clean up existing object URLs to prevent memory leaks
      if (previewUrl.value && previewUrl.value.startsWith('blob:')) {
        URL.revokeObjectURL(previewUrl.value);
        previewUrl.value = null;
      }
      
      // Also revoke any URLs in the fileToUrlMap that are being removed
      fileToUrlMap.forEach((url, file) => {
        if (removedUrls.includes(url) || !updatedImages.includes(file)) {
          URL.revokeObjectURL(url);
          fileToUrlMap.delete(file);
        }
      });

      if (Array.isArray(updatedImages) && updatedImages.length > 0) {
        // Get unique file references by creating a Set of their names or URLs
        const uniqueFiles = [];
        const trackingSet = new Set();
        
        for (const file of updatedImages) {
          // For File objects, use their name as the identifier
          if (file instanceof File) {
            if (!trackingSet.has(file.name)) {
              trackingSet.add(file.name);
              uniqueFiles.push(file);
            }
          } 
          // For string URLs, use the URL itself
          else if (typeof file === 'string') {
            if (!trackingSet.has(file)) {
              trackingSet.add(file);
              uniqueFiles.push(file);
            }
          }
          // For objects with URL property
          else if (file && file.url && typeof file.url === 'string') {
            if (!trackingSet.has(file.url)) {
              trackingSet.add(file.url);
              uniqueFiles.push(file);
            }
          }
          // Any other kind of object
          else if (file) {
            uniqueFiles.push(file); // Include it but can't deduplicate
          }
        }
        
        console.log(`[AddMediaForm] De-duplicated from ${updatedImages.length} to ${uniqueFiles.length} files`);
        
        // Support multiple images
        selectedFiles.value = uniqueFiles;

        // Set the first file as the main file for type detection
        const mainFile = uniqueFiles[0];
        selectedFile.value = mainFile;
        
        console.log("Main file:", mainFile, "Type:", typeof mainFile);

        // Validate each file
        for (const file of updatedImages) {
          // Skip validation for string URLs or non-file objects
          if (!(file instanceof File)) continue;
          
          // Check for GIF size limit (5 MB = 5 * 1024 * 1024 bytes)
          if (file.type === "image/gif" && file.size > 5 * 1024 * 1024) {
            toast.error("Animated GIF files must be less than 5 MB.");
            selectedFile.value = null;
            selectedFiles.value = [];
            previewUrl.value = null;
            return;
          }
        }

        const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
        const validVideoTypes = ["video/mp4", "video/webm", "video/ogg"];
        const validAudioTypes = ["audio/mpeg", "audio/mp3", "audio/wav"];

        // Determine media type from main file
        if (mainFile instanceof File) {
          // Only check type for File objects
          if (validImageTypes.includes(mainFile.type)) {
            isImage.value = true;
            isVideo.value = false;
            isAudio.value = false;
            console.log("[AddMediaForm] Files recognized as images.");
          } else if (validVideoTypes.includes(mainFile.type)) {
            isVideo.value = true;
            isImage.value = false;
            isAudio.value = false;
            console.log("[AddMediaForm] File recognized as a video.");
            // For video, we only support one file
            selectedFiles.value = [mainFile];
          } else if (validAudioTypes.includes(mainFile.type)) {
            isAudio.value = true;
            isImage.value = false;
            isVideo.value = false;
            console.log("[AddMediaForm] File recognized as audio.");
            // For audio, we only support one file
            selectedFiles.value = [mainFile];
          } else {
            errorMessage.value =
              "Invalid file type. Please upload an image, video, or audio file.";
            return;
          }
          
          // Update preview URL for the primary file only if it's a File object
          previewUrl.value = URL.createObjectURL(mainFile);
        } else if (typeof mainFile === 'string') {
          // For URL strings, try to infer the type from the URL
          const url = mainFile.toLowerCase();
          if (url.match(/\.(jpeg|jpg|png|gif|webp)$/)) {
            isImage.value = true;
            isVideo.value = false;
            isAudio.value = false;
          } else if (url.match(/\.(mp4|webm|ogg|mov)$/)) {
            isVideo.value = true;
            isImage.value = false;
            isAudio.value = false;
          } else if (url.match(/\.(mp3|wav|ogg|aac)$/)) {
            isAudio.value = true;
            isImage.value = false;
            isVideo.value = false;
          }
          
          // For URL strings, use the URL directly
          previewUrl.value = mainFile;
          console.log("Using URL string as preview:", mainFile);
        } else {
          console.warn('[AddMediaForm] Unknown type for mainFile:', mainFile);
        }
      }
    };
    
    // Handle thumbnail updates from the ImageUpload component
    const handleThumbnailUpdate = (imageUrl) => {
      console.log("[AddMediaForm] Thumbnail updated to URL:", imageUrl);
      
      // Find the index of the selected image in our selectedFiles array
      const index = selectedFiles.value.findIndex(file => {
        // Handle string URLs directly
        if (typeof file === 'string') {
          return file === imageUrl;
        }
        // Handle File objects
        if (file instanceof File && fileToUrlMap.has(file)) {
          return fileToUrlMap.get(file) === imageUrl;
        }
        return false;
      });
      
      console.log("[AddMediaForm] Found image at index:", index);
      
      if (index >= 0) {
        // Move this image to the first position in selectedFiles
        const selectedImage = selectedFiles.value[index];
        
        // Create new array with the selected image first
        const reorderedFiles = [
          selectedImage,
          ...selectedFiles.value.slice(0, index),
          ...selectedFiles.value.slice(index + 1)
        ];
        
        // Update the array
        selectedFiles.value = reorderedFiles;
        
        // Always set thumbnailIndex to 0 since we moved the selected image to first position
        localThumbnailIndex.value = 0;
        
        // Update previewUrl immediately to show the selected image as the main preview
        previewUrl.value = typeof selectedImage === 'string' ? 
                          selectedImage : 
                          fileToUrlMap.get(selectedImage);
        
        console.log("[AddMediaForm] Reordered files, new thumbnail is at index 0");
        console.log("[AddMediaForm] New files order:", selectedFiles.value);
        console.log("[AddMediaForm] Updated previewUrl to:", previewUrl.value);
        
        // Force an update of the preview if in preview mode
        if (props.currentStep === 'preview') {
          emit("update-currentStep", { form: "media", step: "preview" });
        }
      } else {
        console.error("[AddMediaForm] Could not find image with URL:", imageUrl);
      }
    };

    // Reset all highlight states
    const resetHighlights = () => {
      highlightItemName.value = false;
      highlightCaption.value = false;
    };

    // Validate the form fields, highlight missing fields and show toast errors
    const handleFormValidation = () => {
      resetHighlights();
      let valid = true;

      if (!itemName.value.trim()) {
        valid = false;
        highlightItemName.value = true;
        titleInput.value?.focus();
        toast.error("Title is required.");
      }

      if (!caption.value.trim()) {
        valid = false;
        highlightCaption.value = true;
        if (valid) {
          // Focus the first invalid field (if itemName was valid, then caption)
          captionInput.value?.focus();
        }
        toast.error("Caption is required.");
      }

      // Check if we need a file
      // Allow edit mode to continue without new file if we already have an existing mediaUrl
      const needsFile =
        !props.currentProduct?.mediaUrl ||
        (props.currentProduct?.mediaUrl && selectedFile.value);

      if (needsFile && !previewUrl.value && !selectedFile.value) {
        valid = false;
        toast.error("A file must be selected.");
      }

      if (valid) {
        emit("update-canPreview", true);
        emit("update-currentStep", { form: "media", step: "preview" });
      }

      return valid;
    };

    const handleSubmit = () => {
      console.log("[AddMediaForm] handleSubmit called");
      if (!itemName.value || !caption.value) {
        errorMessage.value = "Missing required information.";
        return;
      }

      // Check if we have either files or existing images
      const hasFiles = selectedFiles.value && selectedFiles.value.length > 0;
      if (!hasFiles && !props.currentProduct?.images?.length && !props.currentProduct?.mediaUrl) {
        errorMessage.value = "Please select at least one media file.";
        return;
      }
      
      console.log("Current selectedFiles:", selectedFiles.value);

      if (!handleFormValidation()) {
        return;
      }

      // Determine media type - default to image if not set explicitly
      const fileType = isImage.value
        ? "image"
        : isVideo.value
        ? "video"
        : isAudio.value
        ? "audio"
        : "image";

      // Create array of all image URLs (existing ones and new files to be uploaded)
      const existingUrls = [];
      const filesToUpload = [];
      
      // Process all files/URLs
      selectedFiles.value.forEach(file => {
        if (file instanceof File && !file.isExistingFile) {
          // This is a new file to upload
          filesToUpload.push(file);
        } else if (typeof file === 'string') {
          // This is a URL string to preserve
          existingUrls.push(file);
        } else if (file && file.isExistingFile && file.url) {
          // This is a file object representing an existing URL
          existingUrls.push(file.url);
        }
      });
      
      console.log("[AddMediaForm] Files to upload:", filesToUpload.length);
      console.log("[AddMediaForm] Existing URLs to preserve:", existingUrls);
      
      // Build the payload using the new standardized format
      const payload = {
        itemName: itemName.value,
        caption: caption.value,
        mediaType: fileType,
        organisation: currentOrgUID.value,
        owner: ownerUID.value || props.currentProduct?.owner,
        thumbnailIndex: localThumbnailIndex.value
      };
      
      // Add the ID if we're editing
      if (props.currentProduct && props.currentProduct.id) {
        payload.id = props.currentProduct.id;
      }
      
      console.log(`[AddMediaForm] Using thumbnailIndex: ${localThumbnailIndex.value} for payload (type: ${typeof localThumbnailIndex.value})`);

      // Add all existing images and previews to be preserved
      if (existingUrls.length > 0) {
        payload.existingUrls = existingUrls;
      }
      
      // If we have new files to upload
      if (filesToUpload.length > 0) {
        payload.files = filesToUpload;
      }
      
      console.log("Owner UID:", ownerUID.value, "Current product owner:", props.currentProduct?.owner);
      
      // For backward compatibility
      if (existingUrls.length > 0 || filesToUpload.length > 0) {
        // Set mediaUrl for backward compatibility (first existing URL or null)
        payload.mediaUrl = existingUrls[0] || null;
        
        // Add additional images for backward compatibility
        if (existingUrls.length > 1) {
          payload.additionalImages = existingUrls.slice(1);
        }
      }

      console.log("[AddMediaForm] Emitting final payload:", payload);
      emit("mediaAddedSuccessfully", payload);
    };

    const emitBackToInitial = () => {
      // Always emit the correct step transition for media form
      console.log("[AddMediaForm] Emitting step update to initial");
      emit("update-currentStep", { form: "media", step: "initial" });
    };

    const resetForm = () => {
      console.log("[AddMediaForm] resetForm called - clearing ALL form data");
      
      // Clean up any object URLs before clearing references
      fileToUrlMap.forEach((url) => {
        URL.revokeObjectURL(url);
      });
      fileToUrlMap.clear();
      
      // If previewUrl is a blob URL, revoke it
      if (previewUrl.value && previewUrl.value.startsWith('blob:')) {
        URL.revokeObjectURL(previewUrl.value);
      }
      
      // Clear all form fields
      itemName.value = "";
      caption.value = "";
      previewUrl.value = null;
      errorMessage.value = "";
      selectedFiles.value = [];
      selectedFile.value = null;
      isImage.value = false;
      isVideo.value = false;
      isAudio.value = false;
      resetHighlights();
    };

    // Watch for changes in the current step
    watch(
      () => props.currentStep,
      (newStep) => {
        console.log("[AddMediaForm] currentStep changed to:", newStep);

        // Handle invalid steps that might come from other forms accidentally
        if (
          newStep !== "initial" &&
          newStep !== "preview" &&
          newStep !== "delete"
        ) {
          console.warn(
            `[AddMediaForm] Received invalid step "${newStep}". AddMediaForm supports "initial", "preview", and "delete" steps.`
          );
          emit("update-currentStep", { form: "media", step: "initial" });
        }

        // NEVER reset form data when navigating between steps
        // Only resetForm() should reset the form data, not step changes
      }
    );

    // Watch for changes in currentStep to handle special steps like delete
    watch(
      () => props.currentStep,
      (newStep) => {
        if (newStep === "delete") {
          // Emit proper step update for delete mode
          emit("update-currentStep", { form: "media", step: "delete" });
          emit("update-title", "Delete Confirmation");
        }
      }
    );

    // Watch for changes in currentProduct to update form title if needed
    watch(
      () => props.currentProduct,
      (newProduct) => {
        if (newProduct && Object.keys(newProduct).length > 0) {
          console.log("[AddMediaForm] currentProduct changed:", newProduct);
          
          // If we're editing an existing item, populate form fields
          if (newProduct.id) {
            // Update title if we're on the initial step
            if (props.currentStep === "initial") {
              emit("update-title", "Edit Media Post");
            }
            
            // Update form fields with existing data
            itemName.value = newProduct.itemName || newProduct.title || "";
            caption.value = newProduct.caption || newProduct.description || "";
            
            // Handle images - prioritize the images array (new format)
            if (newProduct.images && Array.isArray(newProduct.images) && newProduct.images.length > 0) {
              console.log("[AddMediaForm] Found images array with", newProduct.images.length, "images:", newProduct.images);
              
              // Create a Set from the array to remove duplicates, then convert back to array
              const uniqueImages = Array.from(new Set(newProduct.images));
              console.log("[AddMediaForm] After removing duplicates:", uniqueImages.length, "unique images");
              
              // Pass only the unique image URLs to selectedFiles
              selectedFiles.value = uniqueImages;
              
              // Set the preview URL to the first or thumbnail image
              const thumbnailIndex = newProduct.thumbnailIndex || 0;
              previewUrl.value = uniqueImages[Math.min(thumbnailIndex, uniqueImages.length - 1)] || uniqueImages[0];
              
              console.log("[AddMediaForm] Set selectedFiles to unique image URLs:", selectedFiles.value);
              console.log("[AddMediaForm] Set previewUrl to:", previewUrl.value);
            } 
            // Fallback to mediaUrl (old format)
            else if (newProduct.mediaUrl) {
              console.log("[AddMediaForm] No images array, using mediaUrl:", newProduct.mediaUrl);
              previewUrl.value = newProduct.mediaUrl;
              selectedFiles.value = [newProduct.mediaUrl];
              console.log("[AddMediaForm] Set selectedFiles to single mediaUrl:", selectedFiles.value);
            }
            
            // Determine media type
            isImage.value = newProduct.mediaType === "image" || !newProduct.mediaType;
            isVideo.value = newProduct.mediaType === "video";
            isAudio.value = newProduct.mediaType === "audio";
            
            // Enable preview since we have an existing item
            emit("update-canPreview", true);
          }
        }
      },
      { immediate: true } // Run immediately on component creation
    );

    // Clean up object URLs when component is unmounted
    onUnmounted(() => {
      // Revoke all object URLs created for previews
      fileToUrlMap.forEach((url) => {
        URL.revokeObjectURL(url);
      });
      
      // Also revoke the main preview URL if it's an object URL
      if (previewUrl.value && previewUrl.value.startsWith('blob:')) {
        URL.revokeObjectURL(previewUrl.value);
      }
    });

    return {
      itemName,
      caption,
      previewUrl,
      errorMessage,
      acceptedTypes,
      selectedFiles,
      isImage,
      isVideo,
      isAudio,
      selectedFile,
      handleFileUpdate,
      handleThumbnailUpdate,
      handleFormValidation,
      handleSubmit,
      emitBackToInitial,
      resetForm,
      // Expose input refs for highlighting
      titleInput,
      captionInput,
      highlightItemName,
      highlightCaption,
      previewImages,
      localThumbnailIndex,
    };
  },
};
</script>

<style scoped>
.add-media-form {
  display: flex;
  flex-direction: column;
  gap: var(--smallMargin);
  width: 100%;
}
.fullWidth {
  width: 100%;
}
.drop-area {
  border: 1px dashed var(--solidMidDarkgrey);
  padding: var(--margin);
  text-align: center;
  border-radius: var(--borderRadius);
  width: 100%;
  cursor: pointer;
}
.drop-area:hover {
  border-color: var(--primaryColour);
  background-color: var(--solidMidLightgrey);
}
.preview-container {
  margin-top: var(--smallMargin);
  position: relative;
  width: 100%;
}
.preview-image,
.preview-video,
.preview-audio {
  max-width: 100%;
  max-height: 400px;
}
.itemName-preview {
  font-weight: bold;
  text-align: center;
  margin-top: 0.5rem;
}
.caption-preview {
  font-style: italic;
  text-align: center;
  margin-top: 0.5rem;
}
.error {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}

/* Media details styles */
.media-details {
  margin-top: var(--margin);
  text-align: center;
}

.itemName-preview {
  font-weight: bold;
  margin: var(--smallMargin) 0 var(--tinyMargin) 0;
}

.caption-preview {
  font-style: italic;
  margin-bottom: var(--smallMargin);
}

.image-count {
  color: var(--solidMidDarkgrey);
  font-size: 0.9rem;
  margin-top: var(--tinyMargin);
}

/* Highlight style for invalid fields */
.highlight-input {
  transition: background-color 0.3s ease-in-out;
  background-color: var(--lightestred);
}
</style>
