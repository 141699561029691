export const timeToRead = (message) => {
  let strMessage;

  if (typeof message === "string") {
    strMessage = message;
  } else if (typeof message === "object") {
    strMessage = JSON.stringify(message);
  } else {
    console.warn(
      "timeToRead expects a string or object argument, got:",
      typeof message
    );
    return 4000; // default time
  }

  return (strMessage.split(" ").length / 4) * 1200;
};

export const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};
