<template>
  <div class="member-dashboard-actions" v-if="hasPrivileges">
    <div
      v-if="member.userUID !== userCredentials?.data?.uid"
      class="moreOptionsContainer"
      v-click-outside="closeMenu"
      :class="{ open: showActions }"
    >
      <button
        @click="toggleActions"
        :class="[
          'moreOptionsButton',
          'secondaryButton',
          { 'box-shadow-active': showActions },
        ]"
      >
        <svg v-if="!showActions" class="verySmall" alt="more options icon">
          <use href="@/assets/icons/iconset.svg#addCross_tiny"></use>
        </svg>
        <svg v-else class="verySmall" alt="more options icon">
          <use href="@/assets/icons/iconset.svg#subtract_tiny"></use>
        </svg>
      </button>

      <transition name="slide-fade">
        <div v-if="showActions" class="dropdownMenu">
          <div
            v-if="
              userRolePrivileges?.userModeration &&
              member.userUID !== userCredentials?.data?.uid
            "
            @click="openRemoveModal"
            class="menu-item"
          >
            <svg class="compactImg">
              <use href="@/assets/icons/iconset.svg#compactCross"></use>
            </svg>
            Remove User
          </div>
          <div
            v-if="userRolePrivileges?.naturalPersonVerification"
            @click="openVerifyModal"
            class="menu-item"
          >
            <svg class="compactImg">
              <use
                v-if="!member.isHuman"
                href="@/assets/icons/iconset.svg#user_compact"
              ></use>
              <use
                v-else
                href="@/assets/icons/iconset.svg#sensor_compact"
              ></use>
            </svg>
            {{ member.isHuman ? "Suspect bot" : "Confirm human" }}
          </div>
        </div>
      </transition>
    </div>

    <AlertModalLocal
      :visible="showRemoveModal"
      title="Remove Member"
      messageLineOne="Are you sure you want to remove this member?"
      messageLineTwo="This action cannot be undone."
      @confirm="removeMember"
      @close="closeRemoveModal"
      :bgColor="'red'"
    >
      <button
        v-haptic
        class="modal-button secondaryButton"
        @click="closeRemoveModal"
        v-if="!isRemoving"
      >
        Cancel
      </button>
      <button
        v-haptic
        class="modal-button"
        @click="removeMember"
        :disabled="isRemoving"
      >
        {{ isRemoving ? "Processing..." : "Confirm" }}
      </button>
    </AlertModalLocal>

    <AlertModalLocal
      :visible="showVerifyModal"
      :title="member.isHuman ? 'Suspect bot' : 'Confirm human'"
      :messageLineOne="
        member.isHuman
          ? 'Are you sure this person is a bot?'
          : 'Are you sure this person is a human?'
      "
      messageLineTwo="This action can be undone."
      @confirm="confirmVerification"
      @close="closeVerifyModal"
      :bgColor="'green'"
    >
      <button
        v-haptic
        class="modal-button secondaryButton"
        @click="closeVerifyModal"
        v-if="!isVerifying"
      >
        Cancel
      </button>
      <button
        v-haptic
        class="modal-button"
        @click="confirmVerification"
        :disabled="isVerifying"
      >
        {{ isVerifying ? "Processing..." : "Confirm" }}
      </button>
    </AlertModalLocal>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import AlertModalLocal from "@/components/GenericComponents/AlertModalLocal.vue";
import { toast } from "vue3-toastify";
import { db } from "@/firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";

export default {
  name: "MemberDashboardActions",
  components: {
    AlertModalLocal,
  },
  props: {
    member: {
      type: Object,
      required: true,
    },
    orgUID: {
      type: String,
      required: true,
    },
    userRolePrivileges: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const showActions = ref(false);
    const showVerifyModal = ref(false);
    const showRemoveModal = ref(false);
    const isVerifying = ref(false);
    const isRemoving = ref(false);

    const userCredentials = computed(() => store.getters.userCredentials);

    const hasPrivileges = computed(() => {
      return (
        props.userRolePrivileges?.userModeration ||
        props.userRolePrivileges?.naturalPersonVerification
      );
    });

    const toggleActions = () => {
      showActions.value = !showActions.value;
    };

    const closeMenu = () => {
      showActions.value = false;
    };

    const openRemoveModal = () => {
      showRemoveModal.value = true;
    };

    const closeRemoveModal = () => {
      showRemoveModal.value = false;
    };

    const removeMember = async () => {
      try {
        isRemoving.value = true;
        await store.dispatch("leaveCustomOrg", {
          orgUID: props.orgUID,
          userUID: props.member.userUID,
        });
        toast.success("Member removed successfully.", {
          autoClose: 3000,
          icon: "🚫",
        });
        closeRemoveModal();
      } catch (error) {
        console.error("Error removing member:", error);
        toast.error("Failed to remove member.", {
          autoClose: 3000,
          icon: "⚠️",
        });
      } finally {
        isRemoving.value = false;
      }
    };

    const openVerifyModal = () => {
      showVerifyModal.value = true;
    };

    const closeVerifyModal = () => {
      showVerifyModal.value = false;
    };

    const confirmVerification = async () => {
      try {
        isVerifying.value = true;
        const userRef = doc(db, "users", props.member.userUID);
        const userDoc = await getDoc(userRef);
        const isHuman = userDoc.data().isHuman || false;
        await updateDoc(userRef, {
          isHuman: !isHuman,
        });
        toast.success(
          `Person ${
            isHuman ? "suspected as a bot" : "confirmed as a human"
          } successfully.`,
          {
            autoClose: 3000,
            icon: "🫡",
          }
        );
        closeVerifyModal();
        // Trigger bot reaction immediately
        triggerBotReaction(props.member.userUID, !isHuman);
      } catch (error) {
        console.error("Error verifying person:", error);
        toast.error("Failed to verify person.", {
          autoClose: 3000,
          icon: "⚠️",
        });
      } finally {
        isVerifying.value = false;
      }
    };

    const triggerBotReaction = async (userUID, isHuman) => {
      // Logic to trigger bot reaction based on the updated isHuman status
      console.log(
        `Triggering bot reaction for user ${userUID} with isHuman status: ${isHuman}`
      );
      // Add your bot reaction logic here
      if (isHuman) {
        // Logic for confirming a human
        console.log(`User ${userUID} is confirmed as a human.`);
        // Add additional logic for confirming a human
      } else {
        // Logic for suspecting a bot
        console.log(`User ${userUID} is suspected as a bot.`);
        // Add additional logic for suspecting a bot
      }
    };

    onMounted(async () => {
      const userRef = doc(db, "users", props.member.userUID);
      const userDoc = await getDoc(userRef);
      props.member.isHuman = userDoc.data().isHuman || false;
    });

    return {
      showActions,
      showVerifyModal,
      showRemoveModal,
      isVerifying,
      isRemoving,
      userCredentials,
      hasPrivileges,
      toggleActions,
      closeMenu,
      openRemoveModal,
      closeRemoveModal,
      removeMember,
      openVerifyModal,
      closeVerifyModal,
      confirmVerification,
    };
  },
};
</script>

<style scoped>
.member-dashboard-actions {
  display: flex;
  align-items: center;
}

.moreOptionsContainer {
  position: relative;
}

.moreOptionsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--smallClickableHeight) !important;
  height: var(--smallClickableHeight) !important;
  border-radius: 50%;
  background-color: var(--backgroundColourOrg);
  border: none;
  cursor: pointer;
}

.moreOptionsButton.box-shadow-active {
  box-shadow: var(--neuMorphBoxInnerSmall);
}

.moreOptionsButton svg {
  pointer-events: none;
}

.moreOptionsContainer.open .moreOptionsButton {
  background: var(--backgroundColourOrgHover);
}

.dropdownMenu {
  position: absolute;
  top: calc(100% + var(--smallMargin));
  right: var(--margin);
  width: 210px;
  background: var(--glass-bg);
  box-shadow: var(--glass-box-shadow);
  border-radius: var(--borderRadius);
  overflow: hidden;
  z-index: 3;
  padding: var(--smallerMargin);
  transition: all 0.15s ease;
  backdrop-filter: var(--glass-backdrop-filter);
  -webkit-backdrop-filter: var(--glass-backdrop-filter);
  border: var(--glass-border);
}

.menu-item {
  border-radius: var(--borderRadius);
  padding: var(--smallMargin);
  width: 100%;
  height: var(--clickableHeight);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.15s;
  cursor: pointer;
  border-bottom: 1px solid var(--borderColour);
  margin-bottom: var(--tinyMargin);
}

.menu-item .row {
  gap: 0;
}

.menu-item:first-child {
  border-top: 1px solid var(--borderColour);
}

.menu-item:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.menu-item:hover {
  opacity: 0.8;
  transform: translateY(var(--transY));
}

.menu-link {
  width: 100%;
  text-align: right;
  position: relative;
}

.menu-link.pressed {
  border-bottom: none;
  box-shadow: var(--neuMorphBoxInnerSmall);
  transform: translateY(1px);
}

.menu-link.pressed ~ .menu-item:first-child {
  border-top: none;
}

.compactImg {
  margin-right: var(--smallerMargin);
  width: 20px;
  height: 20px;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
