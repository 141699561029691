<template>
  <div>
    <div
      v-if="canShowPrice"
      :class="[
        'priceTag',
        'noWrap',
        isProductOwner ? 'graybadge' : buttonClass,
        'smallerClickableHeight',
      ]"
      @click="!isProductOwner && priceClicked()"
    >
      <div v-if="isLoading" class="loading-message">Loading...</div>
      <div class="row alignCentreRow justifyToCentre" v-else>
        <span class="priceNumberSymbol">
          {{ displayConvertedPrice }} {{ buyercoinSymbol }}
        </span>
        <small v-if="isProductOwner" class="gray">Yours</small>
        <slot></slot>
      </div>
    </div>

    <div v-else>
      <div v-if="!isOpenEconomy">
        <small class="gray">Closed economy</small>
      </div>
      <div v-else>
        <svg class="verySmall" v-haptic>
          <use href="@/assets/icons/iconset.svg#cat"></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { db, auth } from "@/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useStore } from "vuex";
import { calculatePrice } from "@/utils/exchangeRateCalculator.js";
import { formatNumberTo4Digits } from "@/utils/numberFormattingTo4digits";
import { doc, getDoc } from "firebase/firestore";

export default {
  name: "CentTranslatedPrice",
  props: {
    product: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    isProductOwner: Boolean,
    buttonClass: {
      type: String,
      default: "button",
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const isLoading = ref(true);
    const currentUserUID = ref(null);
    const isOpenEconomy = ref(false);

    const isProductOwner = computed(() => {
      return currentUserUID.value === props.product.owner;
    });

    const isUserInSameOrganisation = computed(() => {
      return store.state.currentOrgUID === props.product.organisation;
    });

    const canShowPrice = computed(() => {
      return (
        isProductOwner.value ||
        isUserInSameOrganisation.value ||
        isOpenEconomy.value
      );
    });

    const priceClicked = () => {
      emit("price-clicked", {
        product: props.product,
        productPrice: props.product.price,        // Original price in seller's currency
        convertedPrice: convertedPrice.value,     // Price converted to buyer's currency
        buyerCoinSymbol: buyercoinSymbol,
        sellerCoinSymbol: props.product.coinSymbol,
        type: props.product.type,
      });
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        currentUserUID.value = user.uid;
        initUserState(user);
      }
    });

    const initUserState = async (user) => {
      try {
        await Promise.all([
          store.dispatch("fetchUser", user.uid),
          store.dispatch("fetchUserOrgCoinSymbol", user.uid),
          store.dispatch("fetchAndCommitUserUnits", user.uid),
        ]);
        fetchActiveWalletBalance(user.uid);
      } catch (err) {
        console.error("Error initializing user state:", err);
      } finally {
        isLoading.value = false;
      }
    };

    const sellerOrganisationExistsAndHasMembers = computed(() => {
      const orgUID = props.product.organisation;
      const orgDetails = store.getters.getSellerOrgDetails(orgUID) || {};

      if (!orgDetails) {
        return false;
      }

      return orgDetails.membersCount > 0 && orgDetails.totalOrgCoins > 0;
    });

    const checkOpenEconomyStatus = async (orgUID) => {
      if (!isProductOwner.value && !isUserInSameOrganisation.value) {
        try {
          const orgRef = doc(db, "custom-orgs", orgUID);
          const orgDoc = await getDoc(orgRef);
          if (orgDoc.exists()) {
            isOpenEconomy.value = orgDoc.data().allowTrades || false;
          }
        } catch (error) {
          console.error("Error checking open economy status:", error);
          isOpenEconomy.value = false;
        }
      }
    };

    watch(
      () => props.product.organisation,
      async (newOrgUID) => {
        if (
          newOrgUID &&
          !isProductOwner.value &&
          !isUserInSameOrganisation.value
        ) {
          await checkOpenEconomyStatus(newOrgUID);
        }
      },
      { immediate: true }
    );

    const fetchActiveWalletBalance = (uid) => {
      store.dispatch("subscribeToWalletUpdates", currentUserUID.value);
    };

    const updateOrgDetails = async () => {
      try {
        await Promise.all([
          store.dispatch("fetchAndCommitOrgMembersCount", {
            orgUID: props.product.organisation,
            context: "seller",
          }),
          store.dispatch("fetchAndCommitOrgTotalCoins", {
            orgUID: props.product.organisation,
            context: "seller",
          }),
          store.dispatch("fetchAndCommitOrgMembersCount", {
            orgUID: store.state.currentOrgUID,
            context: "buyer",
          }),
          store.dispatch("fetchAndCommitOrgTotalCoins", {
            orgUID: store.state.currentOrgUID,
            context: "buyer",
          }),
        ]);
      } finally {
        isLoading.value = false;
      }
    };

    watch(
      () => [props.product.organisation, store.state.currentOrgUID],
      async ([newOrg, newCurrentOrgUID]) => {
        if (newOrg && newCurrentOrgUID) {
          await updateOrgDetails();
        }
      },
      { immediate: true }
    );

    const userUnits_customOrgs = computed(
      () => store.getters.userUnits_customOrgs || {}
    );
    const userHasOrganisations = computed(
      () => Object.keys(userUnits_customOrgs.value).length > 0
    );

    const buyerOrgDetails = computed(
      () => store.getters.getBuyerOrgDetails(store.state.currentOrgUID) || {}
    );

    const buyercoinSymbol = computed(() => store.getters.coinSymbol || "Ʉ");

    watch(buyercoinSymbol, (newSymbol) => {
      emit("buyercoinSymbolChanged", newSymbol);
    });

    watch(userHasOrganisations, (hasOrgs) => {
      if (!hasOrgs) {
        emit("userHasNoOrganisations");
      }
    });

    watch(buyerOrgDetails, (details) => {
      if (details.totalOrgCoins === 0) {
        emit("noCoinsInBuyerOrg");
      } else {
        emit("hasCoinsInBuyerOrg");
      }
    });

    const convertedPrice = computed(() => {
      if (store.state.currentOrgUID === props.product.organisation) {
        return props.product.price;
      } else {
        const sellerOrgDetails =
          store.getters.getSellerOrgDetails(props.product.organisation) || {};
        const buyerMembersCount = buyerOrgDetails.value?.membersCount || 1;
        const buyerTotalCoins = buyerOrgDetails.value?.totalOrgCoins || 0;
        const sellerMembersCount = sellerOrgDetails.membersCount || 1;
        const sellerTotalCoins = sellerOrgDetails.totalOrgCoins || 0;

        return calculatePrice(
          buyerMembersCount,
          sellerMembersCount,
          props.product.price,
          buyerTotalCoins,
          sellerTotalCoins
        );
      }
    });

    const displayConvertedPrice = computed(() => {
      return isFinite(convertedPrice.value)
        ? formatNumberTo4Digits(convertedPrice.value)
        : "Processing...";
    });

    onMounted(async () => {
      if (auth.currentUser) {
        await store.dispatch(
          "fetchAndCommitUserUnitsCustomOrgs",
          auth.currentUser?.uid
        );
        fetchActiveWalletBalance();
        await updateOrgDetails();
        await checkOpenEconomyStatus(props.product.organisation);
        isLoading.value = false;
      }
    });

    return {
      isLoading,
      convertedPrice,
      displayConvertedPrice,
      buyercoinSymbol,
      buyerOrgDetails,
      userHasOrganisations,
      sellerOrganisationExistsAndHasMembers,
      priceClicked,
      userUnits_customOrgs,
      isOpenEconomy,
      isProductOwner,
      canShowPrice,
    };
  },
};
</script>

<style scoped>
.priceTag {
  display: flex;
  align-items: center;
}

.priceTag .row {
  gap: var(--smallerMargin) !important;
}

.priceTag.button,
.priceTag.secondaryButton {
  cursor: pointer;
}

.priceNumberSymbol {
  font-weight: var(--fontweightheavy);
}
</style>
