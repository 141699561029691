import { db } from "@/firebase";
import { runTransaction, collection, doc, getDoc } from "firebase/firestore";

const moneyPackHandler = async (
  userId,
  orgId,
  newMoneyPack,
  orgData,
  walletData
) => {
  console.log("[moneyPackHandler] Starting moneyPackHandler in Client for orgId:", orgId);
  try {
    if (!orgData) {
      console.error("[moneyPackHandler] orgData is undefined");
      return null;
    }
    console.log("[moneyPackHandler] orgData:", orgData);
    console.log("[moneyPackHandler] newMoneyPack:", newMoneyPack);
    if (!orgData.monetary_parameters) {
      console.error("[moneyPackHandler] monetary_parameters is undefined");
      return null;
    }

    if (!orgData.monetary_parameters.current_account) {
      console.error("[moneyPackHandler] monetary_parameters.currentAccount is undefined");
      return null;
    }

    // Ensure moneyPack has required fields
    newMoneyPack.value = parseFloat(newMoneyPack.value);
    newMoneyPack.lastUpdatedTime = newMoneyPack.lastUpdatedTime || Date.now();
    
    const currentAccountCap = parseFloat(
      orgData.monetary_parameters.current_account.cap
    );
    let currentAccount = { ...walletData.currentAccount };
    let savingsAccount = { ...walletData.savingsAccount };

    currentAccount.balance = parseFloat(currentAccount.balance) || 0;
    savingsAccount.balance = parseFloat(savingsAccount.balance) || 0;

    console.log("[moneyPackHandler] Current Account before transaction:", currentAccount);
    console.log("[moneyPackHandler] Savings Account before transaction:", savingsAccount);

    // Update remaining lifespans for all moneyIn packs
    const currentTimestamp = Date.now();
    for (let moneyPack of currentAccount.moneyIn) {
      if (!moneyPack.lastUpdatedTime) {
        moneyPack.lastUpdatedTime = moneyPack.transactionTime;
      }
      updateRemainingLifespan(moneyPack, orgId, currentTimestamp);
    }

    // Remove expired or depleted moneyIn transactions
    currentAccount.moneyIn = currentAccount.moneyIn.filter(
      (m) => m.value > 0 && (m.remainingLifespanByWallet[orgId] > 0 || m.remainingLifespanByWallet[orgId] === undefined)
    );

    if (newMoneyPack.moneyPackType === "moneyOut") {
      currentAccount.balance -= newMoneyPack.value;
      currentAccount.moneyOut.push(newMoneyPack);
      console.log("[moneyPackHandler] Added moneyOut transaction:", newMoneyPack);
    } else {
      currentAccount.balance += newMoneyPack.value;
      currentAccount.moneyIn.push(newMoneyPack);
      console.log("[moneyPackHandler] Added moneyIn transaction:", newMoneyPack);
    }

    let newBalance = currentAccount.balance;
    console.log("[moneyPackHandler] New balance after transaction:", newBalance);

    const newWalletData = await runTransaction(db, async (transaction) => {
      try {
        const userWalletRef = doc(
          collection(doc(collection(db, "users"), userId), "Wallets"),
          orgId
        );
        console.log(
          `[moneyPackHandler] INSIDE TRANSACTION FOR USER ${userId}, ATTEMPTING TO UPDATE...`
        );

        // Fetch current wallet data within transaction to ensure consistency
        const walletSnapshot = await transaction.get(userWalletRef);
        const walletDataInTransaction = walletSnapshot.data();
        console.log(
          "[moneyPackHandler] Fetched wallet data within transaction:",
          walletDataInTransaction
        );

        let currentAccountInTransaction = {
          ...walletDataInTransaction.currentAccount,
        };
        let savingsAccountInTransaction = {
          ...walletDataInTransaction.savingsAccount,
        };

        // Log current and new balance for debugging
        console.log(
          "[moneyPackHandler] Current account balance fetched within transaction:",
          currentAccountInTransaction.balance
        );
        console.log("[moneyPackHandler] New balance calculated before updating:", newBalance);

        // Correct balance update
        currentAccountInTransaction.balance = newBalance;

        // Ensure the moneyIn and moneyOut transactions are properly added
        if (newMoneyPack.moneyPackType === "moneyOut") {
          currentAccountInTransaction.moneyOut.push(newMoneyPack);
        } else {
          currentAccountInTransaction.moneyIn.push(newMoneyPack);
        }

        if (newBalance > currentAccountCap) {
          console.log("[moneyPackHandler] Current account cap reached. Moving money to savings.");

          let amountToMove = newBalance - currentAccountCap;
          console.log("[moneyPackHandler] Amount to move to savings:", amountToMove);

          // Sort moneyIn by lifespan to use shorter-lived coins first
          currentAccountInTransaction.moneyIn.sort((a, b) => {
            // Safely access remainingLifespanByWallet with null checks
            const aRemaining = a.remainingLifespanByWallet && a.remainingLifespanByWallet[orgId] !== undefined ? 
                              a.remainingLifespanByWallet[orgId] : (a.lifespan || 365);
            
            const bRemaining = b.remainingLifespanByWallet && b.remainingLifespanByWallet[orgId] !== undefined ? 
                              b.remainingLifespanByWallet[orgId] : (b.lifespan || 365);
                              
            return aRemaining - bRemaining;
          });

          for (let moneyPack of currentAccountInTransaction.moneyIn) {
            if (amountToMove <= 0) break;

            let transferAmount = Math.min(amountToMove, moneyPack.value);
            let remainingLifespan =
              moneyPack.remainingLifespanByWallet[orgId] || moneyPack.lifespan || 365;

            let moneyOutPack = {
              ...moneyPack,
              moneyPackType: "moneyOut",
              value: transferAmount,
              lifespan: remainingLifespan,
              transactionTime: currentTimestamp,
              lastUpdatedTime: currentTimestamp,
              transactionType: "💰 Transfer to savings",
            };

            currentAccountInTransaction.moneyOut.push(moneyOutPack);

            moneyPack.value -= transferAmount;

            if (
              moneyPack.value > 0 &&
              !isNaN(moneyPack.remainingLifespanByWallet[orgId])
            ) {
              updateRemainingLifespan(moneyPack, orgId, currentTimestamp);
            }

            savingsAccountInTransaction.moneyIn.push({
              ...moneyOutPack,
              moneyPackType: "moneyIn",
            });

            currentAccountInTransaction.balance -= transferAmount;
            savingsAccountInTransaction.balance += transferAmount;
            amountToMove -= transferAmount;
          }

          // Remove depleted moneyIn transactions
          currentAccountInTransaction.moneyIn =
            currentAccountInTransaction.moneyIn.filter((mp) => mp.value > 0);

          console.log(
            "[moneyPackHandler] Current account after transfers:",
            currentAccountInTransaction
          );
          console.log(
            "[moneyPackHandler] Savings account after transfers:",
            savingsAccountInTransaction
          );
        }

        const updatedWalletDataInTransaction = {
          currentAccount: currentAccountInTransaction,
          savingsAccount: savingsAccountInTransaction,
        };

        console.log(
          `[moneyPackHandler] UPDATING FIRESTORE FOR USER ${userId} WITH NEW WALLET DATA...`
        );
        await transaction.update(userWalletRef, {
          currentAccount: currentAccountInTransaction,
          savingsAccount: savingsAccountInTransaction,
        });
        console.log(
          `[moneyPackHandler] UPDATED FIRESTORE WITH NEW WALLET DATA FOR USER ${userId}:`,
          updatedWalletDataInTransaction
        );

        return updatedWalletDataInTransaction;
      } catch (transactionError) {
        console.error("[moneyPackHandler] Transaction failed:", transactionError);
        throw transactionError; // Important to throw the error to detect failed transaction
      }
    });

    console.log("[moneyPackHandler] Final wallet state for user", userId, ":", newWalletData);

    // Verify if the data has been updated correctly
    const finalWalletSnapshot = await getDoc(
      doc(collection(db, "users"), userId, "Wallets", orgId)
    );
    const finalWalletData = finalWalletSnapshot.data();
    console.log("[moneyPackHandler] Final wallet data in Firestore:", finalWalletData);

    return finalWalletData;
  } catch (error) {
    console.error("[moneyPackHandler] An error occurred:", error);
    return null;
  }
};

/**
 * Updates the remaining lifespan for a money pack based on current time
 * @param {Object} moneyPack - The money pack to update
 * @param {string} orgId - The organization ID
 * @param {number} currentTimestamp - Current timestamp in milliseconds
 */
const updateRemainingLifespan = (moneyPack, orgId, currentTimestamp) => {
  // Skip update if no lastUpdatedTime field (will be initialized when used)
  if (!moneyPack.lastUpdatedTime) {
    moneyPack.lastUpdatedTime = moneyPack.transactionTime || currentTimestamp;
    return;
  }

  const timeDifference = currentTimestamp - moneyPack.lastUpdatedTime;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
  if (daysDifference > 0) {
    // Initialize remainingLifespanByWallet if not present
    if (!moneyPack.remainingLifespanByWallet) {
      moneyPack.remainingLifespanByWallet = {};
    }
    
    // If no remaining lifespan is recorded for this org, use the default lifespan
    if (moneyPack.remainingLifespanByWallet[orgId] === undefined) {
      moneyPack.remainingLifespanByWallet[orgId] = moneyPack.lifespan || 365;
    }
    
    // Decrease remaining lifespan
    moneyPack.remainingLifespanByWallet[orgId] -= daysDifference;
    
    // Ensure lifespan doesn't go below zero
    moneyPack.remainingLifespanByWallet[orgId] = Math.max(0, moneyPack.remainingLifespanByWallet[orgId]);
    
    // Update the last updated time
    moneyPack.lastUpdatedTime = currentTimestamp;
  }
};

export default moneyPackHandler;
