<template>
  <div :class="{ UseCases: showOnFAQ }">
    <div :class="{ column_container: showOnFAQ }">
      <div :class="['singleColumn', showOnFAQ ? '' : 'column']">
        <p class="shortwidth smallmargintop">
          Unit is a web app that you can easily add to your home screen for
          quick access, just like a native app. Follow the steps below to
          install Unit on your iPhone or Android device.
        </p>
        <section>
          <h1 class="alignCentre smallmargintop purpleText" v-if="showOnFAQ">
            On an iPhone
          </h1>
          <div class="row">
            <figure class="image-container">
              <img
                src="../../assets/how_to_install/iPhone-14-1_safari_glow.png"
                alt="On Safari, click on the button located in the lower part of the screen"
              />
              <figcaption>
                On Safari, click on the button located in the lower part of the
                screen.
              </figcaption>
            </figure>

            <figure class="image-container">
              <img
                src="../../assets/how_to_install/iPhone-14-2_addtohomescreen_glow.png"
                alt="Click on 'Add to Home Screen'"
              />
              <figcaption>Click on 'Add to Home Screen'</figcaption>
            </figure>
          </div>

          <h1 class="alignCentre purpleText bigmargintop" v-if="showOnFAQ">
            On a Pixel/Android
          </h1>
          <div class="row">
            <figure class="image-container">
              <img
                src="../../assets/how_to_install/Pixel-9_glow.png"
                alt="On Chrome, click on the three dots located in the top right corner"
              />
              <figcaption>
                On an Android phone, click on the three dots located in the top
                right corner.
              </figcaption>
            </figure>
            <figure class="image-container">
              <img
                src="../../assets/how_to_install/Pixel-9_2_glow.png"
                alt="Click on 'Install app'"
              />
              <figcaption>Click on 'Install app'</figcaption>
            </figure>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstallWebapp",
  props: {
    showOnFAQ: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style scoped>
.HowToGuide {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--biggerMargin);
}

.row {
  max-width: 65ch;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: var(--margin);
}

.image-container {
  max-height: 65vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

figure {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  unicode-bidi: isolate;
}

.image-container img {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.image-container figcaption {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: var(--gray500);
}
</style>
