<template>
  <div
    class="clap-button-container"
    @click="isOwnContent ? showOwnContentMessage() : handleClap($event)"
    :class="{ disabled: isOwnContent }"
    :title="
      isOwnContent
        ? 'You cannot clap your own content'
        : 'Clap to show appreciation'
    "
  >
    <div class="clap-button" ref="clapButton">
      <svg
        class="clap-icon compactImg"
        :class="{ 'disabled-icon': isOwnContent }"
      >
        <use href="@/assets/icons/iconset.svg#clap"></use>
      </svg>
      <span class="clap-count" v-if="clapCount > 0">{{ clapCount }}</span>
    </div>
    <!-- Floating emojis -->
    <transition-group name="emoji-fade" tag="div">
      <span
        v-for="emoji in clapEmojis"
        :key="emoji.id"
        class="clap-emoji"
        :style="emoji.style"
      >
        {{ emoji.char }}
      </span>
    </transition-group>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useStore } from "vuex";
import { auth, db } from "@/firebase";
import { transferClapMoney } from "@/utils/transferClapMoney";
import { calculateAmountForReceiver } from "@/utils/exchangeRateCalculator";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

/**
 * Returns configuration based on the current total claps.
 */
function getClapConfig(currentCount) {
  if (currentCount >= 100) {
    return { increment: 25, emoji: "🚀" };
  } else if (currentCount >= 50) {
    return { increment: 10, emoji: "🤩" };
  } else if (currentCount >= 20) {
    return { increment: 5, emoji: "🥳" };
  } else if (currentCount > 10) {
    return { increment: 2, emoji: "🎉" };
  }
  return { increment: 1, emoji: "👏" };
}

export default {
  name: "ClapButton",
  props: {
    receiverUid: { type: String, required: true },
    buyerOrgUID: { type: String, required: true },
    receiverOrgUID: { type: String, required: true },
  },
  setup(props) {
    const store = useStore();
    const isProcessing = ref(false);
    const clapCount = ref(0);
    const currentUserUID = ref(null);
    const clapTimeout = ref(null);
    const clapEmojis = ref([]); // Floating emoji objects

    // Define maximum claps allowed per batch.
    const MAX_CLAPS = 145;

    // Track whether the economy is open for trades.
    const isOpenEconomy = ref(false);
    const canClap = computed(() => isOpenEconomy.value);

    // Check if this is the user's own content
    const isOwnContent = computed(
      () => currentUserUID.value === props.receiverUid
    );

    // For direct Firestore fetch of buyer wallet.
    const buyerWalletDirect = ref(0);

    // 1. On auth, store UID and initialize state.
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        currentUserUID.value = user.uid;
        console.log("[ClapButton] Authenticated, UID:", user.uid);
        await initUserState(user.uid);
        // Fetch buyer wallet directly and update buyerWalletDirect.
        const directBalance = await fetchBuyerData();
        buyerWalletDirect.value = directBalance;
        console.log(
          "[ClapButton] Direct fetched buyer wallet balance:",
          directBalance
        );
      }
    });
    const initUserState = async (uid) => {
      try {
        await Promise.all([
          store.dispatch("fetchUser", uid),
          store.dispatch("fetchUserOrgCoinSymbol", uid),
          store.dispatch("fetchAndCommitUserUnits", uid),
        ]);
        // Also subscribe via Vuex if needed.
        fetchActiveWalletBalance(uid);
      } catch (err) {
        console.error("Error initializing user state:", err);
      }
    };

    // 2. Subscribe to buyer wallet updates via Vuex.
    const fetchActiveWalletBalance = (uid) => {
      store.dispatch("subscribeToWalletUpdates", uid);
    };

    // 3. Check open economy status.
    const checkOpenEconomyStatus = async (orgUID) => {
      try {
        const orgRef = doc(db, "custom-orgs", orgUID);
        const orgDoc = await getDoc(orgRef);
        isOpenEconomy.value = orgDoc.exists() && orgDoc.data().allowTrades;
      } catch (error) {
        console.error("Error checking open economy status:", error);
        isOpenEconomy.value = false;
      }
    };
    watch(
      () => props.receiverOrgUID,
      async (newOrgUID) => {
        if (newOrgUID) await checkOpenEconomyStatus(newOrgUID);
      },
      { immediate: true }
    );

    // 4. Buyer wallet balance (from Vuex for legacy logging).
    const buyerWalletBalance = computed(() => {
      const balance = store.state.currentUserWallet?.currentAccount?.balance;
      console.log(
        "[ClapButton] Reading buyer wallet balance from Vuex state:",
        balance
      );
      return balance || 0;
    });

    // 5. Receiver wallet balance fetched and stored locally.
    // Store receiver org data for exchange rate calculation
    const receiverOrgData = ref(null);
    const receiverWalletDirect = ref(0);

    // Function to get receiver wallet balance
    const getReceiverWalletBalance = async () => {
      try {
        const receiverWalletRef = doc(
          db,
          "users",
          props.receiverUid,
          "Wallets",
          props.receiverOrgUID
        );
        const walletSnap = await getDoc(receiverWalletRef);

        if (walletSnap.exists()) {
          const data = walletSnap.data();
          console.log(
            "[ClapDebug] Direct fetch receiver wallet balance:",
            data.currentAccount.balance
          );
          return data.currentAccount.balance;
        }
      } catch (error) {
        console.error(
          "[ClapDebug] Error fetching receiver wallet balance:",
          error
        );
      }
      return 0;
    };

    const fetchReceiverData = async () => {
      try {
        // Fetch receiver wallet balance
        const receiverWalletRef = doc(
          db,
          "users",
          props.receiverUid,
          "Wallets",
          props.receiverOrgUID
        );
        const walletSnap = await getDoc(receiverWalletRef);

        // Try both "organisations" and "custom-orgs" collections
        let orgSnap = await getDoc(
          doc(db, "organisations", props.receiverOrgUID)
        );

        if (!orgSnap.exists()) {
          // If not found in "organisations", try "custom-orgs"
          console.log(
            "[ClapButton] Receiver organization not found in 'organisations', trying 'custom-orgs'"
          );
          orgSnap = await getDoc(doc(db, "custom-orgs", props.receiverOrgUID));
        }

        if (orgSnap.exists()) {
          receiverOrgData.value = orgSnap.data();
          console.log(
            "[ClapButton] Fetched receiver org data:",
            receiverOrgData.value
          );
        } else {
          console.warn(
            "[ClapButton] Could not find receiver organization data in any collection"
          );
        }
      } catch (error) {
        console.error("Error fetching receiver data:", error);
      }
    };
    onMounted(fetchReceiverData);

    // 5b. Fetch sender (buyer) wallet and org data
    const senderOrgData = ref(null);
    const fetchBuyerData = async () => {
      try {
        // Fetch buyer wallet directly
        const buyerWalletRef = doc(
          db,
          "users",
          currentUserUID.value,
          "Wallets",
          props.buyerOrgUID
        );
        const walletSnap = await getDoc(buyerWalletRef);

        // Try both "organisations" and "custom-orgs" collections
        let orgSnap = await getDoc(doc(db, "organisations", props.buyerOrgUID));

        if (!orgSnap.exists()) {
          // If not found in "organisations", try "custom-orgs"
          console.log(
            "[ClapButton] Organization not found in 'organisations', trying 'custom-orgs'"
          );
          orgSnap = await getDoc(doc(db, "custom-orgs", props.buyerOrgUID));
        }

        if (orgSnap.exists()) {
          senderOrgData.value = orgSnap.data();
          console.log(
            "[ClapButton] Fetched sender org data:",
            senderOrgData.value
          );
        } else {
          console.warn(
            "[ClapButton] Could not find sender organization data in any collection"
          );
        }

        if (walletSnap.exists()) {
          const data = walletSnap.data();
          console.log(
            "[ClapButton] Direct fetch buyer wallet balance:",
            data.currentAccount.balance
          );
          return data.currentAccount.balance;
        }
      } catch (error) {
        console.error("Error fetching buyer data:", error);
      }
      return 0;
    };

    // 6. Compute per-clap amounts using the direct buyer balance.
    const buyerClapAmount = computed(() => {
      const amount = buyerWalletDirect.value / 1_000_000;
      console.log(
        "[ClapButton] Computed buyerClapAmount (direct):",
        amount.toFixed(9)
      );
      return amount;
    });

    /**
     * handleClap:
     * - Prevents clapping on own content
     * - Checks if the buyer has sufficient funds (using the direct buyer wallet balance).
     * - Checks maximum claps.
     * - Determines clap configuration.
     * - Increases accumulated clap count.
     * - Creates emoji objects at click location.
     */
    const handleClap = (event) => {
      console.log("[ClapButton] Handling clap...");

      // This check is now handled in the click handler with isOwnContent
      // and there's a visual indication that self-clapping is not allowed

      console.log(
        "[ClapButton] Current buyer wallet balance (direct):",
        buyerWalletDirect.value
      );
      console.log(
        "[ClapButton] Buyer per clap amount (direct):",
        buyerClapAmount.value.toFixed(9)
      );
      if (buyerWalletDirect.value < buyerClapAmount.value) {
        showToast("Insufficient balance to clap!");
        return;
      }
      if (clapCount.value >= MAX_CLAPS) {
        toast.info("Maximum claps reached. Please wait 3 seconds...", {
          autoClose: 3000,
        });
        return;
      }
      const config = getClapConfig(clapCount.value + 1);
      console.log("[ClapButton] Clap config:", config);
      clapCount.value += config.increment;
      console.log("[ClapButton] New total claps:", clapCount.value);
      const rect = event.currentTarget.getBoundingClientRect();
      const baseX = event.clientX - rect.left;
      const baseY = event.clientY - rect.top;
      for (let i = 0; i < config.increment; i++) {
        const offsetX = (Math.random() - 0.5) * 20;
        const offsetY = (Math.random() - 0.5) * 20;
        const emoji = {
          id: Date.now() + Math.random().toString(36).substr(2, 5) + i,
          char: config.emoji,
          style: {
            position: "absolute",
            top: `${baseY + offsetY}px`,
            left: `${baseX + offsetX}px`,
            opacity: 1,
            transform: "scale(1)",
            transition: "all 3s ease",
          },
          animate: false,
        };
        clapEmojis.value.push(emoji);
      }
      animateEmojisOutward();
      if (clapTimeout.value) clearTimeout(clapTimeout.value);
      clapTimeout.value = setTimeout(async () => {
        animateEmojisToButton();
        await commitClaps();
      }, 3000);
    };

    /**
     * animateEmojisOutward:
     * - Each emoji drifts outward with random offsets.
     * - Their opacity gradually decreases from 0.9 to 0.5.
     */
    const animateEmojisOutward = () => {
      clapEmojis.value.forEach((emoji) => {
        if (!emoji.animate) {
          const driftX = (Math.random() - 0.5) * 40;
          const driftY = -Math.random() * 40;
          const currentTop = parseFloat(emoji.style.top);
          const currentLeft = parseFloat(emoji.style.left);
          emoji.style.top = `${currentTop + driftY}px`;
          emoji.style.left = `${currentLeft + driftX}px`;
          emoji.style.opacity = 0.5;
        }
      });
    };

    /**
     * animateEmojisToButton:
     * - When clapping stops, emojis restore opacity and animate to the center.
     */
    const animateEmojisToButton = () => {
      const buttonEl = document.querySelector(".clap-button");
      if (!buttonEl) return;
      const rect = buttonEl.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      clapEmojis.value.forEach((emoji) => {
        emoji.style.transition = "all 0.6s ease";
        emoji.style.opacity = 0.9;
        emoji.style.top = `${centerY - rect.top}px`;
        emoji.style.left = `${centerX - rect.left}px`;
        emoji.style.transform = "scale(0.3)";
        setTimeout(() => {
          emoji.style.opacity = 0;
        }, 100);
      });
      setTimeout(() => {
        clapEmojis.value = [];
      }, 600);
    };

    /**
     * commitClaps:
     * - Logs computed values.
     * - Executes the Firestore transaction via transferClapMoney.
     * - Refreshes the receiver's wallet and shows a toast.
     * - Also logs a direct Firestore fetch for the buyer wallet.
     */
    const commitClaps = async () => {
      isProcessing.value = true;
      const count = clapCount.value;
      console.log("[ClapButton] Committing claps. Total claps:", count);
      console.log(
        "[ClapButton] Buyer wallet balance (from Vuex):",
        buyerWalletBalance.value
      );
      console.log(
        "[ClapButton] Buyer per clap amount (direct):",
        buyerClapAmount.value.toFixed(9)
      );

      const totalBuyerAmount = buyerClapAmount.value * count;
      console.log(
        "[ClapButton] Total buyer amount to deduct:",
        totalBuyerAmount
      );

      // Calculate receiver amount using exchange rate calculation
      let totalReceiverAmount;

      // Only calculate exchange rate if we have all required data
      if (senderOrgData.value && receiverOrgData.value) {
        // Check if sender and receiver are in the same organization
        const isSameOrg = props.buyerOrgUID === props.receiverOrgUID;
        console.log("[ClapDebug] Same organization check:", {
          isSameOrg,
          buyerOrgUID: props.buyerOrgUID,
          receiverOrgUID: props.receiverOrgUID,
        });

        // Get member counts - use members array if exists or fallback to memberCount property
        const senderMembersCount =
          senderOrgData.value.members?.length ||
          senderOrgData.value.memberCount ||
          1;
        const receiverMembersCount =
          receiverOrgData.value.members?.length ||
          receiverOrgData.value.memberCount ||
          1;

        // Check for totalCoins in different possible locations
        let senderTotalCoins = 0;
        if (senderOrgData.value.monetary_parameters?.totalCoins) {
          senderTotalCoins = senderOrgData.value.monetary_parameters.totalCoins;
        } else if (senderOrgData.value.totalCoins) {
          senderTotalCoins = senderOrgData.value.totalCoins;
        } else {
          // If no totalCoins field, estimate based on per capita amounts
          // For sender, we can use the actual wallet balance
          senderTotalCoins = buyerWalletDirect.value * senderMembersCount;
          console.log(
            "[ClapDebug] Estimated sender total coins from wallet balance:",
            senderTotalCoins
          );
        }

        let receiverTotalCoins = 0;
        if (receiverOrgData.value.monetary_parameters?.totalCoins) {
          receiverTotalCoins =
            receiverOrgData.value.monetary_parameters.totalCoins;
        } else if (receiverOrgData.value.totalCoins) {
          receiverTotalCoins = receiverOrgData.value.totalCoins;
        } else {
          // For receiver, need to make a reasonable assumption
          // If we have a defined per capita ratio to use, use it
          const defaultPerCapitaRatio = 5; // Assume receiver has 5x coins per capita as sender
          receiverTotalCoins =
            (senderTotalCoins / senderMembersCount) *
            defaultPerCapitaRatio *
            receiverMembersCount;
          console.log(
            "[ClapDebug] Estimated receiver total coins using default ratio:",
            receiverTotalCoins
          );
        }

        console.log("[ClapDebug] Raw organization data:", {
          sender: senderOrgData.value,
          receiver: receiverOrgData.value,
        });

        console.log("[ClapDebug] Exchange rate calculation data:", {
          senderMembersCount,
          receiverMembersCount,
          senderTotalCoins,
          receiverTotalCoins,
          totalBuyerAmount,
        });

        // Only calculate if we have valid numbers
        if (
          senderMembersCount > 0 &&
          receiverMembersCount > 0 &&
          senderTotalCoins > 0 &&
          receiverTotalCoins > 0
        ) {
          // Calculate per-capita values
          const senderPerCapita = senderTotalCoins / senderMembersCount;
          const receiverPerCapita = receiverTotalCoins / receiverMembersCount;

          // Calculate exchange rate directly (receiver per-capita / sender per-capita)
          const exchangeRate = receiverPerCapita / senderPerCapita;

          // Apply exchange rate to convert sender amount to receiver amount
          // without any additional multiplier
          totalReceiverAmount = totalBuyerAmount * exchangeRate;

          console.log("[ClapDebug] Per-capita and exchange rate details:", {
            senderPerCapita,
            receiverPerCapita,
            exchangeRate,
            totalBuyerAmount,
            totalReceiverAmount,
          });

          // If same organization, we should force a 1:1 exchange rate
          if (isSameOrg && Math.abs(exchangeRate - 1.0) > 0.01) {
            console.warn(
              "[ClapDebug] Exchange rate not 1:1 for same organization! Forcing 1:1 ratio."
            );
            totalReceiverAmount = totalBuyerAmount; // Force 1:1 for same org
          }

          console.log(
            "[ClapDebug] Final calculated receiver amount:",
            totalReceiverAmount,
            "exchange rate:",
            exchangeRate
          );
        } else {
          // Fall back to 1:1 ratio if any values are missing or invalid
          console.error(
            "[ClapButton] Missing required data for exchange rate calculation!"
          );
          toast.error(
            "Error calculating exchange rate. Using fallback calculation.",
            {
              autoClose: 3000,
            }
          );
          totalReceiverAmount = totalBuyerAmount; // Use 1:1 ratio as fallback
          console.log(
            "[ClapButton] Using fallback 1:1 ratio due to missing data:",
            totalReceiverAmount
          );
        }
      } else {
        // Fallback to previous method if we don't have org data
        // This should rarely happen as we fetch org data on mount
        console.error(
          "[ClapButton] Missing organization data for exchange rate calculation!"
        );
        toast.error(
          "Error: Missing organization data. Using fallback calculation.",
          {
            autoClose: 3000,
          }
        );
        totalReceiverAmount = totalBuyerAmount; // Use 1:1 ratio as fallback
        console.log(
          "[ClapButton] Using fallback calculation (1:1 ratio) due to missing org data:",
          totalReceiverAmount
        );
      }

      // Make sure totalReceiverAmount is a number and not 0
      totalReceiverAmount = Number(totalReceiverAmount);
      if (isNaN(totalReceiverAmount) || totalReceiverAmount <= 0) {
        console.error(
          "[ClapButton] Invalid calculated amount! Using fallback value."
        );
        toast.error("Error with calculation. Using fallback value.", {
          autoClose: 3000,
        });
        totalReceiverAmount = totalBuyerAmount; // 1:1 ratio as fallback
        console.log(
          "[ClapButton] Final safeguard: ensuring receiver amount is valid (1:1 ratio):",
          totalReceiverAmount
        );
      }

      // Direct Firestore fetch for buyer wallet for debugging.
      const directBuyerBalance = await fetchBuyerData();
      console.log(
        "[ClapButton] Direct fetch buyer wallet balance:",
        directBuyerBalance
      );

      clapCount.value = 0;
      try {
        console.log("[ClapDebug] Final values before transfer:", {
          senderUID: currentUserUID.value,
          senderOrgUID: props.buyerOrgUID,
          receiverUID: props.receiverUid,
          receiverOrgUID: props.receiverOrgUID,
          senderAmount: totalBuyerAmount,
          receiverAmount: totalReceiverAmount,
          receiverAmountType: typeof totalReceiverAmount,
          isSameOrg: props.buyerOrgUID === props.receiverOrgUID,
        });

        // Track wallet values before and after transfer
        const beforeBuyerWallet = await fetchBuyerData();
        const beforeReceiverWallet = await getReceiverWalletBalance();

        await transferClapMoney(
          currentUserUID.value,
          props.buyerOrgUID,
          props.receiverUid,
          props.receiverOrgUID,
          totalBuyerAmount,
          totalReceiverAmount
        );

        // Get updated wallet values after transfer
        const afterBuyerWallet = await fetchBuyerData();
        const afterReceiverWallet = await getReceiverWalletBalance();

        console.log("[ClapDebug] Transfer results:", {
          buyerBefore: beforeBuyerWallet,
          buyerAfter: afterBuyerWallet,
          buyerDifference: beforeBuyerWallet - afterBuyerWallet,
          receiverBefore: beforeReceiverWallet,
          receiverAfter: afterReceiverWallet,
          receiverDifference: afterReceiverWallet - beforeReceiverWallet,
          expectedBuyerDeduction: totalBuyerAmount,
          expectedReceiverAddition: totalReceiverAmount,
        });
        await fetchReceiverData();
        toast.success(
          `Clapped ${count} times. Transferred ${totalBuyerAmount.toFixed(
            9
          )} from sender and ${totalReceiverAmount.toFixed(9)} to receiver.`,
          { autoClose: 3000 }
        );
      } catch (error) {
        console.error("Error processing clap transaction:", error);
        toast.error("Error processing clap. Please try again.", {
          autoClose: 3000,
        });
      } finally {
        isProcessing.value = false;
      }
    };

    // Simple fallback toast function.
    function showToast(message) {
      const toastEl = document.createElement("div");
      toastEl.className = "toast";
      toastEl.textContent = message;
      document.body.appendChild(toastEl);
      setTimeout(() => {
        toastEl.classList.add("fade-out");
        setTimeout(() => {
          document.body.removeChild(toastEl);
        }, 500);
      }, 2000);
    }

    // Show message when trying to clap own content
    function showOwnContentMessage() {
      toast.info("You cannot clap your own content", {
        autoClose: 2000,
        position: "bottom-center",
      });
    }

    return {
      isProcessing,
      clapCount,
      handleClap,
      canClap,
      buyerWalletBalance,
      buyerClapAmount,
      clapEmojis,
      isOwnContent,
      showOwnContentMessage,
    };
  },
};
</script>

<style scoped>
.clap-button-container {
  position: relative;
  display: inline-block;
}

.clap-button {
  position: relative;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  z-index: 2;
}

.clap-icon {
  width: calc(var(--smallClickableHeight) * 0.85);
  height: calc(var(--smallClickableHeight) * 0.85);
  fill: currentColor;
}

.clap-count {
  position: absolute;
  top: 2px;
  right: -8px;
  min-width: 16px;
  background-color: var(--greenPastel);
  border-radius: var(--borderRadius);
  color: var(--backgroundColourOrg);
  text-align: center;
  font-size: 12px;
  padding: 0 2px;
  border: 1px solid var(--backgroundColourOrg);
  user-select: none;
}

.clap-emoji {
  pointer-events: none;
  position: absolute;
  font-size: var(--biggerMargin);
  z-index: 1;
}

/* Transition-group classes */
.emoji-fade-enter-active,
.emoji-fade-leave-active {
  transition: opacity 3s ease, transform 3s ease;
}
.emoji-fade-enter-from,
.emoji-fade-leave-to {
  opacity: 0.3;
  transform: scale(1);
}

/* Toast styles */
.toast {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 12px 20px;
  border-radius: 4px;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.5s ease;
}
.toast.fade-out {
  opacity: 0;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
  pointer-events: none;
}

.disabled-icon {
  opacity: 0.5;
  filter: grayscale(100%);
}
</style>
