// src/store/modules/mediaActions.js
import {
  collection,
  getDocs,
  getDoc,
  doc,
  addDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "@/firebase";

export default {
  namespaced: true, // Make sure the module is namespaced
  state: {
    mediaPosts: [],
    loading: false,
    currentMedia: null,
  },
  getters: {
    mediaPosts: (state) => state.mediaPosts,
    mediaLoading: (state) => state.loading, // Getter for loading state if needed
    currentMedia: (state) => state.currentMedia,
  },
  actions: {
    // Set the current media for viewing in ProductView
    setCurrentMedia({ commit }, media) {
      console.log("Setting current media in store:", media);
      commit("SET_CURRENT_MEDIA", media);
    },
    
    // Clear the current media when no longer needed
    clearCurrentMedia({ commit }) {
      commit("CLEAR_CURRENT_MEDIA");
    },
    
    // Update existing media item
    async updateMediaItem({ commit }, { id, collection, data, existingUrls = [] }) {
      commit("SET_LOADING", true);
      try {
        console.log(`Updating media item ${id} in collection ${collection}:`, data);
        console.log(`Owner for media update:`, data.owner);
        
        // Create an array of all images
        let allImages = [];
        
        // Add the main URL if present
        if (data.mediaUrl) {
          allImages.push(data.mediaUrl);
        }
        
        // Add any additional images from the data object
        if (data.additionalImages && data.additionalImages.length > 0) {
          data.additionalImages.forEach(url => {
            if (!allImages.includes(url)) {
              allImages.push(url);
            }
          });
        }
        
        // Add any existing URLs not already included
        if (existingUrls && existingUrls.length > 0) {
          existingUrls.forEach(url => {
            if (!allImages.includes(url)) {
              allImages.push(url);
            }
          });
        }
        
        // Remove duplicates
        allImages = [...new Set(allImages)];
        
        console.log(`Converted to ${allImages.length} images in update array:`, allImages);
        
        // Create updated data structure matching product schema
        const updatedData = { 
          ...data,
          images: allImages,
          type: "media", // Add type field for consistency
          thumbnailIndex: data.thumbnailIndex || 0
        };
        
        // Remove legacy fields to standardize the schema
        delete updatedData.additionalImages;
        delete updatedData.mediaUrl;
        
        const docRef = doc(db, collection, id);
        await setDoc(docRef, updatedData, { merge: true });
        console.log(`Media item ${id} updated successfully`);
        return { success: true, id };
      } catch (error) {
        console.error("Error updating media item:", error);
        throw error;
      } finally {
        commit("SET_LOADING", false);
      }
    },
    
    // Upload media file to Storage and return URL
    async uploadMediaFile({ commit }, { file, type, owner, organisation }) {
      commit("SET_LOADING", true);
      try {
        console.log("Uploading file to storage:", { file, type, owner, organisation });
        
        // Handle non-File objects
        if (!(file instanceof File) && !(file instanceof Blob)) {
          if (typeof file === 'string') {
            console.log("File is already a URL, not uploading:", file);
            return file; // Just return the URL as-is
          }
          console.error("Invalid file object, cannot upload:", file);
          throw new Error("Invalid file object for upload");
        }
        
        // Import the uploadUtils utility
        const { uploadMediaFile } = await import("@/utils/uploadUtils.js");
        
        // Upload the file to Firebase Storage
        const url = await uploadMediaFile(file);
        console.log("Successfully uploaded file to Firebase Storage:", url);
        return url;
      } catch (error) {
        console.error("Error uploading media file:", error);
        throw error;
      } finally {
        commit("SET_LOADING", false);
      }
    },
    
    async addMediaPost(
      { commit },
      payload
    ) {
      commit("SET_LOADING", true);
      try {
        const { 
          itemName, 
          caption, 
          images = [], 
          mediaType = "image", 
          owner, 
          organisation 
        } = payload;
        
        // Determine collection based on media type
        let collectionName = "images";
        if (mediaType === "video") {
          collectionName = "videos";
        } else if (mediaType === "audio") {
          collectionName = "audios";
        }
        
        console.log("Adding media post to collection:", collectionName);
        console.log("Media data:", { itemName, caption, mediaType, owner, organisation });
        console.log("Images:", images);
        
        // Prepare the document data using the same structure as products
        const mediaData = {
          itemName,
          caption,
          images: images,
          owner,
          organisation,
          createdAt: serverTimestamp(),
          mediaType: mediaType,
          type: "media",
          thumbnailIndex: payload.thumbnailIndex || 0
        };
        
        const docRef = await addDoc(collection(db, collectionName), mediaData);
        console.log("Media post added with ID:", docRef.id);
        return { success: true, id: docRef.id };
      } catch (error) {
        console.error("Error adding media post:", error);
        throw error;
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async fetchAllMediaPosts({ commit }) {
      commit("SET_LOADING", true);
      try {
        const imageSnap = await getDocs(collection(db, "images"));
        const videoSnap = await getDocs(collection(db, "videos"));
        const audioSnap = await getDocs(collection(db, "audios"));

        // Process images, ensuring they have the right fields
        const images = imageSnap.docs.map((doc) => {
          const data = doc.data();
          
          // Convert legacy structure to new structure if needed
          if (!data.images || data.images.length === 0) {
            // Handle legacy format with mediaUrl and additionalImages
            const allImages = [];
            
            if (data.mediaUrl) {
              allImages.push(data.mediaUrl);
            }
            
            if (data.additionalImages && Array.isArray(data.additionalImages)) {
              data.additionalImages.forEach(url => {
                if (!allImages.includes(url)) {
                  allImages.push(url);
                }
              });
            }
            
            data.images = allImages;
            data.thumbnailIndex = 0;
          }
          
          return {
            id: doc.id,
            ...data,
            type: "media" // Add type field for consistency with products
          };
        });
        
        // Process videos
        const videos = videoSnap.docs.map((doc) => {
          const data = doc.data();
          // Add type for consistency
          return {
            id: doc.id,
            ...data,
            type: "media"
          };
        });
        
        // Process audios
        const audios = audioSnap.docs.map((doc) => {
          const data = doc.data();
          // Add type for consistency
          return {
            id: doc.id,
            ...data,
            type: "media"
          };
        });

        const combined = [...images, ...videos, ...audios];
        commit("SET_MEDIA_POSTS", combined);
        
        console.log(`Fetched ${combined.length} media posts with standardized format`);
      } catch (error) {
        console.error("Error fetching media posts:", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },
  },
  mutations: {
    SET_LOADING(state, status) {
      state.loading = status;
    },
    SET_MEDIA_POSTS(state, posts) {
      state.mediaPosts = posts;
    },
    SET_CURRENT_MEDIA(state, media) {
      state.currentMedia = media;
    },
    CLEAR_CURRENT_MEDIA(state) {
      state.currentMedia = null;
    },
    
    // Optimistic update mutations
    ADD_OPTIMISTIC_MEDIA(state, item) {
      console.log("Adding optimistic media item to UI:", item);
      state.mediaPosts = [item, ...state.mediaPosts];
    },
    
    UPDATE_OPTIMISTIC_MEDIA(state, { tempId, realId }) {
      console.log(`Updating optimistic media item: ${tempId} -> ${realId}`);
      const index = state.mediaPosts.findIndex(item => item.id === tempId);
      if (index !== -1) {
        state.mediaPosts[index].id = realId;
        state.mediaPosts[index].isOptimistic = false;
      }
    },
    
    REMOVE_OPTIMISTIC_MEDIA(state, id) {
      console.log(`Removing optimistic media item: ${id}`);
      state.mediaPosts = state.mediaPosts.filter(item => item.id !== id);
    },
  },
};
