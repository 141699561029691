<template>
  <div class="column alignCentre fullWidth">
    <!-- Drag & Drop and Upload Button Area -->
    <div
      class="drop-area column alignCentre"
      @click="triggerFileInput"
      @dragover.prevent
      @dragenter.prevent="handleDragEnter"
      @dragleave.prevent="handleDragLeave"
      @drop.prevent="handleDrop"
    >
      <svg class="smaller">
        <use href="@/assets/icons/iconset.svg#upload"></use>
      </svg>
      <div class="alignCentre">
        <p class="nomargintopbottom">Drag your files here or click to upload</p>
        <div class="or">or</div>
        <button
          class="smallButton"
          type="button"
          @click.stop="triggerCameraInput"
          v-haptic
        >
          <svg class="compactImg">
            <use href="@/assets/icons/iconset.svg#camera_compact"></use>
          </svg>
          Capture image
        </button>
      </div>
    </div>

    <!-- Preview Area -->
    <div class="previewArea" v-if="imagePreviews.length > 0">
      <div class="thumbnail-and-grid">
        <!-- Thumbnail Image -->
        <div class="thumbnail-container">
          <div
            class="image-container thumbnail"
            @click="setThumbnail(thumbnailImage.originalIndex)"
          >
            <div class="imageActionsCont innerSticker" @click.stop>
              <!-- Thumbnail Icon -->
              <svg
                v-if="thumbnailImage.originalIndex === localThumbnailIndex"
                class="smallEdit compactImg"
              >
                <use href="@/assets/icons/iconset.svg#thumbnail_compact"></use>
              </svg>
              <!-- Edit and Remove Icons -->
              <svg
                class="smallEdit compactImg"
                v-haptic
                @click="editImage(thumbnailImage.originalIndex)"
              >
                <use href="@/assets/icons/iconset.svg#edit_compact"></use>
              </svg>
              <svg
                class="smallClose compactImg"
                v-haptic
                @click="removeImage(thumbnailImage.originalIndex)"
              >
                <use href="@/assets/icons/iconset.svg#trash_compact"></use>
              </svg>
            </div>
            <img class="previewedImg" :src="thumbnailImage.src" alt="" />
          </div>
        </div>
        <!-- Other Images Grid -->
        <div class="images-grid">
          <div
            v-for="(image, index) in otherImages"
            :key="image.originalIndex"
            class="image-container grid-image"
            @click="setThumbnail(image.originalIndex)"
          >
            <div class="imageActionsCont innerSticker" @click.stop>
              <svg
                v-if="image.originalIndex === localThumbnailIndex"
                class="smallEdit compactImg"
              >
                <use href="@/assets/icons/iconset.svg#thumbnail_compact"></use>
              </svg>
              <svg
                class="smallEdit compactImg"
                v-haptic
                @click="editImage(image.originalIndex)"
              >
                <use href="@/assets/icons/iconset.svg#edit_compact"></use>
              </svg>
              <svg
                class="smallClose compactImg"
                v-haptic
                @click="removeImage(image.originalIndex)"
              >
                <use href="@/assets/icons/iconset.svg#trash_compact"></use>
              </svg>
            </div>
            <img class="previewedImg" :src="image.src" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- Cropper -->
    <Overlay
      v-if="isCropping"
      :visible="isCropping"
      :title="'Crop image'"
      @close="closeOverlay"
    >
      <template #before-buttons>
        <ImageCrop
          v-if="currentImageIndex !== null"
          :imageSrc="currentImageSrc"
          :currentIndex="currentImageIndex"
          @updateImage="
            (newImageData) => updateImage(newImageData, currentImageIndex)
          "
          @cancelCrop="isCropping = false"
        />
      </template>
    </Overlay>

    <!-- Hidden Inputs -->
    <input
      type="file"
      accept="image/*"
      multiple
      @change="handleFileSelection"
      ref="fileInput"
      style="display: none"
    />
    <input
      type="file"
      accept="image/*"
      multiple
      capture="environment"
      @change="handleFileSelection"
      ref="cameraInput"
      style="display: none"
    />
  </div>
</template>

<script>
import { ref, watch, computed } from "vue";

import ImageCrop from "@/components/GenericComponents/ImageCrop.vue";
import Overlay from "@/components/GenericComponents/Overlay.vue";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";

export default {
  name: "ImageUpload",
  components: {
    Overlay,
    ImageCrop,
  },
  props: {
    existingImages: {
      type: Array,
      default: () => [],
    },
    newImages: {
      type: Array,
      default: () => [],
    },
    thumbnailIndex: {
      type: Number,
      default: 0,
    },
  },
  emits: ["updateImages", "updateThumbnail"],

  setup(props, { emit }) {
    const store = useStore();
    const fileInput = ref(null);
    const cameraInput = ref(null);
    const existingImages = ref([...props.existingImages]);
    const selectedFiles = ref([...props.newImages]);
    const localThumbnailIndex = ref(props.thumbnailIndex);
    const editedImages = ref([]); // Stores objects { oldUrl, newFile }
    const removedImageUrls = ref([]); // Stores URLs of images to delete
    const editedImageUrls = ref(new Map()); // Map from oldUrl to blobUrl

    watch(
      () => props.existingImages,
      (newVal) => {
        existingImages.value = [...newVal];
      }
    );

    watch(
      () => props.newImages,
      (newVal) => {
        if (Array.isArray(newVal)) {
          selectedFiles.value = [...newVal];
        }
      },
      { immediate: true }
    );

    watch(
      () => props.thumbnailIndex,
      (newIndex) => {
        localThumbnailIndex.value = newIndex;
      }
    );

    const fileToUrlMap = new Map();
    const imagePreviews = computed(() => {
      // Use Sets to track URLs and prevent duplicates
      const urlSet = new Set();
      const uniqueImages = [];
      
      // Process existing images
      existingImages.value.forEach((item, idx) => {
        let displayUrl;
        if (typeof item === 'string') {
          // It's a URL string
          displayUrl = editedImageUrls.value.get(item) || item;
        } else if (item && item.url && typeof item.url === 'string') {
          // It's an object with URL property
          displayUrl = editedImageUrls.value.get(item.url) || item.url;
        } else {
          console.warn("[ImageUpload] Unknown format for existing image:", item);
          displayUrl = ''; // Default empty URL
        }
        
        // Only add if this URL hasn't been seen before
        if (displayUrl && !urlSet.has(displayUrl)) {
          urlSet.add(displayUrl);
          uniqueImages.push({ src: displayUrl, originalIndex: idx });
        }
      });

      // Process new files (creating blob URLs as needed)
      selectedFiles.value.forEach((file, idx) => {
        const originalIndex = idx + existingImages.value.length;
        let src = '';
        
        if (file instanceof File) {
          // For File objects, create and cache object URLs
          if (!fileToUrlMap.has(file)) {
            const objectUrl = URL.createObjectURL(file);
            fileToUrlMap.set(file, objectUrl);
          }
          src = fileToUrlMap.get(file);
        } else if (typeof file === 'string') {
          // For string URLs, use them directly
          src = file;
        } else if (file && file.url && typeof file.url === 'string') {
          // For file-like objects with URL properties
          src = file.url;
        } else {
          console.warn("[ImageUpload] Unknown format for selected file:", file);
          return; // Skip invalid entries
        }
        
        // Only add if this URL hasn't been seen before
        if (src && !urlSet.has(src)) {
          urlSet.add(src);
          uniqueImages.push({ src, originalIndex });
        }
      });

      // Create a mutable array from uniqueImages
      let processedImages = [...uniqueImages]; // Start with deduplicated images

      // Check if localThumbnailIndex.value is valid
      if (
        localThumbnailIndex.value === null ||
        localThumbnailIndex.value >= processedImages.length
      ) {
        if (processedImages.length > 0) {
          localThumbnailIndex.value = 0;
          emit("updateThumbnail", 0);
        } else {
          localThumbnailIndex.value = null;
          emit("updateThumbnail", null);
        }
      }

      // For debugging purposes, log the full state
      console.log("[ImageUpload] uniqueImages:", JSON.stringify(uniqueImages.map(img => ({
        originalIndex: img.originalIndex,
        src: img.src.substring(0, 50) + '...' // truncate for readability
      }))));
      console.log("[ImageUpload] localThumbnailIndex:", localThumbnailIndex.value);
      
      // Simple direct approach - don't try to reorder, just return the images as is
      // The parent component will handle the reordering in handleThumbnailUpdate
      // This avoids issues with mismatched indexes
      
      // Return the final processed array
      const combinedImages = processedImages;

      console.log("[ImageUpload] Processed preview images:", combinedImages.map(img => img.src));
      return combinedImages;
    });

    // Move the definitions of thumbnailImage and otherImages here
    const thumbnailImage = computed(() => {
      try {
        // Safely return the first image or null if there are no images
        return imagePreviews.value && imagePreviews.value.length > 0 
          ? imagePreviews.value[0] 
          : null;
      } catch (err) {
        console.error("[ImageUpload] Error getting thumbnailImage:", err);
        return null;
      }
    });

    const otherImages = computed(() => {
      try {
        // Safely return all images except the first, or an empty array
        return imagePreviews.value && imagePreviews.value.length > 1 
          ? imagePreviews.value.slice(1)
          : [];
      } catch (err) {
        console.error("[ImageUpload] Error getting otherImages:", err);
        return [];
      }
    });

    // Watch to ensure localThumbnailIndex remains valid
    watch(
      () => existingImages.value.length + selectedFiles.value.length,
      (newTotal) => {
        if (
          localThumbnailIndex.value !== null &&
          localThumbnailIndex.value >= newTotal
        ) {
          localThumbnailIndex.value = newTotal > 0 ? newTotal - 1 : null;
          emit("updateThumbnail", localThumbnailIndex.value);
        }
      }
    );

    const isCropping = ref(false);
    const currentImageSrc = ref(null);
    const currentImageIndex = ref(null);

    const setThumbnail = (originalIndex) => {
      try {
        console.log("[ImageUpload] setThumbnail called with image at index:", originalIndex);
        
        // Find the actual image from imagePreviews
        const selectedImage = imagePreviews.value.find(img => img.originalIndex === originalIndex);
        
        if (selectedImage) {
          // Get the URL of the selected image
          const imageUrl = selectedImage.src;
          console.log("[ImageUpload] Selected image URL:", imageUrl);
          
          // Emit the URL to the parent component
          emit("updateThumbnail", imageUrl);
          
          // IMPORTANT: Also update our local display by swapping the selected image to first position
          const selectedItemIndex = imagePreviews.value.findIndex(img => img.src === imageUrl);
          if (selectedItemIndex > 0) {
            // Get references to the arrays we need to modify
            if (typeof existingImages.value === 'object' && Array.isArray(existingImages.value)) {
              // Make a copy to avoid reactivity issues
              const tempExisting = [...existingImages.value];
              
              // If it's in the existing images, swap there
              const existingIdx = tempExisting.findIndex(
                item => typeof item === 'string' ? item === imageUrl : 
                        item?.url === imageUrl
              );
              
              if (existingIdx > 0) {
                // Swap the selected item to first position
                [tempExisting[0], tempExisting[existingIdx]] = [tempExisting[existingIdx], tempExisting[0]];
                existingImages.value = tempExisting;
              }
            }
            
            if (typeof selectedFiles.value === 'object' && Array.isArray(selectedFiles.value)) {
              // Also check if it's in selectedFiles and swap there
              const selectedIdx = selectedFiles.value.findIndex(
                item => typeof item === 'string' ? item === imageUrl : 
                        fileToUrlMap.get(item) === imageUrl
              );
              
              if (selectedIdx >= 0) {
                // Make a copy
                const tempSelected = [...selectedFiles.value];
                // Swap to first position
                [tempSelected[0], tempSelected[selectedIdx]] = [tempSelected[selectedIdx], tempSelected[0]];
                selectedFiles.value = tempSelected;
              }
            }
          }
          
          // Success message
          toast.success("Thumbnail updated", {
            autoClose: 1000,
          });
        } else {
          console.error("[ImageUpload] Could not find image with index:", originalIndex);
        }
      } catch (err) {
        console.error("[ImageUpload] Error setting thumbnail:", err);
      }
    };

    const closeOverlay = async () => {
      document.body.classList.remove("no-scroll");
      isCropping.value = false;
      store.dispatch("toggleNavbarVisibility", true); // Show the Navbar
    };

    const triggerFileInput = () => {
      fileInput.value.click();
    };

    const triggerCameraInput = () => {
      cameraInput.value.click();
    };

    const handleFileSelection = async (event) => {
      const newFiles = event.target.files ? Array.from(event.target.files) : [];
      const existingFileNames = new Set(
        selectedFiles.value.map((file) => file.name)
      );
      const uniqueNewFiles = newFiles.filter(
        (file) => !existingFileNames.has(file.name)
      );

      console.log("New files selected:", newFiles);
      console.log("Unique new files to be added:", uniqueNewFiles);

      const processedFiles = await Promise.all(
        uniqueNewFiles.map((file) => processImageFile(file))
      );

      selectedFiles.value = [...selectedFiles.value, ...processedFiles];
      emitUpdateImages();
      event.target.value = "";
    };

    const processImageFile = (file) => {
      return new Promise((resolve, reject) => {
        // If the file is an animated GIF, do not process it via canvas
        if (file.type === "image/gif") {
          resolve(file);
          return;
        }
        const maxWidth = 1920;
        const maxHeight = 1920;
        const reader = new FileReader();

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            let { width, height } = img;
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob((blob) => {
              const processedFile = new File([blob], file.name, {
                type: file.type,
              });
              resolve(processedFile);
            }, file.type);
          };
        };

        reader.onerror = (err) => {
          reject(err);
        };

        reader.readAsDataURL(file);
      });
    };

    const emitUpdateImages = () => {
      const updatedImages = [...existingImages.value, ...selectedFiles.value];
      const imagePreviewUrls = imagePreviews.value.map((img) => img.src);
      emit(
        "updateImages",
        updatedImages,
        removedImageUrls.value,
        editedImages.value,
        imagePreviewUrls // Add this parameter
      );
    };

    const handleDragEnter = (event) => {
      event.target.classList.add("drag-over");
    };

    const handleDragLeave = (event) => {
      event.target.classList.remove("drag-over");
    };

    const handleDrop = (event) => {
      const newFiles = [...event.dataTransfer.files];
      console.log("Files dropped:", newFiles);
      handleFileSelection({ target: { files: newFiles } });
    };

    const removeImage = (originalIndex) => {
      console.log("Removing image with original index:", originalIndex);

      // Determine if the image is in existingImages or selectedFiles
      if (originalIndex < existingImages.value.length) {
        const removedUrl = existingImages.value.splice(originalIndex, 1)[0];
        removedImageUrls.value.push(removedUrl);
        console.log("Removed URL from existing images:", removedUrl);
      } else {
        const newImageIndex = originalIndex - existingImages.value.length;
        const removedFile = selectedFiles.value.splice(newImageIndex, 1)[0];
        const removedObjectUrl = fileToUrlMap.get(removedFile);
        fileToUrlMap.delete(removedFile);
        URL.revokeObjectURL(removedObjectUrl);
        console.log("Removed file from new images:", removedFile);
      }

      // Adjust localThumbnailIndex if necessary
      const totalImages =
        existingImages.value.length + selectedFiles.value.length;

      if (localThumbnailIndex.value === originalIndex) {
        // The thumbnail image was removed
        if (totalImages > 0) {
          // Set thumbnail to the image at the same position (which shifted into this index)
          if (originalIndex >= totalImages) {
            // If originalIndex is out of bounds, set to last image
            localThumbnailIndex.value = totalImages - 1;
          } else {
            localThumbnailIndex.value = originalIndex;
          }
        } else {
          localThumbnailIndex.value = null; // No images left
        }
        emit("updateThumbnail", localThumbnailIndex.value);
      } else if (originalIndex < localThumbnailIndex.value) {
        // Adjust localThumbnailIndex because the indexes shifted
        localThumbnailIndex.value -= 1;
      }

      emitUpdateImages();
    };

    const editImage = (originalIndex) => {
      document.body.classList.add("no-scroll");
      isCropping.value = true;
      // Find the image src based on originalIndex
      const imageObj = imagePreviews.value.find(
        (img) => img.originalIndex === originalIndex
      );
      if (imageObj) {
        currentImageSrc.value = imageObj.src;
        currentImageIndex.value = originalIndex;
        store.dispatch("toggleNavbarVisibility", false); // Hide the Navbar
        console.log("Editing image at index:", originalIndex);
      } else {
        console.error("Image not found for editing");
      }
    };

    const updateImage = ({ file, url }, originalIndex) => {
      if (originalIndex < existingImages.value.length) {
        const oldUrl = existingImages.value[originalIndex];
        editedImages.value.push({ oldUrl, newFile: file });
        editedImageUrls.value.set(oldUrl, url); // Store blob URL for display
      } else {
        const newImageIndex = originalIndex - existingImages.value.length;
        selectedFiles.value[newImageIndex] = file;
        fileToUrlMap.set(selectedFiles.value[newImageIndex], url);
      }
      emitUpdateImages();
      closeOverlay();
    };

    return {
      fileInput,
      cameraInput,
      triggerFileInput,
      triggerCameraInput,
      handleFileSelection,
      imagePreviews,
      thumbnailImage,
      otherImages,
      handleDragEnter,
      handleDragLeave,
      handleDrop,
      removeImage,
      isCropping,
      editImage,
      updateImage,
      currentImageSrc,
      closeOverlay,
      currentImageIndex,
      localThumbnailIndex,
      setThumbnail,
      removedImageUrls,
      processImageFile,
    };
  },
};
</script>

<style scoped>
.drop-area {
  border: 1px dashed var(--solidMidDarkgrey);
  padding: var(--margin);
  text-align: center;
  border-radius: var(--borderRadius);
  width: 100%;
  cursor: pointer;
}

.drop-area.drag-over {
  border-color: green;
  background-color: var(--lightGreen);
}

.previewArea {
  width: 100%;
  overflow-x: auto;
  padding: var(--smallMargin);
  box-shadow: var(--neuMorphBoxInner);
  border-radius: var(--borderRadiusEvenBigger);
}

.thumbnail-and-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.thumbnail-container {
  flex: 0 0 50%;
  box-sizing: border-box;
}

.thumbnail {
  position: relative;
  width: 100%;
  padding-top: 100%; /* Maintain aspect ratio */
  overflow: hidden;
  border-radius: var(--borderRadiusBigger);
}

.thumbnail .previewedImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.images-grid {
  flex: 0 0 50%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: calc(50% - var(--smallerMargin) / 2);
  grid-template-rows: repeat(2, 1fr);
  gap: var(--smallerMargin);
  box-sizing: border-box;
  padding-left: var(--smallMargin);
}

.grid-image {
  position: relative;
  width: 100%;
  padding-top: calc(
    100% - var(--smallerMargin)
  ); /* Adjust height to prevent overflow */
  overflow: hidden;
  border-radius: var(--borderRadiusBigger);
}

.grid-image .previewedImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-container {
  position: relative;
}

svg.smallClose {
  position: initial;
}

.smallEdit {
  top: var(--tinyMargin);
  transition: all 0.15s;
  opacity: 0.8;
}

.smallEdit:hover {
  opacity: 1;
  transform: translateY(1px);
}

.previewedImg {
  object-fit: cover;
  width: 100%;
}

.alignCentre {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
