import { db } from "@/firebase";
import { runTransaction, doc, getDoc, collection } from "firebase/firestore";
import { calculateAmountForReceiver } from "./exchangeRateCalculator";

/**
 * Performs a clap transfer by updating both the sender's and receiver's wallets in one transaction.
 * It creates a moneyOut pack for the sender and a corresponding moneyIn pack for the receiver,
 * filling all fields from MoneyPackSchema with fallback values. In addition, it fetches the
 * users' display names from the "users" collection so that the transaction includes the sender's name.
 *
 * @param {string} senderUid - The sender's user ID.
 * @param {string} senderOrgUID - The sender's organization UID.
 * @param {string} receiverUid - The receiver's user ID.
 * @param {string} receiverOrgUID - The receiver's organization UID.
 * @param {number} senderAmount - Amount to deduct from the sender.
 * @param {number} [receiverAmount] - Optional: Amount to add to the receiver. If not provided, it will be calculated.
 * @returns {Promise<{newSenderBalance: number, newReceiverBalance: number}>} The new balances.
 */
export async function transferClapMoney(
  senderUid,
  senderOrgUID,
  receiverUid,
  receiverOrgUID,
  senderAmount,
  receiverAmount = null // Make receiverAmount explicitly optional with null default
) {
  console.log("[transferClapMoney] Starting transaction");
  console.log(
    "[transferClapMoney] EXACT VALUES - senderAmount:",
    senderAmount,
    "receiverAmount (if provided):",
    receiverAmount,
    "receiverAmount type:",
    typeof receiverAmount
  );
  
  return await runTransaction(db, async (transaction) => {
    // Get sender wallet document
    const senderWalletRef = doc(db, "users", senderUid, "Wallets", senderOrgUID);
    const senderSnap = await transaction.get(senderWalletRef);
    if (!senderSnap.exists()) throw new Error("Sender wallet does not exist");
    const senderData = senderSnap.data();
    console.log(
      "[transferClapMoney] Sender wallet initial balance:",
      senderData.currentAccount.balance
    );

    // Get sender organization data to calculate exchange rate
    let senderOrgSnap = await transaction.get(doc(db, "organisations", senderOrgUID));
    
    // If not found in "organisations", try "custom-orgs"
    if (!senderOrgSnap.exists()) {
      console.log("[transferClapMoney] Organization not found in 'organisations', trying 'custom-orgs'");
      senderOrgSnap = await transaction.get(doc(db, "custom-orgs", senderOrgUID));
    }
    
    if (!senderOrgSnap.exists()) throw new Error("Sender organization does not exist in any collection");
    const senderOrgData = senderOrgSnap.data();
    
    // Get receiver wallet document
    const receiverWalletRef = doc(
      db,
      "users",
      receiverUid,
      "Wallets",
      receiverOrgUID
    );
    const receiverSnap = await transaction.get(receiverWalletRef);
    if (!receiverSnap.exists())
      throw new Error("Receiver wallet does not exist");
    const receiverData = receiverSnap.data();
    console.log(
      "[transferClapMoney] Receiver wallet initial balance:",
      receiverData.currentAccount.balance
    );
    
    // Get receiver organization data to calculate exchange rate
    let receiverOrgSnap = await transaction.get(doc(db, "organisations", receiverOrgUID));
    
    // If not found in "organisations", try "custom-orgs"
    if (!receiverOrgSnap.exists()) {
      console.log("[transferClapMoney] Receiver organization not found in 'organisations', trying 'custom-orgs'");
      receiverOrgSnap = await transaction.get(doc(db, "custom-orgs", receiverOrgUID));
    }
    
    if (!receiverOrgSnap.exists()) throw new Error("Receiver organization does not exist in any collection");
    const receiverOrgData = receiverOrgSnap.data();

    // Calculate exchange rate and receiver amount if not provided
    console.log("[transferClapMoney] Checking receiverAmount:", receiverAmount, "type:", typeof receiverAmount);
    let finalReceiverAmount = receiverAmount;
    // Check if receiverAmount is provided and valid (not null, undefined, NaN, or 0)
    if (receiverAmount === null || receiverAmount === undefined || isNaN(receiverAmount) || receiverAmount <= 0) {
      console.log("[transferClapMoney] Need to calculate receiverAmount as it's not valid:", receiverAmount);
      // Get member counts - use members array if exists or fallback to memberCount property
      const senderMembersCount = senderOrgData.members?.length || senderOrgData.memberCount || 1;
      const receiverMembersCount = receiverOrgData.members?.length || receiverOrgData.memberCount || 1;
      
      // Check for totalCoins in different possible locations
      let senderTotalCoins = 0;
      if (senderOrgData.monetary_parameters?.totalCoins) {
        senderTotalCoins = senderOrgData.monetary_parameters.totalCoins;
      } else if (senderOrgData.totalCoins) {
        senderTotalCoins = senderOrgData.totalCoins;
      } else if (senderData.currentAccount?.balance) {
        // Fallback to wallet balance if no total coins found
        // This assumes all coins in organization are in wallets
        senderTotalCoins = senderData.currentAccount.balance * senderMembersCount;
      }
      
      let receiverTotalCoins = 0;
      if (receiverOrgData.monetary_parameters?.totalCoins) {
        receiverTotalCoins = receiverOrgData.monetary_parameters.totalCoins;
      } else if (receiverOrgData.totalCoins) {
        receiverTotalCoins = receiverOrgData.totalCoins;
      } else if (receiverData.currentAccount?.balance) {
        // Fallback to wallet balance if no total coins found
        receiverTotalCoins = receiverData.currentAccount.balance * receiverMembersCount;
      }
      
      console.log("[transferClapMoney] Exchange rate calculation data:", {
        senderMembersCount,
        receiverMembersCount,
        senderTotalCoins,
        receiverTotalCoins,
        senderAmount
      });
      
      // Only calculate if we have valid numbers
      if (senderMembersCount > 0 && receiverMembersCount > 0 && senderTotalCoins > 0 && receiverTotalCoins > 0) {
        // Calculate per-capita values
        const senderPerCapita = senderTotalCoins / senderMembersCount;
        const receiverPerCapita = receiverTotalCoins / receiverMembersCount;
        
        // Calculate exchange rate directly (receiver per-capita / sender per-capita)
        const exchangeRate = receiverPerCapita / senderPerCapita;
        
        // Apply exchange rate to convert sender amount to receiver amount
        // No multiplier - just use the exchange rate directly
        finalReceiverAmount = senderAmount * exchangeRate;
        
        console.log(
          "[transferClapMoney] Calculated receiver amount using exchange rate:",
          finalReceiverAmount,
          "exchange rate:",
          exchangeRate
        );
      } else {
        // Fall back to 1:1 ratio if any values are missing or invalid
        console.error("[transferClapMoney] Missing required data for exchange rate calculation!");
        finalReceiverAmount = senderAmount; // Use 1:1 ratio as fallback
        console.log(
          "[transferClapMoney] Using fallback 1:1 ratio due to missing data for exchange rate calculation"
        );
      }
    }

    // Fetch user documents to get their display names.
    const senderUserRef = doc(db, "users", senderUid);
    const senderUserSnap = await transaction.get(senderUserRef);
    const senderName = senderUserSnap.exists()
      ? senderUserSnap.data().displayName || ""
      : "";
    const receiverUserRef = doc(db, "users", receiverUid);
    const receiverUserSnap = await transaction.get(receiverUserRef);
    const receiverName = receiverUserSnap.exists()
      ? receiverUserSnap.data().displayName || ""
      : "";

    // Verify the sender has enough funds.
    if (senderData.currentAccount.balance < senderAmount) {
      console.error(
        "[transferClapMoney] Insufficient sender balance:",
        senderData.currentAccount.balance,
        "needed:",
        senderAmount
      );
      throw new Error("Insufficient sender balance");
    }

    // Generate unique IDs using the same pattern as in your Cloud Functions.
    const senderMoneyPackId = doc(collection(db, "coins")).id;
    const receiverMoneyPackId = doc(collection(db, "coins")).id;
    const currentTimestamp = Date.now();
    
    console.log(
      "[transferClapMoney] Generated IDs:",
      senderMoneyPackId,
      receiverMoneyPackId
    );

    // Create a moneyOut pack for the sender.
    const senderMoneyPack = {
      id: senderMoneyPackId,
      moneyPackType: "moneyOut",
      transactionType: `👏 to ${receiverName || receiverUid}`,
      value: senderAmount,
      transactionTime: currentTimestamp,
      lastUpdatedTime: currentTimestamp,
      lifespan: senderData.monetary_parameters?.current_account?.lifespan || 365,
      remainingLifespanByWallet: {
        [senderOrgUID]:
          senderData.monetary_parameters?.current_account?.lifespan || 365,
      },
      optionalFields: {
        fromOrgName: senderData.monetary_parameters?.coin_name || "",
        fromOrgUID: senderOrgUID,
        toOrgName: receiverData.monetary_parameters?.coin_name || "",
        toOrgUID: receiverOrgUID,
        fromUser: senderUid,
        fromUserName: senderName,
        toUser: receiverUid,
        toUserName: receiverName,
        toAccountName: "current_account",
      },
    };

    // Final safety check to ensure receiver amount is valid
    if (finalReceiverAmount === null || finalReceiverAmount === undefined || isNaN(finalReceiverAmount)) {
      console.error("[transferClapMoney] Final receiver amount is still invalid!");
      finalReceiverAmount = senderAmount; // Use a default 1:1 ratio as final fallback
      console.log("[transferClapMoney] Using 1:1 ratio fallback due to invalid calculation result");
    }
    
    // Ensure the finalReceiverAmount is a number
    finalReceiverAmount = Number(finalReceiverAmount);
    console.log("[transferClapMoney] Final validated receiver amount:", finalReceiverAmount);
    
    // Create a moneyIn pack for the receiver.
    const receiverMoneyPack = {
      id: receiverMoneyPackId,
      moneyPackType: "moneyIn",
      transactionType: `👏 from ${senderName || senderUid}`,
      value: finalReceiverAmount,
      transactionTime: currentTimestamp,
      lastUpdatedTime: currentTimestamp,
      lifespan:
        receiverData.monetary_parameters?.current_account?.lifespan || 365,
      remainingLifespanByWallet: {
        [receiverOrgUID]:
          receiverData.monetary_parameters?.current_account?.lifespan || 365,
      },
      optionalFields: {
        fromOrgName: senderData.monetary_parameters?.coin_name || "",
        fromOrgUID: senderOrgUID,
        toOrgName: receiverData.monetary_parameters?.coin_name || "",
        toOrgUID: receiverOrgUID,
        fromUser: senderUid,
        fromUserName: senderName,
        toUser: receiverUid,
        toUserName: receiverName,
        toAccountName: "current_account",
      },
    };

    console.log("[transferClapMoney] Sender money pack:", senderMoneyPack);
    console.log("[transferClapMoney] Receiver money pack:", receiverMoneyPack);

    // Update sender's wallet: deduct senderAmount and append the moneyOut pack.
    const newSenderBalance = senderData.currentAccount.balance - senderAmount;
    senderData.currentAccount.balance = newSenderBalance;
    senderData.currentAccount.moneyOut.push(senderMoneyPack);
    console.log(
      "[transferClapMoney] New sender balance calculated:",
      newSenderBalance
    );

    // Update receiver's wallet: add finalReceiverAmount and append the moneyIn pack.
    const newReceiverBalance =
      receiverData.currentAccount.balance + finalReceiverAmount;
    receiverData.currentAccount.balance = newReceiverBalance;
    receiverData.currentAccount.moneyIn.push(receiverMoneyPack);
    console.log(
      "[transferClapMoney] New receiver balance calculated:",
      newReceiverBalance
    );

    // Commit updates.
    transaction.update(senderWalletRef, {
      currentAccount: senderData.currentAccount,
    });
    transaction.update(receiverWalletRef, {
      currentAccount: receiverData.currentAccount,
    });
    console.log("[transferClapMoney] Transaction committed.");
    return { newSenderBalance, newReceiverBalance };
  });
}

// For backward compatibility
export const transferBuyerReceiverMoney = transferClapMoney;
